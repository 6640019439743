export const ApoCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 24.01,
    prev_app_rate: 36.95,
    round_eidolons: 3465,
    current_avg_round: 3320,
    prev_avg_round: 3343
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.02,
    prev_app_rate: 0.71,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3356
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.02,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.73,
    prev_app_rate: 0.88,
    round_eidolons: 3464,
    current_avg_round: 3237,
    prev_avg_round: 3288
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 44.57,
    prev_app_rate: 41.26,
    round_eidolons: 3663,
    current_avg_round: 3563,
    prev_avg_round: 3469
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 0.74,
    prev_app_rate: 2.15,
    round_eidolons: 3350,
    current_avg_round: 3375,
    prev_avg_round: 3336
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 2.93,
    prev_app_rate: 25.7,
    round_eidolons: 3322,
    current_avg_round: 3214,
    prev_avg_round: 3370
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 0.62,
    prev_app_rate: 4.43,
    round_eidolons: 3384,
    current_avg_round: 3188,
    prev_avg_round: 3286
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 2.95,
    prev_app_rate: 7.54,
    round_eidolons: 3454,
    current_avg_round: 3368,
    prev_avg_round: 3456
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 6.64,
    prev_app_rate: 22.22,
    round_eidolons: 3561,
    current_avg_round: 3415,
    prev_avg_round: 3411
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 1.36,
    prev_app_rate: 1.3,
    round_eidolons: 3310,
    current_avg_round: 3267,
    prev_avg_round: 3279
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.06,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 9.94,
    prev_app_rate: 3.09,
    round_eidolons: 3466,
    current_avg_round: 3356,
    prev_avg_round: 3322
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 61.33,
    prev_app_rate: 52.25,
    round_eidolons: 3683,
    current_avg_round: 3553,
    prev_avg_round: 3516
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    rarity: '4*',
    current_app_rate: 0.07,
    prev_app_rate: 0.07,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 62.88,
    prev_app_rate: 50.5,
    round_eidolons: 3569,
    current_avg_round: 3424,
    prev_avg_round: 3416
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 25.06,
    prev_app_rate: 32.72,
    round_eidolons: 3446,
    current_avg_round: 3404,
    prev_avg_round: 3352
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 59.65,
    prev_app_rate: 66.1,
    round_eidolons: 3482,
    current_avg_round: 3392,
    prev_avg_round: 3422
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 0.86,
    prev_app_rate: 2.29,
    round_eidolons: 3396,
    current_avg_round: 3308,
    prev_avg_round: 3372
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 2.4,
    prev_app_rate: 1.32,
    round_eidolons: 3422,
    current_avg_round: 3291,
    prev_avg_round: 3272
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.14,
    prev_app_rate: 0.23,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3282
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 2.92,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3428
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 11,
    prev_app_rate: 1.58,
    round_eidolons: 3456,
    current_avg_round: 3234,
    prev_avg_round: 3330
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.05,
    prev_app_rate: 0.04,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 15.43,
    prev_app_rate: 29.03,
    round_eidolons: 3466,
    current_avg_round: 3402,
    prev_avg_round: 3367
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    rarity: '4*',
    current_app_rate: 85.38,
    prev_app_rate: 55.03,
    round_eidolons: 3561,
    current_avg_round: 3425,
    prev_avg_round: 3410
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 4.56,
    prev_app_rate: 4.18,
    round_eidolons: 3385,
    current_avg_round: 3128,
    prev_avg_round: 3314
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 4.19,
    prev_app_rate: 1.91,
    round_eidolons: 3480,
    current_avg_round: 3313,
    prev_avg_round: 3374
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 16.79,
    prev_app_rate: 20.2,
    round_eidolons: 3531,
    current_avg_round: 3363,
    prev_avg_round: 3352
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 0.96,
    prev_app_rate: 3.24,
    round_eidolons: 3263,
    current_avg_round: 3199,
    prev_avg_round: 3311
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 0.02,
    prev_app_rate: 15.19,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3346
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 5.46,
    prev_app_rate: 24.04,
    round_eidolons: 3354,
    current_avg_round: 3236,
    prev_avg_round: 3380
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 36.16,
    prev_app_rate: 0,
    round_eidolons: 3659,
    current_avg_round: 3546,
    prev_avg_round: 3546
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.05,
    prev_app_rate: 0.26,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3336
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 8.83,
    prev_app_rate: 16.03,
    round_eidolons: 3443,
    current_avg_round: 3410,
    prev_avg_round: 3374
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.56,
    prev_app_rate: 1.16,
    round_eidolons: 3443,
    current_avg_round: 3371,
    prev_avg_round: 3323
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.17,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - Hunt',
    rarity: '4*',
    current_app_rate: 31.41,
    prev_app_rate: 25.59,
    round_eidolons: 3586,
    current_avg_round: 3507,
    prev_avg_round: 3367
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.21,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 2.77,
    prev_app_rate: 5.29,
    round_eidolons: 3539,
    current_avg_round: 3436,
    prev_avg_round: 3452
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.05,
    prev_app_rate: 0.29,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3381
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 6.26,
    prev_app_rate: 16.78,
    round_eidolons: 3360,
    current_avg_round: 3334,
    prev_avg_round: 3312
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.37,
    prev_app_rate: 0.19,
    round_eidolons: 3250,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'rappa',
    name: 'Rappa',
    rarity: 'Limited 5*',
    current_app_rate: 15.7,
    prev_app_rate: 15.7,
    round_eidolons: 3655,
    current_avg_round: 3523,
    prev_avg_round: 0
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 74.88,
    prev_app_rate: 62.45,
    round_eidolons: 3644,
    current_avg_round: 3520,
    prev_avg_round: 3476
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 89.76,
    prev_app_rate: 81.52,
    round_eidolons: 3553,
    current_avg_round: 3427,
    prev_avg_round: 3410
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.79,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3295
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.29,
    prev_app_rate: 0.91,
    round_eidolons: 3325,
    current_avg_round: 0,
    prev_avg_round: 3342
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.23,
    prev_app_rate: 0.11,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 7.79,
    prev_app_rate: 7.87,
    round_eidolons: 3346,
    current_avg_round: 3277,
    prev_avg_round: 3294
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 18.03,
    prev_app_rate: 26.18,
    round_eidolons: 3449,
    current_avg_round: 3294,
    prev_avg_round: 3321
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.15,
    prev_app_rate: 0.23,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 5.69,
    prev_app_rate: 10.03,
    round_eidolons: 3396,
    current_avg_round: 3281,
    prev_avg_round: 3320
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 39.9,
    prev_app_rate: 25.07,
    round_eidolons: 3676,
    current_avg_round: 3547,
    prev_avg_round: 3506
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.64,
    prev_app_rate: 0.28,
    round_eidolons: 3392,
    current_avg_round: 3233,
    prev_avg_round: 0
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.2,
    prev_app_rate: 0.02,
    round_eidolons: 3265,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0,
    prev_app_rate: 0.72,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3280
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.09,
    prev_app_rate: 0.13,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 9.28,
    prev_app_rate: 8.55,
    round_eidolons: 3517,
    current_avg_round: 3372,
    prev_avg_round: 3364
  }
];

export const ApoDuos = [
  {
    char: 'ruan-mei',
    app: 89.76,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '91.34%',
    avg_round_1: 3432,
    app_flat_1: 3596,
    char_2: 'Firefly',
    app_rate_2: '67.82%',
    avg_round_2: 3428,
    app_flat_2: 2321,
    char_3: 'Gallagher',
    app_rate_3: '51.81%',
    avg_round_3: 3388,
    app_flat_3: 2448,
    char_4: 'Lingsha',
    app_rate_4: '33.5%',
    avg_round_4: 3548,
    app_flat_4: 1083,
    char_5: 'Rappa',
    app_rate_5: '16.2%',
    avg_round_5: 3533,
    app_flat_5: 800,
    char_6: 'Himeko',
    app_rate_6: '9.58%',
    avg_round_6: 3240,
    app_flat_6: 341,
    char_7: 'Acheron',
    app_rate_7: '3.08%',
    avg_round_7: 3252,
    app_flat_7: 29,
    char_8: 'Boothill',
    app_rate_8: '2.57%',
    avg_round_8: 3400,
    app_flat_8: 122,
    char_9: 'Jiaoqiu',
    app_rate_9: '2.25%',
    avg_round_9: 3300,
    app_flat_9: 25,
    char_10: 'Huohuo',
    app_rate_10: '2.22%',
    avg_round_10: 3276,
    app_flat_10: 53,
    char_11: 'Jade',
    app_rate_11: '2.14%',
    avg_round_11: 3353,
    app_flat_11: 73,
    char_12: 'Luocha',
    app_rate_12: '1.84%',
    avg_round_12: 3310,
    app_flat_12: 65,
    char_13: 'fu-xuan',
    app_rate_13: '1.77%',
    avg_round_13: 3258,
    app_flat_13: 56,
    char_14: 'Sparkle',
    app_rate_14: '1.49%',
    avg_round_14: 3176,
    app_flat_14: 9,
    char_15: 'Bronya',
    app_rate_15: '1.46%',
    avg_round_15: 3349,
    app_flat_15: 36,
    char_16: 'Aventurine',
    app_rate_16: '1.42%',
    avg_round_16: 3378,
    app_flat_16: 44,
    char_17: 'imbibitor-lunae',
    app_rate_17: '1.34%',
    avg_round_17: 3228,
    app_flat_17: 4,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '1.29%',
    avg_round_18: 3329,
    app_flat_18: 55,
    char_19: 'Kafka',
    app_rate_19: '1.15%',
    avg_round_19: 3175,
    app_flat_19: 8,
    char_20: 'Guinaifen',
    app_rate_20: '0.85%',
    avg_round_20: 3220,
    app_flat_20: 10,
    char_21: 'jing-yuan',
    app_rate_21: '0.59%',
    avg_round_21: 3253,
    app_flat_21: 14,
    char_22: 'Feixiao',
    app_rate_22: '0.56%',
    avg_round_22: 3468,
    app_flat_22: 21,
    char_23: 'topaz',
    app_rate_23: '0.46%',
    avg_round_23: 3404,
    app_flat_23: 13,
    char_24: 'black-swan',
    app_rate_24: '0.46%',
    avg_round_24: 3049,
    app_flat_24: 1,
    char_25: 'dr-ratio',
    app_rate_25: '0.36%',
    avg_round_25: 3289,
    app_flat_25: 10,
    char_26: 'Tingyun',
    app_rate_26: '0.33%',
    avg_round_26: 3207,
    app_flat_26: 5,
    char_27: 'Robin',
    app_rate_27: '0.29%',
    avg_round_27: 3448,
    app_flat_27: 3,
    char_28: 'silver-wolf',
    app_rate_28: '0.25%',
    avg_round_28: 2980,
    app_flat_28: 1,
    char_29: 'Bailu',
    app_rate_29: '0.17%',
    avg_round_29: 3304,
    app_flat_29: 5,
    char_30: 'Welt',
    app_rate_30: '0.17%',
    avg_round_30: 3182,
    app_flat_30: 2
  },
  {
    char: 'trailblazer-imaginary',
    app: 85.38,
    char_1: 'ruan-mei',
    app_rate_1: '96.03%',
    avg_round_1: 3432,
    app_flat_1: 3596,
    char_2: 'Firefly',
    app_rate_2: '73.4%',
    avg_round_2: 3423,
    app_flat_2: 2416,
    char_3: 'Gallagher',
    app_rate_3: '54.65%',
    avg_round_3: 3381,
    app_flat_3: 2525,
    char_4: 'Lingsha',
    app_rate_4: '35.19%',
    avg_round_4: 3546,
    app_flat_4: 1104,
    char_5: 'Rappa',
    app_rate_5: '18.05%',
    avg_round_5: 3522,
    app_flat_5: 860,
    char_6: 'Himeko',
    app_rate_6: '10.23%',
    avg_round_6: 3228,
    app_flat_6: 385,
    char_7: 'Boothill',
    app_rate_7: '1.98%',
    avg_round_7: 3416,
    app_flat_7: 100,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '1.32%',
    avg_round_8: 3273,
    app_flat_8: 65,
    char_9: 'Huohuo',
    app_rate_9: '1.32%',
    avg_round_9: 3294,
    app_flat_9: 41,
    char_10: 'Luocha',
    app_rate_10: '1.28%',
    avg_round_10: 3307,
    app_flat_10: 52,
    char_11: 'Jade',
    app_rate_11: '1.09%',
    avg_round_11: 3328,
    app_flat_11: 40,
    char_12: 'fu-xuan',
    app_rate_12: '0.93%',
    avg_round_12: 3253,
    app_flat_12: 39,
    char_13: 'Bronya',
    app_rate_13: '0.71%',
    avg_round_13: 3236,
    app_flat_13: 7,
    char_14: 'Jiaoqiu',
    app_rate_14: '0.51%',
    avg_round_14: 3179,
    app_flat_14: 14,
    char_15: 'Asta',
    app_rate_15: '0.47%',
    avg_round_15: 3187,
    app_flat_15: 23,
    char_16: 'Aventurine',
    app_rate_16: '0.47%',
    avg_round_16: 3381,
    app_flat_16: 18,
    char_17: 'Acheron',
    app_rate_17: '0.29%',
    avg_round_17: 3236,
    app_flat_17: 4,
    char_18: 'Guinaifen',
    app_rate_18: '0.28%',
    avg_round_18: 3252,
    app_flat_18: 11,
    char_19: 'jing-yuan',
    app_rate_19: '0.21%',
    avg_round_19: 3185,
    app_flat_19: 7,
    char_20: 'Serval',
    app_rate_20: '0.17%',
    avg_round_20: 3259,
    app_flat_20: 8,
    char_21: 'Pela',
    app_rate_21: '0.17%',
    avg_round_21: 3253,
    app_flat_21: 12,
    char_22: 'Welt',
    app_rate_22: '0.13%',
    avg_round_22: 3270,
    app_flat_22: 4,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.13%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Xueyi',
    app_rate_24: '0.13%',
    avg_round_24: 3248,
    app_flat_24: 2,
    char_25: 'Kafka',
    app_rate_25: '0.13%',
    avg_round_25: 3283,
    app_flat_25: 3,
    char_26: 'Bailu',
    app_rate_26: '0.1%',
    avg_round_26: 3206,
    app_flat_26: 3,
    char_27: 'silver-wolf',
    app_rate_27: '0.1%',
    avg_round_27: 3234,
    app_flat_27: 3,
    char_28: 'Lynx',
    app_rate_28: '0.08%',
    avg_round_28: 3183,
    app_flat_28: 3,
    char_29: 'Sparkle',
    app_rate_29: '0.07%',
    avg_round_29: 3348,
    app_flat_29: 1,
    char_30: 'Gepard',
    app_rate_30: '0.06%',
    avg_round_30: 3271,
    app_flat_30: 1
  },
  {
    char: 'Robin',
    app: 74.88,
    char_1: 'Feixiao',
    app_rate_1: '74.52%',
    avg_round_1: 3566,
    app_flat_1: 3037,
    char_2: 'topaz',
    app_rate_2: '48.88%',
    avg_round_2: 3584,
    app_flat_2: 1792,
    char_3: 'Aventurine',
    app_rate_3: '48.5%',
    avg_round_3: 3591,
    app_flat_3: 1845,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '34.8%',
    avg_round_4: 3527,
    app_flat_4: 1612,
    char_5: 'fu-xuan',
    app_rate_5: '15.16%',
    avg_round_5: 3496,
    app_flat_5: 626,
    char_6: 'Huohuo',
    app_rate_6: '13.57%',
    avg_round_6: 3435,
    app_flat_6: 546,
    char_7: 'dr-ratio',
    app_rate_7: '10.88%',
    avg_round_7: 3376,
    app_flat_7: 418,
    char_8: 'Yunli',
    app_rate_8: '9.86%',
    avg_round_8: 3392,
    app_flat_8: 399,
    char_9: 'Gallagher',
    app_rate_9: '8.03%',
    avg_round_9: 3537,
    app_flat_9: 339,
    char_10: 'Luocha',
    app_rate_10: '6.72%',
    avg_round_10: 3466,
    app_flat_10: 294,
    char_11: 'Lingsha',
    app_rate_11: '4.91%',
    avg_round_11: 3584,
    app_flat_11: 211,
    char_12: 'Sparkle',
    app_rate_12: '3.44%',
    avg_round_12: 3306,
    app_flat_12: 58,
    char_13: 'Moze',
    app_rate_13: '3.1%',
    avg_round_13: 3459,
    app_flat_13: 130,
    char_14: 'Tingyun',
    app_rate_14: '2.59%',
    avg_round_14: 3320,
    app_flat_14: 99,
    char_15: 'Bronya',
    app_rate_15: '2.33%',
    avg_round_15: 3486,
    app_flat_15: 31,
    char_16: 'Jiaoqiu',
    app_rate_16: '1.82%',
    avg_round_16: 3440,
    app_flat_16: 34,
    char_17: 'Clara',
    app_rate_17: '1.56%',
    avg_round_17: 3262,
    app_flat_17: 57,
    char_18: 'imbibitor-lunae',
    app_rate_18: '1.46%',
    avg_round_18: 3158,
    app_flat_18: 14,
    char_19: 'Acheron',
    app_rate_19: '1.32%',
    avg_round_19: 3425,
    app_flat_19: 13,
    char_20: 'silver-wolf',
    app_rate_20: '1.12%',
    avg_round_20: 3323,
    app_flat_20: 40,
    char_21: 'Jade',
    app_rate_21: '1.1%',
    avg_round_21: 3347,
    app_flat_21: 15,
    char_22: 'Himeko',
    app_rate_22: '0.58%',
    avg_round_22: 3329,
    app_flat_22: 20,
    char_23: 'Blade',
    app_rate_23: '0.5%',
    avg_round_23: 3220,
    app_flat_23: 16,
    char_24: 'Bailu',
    app_rate_24: '0.5%',
    avg_round_24: 3414,
    app_flat_24: 26,
    char_25: 'Lynx',
    app_rate_25: '0.37%',
    avg_round_25: 3479,
    app_flat_25: 15,
    char_26: 'ruan-mei',
    app_rate_26: '0.34%',
    avg_round_26: 3448,
    app_flat_26: 3,
    char_27: 'black-swan',
    app_rate_27: '0.3%',
    avg_round_27: 3409,
    app_flat_27: 3,
    char_28: 'Pela',
    app_rate_28: '0.23%',
    avg_round_28: 3307,
    app_flat_28: 11,
    char_29: 'Gepard',
    app_rate_29: '0.23%',
    avg_round_29: 3420,
    app_flat_29: 8,
    char_30: 'Kafka',
    app_rate_30: '0.19%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Firefly',
    app: 62.88,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '99.67%',
    avg_round_1: 3423,
    app_flat_1: 2416,
    char_2: 'ruan-mei',
    app_rate_2: '96.82%',
    avg_round_2: 3428,
    app_flat_2: 2321,
    char_3: 'Gallagher',
    app_rate_3: '53.07%',
    avg_round_3: 3394,
    app_flat_3: 1617,
    char_4: 'Lingsha',
    app_rate_4: '36.33%',
    avg_round_4: 3532,
    app_flat_4: 722,
    char_5: 'Himeko',
    app_rate_5: '4.85%',
    avg_round_5: 3215,
    app_flat_5: 39,
    char_6: 'Rappa',
    app_rate_6: '2.75%',
    avg_round_6: 3453,
    app_flat_6: 19,
    char_7: 'Luocha',
    app_rate_7: '1.08%',
    avg_round_7: 3305,
    app_flat_7: 30,
    char_8: 'Huohuo',
    app_rate_8: '0.97%',
    avg_round_8: 3186,
    app_flat_8: 14,
    char_9: 'fu-xuan',
    app_rate_9: '0.92%',
    avg_round_9: 3218,
    app_flat_9: 26,
    char_10: 'Bronya',
    app_rate_10: '0.79%',
    avg_round_10: 3212,
    app_flat_10: 2,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '0.41%',
    avg_round_11: 3260,
    app_flat_11: 9,
    char_12: 'Asta',
    app_rate_12: '0.38%',
    avg_round_12: 3330,
    app_flat_12: 12,
    char_13: 'Aventurine',
    app_rate_13: '0.26%',
    avg_round_13: 3250,
    app_flat_13: 4,
    char_14: 'Jade',
    app_rate_14: '0.25%',
    avg_round_14: 3228,
    app_flat_14: 4,
    char_15: 'Acheron',
    app_rate_15: '0.2%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Bailu',
    app_rate_16: '0.11%',
    avg_round_16: 3182,
    app_flat_16: 2,
    char_17: 'Lynx',
    app_rate_17: '0.11%',
    avg_round_17: 3183,
    app_flat_17: 3,
    char_18: 'Jiaoqiu',
    app_rate_18: '0.11%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'silver-wolf',
    app_rate_19: '0.11%',
    avg_round_19: 3234,
    app_flat_19: 3,
    char_20: 'Pela',
    app_rate_20: '0.11%',
    avg_round_20: 3210,
    app_flat_20: 6,
    char_21: 'Robin',
    app_rate_21: '0.1%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Boothill',
    app_rate_22: '0.08%',
    avg_round_22: 3323,
    app_flat_22: 1,
    char_23: 'Guinaifen',
    app_rate_23: '0.07%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Welt',
    app_rate_24: '0.07%',
    avg_round_24: 3280,
    app_flat_24: 1,
    char_25: 'Xueyi',
    app_rate_25: '0.07%',
    avg_round_25: 3195,
    app_flat_25: 1,
    char_26: 'Sparkle',
    app_rate_26: '0.07%',
    avg_round_26: 3348,
    app_flat_26: 1,
    char_27: 'Gepard',
    app_rate_27: '0.05%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: '0.03%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Serval',
    app_rate_29: '0.03%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Feixiao',
    app_rate_30: '0.03%',
    avg_round_30: 3318,
    app_flat_30: 1
  },
  {
    char: 'Feixiao',
    app: 61.33,
    char_1: 'Robin',
    app_rate_1: '90.97%',
    avg_round_1: 3566,
    app_flat_1: 3037,
    char_2: 'topaz',
    app_rate_2: '52.5%',
    avg_round_2: 3600,
    app_flat_2: 1616,
    char_3: 'Aventurine',
    app_rate_3: '51.36%',
    avg_round_3: 3608,
    app_flat_3: 1651,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '40.13%',
    avg_round_4: 3522,
    app_flat_4: 1556,
    char_5: 'fu-xuan',
    app_rate_5: '16.61%',
    avg_round_5: 3502,
    app_flat_5: 598,
    char_6: 'Huohuo',
    app_rate_6: '9.75%',
    avg_round_6: 3493,
    app_flat_6: 349,
    char_7: 'Gallagher',
    app_rate_7: '7.04%',
    avg_round_7: 3580,
    app_flat_7: 272,
    char_8: 'Luocha',
    app_rate_8: '6.79%',
    avg_round_8: 3495,
    app_flat_8: 270,
    char_9: 'Lingsha',
    app_rate_9: '5.41%',
    avg_round_9: 3590,
    app_flat_9: 200,
    char_10: 'Bronya',
    app_rate_10: '4.52%',
    avg_round_10: 3478,
    app_flat_10: 131,
    char_11: 'Moze',
    app_rate_11: '3.6%',
    avg_round_11: 3451,
    app_flat_11: 129,
    char_12: 'Yunli',
    app_rate_12: '3.21%',
    avg_round_12: 3467,
    app_flat_12: 105,
    char_13: 'Sparkle',
    app_rate_13: '2.99%',
    avg_round_13: 3383,
    app_flat_13: 103,
    char_14: 'ruan-mei',
    app_rate_14: '0.82%',
    avg_round_14: 3468,
    app_flat_14: 21,
    char_15: 'Clara',
    app_rate_15: '0.74%',
    avg_round_15: 3354,
    app_flat_15: 29,
    char_16: 'Bailu',
    app_rate_16: '0.59%',
    avg_round_16: 3437,
    app_flat_16: 26,
    char_17: 'Jade',
    app_rate_17: '0.55%',
    avg_round_17: 3101,
    app_flat_17: 1,
    char_18: 'Jiaoqiu',
    app_rate_18: '0.44%',
    avg_round_18: 3461,
    app_flat_18: 12,
    char_19: 'dr-ratio',
    app_rate_19: '0.39%',
    avg_round_19: 3410,
    app_flat_19: 10,
    char_20: 'Lynx',
    app_rate_20: '0.34%',
    avg_round_20: 3497,
    app_flat_20: 15,
    char_21: 'Gepard',
    app_rate_21: '0.25%',
    avg_round_21: 3465,
    app_flat_21: 7,
    char_22: 'Himeko',
    app_rate_22: '0.12%',
    avg_round_22: 3456,
    app_flat_22: 5,
    char_23: 'Asta',
    app_rate_23: '0.1%',
    avg_round_23: 3498,
    app_flat_23: 6,
    char_24: 'Tingyun',
    app_rate_24: '0.1%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Boothill',
    app_rate_25: '0.08%',
    avg_round_25: 3331,
    app_flat_25: 1,
    char_26: 'silver-wolf',
    app_rate_26: '0.08%',
    avg_round_26: 3422,
    app_flat_26: 3,
    char_27: 'Blade',
    app_rate_27: '0.07%',
    avg_round_27: 3381,
    app_flat_27: 1,
    char_28: 'Acheron',
    app_rate_28: '0.07%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Pela',
    app_rate_29: '0.05%',
    avg_round_29: 3454,
    app_flat_29: 3,
    char_30: 'Rappa',
    app_rate_30: '0.05%',
    avg_round_30: 3306,
    app_flat_30: 1
  },
  {
    char: 'Gallagher',
    app: 59.65,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '78.23%',
    avg_round_1: 3381,
    app_flat_1: 2525,
    char_2: 'ruan-mei',
    app_rate_2: '77.97%',
    avg_round_2: 3388,
    app_flat_2: 2448,
    char_3: 'Firefly',
    app_rate_3: '55.94%',
    avg_round_3: 3394,
    app_flat_3: 1617,
    char_4: 'Rappa',
    app_rate_4: '12.11%',
    avg_round_4: 3486,
    app_flat_4: 520,
    char_5: 'Robin',
    app_rate_5: '10.07%',
    avg_round_5: 3537,
    app_flat_5: 339,
    char_6: 'Himeko',
    app_rate_6: '8.73%',
    avg_round_6: 3202,
    app_flat_6: 344,
    char_7: 'Acheron',
    app_rate_7: '8.45%',
    avg_round_7: 3346,
    app_flat_7: 222,
    char_8: 'Feixiao',
    app_rate_8: '7.24%',
    avg_round_8: 3580,
    app_flat_8: 272,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '6.39%',
    avg_round_9: 3449,
    app_flat_9: 261,
    char_10: 'Jiaoqiu',
    app_rate_10: '5.93%',
    avg_round_10: 3380,
    app_flat_10: 171,
    char_11: 'Pela',
    app_rate_11: '3.2%',
    avg_round_11: 3347,
    app_flat_11: 129,
    char_12: 'Sparkle',
    app_rate_12: '3.09%',
    avg_round_12: 3244,
    app_flat_12: 29,
    char_13: 'topaz',
    app_rate_13: '2.78%',
    avg_round_13: 3591,
    app_flat_13: 99,
    char_14: 'Boothill',
    app_rate_14: '2.45%',
    avg_round_14: 3388,
    app_flat_14: 86,
    char_15: 'Bronya',
    app_rate_15: '2.4%',
    avg_round_15: 3366,
    app_flat_15: 53,
    char_16: 'silver-wolf',
    app_rate_16: '1.99%',
    avg_round_16: 3297,
    app_flat_16: 62,
    char_17: 'Kafka',
    app_rate_17: '1.9%',
    avg_round_17: 3250,
    app_flat_17: 40,
    char_18: 'Jade',
    app_rate_18: '1.88%',
    avg_round_18: 3279,
    app_flat_18: 59,
    char_19: 'dr-ratio',
    app_rate_19: '1.71%',
    avg_round_19: 3325,
    app_flat_19: 58,
    char_20: 'Guinaifen',
    app_rate_20: '1.24%',
    avg_round_20: 3313,
    app_flat_20: 42,
    char_21: 'imbibitor-lunae',
    app_rate_21: '1.23%',
    avg_round_21: 3086,
    app_flat_21: 5,
    char_22: 'Tingyun',
    app_rate_22: '0.98%',
    avg_round_22: 3256,
    app_flat_22: 16,
    char_23: 'black-swan',
    app_rate_23: '0.76%',
    avg_round_23: 3240,
    app_flat_23: 13,
    char_24: 'jing-yuan',
    app_rate_24: '0.66%',
    avg_round_24: 3214,
    app_flat_24: 10,
    char_25: 'Asta',
    app_rate_25: '0.47%',
    avg_round_25: 3157,
    app_flat_25: 18,
    char_26: 'Yunli',
    app_rate_26: '0.43%',
    avg_round_26: 3309,
    app_flat_26: 20,
    char_27: 'Moze',
    app_rate_27: '0.41%',
    avg_round_27: 3470,
    app_flat_27: 15,
    char_28: 'Lingsha',
    app_rate_28: '0.22%',
    avg_round_28: 3373,
    app_flat_28: 7,
    char_29: 'Welt',
    app_rate_29: '0.22%',
    avg_round_29: 3235,
    app_flat_29: 8,
    char_30: 'Blade',
    app_rate_30: '0.17%',
    avg_round_30: 3137,
    app_flat_30: 7
  },
  {
    char: 'Aventurine',
    app: 44.57,
    char_1: 'Robin',
    app_rate_1: '81.48%',
    avg_round_1: 3591,
    app_flat_1: 1845,
    char_2: 'Feixiao',
    app_rate_2: '70.68%',
    avg_round_2: 3608,
    app_flat_2: 1651,
    char_3: 'topaz',
    app_rate_3: '57.47%',
    avg_round_3: 3609,
    app_flat_3: 1201,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '22.11%',
    avg_round_4: 3556,
    app_flat_4: 644,
    char_5: 'dr-ratio',
    app_rate_5: '13.6%',
    avg_round_5: 3399,
    app_flat_5: 297,
    char_6: 'Acheron',
    app_rate_6: '8.09%',
    avg_round_6: 3332,
    app_flat_6: 127,
    char_7: 'Jiaoqiu',
    app_rate_7: '7.61%',
    avg_round_7: 3382,
    app_flat_7: 127,
    char_8: 'Sparkle',
    app_rate_8: '7.61%',
    avg_round_8: 3331,
    app_flat_8: 97,
    char_9: 'Moze',
    app_rate_9: '4.42%',
    avg_round_9: 3445,
    app_flat_9: 109,
    char_10: 'Yunli',
    app_rate_10: '3.86%',
    avg_round_10: 3417,
    app_flat_10: 91,
    char_11: 'Bronya',
    app_rate_11: '3.03%',
    avg_round_11: 3465,
    app_flat_11: 70,
    char_12: 'ruan-mei',
    app_rate_12: '2.87%',
    avg_round_12: 3378,
    app_flat_12: 44,
    char_13: 'Pela',
    app_rate_13: '2.78%',
    avg_round_13: 3353,
    app_flat_13: 73,
    char_14: 'silver-wolf',
    app_rate_14: '2.34%',
    avg_round_14: 3334,
    app_flat_14: 60,
    char_15: 'imbibitor-lunae',
    app_rate_15: '1.9%',
    avg_round_15: 3170,
    app_flat_15: 12,
    char_16: 'Tingyun',
    app_rate_16: '1.78%',
    avg_round_16: 3345,
    app_flat_16: 32,
    char_17: 'Kafka',
    app_rate_17: '1.46%',
    avg_round_17: 3250,
    app_flat_17: 27,
    char_18: 'Jade',
    app_rate_18: '1.2%',
    avg_round_18: 3344,
    app_flat_18: 8,
    char_19: 'black-swan',
    app_rate_19: '0.97%',
    avg_round_19: 3246,
    app_flat_19: 18,
    char_20: 'trailblazer-imaginary',
    app_rate_20: '0.9%',
    avg_round_20: 3381,
    app_flat_20: 18,
    char_21: 'Boothill',
    app_rate_21: '0.79%',
    avg_round_21: 3373,
    app_flat_21: 18,
    char_22: 'Clara',
    app_rate_22: '0.65%',
    avg_round_22: 3243,
    app_flat_22: 18,
    char_23: 'Himeko',
    app_rate_23: '0.46%',
    avg_round_23: 3417,
    app_flat_23: 8,
    char_24: 'Firefly',
    app_rate_24: '0.37%',
    avg_round_24: 3250,
    app_flat_24: 4,
    char_25: 'Guinaifen',
    app_rate_25: '0.37%',
    avg_round_25: 3275,
    app_flat_25: 9,
    char_26: 'Asta',
    app_rate_26: '0.25%',
    avg_round_26: 3432,
    app_flat_26: 5,
    char_27: 'Rappa',
    app_rate_27: '0.23%',
    avg_round_27: 3468,
    app_flat_27: 4,
    char_28: 'jing-yuan',
    app_rate_28: '0.16%',
    avg_round_28: 3061,
    app_flat_28: 1,
    char_29: 'Welt',
    app_rate_29: '0.12%',
    avg_round_29: 3337,
    app_flat_29: 4,
    char_30: 'Yukong',
    app_rate_30: '0.09%',
    avg_round_30: 3372,
    app_flat_30: 2
  },
  {
    char: 'topaz',
    app: 39.9,
    char_1: 'Robin',
    app_rate_1: '91.73%',
    avg_round_1: 3584,
    app_flat_1: 1792,
    char_2: 'Feixiao',
    app_rate_2: '80.7%',
    avg_round_2: 3600,
    app_flat_2: 1616,
    char_3: 'Aventurine',
    app_rate_3: '64.2%',
    avg_round_3: 3609,
    app_flat_3: 1201,
    char_4: 'fu-xuan',
    app_rate_4: '13.07%',
    avg_round_4: 3475,
    app_flat_4: 279,
    char_5: 'dr-ratio',
    app_rate_5: '12.43%',
    avg_round_5: 3397,
    app_flat_5: 224,
    char_6: 'Huohuo',
    app_rate_6: '7.62%',
    avg_round_6: 3452,
    app_flat_6: 149,
    char_7: 'Lingsha',
    app_rate_7: '5.17%',
    avg_round_7: 3602,
    app_flat_7: 109,
    char_8: 'Yunli',
    app_rate_8: '4.44%',
    avg_round_8: 3402,
    app_flat_8: 89,
    char_9: 'Luocha',
    app_rate_9: '4.39%',
    avg_round_9: 3468,
    app_flat_9: 118,
    char_10: 'Gallagher',
    app_rate_10: '4.16%',
    avg_round_10: 3591,
    app_flat_10: 99,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '4.13%',
    avg_round_11: 3356,
    app_flat_11: 92,
    char_12: 'Sparkle',
    app_rate_12: '2.22%',
    avg_round_12: 3386,
    app_flat_12: 48,
    char_13: 'Bronya',
    app_rate_13: '1.39%',
    avg_round_13: 3478,
    app_flat_13: 32,
    char_14: 'ruan-mei',
    app_rate_14: '1.03%',
    avg_round_14: 3404,
    app_flat_14: 13,
    char_15: 'Jade',
    app_rate_15: '0.62%',
    avg_round_15: 3681,
    app_flat_15: 1,
    char_16: 'Clara',
    app_rate_16: '0.49%',
    avg_round_16: 3216,
    app_flat_16: 10,
    char_17: 'Bailu',
    app_rate_17: '0.28%',
    avg_round_17: 3411,
    app_flat_17: 7,
    char_18: 'Jiaoqiu',
    app_rate_18: '0.26%',
    avg_round_18: 3483,
    app_flat_18: 4,
    char_19: 'Moze',
    app_rate_19: '0.26%',
    avg_round_19: 3355,
    app_flat_19: 7,
    char_20: 'Asta',
    app_rate_20: '0.23%',
    avg_round_20: 3426,
    app_flat_20: 5,
    char_21: 'Tingyun',
    app_rate_21: '0.18%',
    avg_round_21: 3373,
    app_flat_21: 3,
    char_22: 'Lynx',
    app_rate_22: '0.18%',
    avg_round_22: 3594,
    app_flat_22: 4,
    char_23: 'Gepard',
    app_rate_23: '0.15%',
    avg_round_23: 3445,
    app_flat_23: 5,
    char_24: 'silver-wolf',
    app_rate_24: '0.13%',
    avg_round_24: 3336,
    app_flat_24: 3,
    char_25: 'Himeko',
    app_rate_25: '0.1%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Hanya',
    app_rate_26: '0.08%',
    avg_round_26: 3403,
    app_flat_26: 2,
    char_27: 'Natasha',
    app_rate_27: '0.05%',
    avg_round_27: 3290,
    app_flat_27: 1,
    char_28: 'Boothill',
    app_rate_28: '0.05%',
    avg_round_28: 3116,
    app_flat_28: 2,
    char_29: 'jing-yuan',
    app_rate_29: '0.03%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'black-swan',
    app_rate_30: '0.03%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Lingsha',
    app: 36.16,
    char_1: 'ruan-mei',
    app_rate_1: '83.15%',
    avg_round_1: 3548,
    app_flat_1: 1083,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '83.1%',
    avg_round_2: 3546,
    app_flat_2: 1104,
    char_3: 'Firefly',
    app_rate_3: '63.17%',
    avg_round_3: 3532,
    app_flat_3: 722,
    char_4: 'Rappa',
    app_rate_4: '15.17%',
    avg_round_4: 3619,
    app_flat_4: 289,
    char_5: 'Robin',
    app_rate_5: '10.18%',
    avg_round_5: 3584,
    app_flat_5: 211,
    char_6: 'Feixiao',
    app_rate_6: '9.18%',
    avg_round_6: 3590,
    app_flat_6: 200,
    char_7: 'march-7th-swordmaster',
    app_rate_7: '5.87%',
    avg_round_7: 3534,
    app_flat_7: 125,
    char_8: 'topaz',
    app_rate_8: '5.7%',
    avg_round_8: 3602,
    app_flat_8: 109,
    char_9: 'Himeko',
    app_rate_9: '4.1%',
    avg_round_9: 3378,
    app_flat_9: 84,
    char_10: 'Acheron',
    app_rate_10: '3.25%',
    avg_round_10: 3331,
    app_flat_10: 33,
    char_11: 'Sparkle',
    app_rate_11: '2.68%',
    avg_round_11: 3284,
    app_flat_11: 25,
    char_12: 'Jade',
    app_rate_12: '2.54%',
    avg_round_12: 3508,
    app_flat_12: 36,
    char_13: 'Jiaoqiu',
    app_rate_13: '2.05%',
    avg_round_13: 3404,
    app_flat_13: 19,
    char_14: 'dr-ratio',
    app_rate_14: '1.14%',
    avg_round_14: 3322,
    app_flat_14: 26,
    char_15: 'Bronya',
    app_rate_15: '1.11%',
    avg_round_15: 3455,
    app_flat_15: 19,
    char_16: 'Boothill',
    app_rate_16: '0.94%',
    avg_round_16: 3430,
    app_flat_16: 20,
    char_17: 'silver-wolf',
    app_rate_17: '0.71%',
    avg_round_17: 3308,
    app_flat_17: 13,
    char_18: 'Pela',
    app_rate_18: '0.71%',
    avg_round_18: 3320,
    app_flat_18: 14,
    char_19: 'Kafka',
    app_rate_19: '0.71%',
    avg_round_19: 3286,
    app_flat_19: 8,
    char_20: 'Tingyun',
    app_rate_20: '0.54%',
    avg_round_20: 3262,
    app_flat_20: 9,
    char_21: 'Yunli',
    app_rate_21: '0.54%',
    avg_round_21: 3392,
    app_flat_21: 12,
    char_22: 'Guinaifen',
    app_rate_22: '0.4%',
    avg_round_22: 3309,
    app_flat_22: 10,
    char_23: 'black-swan',
    app_rate_23: '0.4%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Gallagher',
    app_rate_24: '0.37%',
    avg_round_24: 3373,
    app_flat_24: 7,
    char_25: 'jing-yuan',
    app_rate_25: '0.37%',
    avg_round_25: 3336,
    app_flat_25: 1,
    char_26: 'Asta',
    app_rate_26: '0.34%',
    avg_round_26: 3262,
    app_flat_26: 8,
    char_27: 'imbibitor-lunae',
    app_rate_27: '0.26%',
    avg_round_27: 3181,
    app_flat_27: 1,
    char_28: 'Clara',
    app_rate_28: '0.23%',
    avg_round_28: 3434,
    app_flat_28: 4,
    char_29: 'Serval',
    app_rate_29: '0.17%',
    avg_round_29: 3432,
    app_flat_29: 2,
    char_30: 'Moze',
    app_rate_30: '0.17%',
    avg_round_30: 3447,
    app_flat_30: 5
  },
  {
    char: 'march-7th-swordmaster',
    app: 31.41,
    char_1: 'Robin',
    app_rate_1: '82.97%',
    avg_round_1: 3527,
    app_flat_1: 1612,
    char_2: 'Feixiao',
    app_rate_2: '78.37%',
    avg_round_2: 3522,
    app_flat_2: 1556,
    char_3: 'Aventurine',
    app_rate_3: '31.38%',
    avg_round_3: 3556,
    app_flat_3: 644,
    char_4: 'fu-xuan',
    app_rate_4: '20.18%',
    avg_round_4: 3482,
    app_flat_4: 380,
    char_5: 'Huohuo',
    app_rate_5: '16.51%',
    avg_round_5: 3463,
    app_flat_5: 323,
    char_6: 'Gallagher',
    app_rate_6: '12.14%',
    avg_round_6: 3449,
    app_flat_6: 261,
    char_7: 'Luocha',
    app_rate_7: '8.27%',
    avg_round_7: 3469,
    app_flat_7: 166,
    char_8: 'Lingsha',
    app_rate_8: '6.76%',
    avg_round_8: 3534,
    app_flat_8: 125,
    char_9: 'Bronya',
    app_rate_9: '6.24%',
    avg_round_9: 3433,
    app_flat_9: 88,
    char_10: 'Yunli',
    app_rate_10: '5.64%',
    avg_round_10: 3363,
    app_flat_10: 112,
    char_11: 'dr-ratio',
    app_rate_11: '5.51%',
    avg_round_11: 3320,
    app_flat_11: 107,
    char_12: 'topaz',
    app_rate_12: '5.25%',
    avg_round_12: 3356,
    app_flat_12: 92,
    char_13: 'Sparkle',
    app_rate_13: '3.94%',
    avg_round_13: 3375,
    app_flat_13: 60,
    char_14: 'ruan-mei',
    app_rate_14: '3.68%',
    avg_round_14: 3329,
    app_flat_14: 55,
    char_15: 'trailblazer-imaginary',
    app_rate_15: '3.58%',
    avg_round_15: 3273,
    app_flat_15: 65,
    char_16: 'Clara',
    app_rate_16: '1.05%',
    avg_round_16: 3117,
    app_flat_16: 12,
    char_17: 'Firefly',
    app_rate_17: '0.82%',
    avg_round_17: 3260,
    app_flat_17: 9,
    char_18: 'Moze',
    app_rate_18: '0.72%',
    avg_round_18: 3296,
    app_flat_18: 17,
    char_19: 'Bailu',
    app_rate_19: '0.72%',
    avg_round_19: 3448,
    app_flat_19: 18,
    char_20: 'Blade',
    app_rate_20: '0.66%',
    avg_round_20: 3198,
    app_flat_20: 13,
    char_21: 'Lynx',
    app_rate_21: '0.62%',
    avg_round_21: 3450,
    app_flat_21: 14,
    char_22: 'Himeko',
    app_rate_22: '0.62%',
    avg_round_22: 3077,
    app_flat_22: 11,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.62%',
    avg_round_23: 3121,
    app_flat_23: 5,
    char_24: 'Tingyun',
    app_rate_24: '0.56%',
    avg_round_24: 3279,
    app_flat_24: 8,
    char_25: 'Boothill',
    app_rate_25: '0.46%',
    avg_round_25: 3268,
    app_flat_25: 8,
    char_26: 'Jiaoqiu',
    app_rate_26: '0.39%',
    avg_round_26: 3352,
    app_flat_26: 6,
    char_27: 'Jade',
    app_rate_27: '0.39%',
    avg_round_27: 3185,
    app_flat_27: 3,
    char_28: 'Sushang',
    app_rate_28: '0.3%',
    avg_round_28: 3268,
    app_flat_28: 6,
    char_29: 'Gepard',
    app_rate_29: '0.3%',
    avg_round_29: 3407,
    app_flat_29: 3,
    char_30: 'Asta',
    app_rate_30: '0.26%',
    avg_round_30: 3347,
    app_flat_30: 6
  },
  {
    char: 'fu-xuan',
    app: 25.06,
    char_1: 'Robin',
    app_rate_1: '45.29%',
    avg_round_1: 3496,
    app_flat_1: 626,
    char_2: 'Feixiao',
    app_rate_2: '40.64%',
    avg_round_2: 3502,
    app_flat_2: 598,
    char_3: 'Acheron',
    app_rate_3: '38.21%',
    avg_round_3: 3306,
    app_flat_3: 273,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '25.3%',
    avg_round_4: 3482,
    app_flat_4: 380,
    char_5: 'Sparkle',
    app_rate_5: '25.05%',
    avg_round_5: 3287,
    app_flat_5: 95,
    char_6: 'Jiaoqiu',
    app_rate_6: '22.54%',
    avg_round_6: 3346,
    app_flat_6: 198,
    char_7: 'topaz',
    app_rate_7: '20.81%',
    avg_round_7: 3475,
    app_flat_7: 279,
    char_8: 'silver-wolf',
    app_rate_8: '16.78%',
    avg_round_8: 3256,
    app_flat_8: 131,
    char_9: 'Pela',
    app_rate_9: '8.56%',
    avg_round_9: 3318,
    app_flat_9: 121,
    char_10: 'Kafka',
    app_rate_10: '6.99%',
    avg_round_10: 3238,
    app_flat_10: 36,
    char_11: 'Yunli',
    app_rate_11: '6.38%',
    avg_round_11: 3367,
    app_flat_11: 81,
    char_12: 'ruan-mei',
    app_rate_12: '6.33%',
    avg_round_12: 3258,
    app_flat_12: 56,
    char_13: 'dr-ratio',
    app_rate_13: '4.07%',
    avg_round_13: 3236,
    app_flat_13: 43,
    char_14: 'black-swan',
    app_rate_14: '3.83%',
    avg_round_14: 3203,
    app_flat_14: 18,
    char_15: 'Tingyun',
    app_rate_15: '3.62%',
    avg_round_15: 3224,
    app_flat_15: 29,
    char_16: 'trailblazer-imaginary',
    app_rate_16: '3.17%',
    avg_round_16: 3253,
    app_flat_16: 39,
    char_17: 'Bronya',
    app_rate_17: '3.13%',
    avg_round_17: 3409,
    app_flat_17: 23,
    char_18: 'Jade',
    app_rate_18: '3.04%',
    avg_round_18: 3200,
    app_flat_18: 20,
    char_19: 'Guinaifen',
    app_rate_19: '2.39%',
    avg_round_19: 3244,
    app_flat_19: 19,
    char_20: 'Firefly',
    app_rate_20: '2.3%',
    avg_round_20: 3218,
    app_flat_20: 26,
    char_21: 'Himeko',
    app_rate_21: '1.89%',
    avg_round_21: 3188,
    app_flat_21: 15,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.89%',
    avg_round_22: 3051,
    app_flat_22: 3,
    char_23: 'Qingque',
    app_rate_23: '1.19%',
    avg_round_23: 3032,
    app_flat_23: 5,
    char_24: 'Clara',
    app_rate_24: '1.15%',
    avg_round_24: 3267,
    app_flat_24: 10,
    char_25: 'Seele',
    app_rate_25: '1.11%',
    avg_round_25: 3057,
    app_flat_25: 1,
    char_26: 'Rappa',
    app_rate_26: '0.86%',
    avg_round_26: 3304,
    app_flat_26: 13,
    char_27: 'jing-yuan',
    app_rate_27: '0.82%',
    avg_round_27: 3188,
    app_flat_27: 5,
    char_28: 'Moze',
    app_rate_28: '0.74%',
    avg_round_28: 3355,
    app_flat_28: 14,
    char_29: 'Welt',
    app_rate_29: '0.45%',
    avg_round_29: 3238,
    app_flat_29: 2,
    char_30: 'Boothill',
    app_rate_30: '0.37%',
    avg_round_30: 3295,
    app_flat_30: 2
  },
  {
    char: 'Acheron',
    app: 24.01,
    char_1: 'Jiaoqiu',
    app_rate_1: '62.64%',
    avg_round_1: 3365,
    app_flat_1: 515,
    char_2: 'fu-xuan',
    app_rate_2: '39.89%',
    avg_round_2: 3306,
    app_flat_2: 273,
    char_3: 'Sparkle',
    app_rate_3: '31.47%',
    avg_round_3: 3296,
    app_flat_3: 62,
    char_4: 'silver-wolf',
    app_rate_4: '24.04%',
    avg_round_4: 3287,
    app_flat_4: 224,
    char_5: 'Pela',
    app_rate_5: '23.74%',
    avg_round_5: 3341,
    app_flat_5: 328,
    char_6: 'Gallagher',
    app_rate_6: '21.0%',
    avg_round_6: 3346,
    app_flat_6: 222,
    char_7: 'Kafka',
    app_rate_7: '19.06%',
    avg_round_7: 3236,
    app_flat_7: 131,
    char_8: 'Aventurine',
    app_rate_8: '15.03%',
    avg_round_8: 3332,
    app_flat_8: 127,
    char_9: 'ruan-mei',
    app_rate_9: '11.51%',
    avg_round_9: 3252,
    app_flat_9: 29,
    char_10: 'black-swan',
    app_rate_10: '9.1%',
    avg_round_10: 3217,
    app_flat_10: 56,
    char_11: 'Guinaifen',
    app_rate_11: '8.76%',
    avg_round_11: 3300,
    app_flat_11: 71,
    char_12: 'Lingsha',
    app_rate_12: '4.89%',
    avg_round_12: 3331,
    app_flat_12: 33,
    char_13: 'Bronya',
    app_rate_13: '4.89%',
    avg_round_13: 3361,
    app_flat_13: 1,
    char_14: 'Luocha',
    app_rate_14: '4.16%',
    avg_round_14: 3280,
    app_flat_14: 36,
    char_15: 'Robin',
    app_rate_15: '4.12%',
    avg_round_15: 3425,
    app_flat_15: 13,
    char_16: 'Huohuo',
    app_rate_16: '3.48%',
    avg_round_16: 3213,
    app_flat_16: 23,
    char_17: 'Gepard',
    app_rate_17: '2.62%',
    avg_round_17: 3276,
    app_flat_17: 12,
    char_18: 'Himeko',
    app_rate_18: '2.28%',
    avg_round_18: 3134,
    app_flat_18: 8,
    char_19: 'Welt',
    app_rate_19: '1.93%',
    avg_round_19: 3262,
    app_flat_19: 15,
    char_20: 'trailblazer-imaginary',
    app_rate_20: '1.03%',
    avg_round_20: 3236,
    app_flat_20: 4,
    char_21: 'Jade',
    app_rate_21: '1.03%',
    avg_round_21: 3268,
    app_flat_21: 5,
    char_22: 'Bailu',
    app_rate_22: '0.56%',
    avg_round_22: 3173,
    app_flat_22: 2,
    char_23: 'Rappa',
    app_rate_23: '0.52%',
    avg_round_23: 3136,
    app_flat_23: 1,
    char_24: 'Firefly',
    app_rate_24: '0.52%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Lynx',
    app_rate_25: '0.43%',
    avg_round_25: 3295,
    app_flat_25: 4,
    char_26: 'trailblazer-fire',
    app_rate_26: '0.3%',
    avg_round_26: 3344,
    app_flat_26: 2,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.17%',
    avg_round_27: 2980,
    app_flat_27: 1,
    char_28: 'Feixiao',
    app_rate_28: '0.17%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Asta',
    app_rate_29: '0.13%',
    avg_round_29: 2909,
    app_flat_29: 1,
    char_30: 'Boothill',
    app_rate_30: '0.13%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Sparkle',
    app: 18.03,
    char_1: 'Acheron',
    app_rate_1: '41.91%',
    avg_round_1: 3296,
    app_flat_1: 62,
    char_2: 'fu-xuan',
    app_rate_2: '34.82%',
    avg_round_2: 3287,
    app_flat_2: 95,
    char_3: 'Jiaoqiu',
    app_rate_3: '28.42%',
    avg_round_3: 3343,
    app_flat_3: 57,
    char_4: 'imbibitor-lunae',
    app_rate_4: '22.24%',
    avg_round_4: 3129,
    app_flat_4: 31,
    char_5: 'Aventurine',
    app_rate_5: '18.81%',
    avg_round_5: 3331,
    app_flat_5: 97,
    char_6: 'Huohuo',
    app_rate_6: '17.61%',
    avg_round_6: 3281,
    app_flat_6: 76,
    char_7: 'Tingyun',
    app_rate_7: '15.21%',
    avg_round_7: 3246,
    app_flat_7: 61,
    char_8: 'Robin',
    app_rate_8: '14.29%',
    avg_round_8: 3306,
    app_flat_8: 58,
    char_9: 'silver-wolf',
    app_rate_9: '12.86%',
    avg_round_9: 3119,
    app_flat_9: 27,
    char_10: 'Yunli',
    app_rate_10: '12.29%',
    avg_round_10: 3294,
    app_flat_10: 92,
    char_11: 'Gallagher',
    app_rate_11: '10.23%',
    avg_round_11: 3244,
    app_flat_11: 29,
    char_12: 'Feixiao',
    app_rate_12: '10.18%',
    avg_round_12: 3383,
    app_flat_12: 103,
    char_13: 'Luocha',
    app_rate_13: '7.6%',
    avg_round_13: 3241,
    app_flat_13: 24,
    char_14: 'ruan-mei',
    app_rate_14: '7.43%',
    avg_round_14: 3176,
    app_flat_14: 9,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '6.86%',
    avg_round_15: 3375,
    app_flat_15: 60,
    char_16: 'Lingsha',
    app_rate_16: '5.37%',
    avg_round_16: 3284,
    app_flat_16: 25,
    char_17: 'topaz',
    app_rate_17: '4.92%',
    avg_round_17: 3386,
    app_flat_17: 48,
    char_18: 'dr-ratio',
    app_rate_18: '4.35%',
    avg_round_18: 3242,
    app_flat_18: 37,
    char_19: 'Pela',
    app_rate_19: '3.83%',
    avg_round_19: 3066,
    app_flat_19: 2,
    char_20: 'Jade',
    app_rate_20: '3.03%',
    avg_round_20: 3272,
    app_flat_20: 13,
    char_21: 'Himeko',
    app_rate_21: '2.0%',
    avg_round_21: 3196,
    app_flat_21: 8,
    char_22: 'Qingque',
    app_rate_22: '2.0%',
    avg_round_22: 3072,
    app_flat_22: 8,
    char_23: 'Kafka',
    app_rate_23: '1.72%',
    avg_round_23: 3077,
    app_flat_23: 1,
    char_24: 'jing-yuan',
    app_rate_24: '1.49%',
    avg_round_24: 3018,
    app_flat_24: 2,
    char_25: 'Seele',
    app_rate_25: '1.37%',
    avg_round_25: 3057,
    app_flat_25: 1,
    char_26: 'Bronya',
    app_rate_26: '1.26%',
    avg_round_26: 3151,
    app_flat_26: 6,
    char_27: 'Guinaifen',
    app_rate_27: '0.97%',
    avg_round_27: 3334,
    app_flat_27: 2,
    char_28: 'Clara',
    app_rate_28: '0.86%',
    avg_round_28: 3054,
    app_flat_28: 2,
    char_29: 'Gepard',
    app_rate_29: '0.74%',
    avg_round_29: 3247,
    app_flat_29: 1,
    char_30: 'Blade',
    app_rate_30: '0.74%',
    avg_round_30: 3040,
    app_flat_30: 2
  },
  {
    char: 'Jiaoqiu',
    app: 16.79,
    char_1: 'Acheron',
    app_rate_1: '89.56%',
    avg_round_1: 3365,
    app_flat_1: 515,
    char_2: 'fu-xuan',
    app_rate_2: '33.64%',
    avg_round_2: 3346,
    app_flat_2: 198,
    char_3: 'Sparkle',
    app_rate_3: '30.51%',
    avg_round_3: 3343,
    app_flat_3: 57,
    char_4: 'Gallagher',
    app_rate_4: '21.06%',
    avg_round_4: 3380,
    app_flat_4: 171,
    char_5: 'Aventurine',
    app_rate_5: '20.2%',
    avg_round_5: 3382,
    app_flat_5: 127,
    char_6: 'Pela',
    app_rate_6: '18.91%',
    avg_round_6: 3388,
    app_flat_6: 227,
    char_7: 'silver-wolf',
    app_rate_7: '12.58%',
    avg_round_7: 3355,
    app_flat_7: 117,
    char_8: 'ruan-mei',
    app_rate_8: '12.03%',
    avg_round_8: 3300,
    app_flat_8: 25,
    char_9: 'Robin',
    app_rate_9: '8.1%',
    avg_round_9: 3440,
    app_flat_9: 34,
    char_10: 'Kafka',
    app_rate_10: '7.61%',
    avg_round_10: 3341,
    app_flat_10: 37,
    char_11: 'Luocha',
    app_rate_11: '4.91%',
    avg_round_11: 3323,
    app_flat_11: 30,
    char_12: 'Guinaifen',
    app_rate_12: '4.79%',
    avg_round_12: 3385,
    app_flat_12: 28,
    char_13: 'Lingsha',
    app_rate_13: '4.42%',
    avg_round_13: 3404,
    app_flat_13: 19,
    char_14: 'Bronya',
    app_rate_14: '4.42%',
    avg_round_14: 3334,
    app_flat_14: 3,
    char_15: 'dr-ratio',
    app_rate_15: '4.11%',
    avg_round_15: 3334,
    app_flat_15: 32,
    char_16: 'Huohuo',
    app_rate_16: '3.99%',
    avg_round_16: 3279,
    app_flat_16: 16,
    char_17: 'Himeko',
    app_rate_17: '2.76%',
    avg_round_17: 3104,
    app_flat_17: 5,
    char_18: 'black-swan',
    app_rate_18: '2.64%',
    avg_round_18: 3290,
    app_flat_18: 12,
    char_19: 'trailblazer-imaginary',
    app_rate_19: '2.58%',
    avg_round_19: 3179,
    app_flat_19: 14,
    char_20: 'Gepard',
    app_rate_20: '1.66%',
    avg_round_20: 3300,
    app_flat_20: 8,
    char_21: 'Feixiao',
    app_rate_21: '1.6%',
    avg_round_21: 3461,
    app_flat_21: 12,
    char_22: 'Welt',
    app_rate_22: '1.1%',
    avg_round_22: 3309,
    app_flat_22: 10,
    char_23: 'Rappa',
    app_rate_23: '0.86%',
    avg_round_23: 3304,
    app_flat_23: 4,
    char_24: 'imbibitor-lunae',
    app_rate_24: '0.86%',
    avg_round_24: 2886,
    app_flat_24: 1,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.74%',
    avg_round_25: 3352,
    app_flat_25: 6,
    char_26: 'Jade',
    app_rate_26: '0.61%',
    avg_round_26: 3298,
    app_flat_26: 2,
    char_27: 'topaz',
    app_rate_27: '0.61%',
    avg_round_27: 3483,
    app_flat_27: 4,
    char_28: 'Tingyun',
    app_rate_28: '0.61%',
    avg_round_28: 3176,
    app_flat_28: 2,
    char_29: 'Lynx',
    app_rate_29: '0.55%',
    avg_round_29: 3295,
    app_flat_29: 4,
    char_30: 'Firefly',
    app_rate_30: '0.43%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Rappa',
    app: 15.7,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '98.16%',
    avg_round_1: 3522,
    app_flat_1: 860,
    char_2: 'ruan-mei',
    app_rate_2: '92.65%',
    avg_round_2: 3533,
    app_flat_2: 800,
    char_3: 'Gallagher',
    app_rate_3: '46.03%',
    avg_round_3: 3486,
    app_flat_3: 520,
    char_4: 'Lingsha',
    app_rate_4: '34.93%',
    avg_round_4: 3619,
    app_flat_4: 289,
    char_5: 'Firefly',
    app_rate_5: '11.03%',
    avg_round_5: 3453,
    app_flat_5: 19,
    char_6: 'Himeko',
    app_rate_6: '4.14%',
    avg_round_6: 3356,
    app_flat_6: 18,
    char_7: 'Huohuo',
    app_rate_7: '2.43%',
    avg_round_7: 3341,
    app_flat_7: 23,
    char_8: 'Luocha',
    app_rate_8: '1.77%',
    avg_round_8: 3386,
    app_flat_8: 14,
    char_9: 'Jade',
    app_rate_9: '1.64%',
    avg_round_9: 3526,
    app_flat_9: 8,
    char_10: 'fu-xuan',
    app_rate_10: '1.38%',
    avg_round_10: 3304,
    app_flat_10: 13,
    char_11: 'Jiaoqiu',
    app_rate_11: '0.92%',
    avg_round_11: 3304,
    app_flat_11: 4,
    char_12: 'Acheron',
    app_rate_12: '0.79%',
    avg_round_12: 3136,
    app_flat_12: 1,
    char_13: 'Aventurine',
    app_rate_13: '0.66%',
    avg_round_13: 3468,
    app_flat_13: 4,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '0.53%',
    avg_round_14: 3419,
    app_flat_14: 6,
    char_15: 'Pela',
    app_rate_15: '0.46%',
    avg_round_15: 3274,
    app_flat_15: 6,
    char_16: 'Asta',
    app_rate_16: '0.39%',
    avg_round_16: 3178,
    app_flat_16: 3,
    char_17: 'Bronya',
    app_rate_17: '0.26%',
    avg_round_17: 3225,
    app_flat_17: 2,
    char_18: 'Feixiao',
    app_rate_18: '0.2%',
    avg_round_18: 3306,
    app_flat_18: 1,
    char_19: 'Tingyun',
    app_rate_19: '0.2%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Welt',
    app_rate_20: '0.2%',
    avg_round_20: 3438,
    app_flat_20: 1,
    char_21: 'Kafka',
    app_rate_21: '0.2%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Guinaifen',
    app_rate_22: '0.13%',
    avg_round_22: 3195,
    app_flat_22: 1,
    char_23: 'silver-wolf',
    app_rate_23: '0.13%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Robin',
    app_rate_24: '0.13%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Gepard',
    app_rate_25: '0.13%',
    avg_round_25: 3271,
    app_flat_25: 1,
    char_26: 'jing-yuan',
    app_rate_26: '0.07%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Boothill',
    app_rate_27: '0.07%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Xueyi',
    app_rate_28: '0.07%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Sparkle',
    app_rate_29: '0.07%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bailu',
    app_rate_30: '0.07%',
    avg_round_30: 3255,
    app_flat_30: 1
  },
  {
    char: 'Huohuo',
    app: 15.43,
    char_1: 'Robin',
    app_rate_1: '65.87%',
    avg_round_1: 3435,
    app_flat_1: 546,
    char_2: 'Feixiao',
    app_rate_2: '38.74%',
    avg_round_2: 3493,
    app_flat_2: 349,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '33.6%',
    avg_round_3: 3463,
    app_flat_3: 323,
    char_4: 'Yunli',
    app_rate_4: '29.33%',
    avg_round_4: 3370,
    app_flat_4: 239,
    char_5: 'Sparkle',
    app_rate_5: '20.57%',
    avg_round_5: 3281,
    app_flat_5: 76,
    char_6: 'topaz',
    app_rate_6: '19.71%',
    avg_round_6: 3452,
    app_flat_6: 149,
    char_7: 'Tingyun',
    app_rate_7: '16.23%',
    avg_round_7: 3288,
    app_flat_7: 93,
    char_8: 'ruan-mei',
    app_rate_8: '12.89%',
    avg_round_8: 3276,
    app_flat_8: 53,
    char_9: 'imbibitor-lunae',
    app_rate_9: '9.42%',
    avg_round_9: 3144,
    app_flat_9: 7,
    char_10: 'trailblazer-imaginary',
    app_rate_10: '7.28%',
    avg_round_10: 3294,
    app_flat_10: 41,
    char_11: 'dr-ratio',
    app_rate_11: '5.68%',
    avg_round_11: 3279,
    app_flat_11: 44,
    char_12: 'Acheron',
    app_rate_12: '5.41%',
    avg_round_12: 3213,
    app_flat_12: 23,
    char_13: 'Kafka',
    app_rate_13: '4.61%',
    avg_round_13: 3146,
    app_flat_13: 11,
    char_14: 'Jiaoqiu',
    app_rate_14: '4.34%',
    avg_round_14: 3279,
    app_flat_14: 16,
    char_15: 'Firefly',
    app_rate_15: '3.94%',
    avg_round_15: 3186,
    app_flat_15: 14,
    char_16: 'black-swan',
    app_rate_16: '3.21%',
    avg_round_16: 3215,
    app_flat_16: 4,
    char_17: 'silver-wolf',
    app_rate_17: '2.61%',
    avg_round_17: 3230,
    app_flat_17: 15,
    char_18: 'Clara',
    app_rate_18: '2.47%',
    avg_round_18: 3185,
    app_flat_18: 16,
    char_19: 'Rappa',
    app_rate_19: '2.47%',
    avg_round_19: 3341,
    app_flat_19: 23,
    char_20: 'Bronya',
    app_rate_20: '2.27%',
    avg_round_20: 3422,
    app_flat_20: 10,
    char_21: 'Boothill',
    app_rate_21: '1.8%',
    avg_round_21: 3300,
    app_flat_21: 6,
    char_22: 'Jade',
    app_rate_22: '1.27%',
    avg_round_22: 3254,
    app_flat_22: 6,
    char_23: 'Blade',
    app_rate_23: '1.07%',
    avg_round_23: 3265,
    app_flat_23: 4,
    char_24: 'Pela',
    app_rate_24: '0.87%',
    avg_round_24: 3189,
    app_flat_24: 5,
    char_25: 'Moze',
    app_rate_25: '0.8%',
    avg_round_25: 3378,
    app_flat_25: 4,
    char_26: 'Himeko',
    app_rate_26: '0.6%',
    avg_round_26: 3158,
    app_flat_26: 6,
    char_27: 'jing-yuan',
    app_rate_27: '0.53%',
    avg_round_27: 3122,
    app_flat_27: 3,
    char_28: 'Asta',
    app_rate_28: '0.4%',
    avg_round_28: 3082,
    app_flat_28: 2,
    char_29: 'Guinaifen',
    app_rate_29: '0.4%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Sushang',
    app_rate_30: '0.2%',
    avg_round_30: 3222,
    app_flat_30: 2
  },
  {
    char: 'Himeko',
    app: 11.0,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '79.38%',
    avg_round_1: 3228,
    app_flat_1: 385,
    char_2: 'ruan-mei',
    app_rate_2: '78.16%',
    avg_round_2: 3240,
    app_flat_2: 341,
    char_3: 'Gallagher',
    app_rate_3: '47.33%',
    avg_round_3: 3202,
    app_flat_3: 344,
    char_4: 'Firefly',
    app_rate_4: '27.74%',
    avg_round_4: 3215,
    app_flat_4: 39,
    char_5: 'Lingsha',
    app_rate_5: '13.5%',
    avg_round_5: 3378,
    app_flat_5: 84,
    char_6: 'Jade',
    app_rate_6: '13.4%',
    avg_round_6: 3271,
    app_flat_6: 80,
    char_7: 'Rappa',
    app_rate_7: '5.9%',
    avg_round_7: 3356,
    app_flat_7: 18,
    char_8: 'Acheron',
    app_rate_8: '4.97%',
    avg_round_8: 3134,
    app_flat_8: 8,
    char_9: 'fu-xuan',
    app_rate_9: '4.31%',
    avg_round_9: 3188,
    app_flat_9: 15,
    char_10: 'Jiaoqiu',
    app_rate_10: '4.22%',
    avg_round_10: 3104,
    app_flat_10: 5,
    char_11: 'Robin',
    app_rate_11: '3.94%',
    avg_round_11: 3329,
    app_flat_11: 20,
    char_12: 'Sparkle',
    app_rate_12: '3.28%',
    avg_round_12: 3196,
    app_flat_12: 8,
    char_13: 'Luocha',
    app_rate_13: '1.97%',
    avg_round_13: 3164,
    app_flat_13: 11,
    char_14: 'Aventurine',
    app_rate_14: '1.87%',
    avg_round_14: 3417,
    app_flat_14: 8,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.78%',
    avg_round_15: 3077,
    app_flat_15: 11,
    char_16: 'Asta',
    app_rate_16: '1.12%',
    avg_round_16: 3022,
    app_flat_16: 8,
    char_17: 'Kafka',
    app_rate_17: '1.12%',
    avg_round_17: 3087,
    app_flat_17: 3,
    char_18: 'Huohuo',
    app_rate_18: '0.84%',
    avg_round_18: 3158,
    app_flat_18: 6,
    char_19: 'Tingyun',
    app_rate_19: '0.75%',
    avg_round_19: 3207,
    app_flat_19: 3,
    char_20: 'Feixiao',
    app_rate_20: '0.66%',
    avg_round_20: 3456,
    app_flat_20: 5,
    char_21: 'Guinaifen',
    app_rate_21: '0.56%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'silver-wolf',
    app_rate_22: '0.47%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'topaz',
    app_rate_23: '0.37%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jing-yuan',
    app_rate_24: '0.37%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bronya',
    app_rate_25: '0.37%',
    avg_round_25: 3485,
    app_flat_25: 2,
    char_26: 'imbibitor-lunae',
    app_rate_26: '0.28%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Welt',
    app_rate_27: '0.28%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Bailu',
    app_rate_28: '0.19%',
    avg_round_28: 3285,
    app_flat_28: 1,
    char_29: 'Serval',
    app_rate_29: '0.19%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Pela',
    app_rate_30: '0.19%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 9.94,
    char_1: 'Robin',
    app_rate_1: '81.95%',
    avg_round_1: 3376,
    app_flat_1: 418,
    char_2: 'Aventurine',
    app_rate_2: '61.0%',
    avg_round_2: 3399,
    app_flat_2: 297,
    char_3: 'topaz',
    app_rate_3: '49.9%',
    avg_round_3: 3397,
    app_flat_3: 224,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '17.43%',
    avg_round_4: 3320,
    app_flat_4: 107,
    char_5: 'silver-wolf',
    app_rate_5: '11.83%',
    avg_round_5: 3265,
    app_flat_5: 61,
    char_6: 'fu-xuan',
    app_rate_6: '10.27%',
    avg_round_6: 3236,
    app_flat_6: 43,
    char_7: 'Gallagher',
    app_rate_7: '10.27%',
    avg_round_7: 3325,
    app_flat_7: 58,
    char_8: 'Huohuo',
    app_rate_8: '8.82%',
    avg_round_8: 3279,
    app_flat_8: 44,
    char_9: 'Sparkle',
    app_rate_9: '7.88%',
    avg_round_9: 3242,
    app_flat_9: 37,
    char_10: 'Jiaoqiu',
    app_rate_10: '6.95%',
    avg_round_10: 3334,
    app_flat_10: 32,
    char_11: 'Moze',
    app_rate_11: '5.39%',
    avg_round_11: 3368,
    app_flat_11: 26,
    char_12: 'Luocha',
    app_rate_12: '4.88%',
    avg_round_12: 3275,
    app_flat_12: 24,
    char_13: 'Tingyun',
    app_rate_13: '4.56%',
    avg_round_13: 3264,
    app_flat_13: 26,
    char_14: 'Lingsha',
    app_rate_14: '4.15%',
    avg_round_14: 3322,
    app_flat_14: 26,
    char_15: 'ruan-mei',
    app_rate_15: '3.22%',
    avg_round_15: 3289,
    app_flat_15: 10,
    char_16: 'Pela',
    app_rate_16: '2.59%',
    avg_round_16: 3275,
    app_flat_16: 17,
    char_17: 'Feixiao',
    app_rate_17: '2.39%',
    avg_round_17: 3410,
    app_flat_17: 10,
    char_18: 'Bronya',
    app_rate_18: '1.45%',
    avg_round_18: 3249,
    app_flat_18: 6,
    char_19: 'Yunli',
    app_rate_19: '1.24%',
    avg_round_19: 3423,
    app_flat_19: 7,
    char_20: 'black-swan',
    app_rate_20: '1.04%',
    avg_round_20: 3409,
    app_flat_20: 3,
    char_21: 'Bailu',
    app_rate_21: '0.41%',
    avg_round_21: 3333,
    app_flat_21: 3,
    char_22: 'Asta',
    app_rate_22: '0.41%',
    avg_round_22: 3208,
    app_flat_22: 1,
    char_23: 'Jade',
    app_rate_23: '0.41%',
    avg_round_23: 3182,
    app_flat_23: 1,
    char_24: 'Clara',
    app_rate_24: '0.41%',
    avg_round_24: 3136,
    app_flat_24: 3,
    char_25: 'Welt',
    app_rate_25: '0.31%',
    avg_round_25: 3364,
    app_flat_25: 2,
    char_26: 'Acheron',
    app_rate_26: '0.21%',
    avg_round_26: 3087,
    app_flat_26: 1,
    char_27: 'Boothill',
    app_rate_27: '0.1%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Gepard',
    app_rate_28: '0.1%',
    avg_round_28: 3193,
    app_flat_28: 1,
    char_29: 'Hanya',
    app_rate_29: '0.1%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Natasha',
    app_rate_30: '0.1%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Yunli',
    app: 9.28,
    char_1: 'Robin',
    app_rate_1: '79.56%',
    avg_round_1: 3392,
    app_flat_1: 399,
    char_2: 'Huohuo',
    app_rate_2: '48.78%',
    avg_round_2: 3370,
    app_flat_2: 239,
    char_3: 'Tingyun',
    app_rate_3: '30.11%',
    avg_round_3: 3298,
    app_flat_3: 150,
    char_4: 'Sparkle',
    app_rate_4: '23.89%',
    avg_round_4: 3294,
    app_flat_4: 92,
    char_5: 'Feixiao',
    app_rate_5: '21.22%',
    avg_round_5: 3467,
    app_flat_5: 105,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '19.11%',
    avg_round_6: 3363,
    app_flat_6: 112,
    char_7: 'topaz',
    app_rate_7: '19.11%',
    avg_round_7: 3402,
    app_flat_7: 89,
    char_8: 'Aventurine',
    app_rate_8: '18.56%',
    avg_round_8: 3417,
    app_flat_8: 91,
    char_9: 'fu-xuan',
    app_rate_9: '17.22%',
    avg_round_9: 3367,
    app_flat_9: 81,
    char_10: 'Luocha',
    app_rate_10: '7.11%',
    avg_round_10: 3339,
    app_flat_10: 38,
    char_11: 'Gallagher',
    app_rate_11: '2.78%',
    avg_round_11: 3309,
    app_flat_11: 20,
    char_12: 'Clara',
    app_rate_12: '2.22%',
    avg_round_12: 3279,
    app_flat_12: 12,
    char_13: 'Lingsha',
    app_rate_13: '2.11%',
    avg_round_13: 3392,
    app_flat_13: 12,
    char_14: 'Lynx',
    app_rate_14: '1.44%',
    avg_round_14: 3114,
    app_flat_14: 4,
    char_15: 'dr-ratio',
    app_rate_15: '1.33%',
    avg_round_15: 3423,
    app_flat_15: 7,
    char_16: 'ruan-mei',
    app_rate_16: '1.33%',
    avg_round_16: 3385,
    app_flat_16: 3,
    char_17: 'Bronya',
    app_rate_17: '0.89%',
    avg_round_17: 3212,
    app_flat_17: 4,
    char_18: 'Bailu',
    app_rate_18: '0.56%',
    avg_round_18: 3200,
    app_flat_18: 4,
    char_19: 'Blade',
    app_rate_19: '0.56%',
    avg_round_19: 3175,
    app_flat_19: 2,
    char_20: 'Jiaoqiu',
    app_rate_20: '0.33%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Jade',
    app_rate_21: '0.33%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Boothill',
    app_rate_22: '0.33%',
    avg_round_22: 3204,
    app_flat_22: 3,
    char_23: 'black-swan',
    app_rate_23: '0.22%',
    avg_round_23: 3200,
    app_flat_23: 1,
    char_24: 'silver-wolf',
    app_rate_24: '0.22%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Asta',
    app_rate_25: '0.22%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Natasha',
    app_rate_26: '0.11%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'imbibitor-lunae',
    app_rate_27: '0.11%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Gepard',
    app_rate_28: '0.11%',
    avg_round_28: 3154,
    app_flat_28: 1,
    char_29: 'Yukong',
    app_rate_29: '0.11%',
    avg_round_29: 3160,
    app_flat_29: 1,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luocha',
    app: 8.83,
    char_1: 'Robin',
    app_rate_1: '56.94%',
    avg_round_1: 3466,
    app_flat_1: 294,
    char_2: 'Feixiao',
    app_rate_2: '47.14%',
    avg_round_2: 3495,
    app_flat_2: 270,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '29.4%',
    avg_round_3: 3469,
    app_flat_3: 166,
    char_4: 'topaz',
    app_rate_4: '19.84%',
    avg_round_4: 3468,
    app_flat_4: 118,
    char_5: 'ruan-mei',
    app_rate_5: '18.67%',
    avg_round_5: 3310,
    app_flat_5: 65,
    char_6: 'Sparkle',
    app_rate_6: '15.52%',
    avg_round_6: 3241,
    app_flat_6: 24,
    char_7: 'trailblazer-imaginary',
    app_rate_7: '12.37%',
    avg_round_7: 3307,
    app_flat_7: 52,
    char_8: 'Acheron',
    app_rate_8: '11.32%',
    avg_round_8: 3280,
    app_flat_8: 36,
    char_9: 'imbibitor-lunae',
    app_rate_9: '10.5%',
    avg_round_9: 3121,
    app_flat_9: 6,
    char_10: 'Jiaoqiu',
    app_rate_10: '9.33%',
    avg_round_10: 3323,
    app_flat_10: 30,
    char_11: 'Bronya',
    app_rate_11: '9.1%',
    avg_round_11: 3328,
    app_flat_11: 37,
    char_12: 'Firefly',
    app_rate_12: '7.7%',
    avg_round_12: 3305,
    app_flat_12: 30,
    char_13: 'Yunli',
    app_rate_13: '7.47%',
    avg_round_13: 3339,
    app_flat_13: 38,
    char_14: 'Tingyun',
    app_rate_14: '5.72%',
    avg_round_14: 3237,
    app_flat_14: 14,
    char_15: 'dr-ratio',
    app_rate_15: '5.48%',
    avg_round_15: 3275,
    app_flat_15: 24,
    char_16: 'Boothill',
    app_rate_16: '3.85%',
    avg_round_16: 3268,
    app_flat_16: 20,
    char_17: 'Jade',
    app_rate_17: '3.38%',
    avg_round_17: 3201,
    app_flat_17: 11,
    char_18: 'Rappa',
    app_rate_18: '3.15%',
    avg_round_18: 3386,
    app_flat_18: 14,
    char_19: 'Kafka',
    app_rate_19: '3.03%',
    avg_round_19: 3200,
    app_flat_19: 10,
    char_20: 'black-swan',
    app_rate_20: '2.68%',
    avg_round_20: 3219,
    app_flat_20: 7,
    char_21: 'Clara',
    app_rate_21: '2.57%',
    avg_round_21: 3291,
    app_flat_21: 12,
    char_22: 'Himeko',
    app_rate_22: '2.45%',
    avg_round_22: 3164,
    app_flat_22: 11,
    char_23: 'silver-wolf',
    app_rate_23: '2.33%',
    avg_round_23: 3111,
    app_flat_23: 8,
    char_24: 'Pela',
    app_rate_24: '2.22%',
    avg_round_24: 3346,
    app_flat_24: 11,
    char_25: 'Blade',
    app_rate_25: '2.1%',
    avg_round_25: 3116,
    app_flat_25: 4,
    char_26: 'Moze',
    app_rate_26: '1.87%',
    avg_round_26: 3451,
    app_flat_26: 9,
    char_27: 'Welt',
    app_rate_27: '0.93%',
    avg_round_27: 3342,
    app_flat_27: 4,
    char_28: 'Guinaifen',
    app_rate_28: '0.7%',
    avg_round_28: 3320,
    app_flat_28: 3,
    char_29: 'Hanya',
    app_rate_29: '0.58%',
    avg_round_29: 3041,
    app_flat_29: 1,
    char_30: 'Asta',
    app_rate_30: '0.47%',
    avg_round_30: 3489,
    app_flat_30: 1
  },
  {
    char: 'silver-wolf',
    app: 7.79,
    char_1: 'Acheron',
    app_rate_1: '74.07%',
    avg_round_1: 3287,
    app_flat_1: 224,
    char_2: 'fu-xuan',
    app_rate_2: '53.97%',
    avg_round_2: 3256,
    app_flat_2: 131,
    char_3: 'Sparkle',
    app_rate_3: '29.76%',
    avg_round_3: 3119,
    app_flat_3: 27,
    char_4: 'Jiaoqiu',
    app_rate_4: '27.12%',
    avg_round_4: 3355,
    app_flat_4: 117,
    char_5: 'Gallagher',
    app_rate_5: '15.21%',
    avg_round_5: 3297,
    app_flat_5: 62,
    char_6: 'dr-ratio',
    app_rate_6: '15.08%',
    avg_round_6: 3265,
    app_flat_6: 61,
    char_7: 'Pela',
    app_rate_7: '14.29%',
    avg_round_7: 3230,
    app_flat_7: 69,
    char_8: 'Aventurine',
    app_rate_8: '13.36%',
    avg_round_8: 3334,
    app_flat_8: 60,
    char_9: 'Robin',
    app_rate_9: '10.71%',
    avg_round_9: 3323,
    app_flat_9: 40,
    char_10: 'Kafka',
    app_rate_10: '6.75%',
    avg_round_10: 3221,
    app_flat_10: 18,
    char_11: 'Huohuo',
    app_rate_11: '5.16%',
    avg_round_11: 3230,
    app_flat_11: 15,
    char_12: 'Seele',
    app_rate_12: '3.31%',
    avg_round_12: 3057,
    app_flat_12: 1,
    char_13: 'Lingsha',
    app_rate_13: '3.31%',
    avg_round_13: 3308,
    app_flat_13: 13,
    char_14: 'Qingque',
    app_rate_14: '3.31%',
    avg_round_14: 2990,
    app_flat_14: 5,
    char_15: 'ruan-mei',
    app_rate_15: '2.91%',
    avg_round_15: 2980,
    app_flat_15: 1,
    char_16: 'Bronya',
    app_rate_16: '2.78%',
    avg_round_16: 3180,
    app_flat_16: 1,
    char_17: 'Luocha',
    app_rate_17: '2.65%',
    avg_round_17: 3111,
    app_flat_17: 8,
    char_18: 'Guinaifen',
    app_rate_18: '2.51%',
    avg_round_18: 3190,
    app_flat_18: 7,
    char_19: 'Gepard',
    app_rate_19: '1.98%',
    avg_round_19: 3226,
    app_flat_19: 5,
    char_20: 'Tingyun',
    app_rate_20: '1.19%',
    avg_round_20: 3201,
    app_flat_20: 6,
    char_21: 'trailblazer-imaginary',
    app_rate_21: '1.06%',
    avg_round_21: 3234,
    app_flat_21: 3,
    char_22: 'Welt',
    app_rate_22: '1.06%',
    avg_round_22: 3137,
    app_flat_22: 1,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.93%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: '0.93%',
    avg_round_24: 3234,
    app_flat_24: 3,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.93%',
    avg_round_25: 3105,
    app_flat_25: 3,
    char_26: 'Bailu',
    app_rate_26: '0.79%',
    avg_round_26: 3275,
    app_flat_26: 1,
    char_27: 'Feixiao',
    app_rate_27: '0.66%',
    avg_round_27: 3422,
    app_flat_27: 3,
    char_28: 'topaz',
    app_rate_28: '0.66%',
    avg_round_28: 3336,
    app_flat_28: 3,
    char_29: 'Himeko',
    app_rate_29: '0.66%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Lynx',
    app_rate_30: '0.53%',
    avg_round_30: 3273,
    app_flat_30: 3
  },
  {
    char: 'Bronya',
    app: 6.64,
    char_1: 'Feixiao',
    app_rate_1: '41.77%',
    avg_round_1: 3478,
    app_flat_1: 131,
    char_2: 'march-7th-swordmaster',
    app_rate_2: '29.5%',
    avg_round_2: 3433,
    app_flat_2: 88,
    char_3: 'Robin',
    app_rate_3: '26.24%',
    avg_round_3: 3486,
    app_flat_3: 31,
    char_4: 'Gallagher',
    app_rate_4: '21.58%',
    avg_round_4: 3366,
    app_flat_4: 53,
    char_5: 'Aventurine',
    app_rate_5: '20.34%',
    avg_round_5: 3465,
    app_flat_5: 70,
    char_6: 'ruan-mei',
    app_rate_6: '19.72%',
    avg_round_6: 3349,
    app_flat_6: 36,
    char_7: 'Boothill',
    app_rate_7: '17.7%',
    avg_round_7: 3302,
    app_flat_7: 51,
    char_8: 'Acheron',
    app_rate_8: '17.7%',
    avg_round_8: 3361,
    app_flat_8: 1,
    char_9: 'Luocha',
    app_rate_9: '12.11%',
    avg_round_9: 3328,
    app_flat_9: 37,
    char_10: 'fu-xuan',
    app_rate_10: '11.8%',
    avg_round_10: 3409,
    app_flat_10: 23,
    char_11: 'Jiaoqiu',
    app_rate_11: '11.18%',
    avg_round_11: 3334,
    app_flat_11: 3,
    char_12: 'trailblazer-imaginary',
    app_rate_12: '9.16%',
    avg_round_12: 3236,
    app_flat_12: 7,
    char_13: 'topaz',
    app_rate_13: '8.39%',
    avg_round_13: 3478,
    app_flat_13: 32,
    char_14: 'Firefly',
    app_rate_14: '7.45%',
    avg_round_14: 3212,
    app_flat_14: 2,
    char_15: 'Lingsha',
    app_rate_15: '6.06%',
    avg_round_15: 3455,
    app_flat_15: 19,
    char_16: 'Huohuo',
    app_rate_16: '5.28%',
    avg_round_16: 3422,
    app_flat_16: 10,
    char_17: 'Pela',
    app_rate_17: '4.5%',
    avg_round_17: 3256,
    app_flat_17: 4,
    char_18: 'Jade',
    app_rate_18: '4.19%',
    avg_round_18: 3470,
    app_flat_18: 11,
    char_19: 'Sparkle',
    app_rate_19: '3.42%',
    avg_round_19: 3151,
    app_flat_19: 6,
    char_20: 'silver-wolf',
    app_rate_20: '3.26%',
    avg_round_20: 3180,
    app_flat_20: 1,
    char_21: 'Blade',
    app_rate_21: '3.11%',
    avg_round_21: 3173,
    app_flat_21: 3,
    char_22: 'imbibitor-lunae',
    app_rate_22: '2.33%',
    avg_round_22: 3100,
    app_flat_22: 3,
    char_23: 'dr-ratio',
    app_rate_23: '2.17%',
    avg_round_23: 3249,
    app_flat_23: 6,
    char_24: 'Gepard',
    app_rate_24: '1.71%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Yunli',
    app_rate_25: '1.24%',
    avg_round_25: 3212,
    app_flat_25: 4,
    char_26: 'Kafka',
    app_rate_26: '0.93%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'jing-yuan',
    app_rate_27: '0.93%',
    avg_round_27: 3222,
    app_flat_27: 3,
    char_28: 'Tingyun',
    app_rate_28: '0.93%',
    avg_round_28: 3243,
    app_flat_28: 3,
    char_29: 'Clara',
    app_rate_29: '0.78%',
    avg_round_29: 3174,
    app_flat_29: 2,
    char_30: 'Bailu',
    app_rate_30: '0.78%',
    avg_round_30: 3365,
    app_flat_30: 3
  },
  {
    char: 'Pela',
    app: 6.26,
    char_1: 'Acheron',
    app_rate_1: '91.1%',
    avg_round_1: 3341,
    app_flat_1: 328,
    char_2: 'Jiaoqiu',
    app_rate_2: '50.74%',
    avg_round_2: 3388,
    app_flat_2: 227,
    char_3: 'fu-xuan',
    app_rate_3: '34.27%',
    avg_round_3: 3318,
    app_flat_3: 121,
    char_4: 'Gallagher',
    app_rate_4: '30.48%',
    avg_round_4: 3347,
    app_flat_4: 129,
    char_5: 'Aventurine',
    app_rate_5: '19.77%',
    avg_round_5: 3353,
    app_flat_5: 73,
    char_6: 'silver-wolf',
    app_rate_6: '17.79%',
    avg_round_6: 3230,
    app_flat_6: 69,
    char_7: 'Sparkle',
    app_rate_7: '11.04%',
    avg_round_7: 3066,
    app_flat_7: 2,
    char_8: 'Bronya',
    app_rate_8: '4.78%',
    avg_round_8: 3256,
    app_flat_8: 4,
    char_9: 'Lingsha',
    app_rate_9: '4.12%',
    avg_round_9: 3320,
    app_flat_9: 14,
    char_10: 'dr-ratio',
    app_rate_10: '4.12%',
    avg_round_10: 3275,
    app_flat_10: 17,
    char_11: 'Gepard',
    app_rate_11: '3.95%',
    avg_round_11: 3277,
    app_flat_11: 8,
    char_12: 'Guinaifen',
    app_rate_12: '3.46%',
    avg_round_12: 3294,
    app_flat_12: 17,
    char_13: 'Luocha',
    app_rate_13: '3.13%',
    avg_round_13: 3346,
    app_flat_13: 11,
    char_14: 'Robin',
    app_rate_14: '2.8%',
    avg_round_14: 3307,
    app_flat_14: 11,
    char_15: 'Kafka',
    app_rate_15: '2.8%',
    avg_round_15: 3218,
    app_flat_15: 10,
    char_16: 'ruan-mei',
    app_rate_16: '2.31%',
    avg_round_16: 3309,
    app_flat_16: 1,
    char_17: 'trailblazer-imaginary',
    app_rate_17: '2.31%',
    avg_round_17: 3253,
    app_flat_17: 12,
    char_18: 'Huohuo',
    app_rate_18: '2.14%',
    avg_round_18: 3189,
    app_flat_18: 5,
    char_19: 'Tingyun',
    app_rate_19: '1.15%',
    avg_round_19: 3217,
    app_flat_19: 6,
    char_20: 'Rappa',
    app_rate_20: '1.15%',
    avg_round_20: 3274,
    app_flat_20: 6,
    char_21: 'Firefly',
    app_rate_21: '1.15%',
    avg_round_21: 3210,
    app_flat_21: 6,
    char_22: 'Boothill',
    app_rate_22: '0.99%',
    avg_round_22: 3167,
    app_flat_22: 3,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.82%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Bailu',
    app_rate_24: '0.82%',
    avg_round_24: 3267,
    app_flat_24: 2,
    char_25: 'black-swan',
    app_rate_25: '0.66%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Feixiao',
    app_rate_26: '0.49%',
    avg_round_26: 3454,
    app_flat_26: 3,
    char_27: 'Welt',
    app_rate_27: '0.49%',
    avg_round_27: 3156,
    app_flat_27: 3,
    char_28: 'Himeko',
    app_rate_28: '0.33%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.16%',
    avg_round_29: 3264,
    app_flat_29: 1,
    char_30: 'Jade',
    app_rate_30: '0.16%',
    avg_round_30: 3304,
    app_flat_30: 1
  },
  {
    char: 'Tingyun',
    app: 5.69,
    char_1: 'Yunli',
    app_rate_1: '49.09%',
    avg_round_1: 3298,
    app_flat_1: 150,
    char_2: 'Sparkle',
    app_rate_2: '48.19%',
    avg_round_2: 3246,
    app_flat_2: 61,
    char_3: 'Huohuo',
    app_rate_3: '44.02%',
    avg_round_3: 3288,
    app_flat_3: 93,
    char_4: 'Robin',
    app_rate_4: '34.06%',
    avg_round_4: 3320,
    app_flat_4: 99,
    char_5: 'imbibitor-lunae',
    app_rate_5: '31.52%',
    avg_round_5: 3114,
    app_flat_5: 8,
    char_6: 'fu-xuan',
    app_rate_6: '15.94%',
    avg_round_6: 3224,
    app_flat_6: 29,
    char_7: 'Aventurine',
    app_rate_7: '13.95%',
    avg_round_7: 3345,
    app_flat_7: 32,
    char_8: 'Gallagher',
    app_rate_8: '10.33%',
    avg_round_8: 3256,
    app_flat_8: 16,
    char_9: 'Luocha',
    app_rate_9: '8.88%',
    avg_round_9: 3237,
    app_flat_9: 14,
    char_10: 'dr-ratio',
    app_rate_10: '7.97%',
    avg_round_10: 3264,
    app_flat_10: 26,
    char_11: 'ruan-mei',
    app_rate_11: '5.25%',
    avg_round_11: 3207,
    app_flat_11: 5,
    char_12: 'jing-yuan',
    app_rate_12: '4.53%',
    avg_round_12: 3126,
    app_flat_12: 3,
    char_13: 'Lingsha',
    app_rate_13: '3.44%',
    avg_round_13: 3262,
    app_flat_13: 9,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '3.08%',
    avg_round_14: 3279,
    app_flat_14: 8,
    char_15: 'Jade',
    app_rate_15: '2.36%',
    avg_round_15: 3299,
    app_flat_15: 4,
    char_16: 'Jiaoqiu',
    app_rate_16: '1.81%',
    avg_round_16: 3176,
    app_flat_16: 2,
    char_17: 'silver-wolf',
    app_rate_17: '1.63%',
    avg_round_17: 3201,
    app_flat_17: 6,
    char_18: 'Himeko',
    app_rate_18: '1.45%',
    avg_round_18: 3207,
    app_flat_18: 3,
    char_19: 'topaz',
    app_rate_19: '1.27%',
    avg_round_19: 3373,
    app_flat_19: 3,
    char_20: 'Pela',
    app_rate_20: '1.27%',
    avg_round_20: 3217,
    app_flat_20: 6,
    char_21: 'Bronya',
    app_rate_21: '1.09%',
    avg_round_21: 3243,
    app_flat_21: 3,
    char_22: 'Feixiao',
    app_rate_22: '1.09%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'trailblazer-imaginary',
    app_rate_23: '0.91%',
    avg_round_23: 3341,
    app_flat_23: 2,
    char_24: 'Lynx',
    app_rate_24: '0.91%',
    avg_round_24: 3111,
    app_flat_24: 1,
    char_25: 'black-swan',
    app_rate_25: '0.72%',
    avg_round_25: 3200,
    app_flat_25: 1,
    char_26: 'Kafka',
    app_rate_26: '0.54%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Serval',
    app_rate_27: '0.54%',
    avg_round_27: 3246,
    app_flat_27: 1,
    char_28: 'Hanya',
    app_rate_28: '0.54%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Acheron',
    app_rate_29: '0.54%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Clara',
    app_rate_30: '0.54%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    app: 5.46,
    char_1: 'Acheron',
    app_rate_1: '83.77%',
    avg_round_1: 3236,
    app_flat_1: 131,
    char_2: 'black-swan',
    app_rate_2: '38.3%',
    avg_round_2: 3210,
    app_flat_2: 44,
    char_3: 'fu-xuan',
    app_rate_3: '32.08%',
    avg_round_3: 3238,
    app_flat_3: 36,
    char_4: 'Jiaoqiu',
    app_rate_4: '23.4%',
    avg_round_4: 3341,
    app_flat_4: 37,
    char_5: 'Gallagher',
    app_rate_5: '20.75%',
    avg_round_5: 3250,
    app_flat_5: 40,
    char_6: 'ruan-mei',
    app_rate_6: '18.87%',
    avg_round_6: 3175,
    app_flat_6: 8,
    char_7: 'Huohuo',
    app_rate_7: '13.02%',
    avg_round_7: 3146,
    app_flat_7: 11,
    char_8: 'Aventurine',
    app_rate_8: '11.89%',
    avg_round_8: 3250,
    app_flat_8: 27,
    char_9: 'silver-wolf',
    app_rate_9: '9.62%',
    avg_round_9: 3221,
    app_flat_9: 18,
    char_10: 'Guinaifen',
    app_rate_10: '9.43%',
    avg_round_10: 3254,
    app_flat_10: 14,
    char_11: 'Sparkle',
    app_rate_11: '5.66%',
    avg_round_11: 3077,
    app_flat_11: 1,
    char_12: 'Luocha',
    app_rate_12: '4.91%',
    avg_round_12: 3200,
    app_flat_12: 10,
    char_13: 'Lingsha',
    app_rate_13: '4.72%',
    avg_round_13: 3286,
    app_flat_13: 8,
    char_14: 'Pela',
    app_rate_14: '3.21%',
    avg_round_14: 3218,
    app_flat_14: 10,
    char_15: 'Gepard',
    app_rate_15: '2.64%',
    avg_round_15: 3297,
    app_flat_15: 2,
    char_16: 'Robin',
    app_rate_16: '2.64%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Himeko',
    app_rate_17: '2.26%',
    avg_round_17: 3087,
    app_flat_17: 3,
    char_18: 'Jade',
    app_rate_18: '2.26%',
    avg_round_18: 3271,
    app_flat_18: 4,
    char_19: 'Bailu',
    app_rate_19: '2.08%',
    avg_round_19: 3210,
    app_flat_19: 2,
    char_20: 'trailblazer-imaginary',
    app_rate_20: '2.08%',
    avg_round_20: 3283,
    app_flat_20: 3,
    char_21: 'Bronya',
    app_rate_21: '1.13%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: '0.94%',
    avg_round_22: 2909,
    app_flat_22: 1,
    char_23: 'Serval',
    app_rate_23: '0.57%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Tingyun',
    app_rate_24: '0.57%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.57%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Rappa',
    app_rate_26: '0.57%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'trailblazer-fire',
    app_rate_27: '0.38%',
    avg_round_27: 3225,
    app_flat_27: 1,
    char_28: 'Lynx',
    app_rate_28: '0.38%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Sampo',
    app_rate_29: '0.38%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Firefly',
    app_rate_30: '0.19%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 4.56,
    char_1: 'Sparkle',
    app_rate_1: '88.01%',
    avg_round_1: 3129,
    app_flat_1: 31,
    char_2: 'Tingyun',
    app_rate_2: '39.37%',
    avg_round_2: 3114,
    app_flat_2: 8,
    char_3: 'Huohuo',
    app_rate_3: '31.9%',
    avg_round_3: 3144,
    app_flat_3: 7,
    char_4: 'ruan-mei',
    app_rate_4: '26.47%',
    avg_round_4: 3228,
    app_flat_4: 4,
    char_5: 'Robin',
    app_rate_5: '23.98%',
    avg_round_5: 3158,
    app_flat_5: 14,
    char_6: 'Luocha',
    app_rate_6: '20.36%',
    avg_round_6: 3121,
    app_flat_6: 6,
    char_7: 'Aventurine',
    app_rate_7: '18.55%',
    avg_round_7: 3170,
    app_flat_7: 12,
    char_8: 'Gallagher',
    app_rate_8: '16.06%',
    avg_round_8: 3086,
    app_flat_8: 5,
    char_9: 'fu-xuan',
    app_rate_9: '10.41%',
    avg_round_9: 3051,
    app_flat_9: 3,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '4.3%',
    avg_round_10: 3121,
    app_flat_10: 5,
    char_11: 'Bronya',
    app_rate_11: '3.39%',
    avg_round_11: 3100,
    app_flat_11: 3,
    char_12: 'Jiaoqiu',
    app_rate_12: '3.17%',
    avg_round_12: 2886,
    app_flat_12: 1,
    char_13: 'trailblazer-imaginary',
    app_rate_13: '2.49%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Lingsha',
    app_rate_14: '2.04%',
    avg_round_14: 3181,
    app_flat_14: 1,
    char_15: 'Hanya',
    app_rate_15: '1.58%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'silver-wolf',
    app_rate_16: '1.58%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Yukong',
    app_rate_17: '1.13%',
    avg_round_17: 3083,
    app_flat_17: 1,
    char_18: 'Pela',
    app_rate_18: '1.13%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jade',
    app_rate_19: '0.68%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Himeko',
    app_rate_20: '0.68%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Blade',
    app_rate_21: '0.68%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Boothill',
    app_rate_22: '0.68%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jing-yuan',
    app_rate_23: '0.23%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'dr-ratio',
    app_rate_24: '0.23%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Yunli',
    app_rate_25: '0.23%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Bailu',
    app_rate_26: '0.23%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Asta',
    app_rate_27: '0.23%',
    avg_round_27: 3049,
    app_flat_27: 1,
    char_28: 'topaz',
    app_rate_28: '0.23%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    app: 4.19,
    char_1: 'ruan-mei',
    app_rate_1: '45.81%',
    avg_round_1: 3353,
    app_flat_1: 73,
    char_2: 'Himeko',
    app_rate_2: '35.22%',
    avg_round_2: 3271,
    app_flat_2: 80,
    char_3: 'Gallagher',
    app_rate_3: '26.85%',
    avg_round_3: 3279,
    app_flat_3: 59,
    char_4: 'trailblazer-imaginary',
    app_rate_4: '22.17%',
    avg_round_4: 3328,
    app_flat_4: 40,
    char_5: 'Lingsha',
    app_rate_5: '21.92%',
    avg_round_5: 3508,
    app_flat_5: 36,
    char_6: 'Robin',
    app_rate_6: '19.7%',
    avg_round_6: 3347,
    app_flat_6: 15,
    char_7: 'fu-xuan',
    app_rate_7: '18.23%',
    avg_round_7: 3200,
    app_flat_7: 20,
    char_8: 'Sparkle',
    app_rate_8: '13.05%',
    avg_round_8: 3272,
    app_flat_8: 13,
    char_9: 'Aventurine',
    app_rate_9: '12.81%',
    avg_round_9: 3344,
    app_flat_9: 8,
    char_10: 'jing-yuan',
    app_rate_10: '9.11%',
    avg_round_10: 3222,
    app_flat_10: 13,
    char_11: 'Feixiao',
    app_rate_11: '8.13%',
    avg_round_11: 3101,
    app_flat_11: 1,
    char_12: 'Luocha',
    app_rate_12: '7.14%',
    avg_round_12: 3201,
    app_flat_12: 11,
    char_13: 'Bronya',
    app_rate_13: '6.65%',
    avg_round_13: 3470,
    app_flat_13: 11,
    char_14: 'Rappa',
    app_rate_14: '6.16%',
    avg_round_14: 3526,
    app_flat_14: 8,
    char_15: 'topaz',
    app_rate_15: '5.91%',
    avg_round_15: 3681,
    app_flat_15: 1,
    char_16: 'Acheron',
    app_rate_16: '5.91%',
    avg_round_16: 3268,
    app_flat_16: 5,
    char_17: 'Huohuo',
    app_rate_17: '4.68%',
    avg_round_17: 3254,
    app_flat_17: 6,
    char_18: 'Firefly',
    app_rate_18: '3.69%',
    avg_round_18: 3228,
    app_flat_18: 4,
    char_19: 'Tingyun',
    app_rate_19: '3.2%',
    avg_round_19: 3299,
    app_flat_19: 4,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '2.96%',
    avg_round_20: 3185,
    app_flat_20: 3,
    char_21: 'Kafka',
    app_rate_21: '2.96%',
    avg_round_21: 3271,
    app_flat_21: 4,
    char_22: 'Jiaoqiu',
    app_rate_22: '2.46%',
    avg_round_22: 3298,
    app_flat_22: 2,
    char_23: 'Blade',
    app_rate_23: '1.97%',
    avg_round_23: 3094,
    app_flat_23: 1,
    char_24: 'Xueyi',
    app_rate_24: '1.48%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Guinaifen',
    app_rate_25: '1.23%',
    avg_round_25: 3318,
    app_flat_25: 1,
    char_26: 'Qingque',
    app_rate_26: '1.23%',
    avg_round_26: 3210,
    app_flat_26: 3,
    char_27: 'dr-ratio',
    app_rate_27: '0.99%',
    avg_round_27: 3182,
    app_flat_27: 1,
    char_28: 'Lynx',
    app_rate_28: '0.99%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Asta',
    app_rate_29: '0.99%',
    avg_round_29: 3155,
    app_flat_29: 1,
    char_30: 'Bailu',
    app_rate_30: '0.99%',
    avg_round_30: 3305,
    app_flat_30: 3
  },
  {
    char: 'Boothill',
    app: 2.95,
    char_1: 'ruan-mei',
    app_rate_1: '78.32%',
    avg_round_1: 3400,
    app_flat_1: 122,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '57.34%',
    avg_round_2: 3416,
    app_flat_2: 100,
    char_3: 'Gallagher',
    app_rate_3: '49.65%',
    avg_round_3: 3388,
    app_flat_3: 86,
    char_4: 'Bronya',
    app_rate_4: '39.86%',
    avg_round_4: 3302,
    app_flat_4: 51,
    char_5: 'Aventurine',
    app_rate_5: '11.89%',
    avg_round_5: 3373,
    app_flat_5: 18,
    char_6: 'Luocha',
    app_rate_6: '11.54%',
    avg_round_6: 3268,
    app_flat_6: 20,
    char_7: 'Lingsha',
    app_rate_7: '11.54%',
    avg_round_7: 3430,
    app_flat_7: 20,
    char_8: 'Huohuo',
    app_rate_8: '9.44%',
    avg_round_8: 3300,
    app_flat_8: 6,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '4.9%',
    avg_round_9: 3268,
    app_flat_9: 8,
    char_10: 'Robin',
    app_rate_10: '4.55%',
    avg_round_10: 3208,
    app_flat_10: 4,
    char_11: 'fu-xuan',
    app_rate_11: '3.15%',
    avg_round_11: 3295,
    app_flat_11: 2,
    char_12: 'Pela',
    app_rate_12: '2.1%',
    avg_round_12: 3167,
    app_flat_12: 3,
    char_13: 'Feixiao',
    app_rate_13: '1.75%',
    avg_round_13: 3331,
    app_flat_13: 1,
    char_14: 'Firefly',
    app_rate_14: '1.75%',
    avg_round_14: 3323,
    app_flat_14: 1,
    char_15: 'Sparkle',
    app_rate_15: '1.4%',
    avg_round_15: 3218,
    app_flat_15: 1,
    char_16: 'Jiaoqiu',
    app_rate_16: '1.05%',
    avg_round_16: 3290,
    app_flat_16: 1,
    char_17: 'Acheron',
    app_rate_17: '1.05%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Yunli',
    app_rate_18: '1.05%',
    avg_round_18: 3204,
    app_flat_18: 3,
    char_19: 'Clara',
    app_rate_19: '1.05%',
    avg_round_19: 3079,
    app_flat_19: 1,
    char_20: 'Hanya',
    app_rate_20: '1.05%',
    avg_round_20: 3146,
    app_flat_20: 2,
    char_21: 'silver-wolf',
    app_rate_21: '1.05%',
    avg_round_21: 3041,
    app_flat_21: 1,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.05%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Tingyun',
    app_rate_23: '0.7%',
    avg_round_23: 3062,
    app_flat_23: 2,
    char_24: 'topaz',
    app_rate_24: '0.7%',
    avg_round_24: 3116,
    app_flat_24: 2,
    char_25: 'Luka',
    app_rate_25: '0.7%',
    avg_round_25: 3201,
    app_flat_25: 1,
    char_26: 'Guinaifen',
    app_rate_26: '0.35%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Rappa',
    app_rate_27: '0.35%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'dr-ratio',
    app_rate_28: '0.35%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Bailu',
    app_rate_29: '0.35%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    app: 2.93,
    char_1: 'Acheron',
    app_rate_1: '74.65%',
    avg_round_1: 3217,
    app_flat_1: 56,
    char_2: 'Kafka',
    app_rate_2: '71.48%',
    avg_round_2: 3210,
    app_flat_2: 44,
    char_3: 'fu-xuan',
    app_rate_3: '32.75%',
    avg_round_3: 3203,
    app_flat_3: 18,
    char_4: 'Huohuo',
    app_rate_4: '16.9%',
    avg_round_4: 3215,
    app_flat_4: 4,
    char_5: 'Gallagher',
    app_rate_5: '15.49%',
    avg_round_5: 3240,
    app_flat_5: 13,
    char_6: 'Jiaoqiu',
    app_rate_6: '15.14%',
    avg_round_6: 3290,
    app_flat_6: 12,
    char_7: 'Aventurine',
    app_rate_7: '14.79%',
    avg_round_7: 3246,
    app_flat_7: 18,
    char_8: 'ruan-mei',
    app_rate_8: '14.08%',
    avg_round_8: 3049,
    app_flat_8: 1,
    char_9: 'Luocha',
    app_rate_9: '8.1%',
    avg_round_9: 3219,
    app_flat_9: 7,
    char_10: 'Robin',
    app_rate_10: '7.75%',
    avg_round_10: 3409,
    app_flat_10: 3,
    char_11: 'Lingsha',
    app_rate_11: '4.93%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Sparkle',
    app_rate_12: '3.87%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'dr-ratio',
    app_rate_13: '3.52%',
    avg_round_13: 3409,
    app_flat_13: 3,
    char_14: 'Gepard',
    app_rate_14: '2.46%',
    avg_round_14: 3297,
    app_flat_14: 1,
    char_15: 'Bailu',
    app_rate_15: '2.46%',
    avg_round_15: 3116,
    app_flat_15: 1,
    char_16: 'Tingyun',
    app_rate_16: '1.41%',
    avg_round_16: 3200,
    app_flat_16: 1,
    char_17: 'Pela',
    app_rate_17: '1.41%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Asta',
    app_rate_18: '1.06%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'silver-wolf',
    app_rate_19: '1.06%',
    avg_round_19: 3115,
    app_flat_19: 1,
    char_20: 'Guinaifen',
    app_rate_20: '1.06%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Yunli',
    app_rate_21: '0.7%',
    avg_round_21: 3200,
    app_flat_21: 1,
    char_22: 'Feixiao',
    app_rate_22: '0.7%',
    avg_round_22: 3277,
    app_flat_22: 1,
    char_23: 'Sampo',
    app_rate_23: '0.7%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Clara',
    app_rate_24: '0.7%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bronya',
    app_rate_25: '0.7%',
    avg_round_25: 3277,
    app_flat_25: 1,
    char_26: 'Natasha',
    app_rate_26: '0.35%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Luka',
    app_rate_27: '0.35%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'topaz',
    app_rate_28: '0.35%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Jade',
    app_rate_29: '0.35%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Firefly',
    app_rate_30: '0.35%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Moze',
    app: 2.77,
    char_1: 'Robin',
    app_rate_1: '83.64%',
    avg_round_1: 3459,
    app_flat_1: 130,
    char_2: 'Feixiao',
    app_rate_2: '79.55%',
    avg_round_2: 3451,
    app_flat_2: 129,
    char_3: 'Aventurine',
    app_rate_3: '71.0%',
    avg_round_3: 3445,
    app_flat_3: 109,
    char_4: 'dr-ratio',
    app_rate_4: '19.33%',
    avg_round_4: 3368,
    app_flat_4: 26,
    char_5: 'Gallagher',
    app_rate_5: '8.92%',
    avg_round_5: 3470,
    app_flat_5: 15,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '8.18%',
    avg_round_6: 3296,
    app_flat_6: 17,
    char_7: 'fu-xuan',
    app_rate_7: '6.69%',
    avg_round_7: 3355,
    app_flat_7: 14,
    char_8: 'Luocha',
    app_rate_8: '5.95%',
    avg_round_8: 3451,
    app_flat_8: 9,
    char_9: 'Huohuo',
    app_rate_9: '4.46%',
    avg_round_9: 3378,
    app_flat_9: 4,
    char_10: 'topaz',
    app_rate_10: '3.72%',
    avg_round_10: 3355,
    app_flat_10: 7,
    char_11: 'Lingsha',
    app_rate_11: '2.23%',
    avg_round_11: 3447,
    app_flat_11: 5,
    char_12: 'Jiaoqiu',
    app_rate_12: '1.49%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '1.12%',
    avg_round_13: 3331,
    app_flat_13: 1,
    char_14: 'Sparkle',
    app_rate_14: '1.12%',
    avg_round_14: 3341,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: '0.74%',
    avg_round_15: 3358,
    app_flat_15: 1,
    char_16: 'jing-yuan',
    app_rate_16: '0.37%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Jade',
    app_rate_17: '0.37%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: '0.37%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Lynx',
    app_rate_19: '0.37%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Tingyun',
    app_rate_20: '0.37%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    app: 2.4,
    char_1: 'Acheron',
    app_rate_1: '87.55%',
    avg_round_1: 3300,
    app_flat_1: 71,
    char_2: 'Jiaoqiu',
    app_rate_2: '33.48%',
    avg_round_2: 3385,
    app_flat_2: 28,
    char_3: 'ruan-mei',
    app_rate_3: '31.76%',
    avg_round_3: 3220,
    app_flat_3: 10,
    char_4: 'Gallagher',
    app_rate_4: '30.9%',
    avg_round_4: 3313,
    app_flat_4: 42,
    char_5: 'fu-xuan',
    app_rate_5: '24.89%',
    avg_round_5: 3244,
    app_flat_5: 19,
    char_6: 'Kafka',
    app_rate_6: '21.46%',
    avg_round_6: 3254,
    app_flat_6: 14,
    char_7: 'trailblazer-imaginary',
    app_rate_7: '9.87%',
    avg_round_7: 3252,
    app_flat_7: 11,
    char_8: 'Pela',
    app_rate_8: '9.01%',
    avg_round_8: 3294,
    app_flat_8: 17,
    char_9: 'silver-wolf',
    app_rate_9: '8.15%',
    avg_round_9: 3190,
    app_flat_9: 7,
    char_10: 'Sparkle',
    app_rate_10: '7.3%',
    avg_round_10: 3334,
    app_flat_10: 2,
    char_11: 'Aventurine',
    app_rate_11: '6.87%',
    avg_round_11: 3275,
    app_flat_11: 9,
    char_12: 'Lingsha',
    app_rate_12: '6.01%',
    avg_round_12: 3309,
    app_flat_12: 10,
    char_13: 'Himeko',
    app_rate_13: '2.58%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Huohuo',
    app_rate_14: '2.58%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Luocha',
    app_rate_15: '2.58%',
    avg_round_15: 3320,
    app_flat_15: 3,
    char_16: 'Jade',
    app_rate_16: '2.15%',
    avg_round_16: 3318,
    app_flat_16: 1,
    char_17: 'Welt',
    app_rate_17: '2.15%',
    avg_round_17: 2904,
    app_flat_17: 1,
    char_18: 'Firefly',
    app_rate_18: '1.72%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Robin',
    app_rate_19: '1.29%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Bronya',
    app_rate_20: '1.29%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'black-swan',
    app_rate_21: '1.29%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Rappa',
    app_rate_22: '0.86%',
    avg_round_22: 3195,
    app_flat_22: 1,
    char_23: 'trailblazer-fire',
    app_rate_23: '0.86%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Asta',
    app_rate_24: '0.86%',
    avg_round_24: 3164,
    app_flat_24: 1,
    char_25: 'Boothill',
    app_rate_25: '0.43%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Gepard',
    app_rate_26: '0.43%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Tingyun',
    app_rate_27: '0.43%',
    avg_round_27: 2941,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.43%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Serval',
    app_rate_29: '0.43%',
    avg_round_29: 3261,
    app_flat_29: 1,
    char_30: 'Bailu',
    app_rate_30: '0.43%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    app: 1.36,
    char_1: 'Robin',
    app_rate_1: '85.61%',
    avg_round_1: 3262,
    app_flat_1: 57,
    char_2: 'Feixiao',
    app_rate_2: '33.33%',
    avg_round_2: 3354,
    app_flat_2: 29,
    char_3: 'Huohuo',
    app_rate_3: '28.03%',
    avg_round_3: 3185,
    app_flat_3: 16,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '24.24%',
    avg_round_4: 3117,
    app_flat_4: 12,
    char_5: 'Aventurine',
    app_rate_5: '21.21%',
    avg_round_5: 3243,
    app_flat_5: 18,
    char_6: 'fu-xuan',
    app_rate_6: '21.21%',
    avg_round_6: 3267,
    app_flat_6: 10,
    char_7: 'Luocha',
    app_rate_7: '16.67%',
    avg_round_7: 3291,
    app_flat_7: 12,
    char_8: 'Yunli',
    app_rate_8: '15.15%',
    avg_round_8: 3279,
    app_flat_8: 12,
    char_9: 'topaz',
    app_rate_9: '14.39%',
    avg_round_9: 3216,
    app_flat_9: 10,
    char_10: 'Sparkle',
    app_rate_10: '11.36%',
    avg_round_10: 3054,
    app_flat_10: 2,
    char_11: 'Lingsha',
    app_rate_11: '6.06%',
    avg_round_11: 3434,
    app_flat_11: 4,
    char_12: 'Gallagher',
    app_rate_12: '5.3%',
    avg_round_12: 3431,
    app_flat_12: 3,
    char_13: 'Bronya',
    app_rate_13: '3.79%',
    avg_round_13: 3174,
    app_flat_13: 2,
    char_14: 'dr-ratio',
    app_rate_14: '3.03%',
    avg_round_14: 3136,
    app_flat_14: 3,
    char_15: 'Boothill',
    app_rate_15: '2.27%',
    avg_round_15: 3079,
    app_flat_15: 1,
    char_16: 'Tingyun',
    app_rate_16: '2.27%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'black-swan',
    app_rate_17: '1.52%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: '0.76%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'silver-wolf',
    app_rate_19: '0.76%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Acheron',
    app_rate_20: '0.76%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Gepard',
    app_rate_21: '0.76%',
    avg_round_21: 3154,
    app_flat_21: 1,
    char_22: 'trailblazer-imaginary',
    app_rate_22: '0.76%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'ruan-mei',
    app_rate_23: '0.76%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    app: 0.96,
    char_1: 'ruan-mei',
    app_rate_1: '54.84%',
    avg_round_1: 3253,
    app_flat_1: 14,
    char_2: 'Gallagher',
    app_rate_2: '40.86%',
    avg_round_2: 3214,
    app_flat_2: 10,
    char_3: 'Jade',
    app_rate_3: '39.78%',
    avg_round_3: 3222,
    app_flat_3: 13,
    char_4: 'Sparkle',
    app_rate_4: '27.96%',
    avg_round_4: 3018,
    app_flat_4: 2,
    char_5: 'Tingyun',
    app_rate_5: '26.88%',
    avg_round_5: 3126,
    app_flat_5: 3,
    char_6: 'fu-xuan',
    app_rate_6: '21.51%',
    avg_round_6: 3188,
    app_flat_6: 5,
    char_7: 'trailblazer-imaginary',
    app_rate_7: '18.28%',
    avg_round_7: 3185,
    app_flat_7: 7,
    char_8: 'Lingsha',
    app_rate_8: '13.98%',
    avg_round_8: 3336,
    app_flat_8: 1,
    char_9: 'Robin',
    app_rate_9: '10.75%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Huohuo',
    app_rate_10: '8.6%',
    avg_round_10: 3122,
    app_flat_10: 3,
    char_11: 'Aventurine',
    app_rate_11: '7.53%',
    avg_round_11: 3061,
    app_flat_11: 1,
    char_12: 'Bronya',
    app_rate_12: '6.45%',
    avg_round_12: 3222,
    app_flat_12: 3,
    char_13: 'Himeko',
    app_rate_13: '4.3%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Jiaoqiu',
    app_rate_14: '3.23%',
    avg_round_14: 3123,
    app_flat_14: 1,
    char_15: 'Luocha',
    app_rate_15: '3.23%',
    avg_round_15: 3140,
    app_flat_15: 1,
    char_16: 'Firefly',
    app_rate_16: '2.15%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Rappa',
    app_rate_17: '1.08%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'imbibitor-lunae',
    app_rate_18: '1.08%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Moze',
    app_rate_19: '1.08%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'topaz',
    app_rate_20: '1.08%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: '1.08%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Acheron',
    app_rate_22: '1.08%',
    avg_round_22: 3123,
    app_flat_22: 1,
    char_23: 'Lynx',
    app_rate_23: '1.08%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '1.08%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: '1.08%',
    avg_round_25: 3327,
    app_flat_25: 1,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    app: 0.86,
    char_1: 'Acheron',
    app_rate_1: '73.49%',
    avg_round_1: 3276,
    app_flat_1: 12,
    char_2: 'Jiaoqiu',
    app_rate_2: '32.53%',
    avg_round_2: 3300,
    app_flat_2: 8,
    char_3: 'Pela',
    app_rate_3: '28.92%',
    avg_round_3: 3277,
    app_flat_3: 8,
    char_4: 'Robin',
    app_rate_4: '20.48%',
    avg_round_4: 3420,
    app_flat_4: 8,
    char_5: 'Feixiao',
    app_rate_5: '18.07%',
    avg_round_5: 3465,
    app_flat_5: 7,
    char_6: 'silver-wolf',
    app_rate_6: '18.07%',
    avg_round_6: 3226,
    app_flat_6: 5,
    char_7: 'Kafka',
    app_rate_7: '16.87%',
    avg_round_7: 3297,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: '16.87%',
    avg_round_8: 3271,
    app_flat_8: 1,
    char_9: 'Sparkle',
    app_rate_9: '15.66%',
    avg_round_9: 3247,
    app_flat_9: 1,
    char_10: 'Bronya',
    app_rate_10: '13.25%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '10.84%',
    avg_round_11: 3407,
    app_flat_11: 3,
    char_12: 'black-swan',
    app_rate_12: '8.43%',
    avg_round_12: 3297,
    app_flat_12: 1,
    char_13: 'topaz',
    app_rate_13: '7.23%',
    avg_round_13: 3445,
    app_flat_13: 5,
    char_14: 'trailblazer-imaginary',
    app_rate_14: '6.02%',
    avg_round_14: 3271,
    app_flat_14: 1,
    char_15: 'Firefly',
    app_rate_15: '3.61%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Rappa',
    app_rate_16: '2.41%',
    avg_round_16: 3271,
    app_flat_16: 1,
    char_17: 'Huohuo',
    app_rate_17: '1.2%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Guinaifen',
    app_rate_18: '1.2%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jade',
    app_rate_19: '1.2%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'dr-ratio',
    app_rate_20: '1.2%',
    avg_round_20: 3193,
    app_flat_20: 1,
    char_21: 'Yunli',
    app_rate_21: '1.2%',
    avg_round_21: 3154,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: '1.2%',
    avg_round_22: 3154,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    app: 0.74,
    char_1: 'Robin',
    app_rate_1: '50.0%',
    avg_round_1: 3414,
    app_flat_1: 26,
    char_2: 'Feixiao',
    app_rate_2: '48.61%',
    avg_round_2: 3437,
    app_flat_2: 26,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '30.56%',
    avg_round_3: 3448,
    app_flat_3: 18,
    char_4: 'ruan-mei',
    app_rate_4: '20.83%',
    avg_round_4: 3304,
    app_flat_4: 5,
    char_5: 'Acheron',
    app_rate_5: '18.06%',
    avg_round_5: 3173,
    app_flat_5: 2,
    char_6: 'Kafka',
    app_rate_6: '15.28%',
    avg_round_6: 3210,
    app_flat_6: 2,
    char_7: 'topaz',
    app_rate_7: '15.28%',
    avg_round_7: 3411,
    app_flat_7: 7,
    char_8: 'trailblazer-imaginary',
    app_rate_8: '11.11%',
    avg_round_8: 3206,
    app_flat_8: 3,
    char_9: 'Firefly',
    app_rate_9: '9.72%',
    avg_round_9: 3182,
    app_flat_9: 2,
    char_10: 'black-swan',
    app_rate_10: '9.72%',
    avg_round_10: 3116,
    app_flat_10: 1,
    char_11: 'silver-wolf',
    app_rate_11: '8.33%',
    avg_round_11: 3275,
    app_flat_11: 1,
    char_12: 'Yunli',
    app_rate_12: '6.94%',
    avg_round_12: 3200,
    app_flat_12: 4,
    char_13: 'Sparkle',
    app_rate_13: '6.94%',
    avg_round_13: 3135,
    app_flat_13: 1,
    char_14: 'Pela',
    app_rate_14: '6.94%',
    avg_round_14: 3267,
    app_flat_14: 2,
    char_15: 'Bronya',
    app_rate_15: '6.94%',
    avg_round_15: 3365,
    app_flat_15: 3,
    char_16: 'dr-ratio',
    app_rate_16: '5.56%',
    avg_round_16: 3333,
    app_flat_16: 3,
    char_17: 'Jade',
    app_rate_17: '5.56%',
    avg_round_17: 3305,
    app_flat_17: 3,
    char_18: 'Jiaoqiu',
    app_rate_18: '4.17%',
    avg_round_18: 3230,
    app_flat_18: 1,
    char_19: 'Tingyun',
    app_rate_19: '4.17%',
    avg_round_19: 3281,
    app_flat_19: 3,
    char_20: 'Himeko',
    app_rate_20: '2.78%',
    avg_round_20: 3285,
    app_flat_20: 1,
    char_21: 'Clara',
    app_rate_21: '1.39%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Moze',
    app_rate_22: '1.39%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: '1.39%',
    avg_round_23: 3255,
    app_flat_23: 1,
    char_24: 'Welt',
    app_rate_24: '1.39%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Rappa',
    app_rate_25: '1.39%',
    avg_round_25: 3255,
    app_flat_25: 1,
    char_26: 'jing-yuan',
    app_rate_26: '1.39%',
    avg_round_26: 3327,
    app_flat_26: 1,
    char_27: 'imbibitor-lunae',
    app_rate_27: '1.39%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Boothill',
    app_rate_28: '1.39%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Guinaifen',
    app_rate_29: '1.39%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    app: 0.73,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '54.93%',
    avg_round_1: 3187,
    app_flat_1: 23,
    char_2: 'Gallagher',
    app_rate_2: '38.03%',
    avg_round_2: 3157,
    app_flat_2: 18,
    char_3: 'Firefly',
    app_rate_3: '32.39%',
    avg_round_3: 3330,
    app_flat_3: 12,
    char_4: 'Himeko',
    app_rate_4: '16.9%',
    avg_round_4: 3022,
    app_flat_4: 8,
    char_5: 'Lingsha',
    app_rate_5: '16.9%',
    avg_round_5: 3262,
    app_flat_5: 8,
    char_6: 'Aventurine',
    app_rate_6: '15.49%',
    avg_round_6: 3432,
    app_flat_6: 5,
    char_7: 'topaz',
    app_rate_7: '12.68%',
    avg_round_7: 3426,
    app_flat_7: 5,
    char_8: 'ruan-mei',
    app_rate_8: '11.27%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '11.27%',
    avg_round_9: 3347,
    app_flat_9: 6,
    char_10: 'Rappa',
    app_rate_10: '8.45%',
    avg_round_10: 3178,
    app_flat_10: 3,
    char_11: 'Huohuo',
    app_rate_11: '8.45%',
    avg_round_11: 3082,
    app_flat_11: 2,
    char_12: 'Feixiao',
    app_rate_12: '8.45%',
    avg_round_12: 3498,
    app_flat_12: 6,
    char_13: 'Kafka',
    app_rate_13: '7.04%',
    avg_round_13: 2909,
    app_flat_13: 1,
    char_14: 'Robin',
    app_rate_14: '7.04%',
    avg_round_14: 3303,
    app_flat_14: 3,
    char_15: 'dr-ratio',
    app_rate_15: '5.63%',
    avg_round_15: 3208,
    app_flat_15: 1,
    char_16: 'Jiaoqiu',
    app_rate_16: '5.63%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Luocha',
    app_rate_17: '5.63%',
    avg_round_17: 3489,
    app_flat_17: 1,
    char_18: 'Jade',
    app_rate_18: '5.63%',
    avg_round_18: 3155,
    app_flat_18: 1,
    char_19: 'Acheron',
    app_rate_19: '4.23%',
    avg_round_19: 2909,
    app_flat_19: 1,
    char_20: 'black-swan',
    app_rate_20: '4.23%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Sparkle',
    app_rate_21: '4.23%',
    avg_round_21: 3049,
    app_flat_21: 1,
    char_22: 'Guinaifen',
    app_rate_22: '2.82%',
    avg_round_22: 3164,
    app_flat_22: 1,
    char_23: 'Yunli',
    app_rate_23: '2.82%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'fu-xuan',
    app_rate_24: '2.82%',
    avg_round_24: 3049,
    app_flat_24: 1,
    char_25: 'Jingliu',
    app_rate_25: '1.41%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Bailu',
    app_rate_26: '1.41%',
    avg_round_26: 3255,
    app_flat_26: 1,
    char_27: 'Hook',
    app_rate_27: '1.41%',
    avg_round_27: 3060,
    app_flat_27: 1,
    char_28: 'imbibitor-lunae',
    app_rate_28: '1.41%',
    avg_round_28: 3049,
    app_flat_28: 1,
    char_29: 'Lynx',
    app_rate_29: '1.41%',
    avg_round_29: 3548,
    app_flat_29: 1,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    app: 0.64,
    char_1: 'Acheron',
    app_rate_1: '72.58%',
    avg_round_1: 3262,
    app_flat_1: 15,
    char_2: 'Jiaoqiu',
    app_rate_2: '29.03%',
    avg_round_2: 3309,
    app_flat_2: 10,
    char_3: 'ruan-mei',
    app_rate_3: '24.19%',
    avg_round_3: 3182,
    app_flat_3: 2,
    char_4: 'Gallagher',
    app_rate_4: '20.97%',
    avg_round_4: 3235,
    app_flat_4: 8,
    char_5: 'Sparkle',
    app_rate_5: '19.35%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'trailblazer-imaginary',
    app_rate_6: '17.74%',
    avg_round_6: 3270,
    app_flat_6: 4,
    char_7: 'fu-xuan',
    app_rate_7: '17.74%',
    avg_round_7: 3238,
    app_flat_7: 2,
    char_8: 'Luocha',
    app_rate_8: '12.9%',
    avg_round_8: 3342,
    app_flat_8: 4,
    char_9: 'silver-wolf',
    app_rate_9: '12.9%',
    avg_round_9: 3137,
    app_flat_9: 1,
    char_10: 'Robin',
    app_rate_10: '9.68%',
    avg_round_10: 3364,
    app_flat_10: 2,
    char_11: 'Guinaifen',
    app_rate_11: '8.06%',
    avg_round_11: 2904,
    app_flat_11: 1,
    char_12: 'Aventurine',
    app_rate_12: '8.06%',
    avg_round_12: 3337,
    app_flat_12: 4,
    char_13: 'Firefly',
    app_rate_13: '6.45%',
    avg_round_13: 3280,
    app_flat_13: 1,
    char_14: 'Lingsha',
    app_rate_14: '6.45%',
    avg_round_14: 3244,
    app_flat_14: 2,
    char_15: 'dr-ratio',
    app_rate_15: '4.84%',
    avg_round_15: 3364,
    app_flat_15: 2,
    char_16: 'Pela',
    app_rate_16: '4.84%',
    avg_round_16: 3156,
    app_flat_16: 3,
    char_17: 'Himeko',
    app_rate_17: '4.84%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Rappa',
    app_rate_18: '4.84%',
    avg_round_18: 3438,
    app_flat_18: 1,
    char_19: 'Bronya',
    app_rate_19: '3.23%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'trailblazer-fire',
    app_rate_20: '1.61%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Bailu',
    app_rate_21: '1.61%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Huohuo',
    app_rate_22: '1.61%',
    avg_round_22: 3262,
    app_flat_22: 1,
    char_23: 'Kafka',
    app_rate_23: '1.61%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Feixiao',
    app_rate_24: '1.61%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'topaz',
    app_rate_25: '1.61%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Jade',
    app_rate_26: '1.61%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    app: 0.62,
    char_1: 'Robin',
    app_rate_1: '60.0%',
    avg_round_1: 3220,
    app_flat_1: 16,
    char_2: 'march-7th-swordmaster',
    app_rate_2: '33.33%',
    avg_round_2: 3198,
    app_flat_2: 13,
    char_3: 'Bronya',
    app_rate_3: '33.33%',
    avg_round_3: 3173,
    app_flat_3: 3,
    char_4: 'Luocha',
    app_rate_4: '30.0%',
    avg_round_4: 3116,
    app_flat_4: 4,
    char_5: 'Huohuo',
    app_rate_5: '26.67%',
    avg_round_5: 3265,
    app_flat_5: 4,
    char_6: 'Sparkle',
    app_rate_6: '21.67%',
    avg_round_6: 3040,
    app_flat_6: 2,
    char_7: 'Gallagher',
    app_rate_7: '16.67%',
    avg_round_7: 3137,
    app_flat_7: 7,
    char_8: 'fu-xuan',
    app_rate_8: '13.33%',
    avg_round_8: 3227,
    app_flat_8: 3,
    char_9: 'Jade',
    app_rate_9: '13.33%',
    avg_round_9: 3094,
    app_flat_9: 1,
    char_10: 'ruan-mei',
    app_rate_10: '10.0%',
    avg_round_10: 3162,
    app_flat_10: 2,
    char_11: 'Yunli',
    app_rate_11: '8.33%',
    avg_round_11: 3175,
    app_flat_11: 2,
    char_12: 'Feixiao',
    app_rate_12: '6.67%',
    avg_round_12: 3381,
    app_flat_12: 1,
    char_13: 'Lingsha',
    app_rate_13: '5.0%',
    avg_round_13: 3278,
    app_flat_13: 1,
    char_14: 'Aventurine',
    app_rate_14: '5.0%',
    avg_round_14: 3250,
    app_flat_14: 1,
    char_15: 'imbibitor-lunae',
    app_rate_15: '5.0%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Jiaoqiu',
    app_rate_16: '3.33%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'silver-wolf',
    app_rate_17: '3.33%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'trailblazer-imaginary',
    app_rate_18: '1.67%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Hanya',
    app_rate_19: '1.67%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Natasha',
    app_rate_20: '1.67%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    app: 0.56,
    char_1: 'Robin',
    app_rate_1: '50.0%',
    avg_round_1: 3479,
    app_flat_1: 15,
    char_2: 'Feixiao',
    app_rate_2: '37.04%',
    avg_round_2: 3497,
    app_flat_2: 15,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '35.19%',
    avg_round_3: 3450,
    app_flat_3: 14,
    char_4: 'Yunli',
    app_rate_4: '24.07%',
    avg_round_4: 3114,
    app_flat_4: 4,
    char_5: 'Sparkle',
    app_rate_5: '18.52%',
    avg_round_5: 3236,
    app_flat_5: 2,
    char_6: 'Acheron',
    app_rate_6: '18.52%',
    avg_round_6: 3295,
    app_flat_6: 4,
    char_7: 'Jiaoqiu',
    app_rate_7: '16.67%',
    avg_round_7: 3295,
    app_flat_7: 4,
    char_8: 'ruan-mei',
    app_rate_8: '14.81%',
    avg_round_8: 3183,
    app_flat_8: 3,
    char_9: 'Firefly',
    app_rate_9: '12.96%',
    avg_round_9: 3183,
    app_flat_9: 3,
    char_10: 'trailblazer-imaginary',
    app_rate_10: '12.96%',
    avg_round_10: 3183,
    app_flat_10: 3,
    char_11: 'topaz',
    app_rate_11: '12.96%',
    avg_round_11: 3594,
    app_flat_11: 4,
    char_12: 'Tingyun',
    app_rate_12: '9.26%',
    avg_round_12: 3111,
    app_flat_12: 1,
    char_13: 'silver-wolf',
    app_rate_13: '7.41%',
    avg_round_13: 3273,
    app_flat_13: 3,
    char_14: 'Bronya',
    app_rate_14: '7.41%',
    avg_round_14: 3394,
    app_flat_14: 2,
    char_15: 'Jade',
    app_rate_15: '7.41%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Kafka',
    app_rate_16: '3.7%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Himeko',
    app_rate_17: '1.85%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'jing-yuan',
    app_rate_18: '1.85%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Serval',
    app_rate_19: '1.85%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Pela',
    app_rate_20: '1.85%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Moze',
    app_rate_21: '1.85%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: '1.85%',
    avg_round_22: 3548,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    app: 0.37,
    char_1: 'Sparkle',
    app_rate_1: '97.22%',
    avg_round_1: 3072,
    app_flat_1: 8,
    char_2: 'fu-xuan',
    app_rate_2: '80.56%',
    avg_round_2: 3032,
    app_flat_2: 5,
    char_3: 'silver-wolf',
    app_rate_3: '69.44%',
    avg_round_3: 2990,
    app_flat_3: 5,
    char_4: 'Jade',
    app_rate_4: '13.89%',
    avg_round_4: 3210,
    app_flat_4: 3,
    char_5: 'ruan-mei',
    app_rate_5: '5.56%',
    avg_round_5: 3115,
    app_flat_5: 1,
    char_6: 'Huohuo',
    app_rate_6: '5.56%',
    avg_round_6: 3206,
    app_flat_6: 1,
    char_7: 'Lingsha',
    app_rate_7: '5.56%',
    avg_round_7: 3115,
    app_flat_7: 1,
    char_8: 'Gallagher',
    app_rate_8: '5.56%',
    avg_round_8: 2953,
    app_flat_8: 1,
    char_9: 'Tingyun',
    app_rate_9: '5.56%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Himeko',
    app_rate_10: '2.78%',
    avg_round_10: 3115,
    app_flat_10: 1,
    char_11: 'Luocha',
    app_rate_11: '2.78%',
    avg_round_11: 3079,
    app_flat_11: 1,
    char_12: 'Bronya',
    app_rate_12: '2.78%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Robin',
    app_rate_13: '2.78%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    app: 0.29,
    char_1: 'fu-xuan',
    app_rate_1: '96.43%',
    avg_round_1: 3057,
    app_flat_1: 1,
    char_2: 'silver-wolf',
    app_rate_2: '89.29%',
    avg_round_2: 3057,
    app_flat_2: 1,
    char_3: 'Sparkle',
    app_rate_3: '85.71%',
    avg_round_3: 3057,
    app_flat_3: 1,
    char_4: 'Robin',
    app_rate_4: '14.29%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Jade',
    app_rate_5: '7.14%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Huohuo',
    app_rate_6: '3.57%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: '3.57%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    app: 0.23,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '63.64%',
    avg_round_1: 3259,
    app_flat_1: 8,
    char_2: 'ruan-mei',
    app_rate_2: '59.09%',
    avg_round_2: 3237,
    app_flat_2: 6,
    char_3: 'Gallagher',
    app_rate_3: '45.45%',
    avg_round_3: 3228,
    app_flat_3: 7,
    char_4: 'Lingsha',
    app_rate_4: '27.27%',
    avg_round_4: 3432,
    app_flat_4: 2,
    char_5: 'Sparkle',
    app_rate_5: '18.18%',
    avg_round_5: 3246,
    app_flat_5: 1,
    char_6: 'Kafka',
    app_rate_6: '13.64%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Tingyun',
    app_rate_7: '13.64%',
    avg_round_7: 3246,
    app_flat_7: 1,
    char_8: 'Jade',
    app_rate_8: '13.64%',
    avg_round_8: 3354,
    app_flat_8: 1,
    char_9: 'Robin',
    app_rate_9: '9.09%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Huohuo',
    app_rate_10: '9.09%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Firefly',
    app_rate_11: '9.09%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Himeko',
    app_rate_12: '9.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Lynx',
    app_rate_13: '4.55%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Guinaifen',
    app_rate_14: '4.55%',
    avg_round_14: 3261,
    app_flat_14: 1,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    app: 0.2,
    char_1: 'ruan-mei',
    app_rate_1: '63.16%',
    avg_round_1: 3302,
    app_flat_1: 1,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '57.89%',
    avg_round_2: 3248,
    app_flat_2: 2,
    char_3: 'Jade',
    app_rate_3: '31.58%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Gallagher',
    app_rate_4: '26.32%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Sparkle',
    app_rate_5: '26.32%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Lingsha',
    app_rate_6: '21.05%',
    avg_round_6: 3248,
    app_flat_6: 2,
    char_7: 'Firefly',
    app_rate_7: '21.05%',
    avg_round_7: 3195,
    app_flat_7: 1,
    char_8: 'fu-xuan',
    app_rate_8: '21.05%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Huohuo',
    app_rate_9: '10.53%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Aventurine',
    app_rate_10: '5.26%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Rappa',
    app_rate_11: '5.26%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'silver-wolf',
    app_rate_12: '5.26%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Robin',
    app_rate_13: '5.26%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    app: 0.15,
    char_1: 'march-7th-swordmaster',
    app_rate_1: '60.0%',
    avg_round_1: 3268,
    app_flat_1: 6,
    char_2: 'Robin',
    app_rate_2: '60.0%',
    avg_round_2: 3268,
    app_flat_2: 6,
    char_3: 'ruan-mei',
    app_rate_3: '40.0%',
    avg_round_3: 3252,
    app_flat_3: 2,
    char_4: 'Gallagher',
    app_rate_4: '40.0%',
    avg_round_4: 3281,
    app_flat_4: 3,
    char_5: 'trailblazer-imaginary',
    app_rate_5: '33.33%',
    avg_round_5: 3252,
    app_flat_5: 2,
    char_6: 'fu-xuan',
    app_rate_6: '20.0%',
    avg_round_6: 3190,
    app_flat_6: 2,
    char_7: 'Huohuo',
    app_rate_7: '20.0%',
    avg_round_7: 3222,
    app_flat_7: 2,
    char_8: 'Aventurine',
    app_rate_8: '13.33%',
    avg_round_8: 3440,
    app_flat_8: 1,
    char_9: 'Lingsha',
    app_rate_9: '6.67%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Sparkle',
    app_rate_10: '6.67%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    app: 0.14,
    char_1: 'imbibitor-lunae',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Luocha',
    app_rate_2: '35.71%',
    avg_round_2: 3041,
    app_flat_2: 1,
    char_3: 'topaz',
    app_rate_3: '21.43%',
    avg_round_3: 3403,
    app_flat_3: 2,
    char_4: 'Aventurine',
    app_rate_4: '21.43%',
    avg_round_4: 3403,
    app_flat_4: 2,
    char_5: 'Tingyun',
    app_rate_5: '21.43%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Boothill',
    app_rate_6: '21.43%',
    avg_round_6: 3146,
    app_flat_6: 2,
    char_7: 'Sparkle',
    app_rate_7: '14.29%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: '14.29%',
    avg_round_8: 3257,
    app_flat_8: 1,
    char_9: 'Feixiao',
    app_rate_9: '14.29%',
    avg_round_9: 3403,
    app_flat_9: 2,
    char_10: 'Huohuo',
    app_rate_10: '14.29%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Gallagher',
    app_rate_11: '14.29%',
    avg_round_11: 3250,
    app_flat_11: 1,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '7.14%',
    avg_round_12: 3257,
    app_flat_12: 1,
    char_13: 'Robin',
    app_rate_13: '7.14%',
    avg_round_13: 3257,
    app_flat_13: 1,
    char_14: 'Blade',
    app_rate_14: '7.14%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'silver-wolf',
    app_rate_15: '7.14%',
    avg_round_15: 3041,
    app_flat_15: 1,
    char_16: 'dr-ratio',
    app_rate_16: '7.14%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Luka',
    app_rate_17: '7.14%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Yukong',
    app_rate_18: '7.14%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'trailblazer-imaginary',
    app_rate_19: '7.14%',
    avg_round_19: 3250,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    app: 0.09,
    char_1: 'imbibitor-lunae',
    app_rate_1: '55.56%',
    avg_round_1: 3083,
    app_flat_1: 1,
    char_2: 'Aventurine',
    app_rate_2: '44.44%',
    avg_round_2: 3372,
    app_flat_2: 2,
    char_3: 'Sparkle',
    app_rate_3: '33.33%',
    avg_round_3: 3122,
    app_flat_3: 2,
    char_4: 'Feixiao',
    app_rate_4: '22.22%',
    avg_round_4: 3372,
    app_flat_4: 2,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '22.22%',
    avg_round_5: 3372,
    app_flat_5: 2,
    char_6: 'Huohuo',
    app_rate_6: '22.22%',
    avg_round_6: 3083,
    app_flat_6: 1,
    char_7: 'Luocha',
    app_rate_7: '22.22%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Robin',
    app_rate_8: '11.11%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'dr-ratio',
    app_rate_9: '11.11%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'topaz',
    app_rate_10: '11.11%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Hanya',
    app_rate_11: '11.11%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Yunli',
    app_rate_12: '11.11%',
    avg_round_12: 3160,
    app_flat_12: 1,
    char_13: 'Lingsha',
    app_rate_13: '11.11%',
    avg_round_13: 3160,
    app_flat_13: 1,
    char_14: 'ruan-mei',
    app_rate_14: '11.11%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    app: 0.07,
    char_1: 'Acheron',
    app_rate_1: '100.0%',
    avg_round_1: 3344,
    app_flat_1: 2,
    char_2: 'Kafka',
    app_rate_2: '28.57%',
    avg_round_2: 3225,
    app_flat_2: 1,
    char_3: 'Guinaifen',
    app_rate_3: '28.57%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: '28.57%',
    avg_round_4: 3225,
    app_flat_4: 1,
    char_5: 'silver-wolf',
    app_rate_5: '28.57%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Welt',
    app_rate_6: '14.29%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Jiaoqiu',
    app_rate_7: '14.29%',
    avg_round_7: 3464,
    app_flat_7: 1,
    char_8: 'Pela',
    app_rate_8: '14.29%',
    avg_round_8: 3464,
    app_flat_8: 1,
    char_9: 'Sparkle',
    app_rate_9: '14.29%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Jade',
    app_rate_10: '14.29%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Robin',
    app_rate_11: '14.29%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    app: 0.05,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '60.0%',
    avg_round_1: 3224,
    app_flat_1: 2,
    char_2: 'Gallagher',
    app_rate_2: '60.0%',
    avg_round_2: 3224,
    app_flat_2: 2,
    char_3: 'ruan-mei',
    app_rate_3: '40.0%',
    avg_round_3: 3247,
    app_flat_3: 1,
    char_4: 'Boothill',
    app_rate_4: '40.0%',
    avg_round_4: 3201,
    app_flat_4: 1,
    char_5: 'Kafka',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'black-swan',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Luocha',
    app_rate_7: '20.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Hanya',
    app_rate_8: '20.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Huohuo',
    app_rate_9: '20.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    app: 0.05,
    char_1: 'Robin',
    app_rate_1: '40.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Lingsha',
    app_rate_2: '40.0%',
    avg_round_2: 3535,
    app_flat_2: 1,
    char_3: 'Gallagher',
    app_rate_3: '40.0%',
    avg_round_3: 3144,
    app_flat_3: 2,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '20.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Huohuo',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'trailblazer-imaginary',
    app_rate_6: '20.0%',
    avg_round_6: 3535,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: '20.0%',
    avg_round_7: 3535,
    app_flat_7: 1,
    char_8: 'Acheron',
    app_rate_8: '20.0%',
    avg_round_8: 3227,
    app_flat_8: 1,
    char_9: 'Jiaoqiu',
    app_rate_9: '20.0%',
    avg_round_9: 3227,
    app_flat_9: 1,
    char_10: 'Sparkle',
    app_rate_10: '20.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Himeko',
    app_rate_11: '20.0%',
    avg_round_11: 3060,
    app_flat_11: 1,
    char_12: 'Asta',
    app_rate_12: '20.0%',
    avg_round_12: 3060,
    app_flat_12: 1,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    app: 0.05,
    char_1: 'topaz',
    app_rate_1: '40.0%',
    avg_round_1: 3290,
    app_flat_1: 1,
    char_2: 'Robin',
    app_rate_2: '40.0%',
    avg_round_2: 3290,
    app_flat_2: 1,
    char_3: 'Bronya',
    app_rate_3: '40.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Acheron',
    app_rate_4: '20.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Kafka',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'black-swan',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Feixiao',
    app_rate_7: '20.0%',
    avg_round_7: 3290,
    app_flat_7: 1,
    char_8: 'Blade',
    app_rate_8: '20.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Sparkle',
    app_rate_9: '20.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Yunli',
    app_rate_10: '20.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Tingyun',
    app_rate_11: '20.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'dr-ratio',
    app_rate_12: '20.0%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.03,
    char_1: 'Robin',
    app_rate_1: '100.0%',
    avg_round_1: 3257,
    app_flat_1: 1,
    char_2: 'march-7th-swordmaster',
    app_rate_2: '66.67%',
    avg_round_2: 3257,
    app_flat_2: 1,
    char_3: 'Huohuo',
    app_rate_3: '66.67%',
    avg_round_3: 3257,
    app_flat_3: 1,
    char_4: 'Feixiao',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Gallagher',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    app: 0.03,
    char_1: 'Kafka',
    app_rate_1: '66.67%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'black-swan',
    app_rate_2: '66.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Luocha',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'topaz',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Jiaoqiu',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Aventurine',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Huohuo',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    app: 0.02,
    char_1: 'Lingsha',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Asta',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Sparkle',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Himeko',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'ruan-mei',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    app: 0.02,
    char_1: 'Jade',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Sparkle',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'fu-xuan',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '50.0%',
    avg_round_4: 3300,
    app_flat_4: 1,
    char_5: 'Robin',
    app_rate_5: '50.0%',
    avg_round_5: 3300,
    app_flat_5: 1,
    char_6: 'Aventurine',
    app_rate_6: '50.0%',
    avg_round_6: 3300,
    app_flat_6: 1,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    app: 0.02,
    char_1: 'Jade',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Gallagher',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '50.0%',
    avg_round_4: 3488,
    app_flat_4: 1,
    char_5: 'Robin',
    app_rate_5: '50.0%',
    avg_round_5: 3488,
    app_flat_5: 1,
    char_6: 'Aventurine',
    app_rate_6: '50.0%',
    avg_round_6: 3488,
    app_flat_6: 1,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.01,
    char_1: 'Feixiao',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'topaz',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Robin',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];

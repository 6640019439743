import React from 'react';
import { Table } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import {
  faSquareCheck,
  faSquareXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export const ProfileHarmonyEnergy: React.FC = () => {
  return (
    <>
      <div className={`content-header Imaginary`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Calculations
      </div>
      <h6>E6 Rotation - energy calcs</h6>
      <p>
        The table below shows how much energy an E6 Harmony Trailblazer gains
        with 3 Skill rotation (so you can have a permanent uptime on the
        Ultimate buff) based on the Light Cone and Relic choices. The E6 upgrade
        makes a massive difference when it comes to how much energy you generate
        and in some cases, you will be able to achieve a 2 Skill rotation or
        switch out to a Break Effect Rope fully instead of an ERR one.
      </p>
      <Table bordered responsive className="robin-lc-table">
        <thead>
          <tr>
            <th>Light Cone</th>
            <th>ERR Rope</th>
            <th>ERR Set</th>
            <th>Total ERR</th>
            <th>Rotation Energy</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="with-cone multiple">
              <div className="cone">
                <HSRLightCone
                  slug="past-self-in-mirror"
                  mode="min"
                  superImp="1"
                />
              </div>
              <div className="cone">
                <HSRLightCone
                  slug="dance-dance-dance"
                  mode="min"
                  superImp="5"
                />
              </div>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>24%</td>
            <td className="green">163.0</td>
          </tr>
          <tr>
            <td className="with-cone multiple">
              <div className="cone">
                <HSRLightCone
                  slug="past-self-in-mirror"
                  mode="min"
                  superImp="1"
                />
              </div>
              <div className="cone">
                <HSRLightCone
                  slug="dance-dance-dance"
                  mode="min"
                  superImp="5"
                />
              </div>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>19%</td>
            <td className="green">156.5</td>
          </tr>
          <tr>
            <td className="with-cone multiple">
              <div className="cone">
                <HSRLightCone
                  slug="past-self-in-mirror"
                  mode="min"
                  superImp="1"
                />
              </div>
              <div className="cone">
                <HSRLightCone
                  slug="dance-dance-dance"
                  mode="min"
                  superImp="5"
                />
              </div>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>0%</td>
            <td className="red">131.0</td>
          </tr>
          <tr>
            <td className="with-cone multiple">
              <div className="cone">
                <HSRLightCone
                  slug="memories-of-the-past"
                  mode="min"
                  superImp="5"
                />
              </div>
              <div className="cone">
                <HSRLightCone slug="meshing-cogs" mode="min" superImp="5" />
              </div>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>24%</td>
            <td className="green">192.9</td>
          </tr>
          <tr>
            <td className="with-cone multiple">
              <div className="cone">
                <HSRLightCone
                  slug="memories-of-the-past"
                  mode="min"
                  superImp="5"
                />
              </div>
              <div className="cone">
                <HSRLightCone slug="meshing-cogs" mode="min" superImp="5" />
              </div>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>19%</td>
            <td className="green">185.1</td>
          </tr>
          <tr>
            <td className="with-cone multiple">
              <div className="cone">
                <HSRLightCone
                  slug="memories-of-the-past"
                  mode="min"
                  superImp="5"
                />
              </div>
              <div className="cone">
                <HSRLightCone slug="meshing-cogs" mode="min" superImp="5" />
              </div>
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>0%</td>
            <td className="green">155.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="but-the-battle-isnt-over"
                mode="min"
                superImp="1"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>34%</td>
            <td className="green">176.1</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="but-the-battle-isnt-over"
                mode="min"
                superImp="1"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>29%</td>
            <td className="green">169.6</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="but-the-battle-isnt-over"
                mode="min"
                superImp="1"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>10%</td>
            <td className="green">144.1</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export const MoCDuos = [
  {
    char: 'topaz',
    round: 5.84,
    char_1: 'Robin',
    app_rate_1: 90.42,
    avg_round_1: 5.68,
    app_flat_1: 2008,
    char_2: 'Feixiao',
    app_rate_2: 83.34,
    avg_round_2: 5.5,
    app_flat_2: 1903,
    char_3: 'Aventurine',
    app_rate_3: 63.06,
    avg_round_3: 5.48,
    app_flat_3: 1375,
    char_4: 'fu-xuan',
    app_rate_4: 17.13,
    avg_round_4: 6.37,
    app_flat_4: 404,
    char_5: 'Lingsha',
    app_rate_5: 6.18,
    avg_round_5: 6.15,
    app_flat_5: 151,
    char_6: 'Huohuo',
    app_rate_6: 5.63,
    avg_round_6: 6.81,
    app_flat_6: 124,
    char_7: 'Yunli',
    app_rate_7: 4.96,
    avg_round_7: 7.32,
    app_flat_7: 130,
    char_8: 'dr-ratio',
    app_rate_8: 4.91,
    avg_round_8: 8.06,
    app_flat_8: 77,
    char_9: 'Clara',
    app_rate_9: 3.72,
    avg_round_9: 8.7,
    app_flat_9: 91,
    char_10: 'Luocha',
    app_rate_10: 3.67,
    avg_round_10: 7.23,
    app_flat_10: 100,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 3.4,
    avg_round_11: 8.37,
    app_flat_11: 92,
    char_12: 'Sparkle',
    app_rate_12: 2.68,
    avg_round_12: 7.17,
    app_flat_12: 60,
    char_13: 'Gallagher',
    app_rate_13: 2.3,
    avg_round_13: 6.45,
    app_flat_13: 62,
    char_14: 'Himeko',
    app_rate_14: 1.46,
    avg_round_14: 8.38,
    app_flat_14: 29,
    char_15: 'Bronya',
    app_rate_15: 1.46,
    avg_round_15: 6.86,
    app_flat_15: 29,
    char_16: 'ruan-mei',
    app_rate_16: 1.35,
    avg_round_16: 9.0,
    app_flat_16: 27,
    char_17: 'Moze',
    app_rate_17: 0.72,
    avg_round_17: 7.05,
    app_flat_17: 19,
    char_18: 'Bailu',
    app_rate_18: 0.54,
    avg_round_18: 8.67,
    app_flat_18: 15,
    char_19: 'Gepard',
    app_rate_19: 0.47,
    avg_round_19: 7,
    app_flat_19: 17,
    char_20: 'Jiaoqiu',
    app_rate_20: 0.41,
    avg_round_20: 8,
    app_flat_20: 7,
    char_21: 'Jade',
    app_rate_21: 0.38,
    avg_round_21: 7,
    app_flat_21: 1,
    char_22: 'Tingyun',
    app_rate_22: 0.36,
    avg_round_22: 8.82,
    app_flat_22: 11,
    char_23: 'Asta',
    app_rate_23: 0.25,
    avg_round_23: 10.0,
    app_flat_23: 4,
    char_24: 'Blade',
    app_rate_24: 0.16,
    avg_round_24: 9.67,
    app_flat_24: 3,
    char_25: 'march-7th',
    app_rate_25: 0.16,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'Hanya',
    app_rate_26: 0.14,
    avg_round_26: 8.5,
    app_flat_26: 2,
    char_27: 'Lynx',
    app_rate_27: 0.14,
    avg_round_27: 6.75,
    app_flat_27: 4,
    char_28: 'silver-wolf',
    app_rate_28: 0.11,
    avg_round_28: 8.67,
    app_flat_28: 3,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.11,
    avg_round_29: 9,
    app_flat_29: 3,
    char_30: 'jing-yuan',
    app_rate_30: 0.07,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Feixiao',
    round: 6.01,
    char_1: 'Robin',
    app_rate_1: 90.21,
    avg_round_1: 5.81,
    app_flat_1: 3583,
    char_2: 'topaz',
    app_rate_2: 52.49,
    avg_round_2: 5.5,
    app_flat_2: 1903,
    char_3: 'Aventurine',
    app_rate_3: 52.43,
    avg_round_3: 5.56,
    app_flat_3: 2049,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 34.66,
    avg_round_4: 6.49,
    app_flat_4: 1631,
    char_5: 'fu-xuan',
    app_rate_5: 22.27,
    avg_round_5: 6.52,
    app_flat_5: 921,
    char_6: 'Moze',
    app_rate_6: 7.44,
    avg_round_6: 6.41,
    app_flat_6: 301,
    char_7: 'Lingsha',
    app_rate_7: 6.56,
    avg_round_7: 5.82,
    app_flat_7: 284,
    char_8: 'Huohuo',
    app_rate_8: 6.26,
    avg_round_8: 6.81,
    app_flat_8: 246,
    char_9: 'Luocha',
    app_rate_9: 5.05,
    avg_round_9: 6.84,
    app_flat_9: 221,
    char_10: 'Gallagher',
    app_rate_10: 4.29,
    avg_round_10: 6.08,
    app_flat_10: 196,
    char_11: 'Sparkle',
    app_rate_11: 3.88,
    avg_round_11: 7.61,
    app_flat_11: 146,
    char_12: 'Bronya',
    app_rate_12: 3.56,
    avg_round_12: 7.03,
    app_flat_12: 134,
    char_13: 'Yunli',
    app_rate_13: 3.25,
    avg_round_13: 6.88,
    app_flat_13: 133,
    char_14: 'Clara',
    app_rate_14: 1.72,
    avg_round_14: 8.22,
    app_flat_14: 79,
    char_15: 'ruan-mei',
    app_rate_15: 1.09,
    avg_round_15: 9.13,
    app_flat_15: 29,
    char_16: 'Bailu',
    app_rate_16: 0.95,
    avg_round_16: 7.71,
    app_flat_16: 48,
    char_17: 'Jiaoqiu',
    app_rate_17: 0.79,
    avg_round_17: 6.97,
    app_flat_17: 30,
    char_18: 'Gepard',
    app_rate_18: 0.74,
    avg_round_18: 7.68,
    app_flat_18: 37,
    char_19: 'Jade',
    app_rate_19: 0.64,
    avg_round_19: 8,
    app_flat_19: 4,
    char_20: 'Himeko',
    app_rate_20: 0.31,
    avg_round_20: 8.11,
    app_flat_20: 18,
    char_21: 'Blade',
    app_rate_21: 0.23,
    avg_round_21: 8.22,
    app_flat_21: 9,
    char_22: 'Lynx',
    app_rate_22: 0.16,
    avg_round_22: 7.75,
    app_flat_22: 8,
    char_23: 'black-swan',
    app_rate_23: 0.13,
    avg_round_23: 9,
    app_flat_23: 3,
    char_24: 'Hanya',
    app_rate_24: 0.1,
    avg_round_24: 8.67,
    app_flat_24: 3,
    char_25: 'Acheron',
    app_rate_25: 0.1,
    avg_round_25: 8,
    app_flat_25: 3,
    char_26: 'march-7th',
    app_rate_26: 0.1,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Firefly',
    app_rate_27: 0.09,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Asta',
    app_rate_28: 0.07,
    avg_round_28: 8.33,
    app_flat_28: 3,
    char_29: 'silver-wolf',
    app_rate_29: 0.07,
    avg_round_29: 9,
    app_flat_29: 1,
    char_30: 'Tingyun',
    app_rate_30: 0.06,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Aventurine',
    round: 6.08,
    char_1: 'Robin',
    app_rate_1: 77.59,
    avg_round_1: 5.63,
    app_flat_1: 2087,
    char_2: 'Feixiao',
    app_rate_2: 72.18,
    avg_round_2: 5.56,
    app_flat_2: 2049,
    char_3: 'topaz',
    app_rate_3: 54.67,
    avg_round_3: 5.48,
    app_flat_3: 1375,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 18.68,
    avg_round_4: 6.47,
    app_flat_4: 678,
    char_5: 'Acheron',
    app_rate_5: 12.11,
    avg_round_5: 8.01,
    app_flat_5: 277,
    char_6: 'Jiaoqiu',
    app_rate_6: 11.12,
    avg_round_6: 7.75,
    app_flat_6: 256,
    char_7: 'Moze',
    app_rate_7: 7.8,
    avg_round_7: 6.33,
    app_flat_7: 216,
    char_8: 'Sparkle',
    app_rate_8: 7.74,
    avg_round_8: 7.78,
    app_flat_8: 129,
    char_9: 'dr-ratio',
    app_rate_9: 5.24,
    avg_round_9: 8.15,
    app_flat_9: 95,
    char_10: 'Pela',
    app_rate_10: 4.2,
    avg_round_10: 8.13,
    app_flat_10: 147,
    char_11: 'ruan-mei',
    app_rate_11: 2.99,
    avg_round_11: 8.57,
    app_flat_11: 69,
    char_12: 'Yunli',
    app_rate_12: 2.91,
    avg_round_12: 7.13,
    app_flat_12: 93,
    char_13: 'Bronya',
    app_rate_13: 2.91,
    avg_round_13: 7.84,
    app_flat_13: 86,
    char_14: 'black-swan',
    app_rate_14: 2.6,
    avg_round_14: 8.36,
    app_flat_14: 56,
    char_15: 'Clara',
    app_rate_15: 2.58,
    avg_round_15: 8.81,
    app_flat_15: 72,
    char_16: 'silver-wolf',
    app_rate_16: 2.54,
    avg_round_16: 9.19,
    app_flat_16: 72,
    char_17: 'Himeko',
    app_rate_17: 2.29,
    avg_round_17: 8.3,
    app_flat_17: 58,
    char_18: 'Kafka',
    app_rate_18: 1.6,
    avg_round_18: 8.73,
    app_flat_18: 30,
    char_19: 'Tingyun',
    app_rate_19: 1.48,
    avg_round_19: 7.83,
    app_flat_19: 41,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 1.39,
    avg_round_20: 8.17,
    app_flat_20: 36,
    char_21: 'Firefly',
    app_rate_21: 0.94,
    avg_round_21: 7.91,
    app_flat_21: 23,
    char_22: 'Jade',
    app_rate_22: 0.92,
    avg_round_22: 8,
    app_flat_22: 4,
    char_23: 'Boothill',
    app_rate_23: 0.72,
    avg_round_23: 9.0,
    app_flat_23: 24,
    char_24: 'Guinaifen',
    app_rate_24: 0.63,
    avg_round_24: 9.67,
    app_flat_24: 22,
    char_25: 'imbibitor-lunae',
    app_rate_25: 0.59,
    avg_round_25: 9.2,
    app_flat_25: 5,
    char_26: 'jing-yuan',
    app_rate_26: 0.31,
    avg_round_26: 8.67,
    app_flat_26: 6,
    char_27: 'Asta',
    app_rate_27: 0.29,
    avg_round_27: 10.0,
    app_flat_27: 6,
    char_28: 'Sampo',
    app_rate_28: 0.18,
    avg_round_28: 9,
    app_flat_28: 3,
    char_29: 'Hanya',
    app_rate_29: 0.18,
    avg_round_29: 8.75,
    app_flat_29: 4,
    char_30: 'Herta',
    app_rate_30: 0.06,
    avg_round_30: 8.5,
    app_flat_30: 2
  },
  {
    char: 'Robin',
    round: 6.1,
    char_1: 'Feixiao',
    app_rate_1: 75.61,
    avg_round_1: 5.81,
    app_flat_1: 3583,
    char_2: 'topaz',
    app_rate_2: 47.73,
    avg_round_2: 5.68,
    app_flat_2: 2008,
    char_3: 'Aventurine',
    app_rate_3: 47.25,
    avg_round_3: 5.63,
    app_flat_3: 2087,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 28.61,
    avg_round_4: 6.43,
    app_flat_4: 1580,
    char_5: 'fu-xuan',
    app_rate_5: 20.06,
    avg_round_5: 6.55,
    app_flat_5: 939,
    char_6: 'Huohuo',
    app_rate_6: 11.48,
    avg_round_6: 6.98,
    app_flat_6: 482,
    char_7: 'Yunli',
    app_rate_7: 10.37,
    avg_round_7: 7.18,
    app_flat_7: 485,
    char_8: 'Lingsha',
    app_rate_8: 6.1,
    avg_round_8: 6.04,
    app_flat_8: 310,
    char_9: 'Luocha',
    app_rate_9: 5.91,
    avg_round_9: 7.28,
    app_flat_9: 284,
    char_10: 'Moze',
    app_rate_10: 5.79,
    avg_round_10: 6.18,
    app_flat_10: 268,
    char_11: 'Gallagher',
    app_rate_11: 5.26,
    avg_round_11: 6.43,
    app_flat_11: 261,
    char_12: 'Clara',
    app_rate_12: 4.87,
    avg_round_12: 8.15,
    app_flat_12: 223,
    char_13: 'Sparkle',
    app_rate_13: 4.6,
    avg_round_13: 7.43,
    app_flat_13: 97,
    char_14: 'Tingyun',
    app_rate_14: 3.95,
    avg_round_14: 7.11,
    app_flat_14: 188,
    char_15: 'dr-ratio',
    app_rate_15: 3.39,
    avg_round_15: 8.14,
    app_flat_15: 108,
    char_16: 'Jiaoqiu',
    app_rate_16: 2.56,
    avg_round_16: 6.79,
    app_flat_16: 72,
    char_17: 'Acheron',
    app_rate_17: 2.36,
    avg_round_17: 6.9,
    app_flat_17: 48,
    char_18: 'Himeko',
    app_rate_18: 2.31,
    avg_round_18: 8.27,
    app_flat_18: 102,
    char_19: 'Bronya',
    app_rate_19: 1.78,
    avg_round_19: 6.18,
    app_flat_19: 38,
    char_20: 'black-swan',
    app_rate_20: 1.34,
    avg_round_20: 9.12,
    app_flat_20: 17,
    char_21: 'Kafka',
    app_rate_21: 1.27,
    avg_round_21: 9.12,
    app_flat_21: 17,
    char_22: 'Bailu',
    app_rate_22: 0.9,
    avg_round_22: 7.76,
    app_flat_22: 50,
    char_23: 'Blade',
    app_rate_23: 0.88,
    avg_round_23: 9.12,
    app_flat_23: 30,
    char_24: 'Jade',
    app_rate_24: 0.86,
    avg_round_24: 7.67,
    app_flat_24: 6,
    char_25: 'imbibitor-lunae',
    app_rate_25: 0.71,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Gepard',
    app_rate_26: 0.62,
    avg_round_26: 7.56,
    app_flat_26: 34,
    char_27: 'ruan-mei',
    app_rate_27: 0.45,
    avg_round_27: 9.33,
    app_flat_27: 6,
    char_28: 'silver-wolf',
    app_rate_28: 0.44,
    avg_round_28: 9.58,
    app_flat_28: 12,
    char_29: 'Lynx',
    app_rate_29: 0.37,
    avg_round_29: 8.22,
    app_flat_29: 17,
    char_30: 'Pela',
    app_rate_30: 0.37,
    avg_round_30: 7.6,
    app_flat_30: 5
  },
  {
    char: 'Lingsha',
    round: 6.16,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 79.91,
    avg_round_1: 6.13,
    app_flat_1: 995,
    char_2: 'ruan-mei',
    app_rate_2: 79.39,
    avg_round_2: 6.08,
    app_flat_2: 981,
    char_3: 'Firefly',
    app_rate_3: 74.61,
    avg_round_3: 6.09,
    app_flat_3: 873,
    char_4: 'Robin',
    app_rate_4: 14.0,
    avg_round_4: 6.04,
    app_flat_4: 310,
    char_5: 'Feixiao',
    app_rate_5: 12.61,
    avg_round_5: 5.82,
    app_flat_5: 284,
    char_6: 'topaz',
    app_rate_6: 7.48,
    avg_round_6: 6.15,
    app_flat_6: 151,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 6.58,
    avg_round_7: 6.34,
    app_flat_7: 168,
    char_8: 'Himeko',
    app_rate_8: 4.15,
    avg_round_8: 6.6,
    app_flat_8: 92,
    char_9: 'Sparkle',
    app_rate_9: 2.76,
    avg_round_9: 7.83,
    app_flat_9: 30,
    char_10: 'Acheron',
    app_rate_10: 2.43,
    avg_round_10: 8.88,
    app_flat_10: 32,
    char_11: 'Boothill',
    app_rate_11: 1.77,
    avg_round_11: 6.82,
    app_flat_11: 34,
    char_12: 'Bronya',
    app_rate_12: 1.53,
    avg_round_12: 7.12,
    app_flat_12: 16,
    char_13: 'Jiaoqiu',
    app_rate_13: 1.42,
    avg_round_13: 8.22,
    app_flat_13: 15,
    char_14: 'Kafka',
    app_rate_14: 1.15,
    avg_round_14: 9.33,
    app_flat_14: 22,
    char_15: 'Yunli',
    app_rate_15: 1.12,
    avg_round_15: 7.38,
    app_flat_15: 24,
    char_16: 'black-swan',
    app_rate_16: 1.04,
    avg_round_16: 9.38,
    app_flat_16: 16,
    char_17: 'Pela',
    app_rate_17: 0.9,
    avg_round_17: 7.54,
    app_flat_17: 13,
    char_18: 'silver-wolf',
    app_rate_18: 0.79,
    avg_round_18: 8.5,
    app_flat_18: 10,
    char_19: 'Moze',
    app_rate_19: 0.71,
    avg_round_19: 6.1,
    app_flat_19: 20,
    char_20: 'Clara',
    app_rate_20: 0.68,
    avg_round_20: 8.17,
    app_flat_20: 10,
    char_21: 'Tingyun',
    app_rate_21: 0.66,
    avg_round_21: 8.91,
    app_flat_21: 11,
    char_22: 'Asta',
    app_rate_22: 0.66,
    avg_round_22: 8.62,
    app_flat_22: 14,
    char_23: 'Jade',
    app_rate_23: 0.63,
    avg_round_23: 5.4,
    app_flat_23: 5,
    char_24: 'dr-ratio',
    app_rate_24: 0.46,
    avg_round_24: 8.78,
    app_flat_24: 9,
    char_25: 'Blade',
    app_rate_25: 0.41,
    avg_round_25: 7.57,
    app_flat_25: 7,
    char_26: 'Guinaifen',
    app_rate_26: 0.41,
    avg_round_26: 9.4,
    app_flat_26: 9,
    char_27: 'Gallagher',
    app_rate_27: 0.3,
    avg_round_27: 10.0,
    app_flat_27: 4,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.25,
    avg_round_28: 7,
    app_flat_28: 1,
    char_29: 'Jingliu',
    app_rate_29: 0.22,
    avg_round_29: 7.5,
    app_flat_29: 2,
    char_30: 'jing-yuan',
    app_rate_30: 0.16,
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'Moze',
    round: 6.28,
    char_1: 'Feixiao',
    app_rate_1: 88.81,
    avg_round_1: 6.41,
    app_flat_1: 301,
    char_2: 'Robin',
    app_rate_2: 82.54,
    avg_round_2: 6.18,
    app_flat_2: 268,
    char_3: 'Aventurine',
    app_rate_3: 67.63,
    avg_round_3: 6.33,
    app_flat_3: 216,
    char_4: 'fu-xuan',
    app_rate_4: 15.59,
    avg_round_4: 7.04,
    app_flat_4: 50,
    char_5: 'dr-ratio',
    app_rate_5: 6.78,
    avg_round_5: 7.7,
    app_flat_5: 10,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 5.93,
    avg_round_6: 8.9,
    app_flat_6: 20,
    char_7: 'Gallagher',
    app_rate_7: 5.76,
    avg_round_7: 6.73,
    app_flat_7: 15,
    char_8: 'topaz',
    app_rate_8: 5.42,
    avg_round_8: 7.05,
    app_flat_8: 19,
    char_9: 'Lingsha',
    app_rate_9: 4.41,
    avg_round_9: 6.1,
    app_flat_9: 20,
    char_10: 'Luocha',
    app_rate_10: 3.39,
    avg_round_10: 7.27,
    app_flat_10: 11,
    char_11: 'Jiaoqiu',
    app_rate_11: 2.2,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Sparkle',
    app_rate_12: 2.03,
    avg_round_12: 8.5,
    app_flat_12: 6,
    char_13: 'Clara',
    app_rate_13: 1.69,
    avg_round_13: 9.6,
    app_flat_13: 5,
    char_14: 'Bronya',
    app_rate_14: 1.36,
    avg_round_14: 7.2,
    app_flat_14: 5,
    char_15: 'Gepard',
    app_rate_15: 1.19,
    avg_round_15: 7.8,
    app_flat_15: 5,
    char_16: 'Huohuo',
    app_rate_16: 1.19,
    avg_round_16: 8,
    app_flat_16: 2,
    char_17: 'ruan-mei',
    app_rate_17: 1.02,
    avg_round_17: 10,
    app_flat_17: 2,
    char_18: 'Yunli',
    app_rate_18: 0.85,
    avg_round_18: 8,
    app_flat_18: 2,
    char_19: 'Himeko',
    app_rate_19: 0.85,
    avg_round_19: 8.5,
    app_flat_19: 2,
    char_20: 'Blade',
    app_rate_20: 0.34,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Lynx',
    app_rate_21: 0.34,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'Bailu',
    app_rate_22: 0.17,
    avg_round_22: 5,
    app_flat_22: 1,
    char_23: 'silver-wolf',
    app_rate_23: 0.17,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Asta',
    app_rate_24: 0.17,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Jade',
    app_rate_25: 0.17,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Firefly',
    round: 6.56,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 99.71,
    avg_round_1: 6.6,
    app_flat_1: 3873,
    char_2: 'ruan-mei',
    app_rate_2: 96.72,
    avg_round_2: 6.52,
    app_flat_2: 3693,
    char_3: 'Gallagher',
    app_rate_3: 60.66,
    avg_round_3: 6.69,
    app_flat_3: 2784,
    char_4: 'Lingsha',
    app_rate_4: 30.68,
    avg_round_4: 6.09,
    app_flat_4: 873,
    char_5: 'Himeko',
    app_rate_5: 2.79,
    avg_round_5: 8.07,
    app_flat_5: 57,
    char_6: 'Huohuo',
    app_rate_6: 1.93,
    avg_round_6: 7.26,
    app_flat_6: 78,
    char_7: 'Luocha',
    app_rate_7: 1.69,
    avg_round_7: 7.36,
    app_flat_7: 74,
    char_8: 'Bronya',
    app_rate_8: 1.18,
    avg_round_8: 9.0,
    app_flat_8: 7,
    char_9: 'Asta',
    app_rate_9: 0.77,
    avg_round_9: 8.24,
    app_flat_9: 42,
    char_10: 'fu-xuan',
    app_rate_10: 0.75,
    avg_round_10: 8.19,
    app_flat_10: 27,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 0.58,
    avg_round_11: 8,
    app_flat_11: 30,
    char_12: 'Aventurine',
    app_rate_12: 0.54,
    avg_round_12: 7.91,
    app_flat_12: 23,
    char_13: 'Pela',
    app_rate_13: 0.22,
    avg_round_13: 7.88,
    app_flat_13: 17,
    char_14: 'Jiaoqiu',
    app_rate_14: 0.22,
    avg_round_14: 8.6,
    app_flat_14: 5,
    char_15: 'Bailu',
    app_rate_15: 0.18,
    avg_round_15: 8,
    app_flat_15: 5,
    char_16: 'Robin',
    app_rate_16: 0.17,
    avg_round_16: 9,
    app_flat_16: 3,
    char_17: 'Sparkle',
    app_rate_17: 0.16,
    avg_round_17: 8.5,
    app_flat_17: 6,
    char_18: 'Lynx',
    app_rate_18: 0.13,
    avg_round_18: 7.2,
    app_flat_18: 7,
    char_19: 'Gepard',
    app_rate_19: 0.09,
    avg_round_19: 8,
    app_flat_19: 1,
    char_20: 'silver-wolf',
    app_rate_20: 0.09,
    avg_round_20: 7.33,
    app_flat_20: 3,
    char_21: 'Boothill',
    app_rate_21: 0.08,
    avg_round_21: 8.5,
    app_flat_21: 2,
    char_22: 'Feixiao',
    app_rate_22: 0.07,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Welt',
    app_rate_23: 0.06,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Acheron',
    app_rate_24: 0.04,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Guinaifen',
    app_rate_25: 0.03,
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'black-swan',
    app_rate_26: 0.03,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Clara',
    app_rate_27: 0.03,
    avg_round_27: 9.5,
    app_flat_27: 2,
    char_28: 'Tingyun',
    app_rate_28: 0.02,
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'Hanya',
    app_rate_29: 0.02,
    avg_round_29: 7,
    app_flat_29: 1,
    char_30: 'Yunli',
    app_rate_30: 0.02,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'march-7th-swordmaster',
    round: 6.63,
    char_1: 'Feixiao',
    app_rate_1: 81.4,
    avg_round_1: 6.49,
    app_flat_1: 1631,
    char_2: 'Robin',
    app_rate_2: 80.17,
    avg_round_2: 6.43,
    app_flat_2: 1580,
    char_3: 'Aventurine',
    app_rate_3: 31.87,
    avg_round_3: 6.47,
    app_flat_3: 678,
    char_4: 'fu-xuan',
    app_rate_4: 26.87,
    avg_round_4: 6.76,
    app_flat_4: 506,
    char_5: 'Huohuo',
    app_rate_5: 10.9,
    avg_round_5: 7.22,
    app_flat_5: 203,
    char_6: 'Gallagher',
    app_rate_6: 10.57,
    avg_round_6: 7.1,
    app_flat_6: 230,
    char_7: 'Lingsha',
    app_rate_7: 8.03,
    avg_round_7: 6.34,
    app_flat_7: 168,
    char_8: 'Luocha',
    app_rate_8: 6.7,
    avg_round_8: 7.27,
    app_flat_8: 128,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 5.2,
    avg_round_9: 8.37,
    app_flat_9: 106,
    char_10: 'topaz',
    app_rate_10: 5.03,
    avg_round_10: 8.37,
    app_flat_10: 92,
    char_11: 'Sparkle',
    app_rate_11: 4.67,
    avg_round_11: 8.21,
    app_flat_11: 73,
    char_12: 'ruan-mei',
    app_rate_12: 4.47,
    avg_round_12: 8.55,
    app_flat_12: 78,
    char_13: 'Bronya',
    app_rate_13: 4.2,
    avg_round_13: 7.89,
    app_flat_13: 72,
    char_14: 'Yunli',
    app_rate_14: 4.13,
    avg_round_14: 8.57,
    app_flat_14: 86,
    char_15: 'Himeko',
    app_rate_15: 3.33,
    avg_round_15: 8.12,
    app_flat_15: 57,
    char_16: 'Clara',
    app_rate_16: 3.0,
    avg_round_16: 8.62,
    app_flat_16: 57,
    char_17: 'Firefly',
    app_rate_17: 1.73,
    avg_round_17: 8,
    app_flat_17: 30,
    char_18: 'Bailu',
    app_rate_18: 1.43,
    avg_round_18: 7.85,
    app_flat_18: 34,
    char_19: 'Moze',
    app_rate_19: 1.17,
    avg_round_19: 8.9,
    app_flat_19: 20,
    char_20: 'Gepard',
    app_rate_20: 0.8,
    avg_round_20: 8.4,
    app_flat_20: 15,
    char_21: 'Tingyun',
    app_rate_21: 0.73,
    avg_round_21: 9.1,
    app_flat_21: 10,
    char_22: 'Jiaoqiu',
    app_rate_22: 0.53,
    avg_round_22: 7.67,
    app_flat_22: 9,
    char_23: 'Boothill',
    app_rate_23: 0.43,
    avg_round_23: 8.89,
    app_flat_23: 9,
    char_24: 'Lynx',
    app_rate_24: 0.43,
    avg_round_24: 8.4,
    app_flat_24: 10,
    char_25: 'Asta',
    app_rate_25: 0.4,
    avg_round_25: 9.43,
    app_flat_25: 11,
    char_26: 'Blade',
    app_rate_26: 0.37,
    avg_round_26: 9.4,
    app_flat_26: 7,
    char_27: 'dr-ratio',
    app_rate_27: 0.33,
    avg_round_27: 9,
    app_flat_27: 3,
    char_28: 'Jade',
    app_rate_28: 0.13,
    avg_round_28: 7,
    app_flat_28: 2,
    char_29: 'black-swan',
    app_rate_29: 0.13,
    avg_round_29: 10,
    app_flat_29: 2,
    char_30: 'Natasha',
    app_rate_30: 0.13,
    avg_round_30: 7.5,
    app_flat_30: 2
  },
  {
    char: 'trailblazer-imaginary',
    round: 6.7,
    char_1: 'ruan-mei',
    app_rate_1: 96.21,
    avg_round_1: 6.65,
    app_flat_1: 4527,
    char_2: 'Firefly',
    app_rate_2: 86.93,
    avg_round_2: 6.6,
    app_flat_2: 3873,
    char_3: 'Gallagher',
    app_rate_3: 62.39,
    avg_round_3: 6.84,
    app_flat_3: 3505,
    char_4: 'Lingsha',
    app_rate_4: 28.64,
    avg_round_4: 6.13,
    app_flat_4: 995,
    char_5: 'Himeko',
    app_rate_5: 10.65,
    avg_round_5: 7.21,
    app_flat_5: 646,
    char_6: 'Boothill',
    app_rate_6: 3.08,
    avg_round_6: 7.4,
    app_flat_6: 201,
    char_7: 'Luocha',
    app_rate_7: 2.01,
    avg_round_7: 7.47,
    app_flat_7: 107,
    char_8: 'Huohuo',
    app_rate_8: 1.9,
    avg_round_8: 7.34,
    app_flat_8: 89,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 1.53,
    avg_round_9: 8.37,
    app_flat_9: 106,
    char_10: 'Bronya',
    app_rate_10: 1.2,
    avg_round_10: 9.0,
    app_flat_10: 20,
    char_11: 'Asta',
    app_rate_11: 0.89,
    avg_round_11: 8.45,
    app_flat_11: 58,
    char_12: 'fu-xuan',
    app_rate_12: 0.74,
    avg_round_12: 8.18,
    app_flat_12: 33,
    char_13: 'Aventurine',
    app_rate_13: 0.69,
    avg_round_13: 8.17,
    app_flat_13: 36,
    char_14: 'Jiaoqiu',
    app_rate_14: 0.45,
    avg_round_14: 8.38,
    app_flat_14: 16,
    char_15: 'Guinaifen',
    app_rate_15: 0.31,
    avg_round_15: 8.75,
    app_flat_15: 24,
    char_16: 'Pela',
    app_rate_16: 0.23,
    avg_round_16: 8.05,
    app_flat_16: 19,
    char_17: 'Sparkle',
    app_rate_17: 0.2,
    avg_round_17: 8.12,
    app_flat_17: 8,
    char_18: 'Bailu',
    app_rate_18: 0.18,
    avg_round_18: 8,
    app_flat_18: 5,
    char_19: 'Robin',
    app_rate_19: 0.17,
    avg_round_19: 9.0,
    app_flat_19: 6,
    char_20: 'imbibitor-lunae',
    app_rate_20: 0.16,
    avg_round_20: 6.71,
    app_flat_20: 7,
    char_21: 'Acheron',
    app_rate_21: 0.13,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Lynx',
    app_rate_22: 0.13,
    avg_round_22: 7.2,
    app_flat_22: 7,
    char_23: 'Welt',
    app_rate_23: 0.12,
    avg_round_23: 9.5,
    app_flat_23: 2,
    char_24: 'silver-wolf',
    app_rate_24: 0.11,
    avg_round_24: 8.2,
    app_flat_24: 5,
    char_25: 'Kafka',
    app_rate_25: 0.11,
    avg_round_25: 9.2,
    app_flat_25: 5,
    char_26: 'Gepard',
    app_rate_26: 0.1,
    avg_round_26: 8.5,
    app_flat_26: 2,
    char_27: 'jing-yuan',
    app_rate_27: 0.09,
    avg_round_27: 8.67,
    app_flat_27: 3,
    char_28: 'Xueyi',
    app_rate_28: 0.08,
    avg_round_28: 8.33,
    app_flat_28: 6,
    char_29: 'Sushang',
    app_rate_29: 0.06,
    avg_round_29: 8,
    app_flat_29: 5,
    char_30: 'Tingyun',
    app_rate_30: 0.06,
    avg_round_30: 9,
    app_flat_30: 2
  },
  {
    char: 'ruan-mei',
    round: 6.73,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 91.67,
    avg_round_1: 6.65,
    app_flat_1: 4527,
    char_2: 'Firefly',
    app_rate_2: 80.34,
    avg_round_2: 6.52,
    app_flat_2: 3693,
    char_3: 'Gallagher',
    app_rate_3: 59.7,
    avg_round_3: 6.78,
    app_flat_3: 3431,
    char_4: 'Lingsha',
    app_rate_4: 27.11,
    avg_round_4: 6.08,
    app_flat_4: 981,
    char_5: 'Himeko',
    app_rate_5: 9.84,
    avg_round_5: 7.07,
    app_flat_5: 574,
    char_6: 'Boothill',
    app_rate_6: 4.85,
    avg_round_6: 7.43,
    app_flat_6: 298,
    char_7: 'Huohuo',
    app_rate_7: 3.24,
    avg_round_7: 7.8,
    app_flat_7: 118,
    char_8: 'Bronya',
    app_rate_8: 3.09,
    avg_round_8: 7.7,
    app_flat_8: 115,
    char_9: 'Luocha',
    app_rate_9: 2.45,
    avg_round_9: 7.67,
    app_flat_9: 118,
    char_10: 'Acheron',
    app_rate_10: 1.55,
    avg_round_10: 9.11,
    app_flat_10: 17,
    char_11: 'Jiaoqiu',
    app_rate_11: 1.46,
    avg_round_11: 8.5,
    app_flat_11: 20,
    char_12: 'fu-xuan',
    app_rate_12: 1.45,
    avg_round_12: 8.33,
    app_flat_12: 45,
    char_13: 'Aventurine',
    app_rate_13: 1.43,
    avg_round_13: 8.57,
    app_flat_13: 69,
    char_14: 'Kafka',
    app_rate_14: 1.33,
    avg_round_14: 9.0,
    app_flat_14: 26,
    char_15: 'Sparkle',
    app_rate_15: 1.27,
    avg_round_15: 8.43,
    app_flat_15: 14,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 1.25,
    avg_round_16: 8.55,
    app_flat_16: 78,
    char_17: 'black-swan',
    app_rate_17: 1.22,
    avg_round_17: 8.92,
    app_flat_17: 22,
    char_18: 'imbibitor-lunae',
    app_rate_18: 0.9,
    avg_round_18: 8.67,
    app_flat_18: 12,
    char_19: 'Feixiao',
    app_rate_19: 0.72,
    avg_round_19: 9.13,
    app_flat_19: 29,
    char_20: 'topaz',
    app_rate_20: 0.56,
    avg_round_20: 9.0,
    app_flat_20: 27,
    char_21: 'Guinaifen',
    app_rate_21: 0.45,
    avg_round_21: 8.64,
    app_flat_21: 26,
    char_22: 'Pela',
    app_rate_22: 0.38,
    avg_round_22: 10,
    app_flat_22: 2,
    char_23: 'Robin',
    app_rate_23: 0.35,
    avg_round_23: 9.33,
    app_flat_23: 6,
    char_24: 'Tingyun',
    app_rate_24: 0.34,
    avg_round_24: 8,
    app_flat_24: 4,
    char_25: 'Asta',
    app_rate_25: 0.29,
    avg_round_25: 8.4,
    app_flat_25: 5,
    char_26: 'Yunli',
    app_rate_26: 0.27,
    avg_round_26: 9.0,
    app_flat_26: 8,
    char_27: 'Blade',
    app_rate_27: 0.26,
    avg_round_27: 10.0,
    app_flat_27: 8,
    char_28: 'Bailu',
    app_rate_28: 0.26,
    avg_round_28: 8.43,
    app_flat_28: 7,
    char_29: 'Jade',
    app_rate_29: 0.25,
    avg_round_29: 7.2,
    app_flat_29: 5,
    char_30: 'dr-ratio',
    app_rate_30: 0.18,
    avg_round_30: 9.75,
    app_flat_30: 8
  },
  {
    char: 'Gallagher',
    round: 6.85,
    char_1: 'ruan-mei',
    app_rate_1: 83.96,
    avg_round_1: 6.78,
    app_flat_1: 3431,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 83.61,
    avg_round_2: 6.84,
    app_flat_2: 3505,
    char_3: 'Firefly',
    app_rate_3: 70.86,
    avg_round_3: 6.69,
    app_flat_3: 2784,
    char_4: 'Himeko',
    app_rate_4: 9.8,
    avg_round_4: 7.29,
    app_flat_4: 549,
    char_5: 'Acheron',
    app_rate_5: 6.49,
    avg_round_5: 7.65,
    app_flat_5: 224,
    char_6: 'Robin',
    app_rate_6: 5.8,
    avg_round_6: 6.43,
    app_flat_6: 261,
    char_7: 'Boothill',
    app_rate_7: 4.98,
    avg_round_7: 7.49,
    app_flat_7: 241,
    char_8: 'Jiaoqiu',
    app_rate_8: 4.22,
    avg_round_8: 7.31,
    app_flat_8: 163,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 4.16,
    avg_round_9: 7.1,
    app_flat_9: 230,
    char_10: 'Feixiao',
    app_rate_10: 3.96,
    avg_round_10: 6.08,
    app_flat_10: 196,
    char_11: 'Pela',
    app_rate_11: 3.29,
    avg_round_11: 7.72,
    app_flat_11: 154,
    char_12: 'Bronya',
    app_rate_12: 3.19,
    avg_round_12: 7.47,
    app_flat_12: 116,
    char_13: 'Sparkle',
    app_rate_13: 3.03,
    avg_round_13: 8.26,
    app_flat_13: 59,
    char_14: 'Kafka',
    app_rate_14: 1.48,
    avg_round_14: 8.12,
    app_flat_14: 48,
    char_15: 'black-swan',
    app_rate_15: 1.43,
    avg_round_15: 8.27,
    app_flat_15: 44,
    char_16: 'topaz',
    app_rate_16: 1.34,
    avg_round_16: 6.45,
    app_flat_16: 62,
    char_17: 'silver-wolf',
    app_rate_17: 1.17,
    avg_round_17: 8.08,
    app_flat_17: 51,
    char_18: 'imbibitor-lunae',
    app_rate_18: 1.01,
    avg_round_18: 7.68,
    app_flat_18: 19,
    char_19: 'Tingyun',
    app_rate_19: 0.96,
    avg_round_19: 8.22,
    app_flat_19: 36,
    char_20: 'Asta',
    app_rate_20: 0.89,
    avg_round_20: 8.47,
    app_flat_20: 47,
    char_21: 'Guinaifen',
    app_rate_21: 0.85,
    avg_round_21: 8.59,
    app_flat_21: 42,
    char_22: 'Clara',
    app_rate_22: 0.63,
    avg_round_22: 8.6,
    app_flat_22: 29,
    char_23: 'Yunli',
    app_rate_23: 0.54,
    avg_round_23: 7.25,
    app_flat_23: 32,
    char_24: 'Moze',
    app_rate_24: 0.45,
    avg_round_24: 6.73,
    app_flat_24: 15,
    char_25: 'jing-yuan',
    app_rate_25: 0.3,
    avg_round_25: 9.2,
    app_flat_25: 9,
    char_26: 'dr-ratio',
    app_rate_26: 0.25,
    avg_round_26: 8.83,
    app_flat_26: 12,
    char_27: 'Welt',
    app_rate_27: 0.24,
    avg_round_27: 9.17,
    app_flat_27: 10,
    char_28: 'Blade',
    app_rate_28: 0.24,
    avg_round_28: 9.75,
    app_flat_28: 8,
    char_29: 'Jade',
    app_rate_29: 0.22,
    avg_round_29: 8.33,
    app_flat_29: 3,
    char_30: 'Lingsha',
    app_rate_30: 0.14,
    avg_round_30: 10.0,
    app_flat_30: 4
  },
  {
    char: 'fu-xuan',
    round: 7.0,
    char_1: 'Robin',
    app_rate_1: 51.77,
    avg_round_1: 6.55,
    app_flat_1: 939,
    char_2: 'Feixiao',
    app_rate_2: 48.17,
    avg_round_2: 6.52,
    app_flat_2: 921,
    char_3: 'Acheron',
    app_rate_3: 34.54,
    avg_round_3: 8.0,
    app_flat_3: 343,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 24.75,
    avg_round_4: 6.76,
    app_flat_4: 506,
    char_5: 'Sparkle',
    app_rate_5: 23.83,
    avg_round_5: 7.93,
    app_flat_5: 127,
    char_6: 'topaz',
    app_rate_6: 23.33,
    avg_round_6: 6.37,
    app_flat_6: 404,
    char_7: 'Jiaoqiu',
    app_rate_7: 21.89,
    avg_round_7: 7.64,
    app_flat_7: 259,
    char_8: 'silver-wolf',
    app_rate_8: 13.45,
    avg_round_8: 8.41,
    app_flat_8: 132,
    char_9: 'Pela',
    app_rate_9: 11.02,
    avg_round_9: 7.92,
    app_flat_9: 170,
    char_10: 'Yunli',
    app_rate_10: 6.63,
    avg_round_10: 7.58,
    app_flat_10: 108,
    char_11: 'ruan-mei',
    app_rate_11: 4.76,
    avg_round_11: 8.33,
    app_flat_11: 45,
    char_12: 'black-swan',
    app_rate_12: 4.3,
    avg_round_12: 9.2,
    app_flat_12: 38,
    char_13: 'Bronya',
    app_rate_13: 3.84,
    avg_round_13: 7.29,
    app_flat_13: 31,
    char_14: 'Tingyun',
    app_rate_14: 3.65,
    avg_round_14: 7.78,
    app_flat_14: 45,
    char_15: 'Clara',
    app_rate_15: 3.13,
    avg_round_15: 8.13,
    app_flat_15: 47,
    char_16: 'Moze',
    app_rate_16: 2.82,
    avg_round_16: 7.04,
    app_flat_16: 50,
    char_17: 'Kafka',
    app_rate_17: 2.76,
    avg_round_17: 9.43,
    app_flat_17: 21,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 2.33,
    avg_round_18: 8.18,
    app_flat_18: 33,
    char_19: 'Firefly',
    app_rate_19: 2.06,
    avg_round_19: 8.19,
    app_flat_19: 27,
    char_20: 'Himeko',
    app_rate_20: 1.78,
    avg_round_20: 8.95,
    app_flat_20: 21,
    char_21: 'Guinaifen',
    app_rate_21: 1.41,
    avg_round_21: 9.4,
    app_flat_21: 27,
    char_22: 'imbibitor-lunae',
    app_rate_22: 1.29,
    avg_round_22: 9.67,
    app_flat_22: 6,
    char_23: 'Seele',
    app_rate_23: 1.26,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Blade',
    app_rate_24: 0.71,
    avg_round_24: 9.0,
    app_flat_24: 7,
    char_25: 'Boothill',
    app_rate_25: 0.58,
    avg_round_25: 7.33,
    app_flat_25: 6,
    char_26: 'Jade',
    app_rate_26: 0.58,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Qingque',
    app_rate_27: 0.58,
    avg_round_27: 10,
    app_flat_27: 2,
    char_28: 'dr-ratio',
    app_rate_28: 0.49,
    avg_round_28: 7,
    app_flat_28: 4,
    char_29: 'Asta',
    app_rate_29: 0.4,
    avg_round_29: 9.0,
    app_flat_29: 4,
    char_30: 'Welt',
    app_rate_30: 0.34,
    avg_round_30: 8.8,
    app_flat_30: 5
  },
  {
    char: 'Yunli',
    round: 7.17,
    char_1: 'Robin',
    app_rate_1: 75.56,
    avg_round_1: 7.18,
    app_flat_1: 485,
    char_2: 'Huohuo',
    app_rate_2: 47.14,
    avg_round_2: 7.12,
    app_flat_2: 302,
    char_3: 'Tingyun',
    app_rate_3: 36.92,
    avg_round_3: 7.27,
    app_flat_3: 247,
    char_4: 'Sparkle',
    app_rate_4: 26.69,
    avg_round_4: 7.41,
    app_flat_4: 136,
    char_5: 'Feixiao',
    app_rate_5: 19.84,
    avg_round_5: 6.88,
    app_flat_5: 133,
    char_6: 'topaz',
    app_rate_6: 19.06,
    avg_round_6: 7.32,
    app_flat_6: 130,
    char_7: 'fu-xuan',
    app_rate_7: 18.72,
    avg_round_7: 7.58,
    app_flat_7: 108,
    char_8: 'Aventurine',
    app_rate_8: 12.91,
    avg_round_8: 7.13,
    app_flat_8: 93,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 10.75,
    avg_round_9: 8.57,
    app_flat_9: 86,
    char_10: 'Luocha',
    app_rate_10: 8.49,
    avg_round_10: 8.27,
    app_flat_10: 65,
    char_11: 'Gallagher',
    app_rate_11: 3.55,
    avg_round_11: 7.25,
    app_flat_11: 32,
    char_12: 'Lingsha',
    app_rate_12: 3.55,
    avg_round_12: 7.38,
    app_flat_12: 24,
    char_13: 'ruan-mei',
    app_rate_13: 2.51,
    avg_round_13: 9.0,
    app_flat_13: 8,
    char_14: 'Clara',
    app_rate_14: 1.56,
    avg_round_14: 8,
    app_flat_14: 9,
    char_15: 'Jiaoqiu',
    app_rate_15: 1.56,
    avg_round_15: 7.6,
    app_flat_15: 10,
    char_16: 'Lynx',
    app_rate_16: 1.47,
    avg_round_16: 8.8,
    app_flat_16: 7,
    char_17: 'Blade',
    app_rate_17: 1.47,
    avg_round_17: 8.8,
    app_flat_17: 7,
    char_18: 'Himeko',
    app_rate_18: 1.21,
    avg_round_18: 9.27,
    app_flat_18: 11,
    char_19: 'Bailu',
    app_rate_19: 1.13,
    avg_round_19: 9.17,
    app_flat_19: 10,
    char_20: 'Bronya',
    app_rate_20: 1.04,
    avg_round_20: 9.67,
    app_flat_20: 3,
    char_21: 'silver-wolf',
    app_rate_21: 0.95,
    avg_round_21: 9,
    app_flat_21: 4,
    char_22: 'Pela',
    app_rate_22: 0.61,
    avg_round_22: 8.5,
    app_flat_22: 2,
    char_23: 'Acheron',
    app_rate_23: 0.52,
    avg_round_23: 8,
    app_flat_23: 2,
    char_24: 'black-swan',
    app_rate_24: 0.52,
    avg_round_24: 9.33,
    app_flat_24: 3,
    char_25: 'Moze',
    app_rate_25: 0.43,
    avg_round_25: 8,
    app_flat_25: 2,
    char_26: 'Jade',
    app_rate_26: 0.26,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Natasha',
    app_rate_27: 0.17,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Firefly',
    app_rate_28: 0.17,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Boothill',
    app_rate_29: 0.17,
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'trailblazer-imaginary',
    app_rate_30: 0.17,
    avg_round_30: 10,
    app_flat_30: 2
  },
  {
    char: 'Himeko',
    round: 7.26,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 72.24,
    avg_round_1: 7.21,
    app_flat_1: 646,
    char_2: 'ruan-mei',
    app_rate_2: 70.05,
    avg_round_2: 7.07,
    app_flat_2: 574,
    char_3: 'Gallagher',
    app_rate_3: 49.6,
    avg_round_3: 7.29,
    app_flat_3: 549,
    char_4: 'Firefly',
    app_rate_4: 16.53,
    avg_round_4: 8.07,
    app_flat_4: 57,
    char_5: 'Robin',
    app_rate_5: 12.88,
    avg_round_5: 8.27,
    app_flat_5: 102,
    char_6: 'Lingsha',
    app_rate_6: 10.09,
    avg_round_6: 6.6,
    app_flat_6: 92,
    char_7: 'Aventurine',
    app_rate_7: 7.77,
    avg_round_7: 8.3,
    app_flat_7: 58,
    char_8: 'Sparkle',
    app_rate_8: 7.57,
    avg_round_8: 8.18,
    app_flat_8: 44,
    char_9: 'Luocha',
    app_rate_9: 6.71,
    avg_round_9: 8.28,
    app_flat_9: 49,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 6.64,
    avg_round_10: 8.12,
    app_flat_10: 57,
    char_11: 'Huohuo',
    app_rate_11: 6.51,
    avg_round_11: 8.56,
    app_flat_11: 53,
    char_12: 'topaz',
    app_rate_12: 4.32,
    avg_round_12: 8.38,
    app_flat_12: 29,
    char_13: 'Tingyun',
    app_rate_13: 3.92,
    avg_round_13: 8.21,
    app_flat_13: 26,
    char_14: 'fu-xuan',
    app_rate_14: 3.85,
    avg_round_14: 8.95,
    app_flat_14: 21,
    char_15: 'Jiaoqiu',
    app_rate_15: 3.12,
    avg_round_15: 8.55,
    app_flat_15: 19,
    char_16: 'Jade',
    app_rate_16: 2.59,
    avg_round_16: 8.1,
    app_flat_16: 10,
    char_17: 'Asta',
    app_rate_17: 2.52,
    avg_round_17: 9.33,
    app_flat_17: 22,
    char_18: 'Acheron',
    app_rate_18: 2.32,
    avg_round_18: 9.4,
    app_flat_18: 9,
    char_19: 'Feixiao',
    app_rate_19: 1.46,
    avg_round_19: 8.11,
    app_flat_19: 18,
    char_20: 'Boothill',
    app_rate_20: 1.13,
    avg_round_20: 9,
    app_flat_20: 4,
    char_21: 'Bronya',
    app_rate_21: 1.13,
    avg_round_21: 8.86,
    app_flat_21: 7,
    char_22: 'Yunli',
    app_rate_22: 0.93,
    avg_round_22: 9.27,
    app_flat_22: 11,
    char_23: 'dr-ratio',
    app_rate_23: 0.8,
    avg_round_23: 9.14,
    app_flat_23: 7,
    char_24: 'jing-yuan',
    app_rate_24: 0.6,
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'imbibitor-lunae',
    app_rate_25: 0.53,
    avg_round_25: 8.5,
    app_flat_25: 2,
    char_26: 'Kafka',
    app_rate_26: 0.46,
    avg_round_26: 8.5,
    app_flat_26: 2,
    char_27: 'Bailu',
    app_rate_27: 0.46,
    avg_round_27: 9.5,
    app_flat_27: 4,
    char_28: 'Guinaifen',
    app_rate_28: 0.46,
    avg_round_28: 7.5,
    app_flat_28: 2,
    char_29: 'Pela',
    app_rate_29: 0.46,
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Welt',
    app_rate_30: 0.4,
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'Huohuo',
    round: 7.33,
    char_1: 'Robin',
    app_rate_1: 51.47,
    avg_round_1: 6.98,
    app_flat_1: 482,
    char_2: 'Yunli',
    app_rate_2: 29.01,
    avg_round_2: 7.12,
    app_flat_2: 302,
    char_3: 'Feixiao',
    app_rate_3: 23.52,
    avg_round_3: 6.81,
    app_flat_3: 246,
    char_4: 'Sparkle',
    app_rate_4: 23.09,
    avg_round_4: 7.88,
    app_flat_4: 137,
    char_5: 'Tingyun',
    app_rate_5: 19.2,
    avg_round_5: 7.29,
    app_flat_5: 176,
    char_6: 'ruan-mei',
    app_rate_6: 18.56,
    avg_round_6: 7.8,
    app_flat_6: 118,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 17.44,
    avg_round_7: 7.22,
    app_flat_7: 203,
    char_8: 'Acheron',
    app_rate_8: 13.65,
    avg_round_8: 8.36,
    app_flat_8: 87,
    char_9: 'topaz',
    app_rate_9: 13.33,
    avg_round_9: 6.81,
    app_flat_9: 124,
    char_10: 'black-swan',
    app_rate_10: 11.52,
    avg_round_10: 9.32,
    app_flat_10: 35,
    char_11: 'Kafka',
    app_rate_11: 10.88,
    avg_round_11: 9.18,
    app_flat_11: 31,
    char_12: 'trailblazer-imaginary',
    app_rate_12: 10.35,
    avg_round_12: 7.34,
    app_flat_12: 89,
    char_13: 'Jiaoqiu',
    app_rate_13: 10.19,
    avg_round_13: 7.99,
    app_flat_13: 73,
    char_14: 'Firefly',
    app_rate_14: 9.17,
    avg_round_14: 7.26,
    app_flat_14: 78,
    char_15: 'Clara',
    app_rate_15: 6.99,
    avg_round_15: 8.24,
    app_flat_15: 72,
    char_16: 'imbibitor-lunae',
    app_rate_16: 6.29,
    avg_round_16: 10.0,
    app_flat_16: 8,
    char_17: 'Himeko',
    app_rate_17: 5.23,
    avg_round_17: 8.56,
    app_flat_17: 53,
    char_18: 'Pela',
    app_rate_18: 4.27,
    avg_round_18: 7.85,
    app_flat_18: 40,
    char_19: 'Bronya',
    app_rate_19: 2.99,
    avg_round_19: 9.62,
    app_flat_19: 16,
    char_20: 'silver-wolf',
    app_rate_20: 2.24,
    avg_round_20: 8.6,
    app_flat_20: 15,
    char_21: 'Blade',
    app_rate_21: 2.13,
    avg_round_21: 9.71,
    app_flat_21: 13,
    char_22: 'Guinaifen',
    app_rate_22: 1.87,
    avg_round_22: 9.2,
    app_flat_22: 9,
    char_23: 'Boothill',
    app_rate_23: 1.17,
    avg_round_23: 8.89,
    app_flat_23: 9,
    char_24: 'Jingliu',
    app_rate_24: 0.75,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'dr-ratio',
    app_rate_25: 0.69,
    avg_round_25: 9.75,
    app_flat_25: 6,
    char_26: 'Jade',
    app_rate_26: 0.69,
    avg_round_26: 9.25,
    app_flat_26: 4,
    char_27: 'Asta',
    app_rate_27: 0.69,
    avg_round_27: 10.0,
    app_flat_27: 5,
    char_28: 'jing-yuan',
    app_rate_28: 0.48,
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'fu-xuan',
    app_rate_29: 0.43,
    avg_round_29: 9,
    app_flat_29: 4,
    char_30: 'Moze',
    app_rate_30: 0.37,
    avg_round_30: 8,
    app_flat_30: 2
  },
  {
    char: 'Boothill',
    round: 7.57,
    char_1: 'ruan-mei',
    app_rate_1: 88.89,
    avg_round_1: 7.43,
    app_flat_1: 298,
    char_2: 'Gallagher',
    app_rate_2: 64.96,
    avg_round_2: 7.49,
    app_flat_2: 241,
    char_3: 'trailblazer-imaginary',
    app_rate_3: 53.85,
    avg_round_3: 7.4,
    app_flat_3: 201,
    char_4: 'Bronya',
    app_rate_4: 43.59,
    avg_round_4: 7.89,
    app_flat_4: 139,
    char_5: 'Lingsha',
    app_rate_5: 11.11,
    avg_round_5: 6.82,
    app_flat_5: 34,
    char_6: 'Aventurine',
    app_rate_6: 6.32,
    avg_round_6: 9.0,
    app_flat_6: 24,
    char_7: 'Luocha',
    app_rate_7: 5.47,
    avg_round_7: 8.42,
    app_flat_7: 24,
    char_8: 'Jiaoqiu',
    app_rate_8: 3.93,
    avg_round_8: 8.62,
    app_flat_8: 8,
    char_9: 'Huohuo',
    app_rate_9: 3.76,
    avg_round_9: 8.89,
    app_flat_9: 9,
    char_10: 'fu-xuan',
    app_rate_10: 3.25,
    avg_round_10: 7.33,
    app_flat_10: 6,
    char_11: 'Himeko',
    app_rate_11: 2.91,
    avg_round_11: 9,
    app_flat_11: 4,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 2.22,
    avg_round_12: 8.89,
    app_flat_12: 9,
    char_13: 'Robin',
    app_rate_13: 1.37,
    avg_round_13: 8,
    app_flat_13: 2,
    char_14: 'silver-wolf',
    app_rate_14: 1.37,
    avg_round_14: 9.5,
    app_flat_14: 4,
    char_15: 'Firefly',
    app_rate_15: 1.2,
    avg_round_15: 8.5,
    app_flat_15: 2,
    char_16: 'Sparkle',
    app_rate_16: 1.03,
    avg_round_16: 10.0,
    app_flat_16: 4,
    char_17: 'Acheron',
    app_rate_17: 0.85,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Hanya',
    app_rate_18: 0.68,
    avg_round_18: 8.67,
    app_flat_18: 3,
    char_19: 'Pela',
    app_rate_19: 0.51,
    avg_round_19: 9,
    app_flat_19: 2,
    char_20: 'Gepard',
    app_rate_20: 0.51,
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'Yunli',
    app_rate_21: 0.34,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Sampo',
    app_rate_22: 0.34,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Argenti',
    app_rate_23: 0.17,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'topaz',
    app_rate_24: 0.17,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Hook',
    app_rate_25: 0.17,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Luka',
    app_rate_26: 0.17,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Blade',
    app_rate_27: 0.17,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Clara',
    app_rate_28: 0.17,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Welt',
    app_rate_29: 0.17,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Asta',
    app_rate_30: 0.17,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Luocha',
    round: 7.58,
    char_1: 'Robin',
    app_rate_1: 45.26,
    avg_round_1: 7.28,
    app_flat_1: 284,
    char_2: 'Feixiao',
    app_rate_2: 32.42,
    avg_round_2: 6.84,
    app_flat_2: 221,
    char_3: 'ruan-mei',
    app_rate_3: 23.95,
    avg_round_3: 7.67,
    app_flat_3: 118,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 18.67,
    avg_round_4: 7.47,
    app_flat_4: 107,
    char_5: 'march-7th-swordmaster',
    app_rate_5: 18.31,
    avg_round_5: 7.27,
    app_flat_5: 128,
    char_6: 'Acheron',
    app_rate_6: 17.85,
    avg_round_6: 8.33,
    app_flat_6: 88,
    char_7: 'Sparkle',
    app_rate_7: 15.3,
    avg_round_7: 8.36,
    app_flat_7: 44,
    char_8: 'topaz',
    app_rate_8: 14.85,
    avg_round_8: 7.23,
    app_flat_8: 100,
    char_9: 'Firefly',
    app_rate_9: 13.75,
    avg_round_9: 7.36,
    app_flat_9: 74,
    char_10: 'Jiaoqiu',
    app_rate_10: 13.3,
    avg_round_10: 7.93,
    app_flat_10: 68,
    char_11: 'Himeko',
    app_rate_11: 9.2,
    avg_round_11: 8.28,
    app_flat_11: 49,
    char_12: 'Yunli',
    app_rate_12: 8.93,
    avg_round_12: 8.27,
    app_flat_12: 65,
    char_13: 'Bronya',
    app_rate_13: 8.47,
    avg_round_13: 8.05,
    app_flat_13: 39,
    char_14: 'Clara',
    app_rate_14: 7.65,
    avg_round_14: 9.0,
    app_flat_14: 57,
    char_15: 'black-swan',
    app_rate_15: 7.47,
    avg_round_15: 9.17,
    app_flat_15: 22,
    char_16: 'Kafka',
    app_rate_16: 6.92,
    avg_round_16: 9.0,
    app_flat_16: 23,
    char_17: 'Tingyun',
    app_rate_17: 6.65,
    avg_round_17: 8.47,
    app_flat_17: 35,
    char_18: 'Pela',
    app_rate_18: 6.56,
    avg_round_18: 8.23,
    app_flat_18: 48,
    char_19: 'imbibitor-lunae',
    app_rate_19: 5.65,
    avg_round_19: 9.5,
    app_flat_19: 2,
    char_20: 'Blade',
    app_rate_20: 4.83,
    avg_round_20: 9.78,
    app_flat_20: 17,
    char_21: 'Boothill',
    app_rate_21: 2.91,
    avg_round_21: 8.42,
    app_flat_21: 24,
    char_22: 'silver-wolf',
    app_rate_22: 2.55,
    avg_round_22: 8.86,
    app_flat_22: 14,
    char_23: 'Moze',
    app_rate_23: 1.82,
    avg_round_23: 7.27,
    app_flat_23: 11,
    char_24: 'dr-ratio',
    app_rate_24: 1.18,
    avg_round_24: 9.33,
    app_flat_24: 3,
    char_25: 'Asta',
    app_rate_25: 1.09,
    avg_round_25: 9,
    app_flat_25: 5,
    char_26: 'Jade',
    app_rate_26: 1.0,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Guinaifen',
    app_rate_27: 0.82,
    avg_round_27: 8.75,
    app_flat_27: 4,
    char_28: 'Jingliu',
    app_rate_28: 0.73,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'jing-yuan',
    app_rate_29: 0.64,
    avg_round_29: 9.5,
    app_flat_29: 2,
    char_30: 'Welt',
    app_rate_30: 0.46,
    avg_round_30: 8,
    app_flat_30: 2
  },
  {
    char: 'Tingyun',
    round: 7.59,
    char_1: 'Yunli',
    app_rate_1: 55.25,
    avg_round_1: 7.27,
    app_flat_1: 247,
    char_2: 'Huohuo',
    app_rate_2: 46.69,
    avg_round_2: 7.29,
    app_flat_2: 176,
    char_3: 'Sparkle',
    app_rate_3: 44.75,
    avg_round_3: 8.19,
    app_flat_3: 123,
    char_4: 'Robin',
    app_rate_4: 43.06,
    avg_round_4: 7.11,
    app_flat_4: 188,
    char_5: 'imbibitor-lunae',
    app_rate_5: 19.58,
    avg_round_5: 9.55,
    app_flat_5: 19,
    char_6: 'fu-xuan',
    app_rate_6: 15.43,
    avg_round_6: 7.78,
    app_flat_6: 45,
    char_7: 'Aventurine',
    app_rate_7: 9.86,
    avg_round_7: 7.83,
    app_flat_7: 41,
    char_8: 'Luocha',
    app_rate_8: 9.47,
    avg_round_8: 8.47,
    app_flat_8: 35,
    char_9: 'Gallagher',
    app_rate_9: 9.47,
    avg_round_9: 8.22,
    app_flat_9: 36,
    char_10: 'Clara',
    app_rate_10: 9.08,
    avg_round_10: 8.42,
    app_flat_10: 38,
    char_11: 'Himeko',
    app_rate_11: 7.65,
    avg_round_11: 8.21,
    app_flat_11: 26,
    char_12: 'ruan-mei',
    app_rate_12: 4.8,
    avg_round_12: 8,
    app_flat_12: 4,
    char_13: 'jing-yuan',
    app_rate_13: 4.02,
    avg_round_13: 8.75,
    app_flat_13: 12,
    char_14: 'Lingsha',
    app_rate_14: 3.11,
    avg_round_14: 8.91,
    app_flat_14: 11,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 2.85,
    avg_round_15: 9.1,
    app_flat_15: 10,
    char_16: 'topaz',
    app_rate_16: 2.08,
    avg_round_16: 8.82,
    app_flat_16: 11,
    char_17: 'Jiaoqiu',
    app_rate_17: 1.95,
    avg_round_17: 8.75,
    app_flat_17: 6,
    char_18: 'dr-ratio',
    app_rate_18: 1.43,
    avg_round_18: 9.4,
    app_flat_18: 5,
    char_19: 'Bailu',
    app_rate_19: 0.91,
    avg_round_19: 9.4,
    app_flat_19: 5,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 0.78,
    avg_round_20: 9,
    app_flat_20: 2,
    char_21: 'Asta',
    app_rate_21: 0.78,
    avg_round_21: 9.5,
    app_flat_21: 2,
    char_22: 'silver-wolf',
    app_rate_22: 0.78,
    avg_round_22: 8.5,
    app_flat_22: 2,
    char_23: 'Pela',
    app_rate_23: 0.78,
    avg_round_23: 9,
    app_flat_23: 3,
    char_24: 'Lynx',
    app_rate_24: 0.65,
    avg_round_24: 7.67,
    app_flat_24: 3,
    char_25: 'black-swan',
    app_rate_25: 0.65,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Bronya',
    app_rate_26: 0.52,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Feixiao',
    app_rate_27: 0.52,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Hanya',
    app_rate_28: 0.52,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Kafka',
    app_rate_29: 0.52,
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Seele',
    app_rate_30: 0.39,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    round: 7.6,
    char_1: 'ruan-mei',
    app_rate_1: 35.44,
    avg_round_1: 7.7,
    app_flat_1: 115,
    char_2: 'Boothill',
    app_rate_2: 27.3,
    avg_round_2: 7.89,
    app_flat_2: 139,
    char_3: 'Feixiao',
    app_rate_3: 26.87,
    avg_round_3: 7.03,
    app_flat_3: 134,
    char_4: 'Gallagher',
    app_rate_4: 26.02,
    avg_round_4: 7.47,
    app_flat_4: 116,
    char_5: 'Acheron',
    app_rate_5: 20.02,
    avg_round_5: 7,
    app_flat_5: 1,
    char_6: 'Robin',
    app_rate_6: 16.06,
    avg_round_6: 6.18,
    app_flat_6: 38,
    char_7: 'Aventurine',
    app_rate_7: 15.95,
    avg_round_7: 7.84,
    app_flat_7: 86,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 13.49,
    avg_round_8: 7.89,
    app_flat_8: 72,
    char_9: 'fu-xuan',
    app_rate_9: 13.38,
    avg_round_9: 7.29,
    app_flat_9: 31,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 13.17,
    avg_round_10: 9.0,
    app_flat_10: 20,
    char_11: 'Jiaoqiu',
    app_rate_11: 12.63,
    avg_round_11: 8.2,
    app_flat_11: 5,
    char_12: 'Firefly',
    app_rate_12: 11.24,
    avg_round_12: 9.0,
    app_flat_12: 7,
    char_13: 'Luocha',
    app_rate_13: 9.96,
    avg_round_13: 8.05,
    app_flat_13: 39,
    char_14: 'Blade',
    app_rate_14: 8.03,
    avg_round_14: 9.4,
    app_flat_14: 18,
    char_15: 'topaz',
    app_rate_15: 6.96,
    avg_round_15: 6.86,
    app_flat_15: 29,
    char_16: 'Pela',
    app_rate_16: 6.21,
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'Sparkle',
    app_rate_17: 6.21,
    avg_round_17: 8.21,
    app_flat_17: 14,
    char_18: 'Huohuo',
    app_rate_18: 6.0,
    avg_round_18: 9.62,
    app_flat_18: 16,
    char_19: 'Lingsha',
    app_rate_19: 6.0,
    avg_round_19: 7.12,
    app_flat_19: 16,
    char_20: 'Jingliu',
    app_rate_20: 2.36,
    avg_round_20: 8,
    app_flat_20: 1,
    char_21: 'silver-wolf',
    app_rate_21: 2.36,
    avg_round_21: 9.67,
    app_flat_21: 3,
    char_22: 'Gepard',
    app_rate_22: 1.93,
    avg_round_22: 7,
    app_flat_22: 1,
    char_23: 'Himeko',
    app_rate_23: 1.82,
    avg_round_23: 8.86,
    app_flat_23: 7,
    char_24: 'Jade',
    app_rate_24: 1.61,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Yunli',
    app_rate_25: 1.28,
    avg_round_25: 9.67,
    app_flat_25: 3,
    char_26: 'Lynx',
    app_rate_26: 1.07,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Bailu',
    app_rate_27: 0.96,
    avg_round_27: 10.0,
    app_flat_27: 4,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.86,
    avg_round_28: 7,
    app_flat_28: 1,
    char_29: 'Moze',
    app_rate_29: 0.86,
    avg_round_29: 7.2,
    app_flat_29: 5,
    char_30: 'Clara',
    app_rate_30: 0.54,
    avg_round_30: 10,
    app_flat_30: 2
  },
  {
    char: 'Jiaoqiu',
    round: 7.67,
    char_1: 'Acheron',
    app_rate_1: 86.45,
    avg_round_1: 7.66,
    app_flat_1: 761,
    char_2: 'fu-xuan',
    app_rate_2: 32.41,
    avg_round_2: 7.64,
    app_flat_2: 259,
    char_3: 'Sparkle',
    app_rate_3: 29.45,
    avg_round_3: 7.62,
    app_flat_3: 81,
    char_4: 'Aventurine',
    app_rate_4: 25.86,
    avg_round_4: 7.75,
    app_flat_4: 256,
    char_5: 'Pela',
    app_rate_5: 25.32,
    avg_round_5: 7.57,
    app_flat_5: 387,
    char_6: 'Gallagher',
    app_rate_6: 14.64,
    avg_round_6: 7.31,
    app_flat_6: 163,
    char_7: 'silver-wolf',
    app_rate_7: 10.77,
    avg_round_7: 7.97,
    app_flat_7: 129,
    char_8: 'Robin',
    app_rate_8: 9.77,
    avg_round_8: 6.79,
    app_flat_8: 72,
    char_9: 'Huohuo',
    app_rate_9: 8.68,
    avg_round_9: 7.99,
    app_flat_9: 73,
    char_10: 'black-swan',
    app_rate_10: 8.0,
    avg_round_10: 8.28,
    app_flat_10: 71,
    char_11: 'ruan-mei',
    app_rate_11: 7.14,
    avg_round_11: 8.5,
    app_flat_11: 20,
    char_12: 'Luocha',
    app_rate_12: 6.64,
    avg_round_12: 7.93,
    app_flat_12: 68,
    char_13: 'Bronya',
    app_rate_13: 5.36,
    avg_round_13: 8.2,
    app_flat_13: 5,
    char_14: 'Kafka',
    app_rate_14: 4.45,
    avg_round_14: 8.08,
    app_flat_14: 36,
    char_15: 'Feixiao',
    app_rate_15: 2.55,
    avg_round_15: 6.97,
    app_flat_15: 30,
    char_16: 'Lingsha',
    app_rate_16: 2.36,
    avg_round_16: 8.22,
    app_flat_16: 15,
    char_17: 'Gepard',
    app_rate_17: 2.32,
    avg_round_17: 8.7,
    app_flat_17: 20,
    char_18: 'Himeko',
    app_rate_18: 2.14,
    avg_round_18: 8.55,
    app_flat_18: 19,
    char_19: 'dr-ratio',
    app_rate_19: 2.09,
    avg_round_19: 8.47,
    app_flat_19: 17,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 2.09,
    avg_round_20: 8.38,
    app_flat_20: 16,
    char_21: 'Guinaifen',
    app_rate_21: 1.64,
    avg_round_21: 7.79,
    app_flat_21: 19,
    char_22: 'Boothill',
    app_rate_22: 1.05,
    avg_round_22: 8.62,
    app_flat_22: 8,
    char_23: 'imbibitor-lunae',
    app_rate_23: 1.0,
    avg_round_23: 7.67,
    app_flat_23: 3,
    char_24: 'Firefly',
    app_rate_24: 0.91,
    avg_round_24: 8.6,
    app_flat_24: 5,
    char_25: 'topaz',
    app_rate_25: 0.82,
    avg_round_25: 8,
    app_flat_25: 7,
    char_26: 'Yunli',
    app_rate_26: 0.82,
    avg_round_26: 7.6,
    app_flat_26: 10,
    char_27: 'march-7th-swordmaster',
    app_rate_27: 0.73,
    avg_round_27: 7.67,
    app_flat_27: 9,
    char_28: 'Tingyun',
    app_rate_28: 0.68,
    avg_round_28: 8.75,
    app_flat_28: 6,
    char_29: 'Moze',
    app_rate_29: 0.59,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Welt',
    app_rate_30: 0.5,
    avg_round_30: 8.67,
    app_flat_30: 5
  },
  {
    char: 'Sparkle',
    round: 7.85,
    char_1: 'Acheron',
    app_rate_1: 43.46,
    avg_round_1: 7.8,
    app_flat_1: 98,
    char_2: 'fu-xuan',
    app_rate_2: 33.96,
    avg_round_2: 7.93,
    app_flat_2: 127,
    char_3: 'Jiaoqiu',
    app_rate_3: 28.36,
    avg_round_3: 7.62,
    app_flat_3: 81,
    char_4: 'Huohuo',
    app_rate_4: 18.95,
    avg_round_4: 7.88,
    app_flat_4: 137,
    char_5: 'Aventurine',
    app_rate_5: 17.33,
    avg_round_5: 7.78,
    app_flat_5: 129,
    char_6: 'Robin',
    app_rate_6: 16.94,
    avg_round_6: 7.43,
    app_flat_6: 97,
    char_7: 'Tingyun',
    app_rate_7: 15.1,
    avg_round_7: 8.19,
    app_flat_7: 123,
    char_8: 'Yunli',
    app_rate_8: 13.48,
    avg_round_8: 7.41,
    app_flat_8: 136,
    char_9: 'imbibitor-lunae',
    app_rate_9: 13.26,
    avg_round_9: 9.56,
    app_flat_9: 32,
    char_10: 'Feixiao',
    app_rate_10: 11.95,
    avg_round_10: 7.61,
    app_flat_10: 146,
    char_11: 'silver-wolf',
    app_rate_11: 11.33,
    avg_round_11: 9.07,
    app_flat_11: 15,
    char_12: 'Gallagher',
    app_rate_12: 10.11,
    avg_round_12: 8.26,
    app_flat_12: 59,
    char_13: 'Luocha',
    app_rate_13: 7.35,
    avg_round_13: 8.36,
    app_flat_13: 44,
    char_14: 'Pela',
    app_rate_14: 6.7,
    avg_round_14: 8.54,
    app_flat_14: 13,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 6.13,
    avg_round_15: 8.21,
    app_flat_15: 73,
    char_16: 'ruan-mei',
    app_rate_16: 5.95,
    avg_round_16: 8.43,
    app_flat_16: 14,
    char_17: 'topaz',
    app_rate_17: 5.21,
    avg_round_17: 7.17,
    app_flat_17: 60,
    char_18: 'Himeko',
    app_rate_18: 4.99,
    avg_round_18: 8.18,
    app_flat_18: 44,
    char_19: 'Clara',
    app_rate_19: 4.73,
    avg_round_19: 8.72,
    app_flat_19: 47,
    char_20: 'Lingsha',
    app_rate_20: 4.42,
    avg_round_20: 7.83,
    app_flat_20: 30,
    char_21: 'Bronya',
    app_rate_21: 2.54,
    avg_round_21: 8.21,
    app_flat_21: 14,
    char_22: 'Blade',
    app_rate_22: 2.23,
    avg_round_22: 9.38,
    app_flat_22: 14,
    char_23: 'Seele',
    app_rate_23: 2.14,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'black-swan',
    app_rate_24: 1.53,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'jing-yuan',
    app_rate_25: 1.23,
    avg_round_25: 9.17,
    app_flat_25: 6,
    char_26: 'Gepard',
    app_rate_26: 0.96,
    avg_round_26: 10,
    app_flat_26: 2,
    char_27: 'Qingque',
    app_rate_27: 0.92,
    avg_round_27: 10,
    app_flat_27: 2,
    char_28: 'trailblazer-imaginary',
    app_rate_28: 0.88,
    avg_round_28: 8.12,
    app_flat_28: 8,
    char_29: 'Lynx',
    app_rate_29: 0.88,
    avg_round_29: 9.5,
    app_flat_29: 2,
    char_30: 'Guinaifen',
    app_rate_30: 0.79,
    avg_round_30: 8.75,
    app_flat_30: 4
  },
  {
    char: 'Pela',
    round: 7.91,
    char_1: 'Acheron',
    app_rate_1: 95.57,
    avg_round_1: 7.95,
    app_flat_1: 573,
    char_2: 'Jiaoqiu',
    app_rate_2: 50.32,
    avg_round_2: 7.57,
    app_flat_2: 387,
    char_3: 'fu-xuan',
    app_rate_3: 32.43,
    avg_round_3: 7.92,
    app_flat_3: 170,
    char_4: 'Gallagher',
    app_rate_4: 22.67,
    avg_round_4: 7.72,
    app_flat_4: 154,
    char_5: 'Aventurine',
    app_rate_5: 19.42,
    avg_round_5: 8.13,
    app_flat_5: 147,
    char_6: 'silver-wolf',
    app_rate_6: 17.07,
    avg_round_6: 9.08,
    app_flat_6: 118,
    char_7: 'Sparkle',
    app_rate_7: 13.82,
    avg_round_7: 8.54,
    app_flat_7: 13,
    char_8: 'Huohuo',
    app_rate_8: 7.23,
    avg_round_8: 7.85,
    app_flat_8: 40,
    char_9: 'Luocha',
    app_rate_9: 6.5,
    avg_round_9: 8.23,
    app_flat_9: 48,
    char_10: 'Bronya',
    app_rate_10: 5.24,
    avg_round_10: 8,
    app_flat_10: 1,
    char_11: 'Gepard',
    app_rate_11: 4.07,
    avg_round_11: 8.67,
    app_flat_11: 18,
    char_12: 'ruan-mei',
    app_rate_12: 3.7,
    avg_round_12: 10,
    app_flat_12: 2,
    char_13: 'Guinaifen',
    app_rate_13: 3.34,
    avg_round_13: 9.31,
    app_flat_13: 30,
    char_14: 'Lingsha',
    app_rate_14: 2.98,
    avg_round_14: 7.54,
    app_flat_14: 13,
    char_15: 'Robin',
    app_rate_15: 2.8,
    avg_round_15: 7.6,
    app_flat_15: 5,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 2.08,
    avg_round_16: 8.05,
    app_flat_16: 19,
    char_17: 'black-swan',
    app_rate_17: 1.9,
    avg_round_17: 8.22,
    app_flat_17: 9,
    char_18: 'Firefly',
    app_rate_18: 1.81,
    avg_round_18: 7.88,
    app_flat_18: 17,
    char_19: 'Welt',
    app_rate_19: 1.26,
    avg_round_19: 9.29,
    app_flat_19: 7,
    char_20: 'Kafka',
    app_rate_20: 0.9,
    avg_round_20: 8.5,
    app_flat_20: 4,
    char_21: 'Bailu',
    app_rate_21: 0.63,
    avg_round_21: 9.67,
    app_flat_21: 5,
    char_22: 'Himeko',
    app_rate_22: 0.63,
    avg_round_22: 9.33,
    app_flat_22: 3,
    char_23: 'Yunli',
    app_rate_23: 0.63,
    avg_round_23: 8.5,
    app_flat_23: 2,
    char_24: 'Tingyun',
    app_rate_24: 0.54,
    avg_round_24: 9,
    app_flat_24: 3,
    char_25: 'Lynx',
    app_rate_25: 0.45,
    avg_round_25: 8.5,
    app_flat_25: 2,
    char_26: 'dr-ratio',
    app_rate_26: 0.36,
    avg_round_26: 8.5,
    app_flat_26: 2,
    char_27: 'Boothill',
    app_rate_27: 0.27,
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.27,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Jingliu',
    app_rate_29: 0.27,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Clara',
    app_rate_30: 0.27,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Acheron',
    round: 7.98,
    char_1: 'Jiaoqiu',
    app_rate_1: 62.44,
    avg_round_1: 7.66,
    app_flat_1: 761,
    char_2: 'fu-xuan',
    app_rate_2: 36.93,
    avg_round_2: 8.0,
    app_flat_2: 343,
    char_3: 'Pela',
    app_rate_3: 34.73,
    avg_round_3: 7.95,
    app_flat_3: 573,
    char_4: 'Sparkle',
    app_rate_4: 32.6,
    avg_round_4: 7.8,
    app_flat_4: 98,
    char_5: 'silver-wolf',
    app_rate_5: 22.32,
    avg_round_5: 8.43,
    app_flat_5: 280,
    char_6: 'Aventurine',
    app_rate_6: 20.35,
    avg_round_6: 8.01,
    app_flat_6: 277,
    char_7: 'Gallagher',
    app_rate_7: 16.25,
    avg_round_7: 7.65,
    app_flat_7: 224,
    char_8: 'black-swan',
    app_rate_8: 14.22,
    avg_round_8: 8.84,
    app_flat_8: 158,
    char_9: 'Kafka',
    app_rate_9: 10.6,
    avg_round_9: 9.0,
    app_flat_9: 122,
    char_10: 'Huohuo',
    app_rate_10: 8.4,
    avg_round_10: 8.36,
    app_flat_10: 87,
    char_11: 'Robin',
    app_rate_11: 6.5,
    avg_round_11: 6.9,
    app_flat_11: 48,
    char_12: 'Luocha',
    app_rate_12: 6.43,
    avg_round_12: 8.33,
    app_flat_12: 88,
    char_13: 'Bronya',
    app_rate_13: 6.14,
    avg_round_13: 7,
    app_flat_13: 1,
    char_14: 'ruan-mei',
    app_rate_14: 5.45,
    avg_round_14: 9.11,
    app_flat_14: 17,
    char_15: 'Guinaifen',
    app_rate_15: 4.99,
    avg_round_15: 9.28,
    app_flat_15: 85,
    char_16: 'Gepard',
    app_rate_16: 3.15,
    avg_round_16: 8.93,
    app_flat_16: 26,
    char_17: 'Lingsha',
    app_rate_17: 2.92,
    avg_round_17: 8.88,
    app_flat_17: 32,
    char_18: 'Welt',
    app_rate_18: 1.44,
    avg_round_18: 9.11,
    app_flat_18: 15,
    char_19: 'Himeko',
    app_rate_19: 1.15,
    avg_round_19: 9.4,
    app_flat_19: 9,
    char_20: 'Bailu',
    app_rate_20: 0.53,
    avg_round_20: 9.5,
    app_flat_20: 6,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 0.43,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Lynx',
    app_rate_22: 0.39,
    avg_round_22: 9,
    app_flat_22: 4,
    char_23: 'Sampo',
    app_rate_23: 0.23,
    avg_round_23: 8.33,
    app_flat_23: 3,
    char_24: 'Feixiao',
    app_rate_24: 0.23,
    avg_round_24: 8,
    app_flat_24: 3,
    char_25: 'Yunli',
    app_rate_25: 0.2,
    avg_round_25: 8,
    app_flat_25: 2,
    char_26: 'Boothill',
    app_rate_26: 0.16,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Firefly',
    app_rate_27: 0.13,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Clara',
    app_rate_28: 0.13,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Jade',
    app_rate_29: 0.1,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Asta',
    app_rate_30: 0.1,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    round: 8.09,
    char_1: 'Robin',
    app_rate_1: 56.72,
    avg_round_1: 7.76,
    app_flat_1: 50,
    char_2: 'Feixiao',
    app_rate_2: 50.0,
    avg_round_2: 7.71,
    app_flat_2: 48,
    char_3: 'march-7th-swordmaster',
    app_rate_3: 32.09,
    avg_round_3: 7.85,
    app_flat_3: 34,
    char_4: 'ruan-mei',
    app_rate_4: 20.9,
    avg_round_4: 8.43,
    app_flat_4: 7,
    char_5: 'topaz',
    app_rate_5: 17.91,
    avg_round_5: 8.67,
    app_flat_5: 15,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 13.43,
    avg_round_6: 8,
    app_flat_6: 5,
    char_7: 'Firefly',
    app_rate_7: 11.94,
    avg_round_7: 8,
    app_flat_7: 5,
    char_8: 'Acheron',
    app_rate_8: 11.94,
    avg_round_8: 9.5,
    app_flat_8: 6,
    char_9: 'black-swan',
    app_rate_9: 10.45,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'Yunli',
    app_rate_10: 9.7,
    avg_round_10: 9.17,
    app_flat_10: 10,
    char_11: 'Kafka',
    app_rate_11: 8.21,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Sparkle',
    app_rate_12: 6.72,
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: 'Bronya',
    app_rate_13: 6.72,
    avg_round_13: 10.0,
    app_flat_13: 4,
    char_14: 'Jiaoqiu',
    app_rate_14: 5.97,
    avg_round_14: 9.67,
    app_flat_14: 5,
    char_15: 'Pela',
    app_rate_15: 5.22,
    avg_round_15: 9.67,
    app_flat_15: 5,
    char_16: 'Tingyun',
    app_rate_16: 5.22,
    avg_round_16: 9.4,
    app_flat_16: 5,
    char_17: 'Himeko',
    app_rate_17: 5.22,
    avg_round_17: 9.5,
    app_flat_17: 4,
    char_18: 'Clara',
    app_rate_18: 4.48,
    avg_round_18: 7.5,
    app_flat_18: 2,
    char_19: 'silver-wolf',
    app_rate_19: 4.48,
    avg_round_19: 9,
    app_flat_19: 2,
    char_20: 'imbibitor-lunae',
    app_rate_20: 2.99,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Asta',
    app_rate_21: 2.24,
    avg_round_21: 9,
    app_flat_21: 3,
    char_22: 'Blade',
    app_rate_22: 1.49,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Jade',
    app_rate_23: 1.49,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Moze',
    app_rate_24: 0.75,
    avg_round_24: 5,
    app_flat_24: 1,
    char_25: 'dan-heng',
    app_rate_25: 0.75,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'fu-xuan',
    app_rate_26: 0.75,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Guinaifen',
    app_rate_27: 0.75,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: 0.75,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'Sampo',
    app_rate_29: 0.75,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    round: 8.21,
    char_1: 'Robin',
    app_rate_1: 40.79,
    avg_round_1: 8.22,
    app_flat_1: 17,
    char_2: 'Sparkle',
    app_rate_2: 26.32,
    avg_round_2: 9.5,
    app_flat_2: 2,
    char_3: 'ruan-mei',
    app_rate_3: 23.68,
    avg_round_3: 7.6,
    app_flat_3: 7,
    char_4: 'Yunli',
    app_rate_4: 22.37,
    avg_round_4: 8.8,
    app_flat_4: 7,
    char_5: 'Blade',
    app_rate_5: 17.11,
    avg_round_5: 8.33,
    app_flat_5: 3,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 17.11,
    avg_round_6: 7.2,
    app_flat_6: 7,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 17.11,
    avg_round_7: 8.4,
    app_flat_7: 10,
    char_8: 'Acheron',
    app_rate_8: 15.79,
    avg_round_8: 9,
    app_flat_8: 4,
    char_9: 'Firefly',
    app_rate_9: 15.79,
    avg_round_9: 7.2,
    app_flat_9: 7,
    char_10: 'Feixiao',
    app_rate_10: 14.47,
    avg_round_10: 7.75,
    app_flat_10: 8,
    char_11: 'Clara',
    app_rate_11: 13.16,
    avg_round_11: 9,
    app_flat_11: 4,
    char_12: 'Bronya',
    app_rate_12: 13.16,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'Jiaoqiu',
    app_rate_13: 11.84,
    avg_round_13: 9,
    app_flat_13: 4,
    char_14: 'silver-wolf',
    app_rate_14: 7.89,
    avg_round_14: 9.5,
    app_flat_14: 2,
    char_15: 'topaz',
    app_rate_15: 7.89,
    avg_round_15: 6.75,
    app_flat_15: 4,
    char_16: 'Tingyun',
    app_rate_16: 6.58,
    avg_round_16: 7.67,
    app_flat_16: 3,
    char_17: 'Himeko',
    app_rate_17: 6.58,
    avg_round_17: 8,
    app_flat_17: 3,
    char_18: 'Pela',
    app_rate_18: 6.58,
    avg_round_18: 8.5,
    app_flat_18: 2,
    char_19: 'Kafka',
    app_rate_19: 6.58,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'black-swan',
    app_rate_20: 3.95,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Moze',
    app_rate_21: 2.63,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'Jade',
    app_rate_22: 1.32,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Jingliu',
    app_rate_23: 1.32,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    round: 8.41,
    char_1: 'Acheron',
    app_rate_1: 58.9,
    avg_round_1: 8.93,
    app_flat_1: 26,
    char_2: 'Feixiao',
    app_rate_2: 31.9,
    avg_round_2: 7.68,
    app_flat_2: 37,
    char_3: 'Robin',
    app_rate_3: 31.9,
    avg_round_3: 7.56,
    app_flat_3: 34,
    char_4: 'Jiaoqiu',
    app_rate_4: 31.29,
    avg_round_4: 8.7,
    app_flat_4: 20,
    char_5: 'Pela',
    app_rate_5: 27.61,
    avg_round_5: 8.67,
    app_flat_5: 18,
    char_6: 'silver-wolf',
    app_rate_6: 15.34,
    avg_round_6: 9.0,
    app_flat_6: 7,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 14.72,
    avg_round_7: 8.4,
    app_flat_7: 15,
    char_8: 'Sparkle',
    app_rate_8: 13.5,
    avg_round_8: 10,
    app_flat_8: 2,
    char_9: 'topaz',
    app_rate_9: 12.88,
    avg_round_9: 7,
    app_flat_9: 17,
    char_10: 'ruan-mei',
    app_rate_10: 11.04,
    avg_round_10: 8.5,
    app_flat_10: 2,
    char_11: 'Bronya',
    app_rate_11: 11.04,
    avg_round_11: 7,
    app_flat_11: 1,
    char_12: 'black-swan',
    app_rate_12: 9.2,
    avg_round_12: 9.5,
    app_flat_12: 2,
    char_13: 'trailblazer-imaginary',
    app_rate_13: 6.13,
    avg_round_13: 8.5,
    app_flat_13: 2,
    char_14: 'Kafka',
    app_rate_14: 5.52,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Firefly',
    app_rate_15: 4.91,
    avg_round_15: 8,
    app_flat_15: 1,
    char_16: 'Moze',
    app_rate_16: 4.29,
    avg_round_16: 7.8,
    app_flat_16: 5,
    char_17: 'Guinaifen',
    app_rate_17: 4.29,
    avg_round_17: 9.33,
    app_flat_17: 5,
    char_18: 'Boothill',
    app_rate_18: 1.84,
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'Huohuo',
    app_rate_19: 1.23,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Himeko',
    app_rate_20: 0.61,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: 0.61,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'Tingyun',
    app_rate_22: 0.61,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Yunli',
    app_rate_23: 0.61,
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    round: 8.42,
    char_1: 'Robin',
    app_rate_1: 82.13,
    avg_round_1: 8.14,
    app_flat_1: 108,
    char_2: 'Aventurine',
    app_rate_2: 77.23,
    avg_round_2: 8.15,
    app_flat_2: 95,
    char_3: 'topaz',
    app_rate_3: 62.82,
    avg_round_3: 8.06,
    app_flat_3: 77,
    char_4: 'Jiaoqiu',
    app_rate_4: 13.26,
    avg_round_4: 8.47,
    app_flat_4: 17,
    char_5: 'Moze',
    app_rate_5: 11.53,
    avg_round_5: 7.7,
    app_flat_5: 10,
    char_6: 'silver-wolf',
    app_rate_6: 5.76,
    avg_round_6: 9.22,
    app_flat_6: 9,
    char_7: 'ruan-mei',
    app_rate_7: 5.48,
    avg_round_7: 9.75,
    app_flat_7: 8,
    char_8: 'Gallagher',
    app_rate_8: 5.48,
    avg_round_8: 8.83,
    app_flat_8: 12,
    char_9: 'Lingsha',
    app_rate_9: 4.9,
    avg_round_9: 8.78,
    app_flat_9: 9,
    char_10: 'fu-xuan',
    app_rate_10: 4.61,
    avg_round_10: 7,
    app_flat_10: 4,
    char_11: 'Sparkle',
    app_rate_11: 4.03,
    avg_round_11: 8,
    app_flat_11: 4,
    char_12: 'Huohuo',
    app_rate_12: 3.75,
    avg_round_12: 9.75,
    app_flat_12: 6,
    char_13: 'Luocha',
    app_rate_13: 3.75,
    avg_round_13: 9.33,
    app_flat_13: 3,
    char_14: 'Himeko',
    app_rate_14: 3.46,
    avg_round_14: 9.14,
    app_flat_14: 7,
    char_15: 'Tingyun',
    app_rate_15: 3.17,
    avg_round_15: 9.4,
    app_flat_15: 5,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 2.88,
    avg_round_16: 9,
    app_flat_16: 3,
    char_17: 'Pela',
    app_rate_17: 1.15,
    avg_round_17: 8.5,
    app_flat_17: 2,
    char_18: 'Bronya',
    app_rate_18: 0.86,
    avg_round_18: 9.5,
    app_flat_18: 2,
    char_19: 'Clara',
    app_rate_19: 0.86,
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'Feixiao',
    app_rate_20: 0.58,
    avg_round_20: 8.5,
    app_flat_20: 2,
    char_21: 'imbibitor-lunae',
    app_rate_21: 0.58,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.58,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'Natasha',
    app_rate_23: 0.29,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Guinaifen',
    app_rate_24: 0.29,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Kafka',
    app_rate_25: 0.29,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'Welt',
    app_rate_26: 0.29,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    round: 8.47,
    char_1: 'Acheron',
    app_rate_1: 83.95,
    avg_round_1: 8.43,
    app_flat_1: 280,
    char_2: 'fu-xuan',
    app_rate_2: 54.07,
    avg_round_2: 8.41,
    app_flat_2: 132,
    char_3: 'Sparkle',
    app_rate_3: 31.98,
    avg_round_3: 9.07,
    app_flat_3: 15,
    char_4: 'Jiaoqiu',
    app_rate_4: 29.26,
    avg_round_4: 7.97,
    app_flat_4: 129,
    char_5: 'Pela',
    app_rate_5: 23.33,
    avg_round_5: 9.08,
    app_flat_5: 118,
    char_6: 'Aventurine',
    app_rate_6: 16.05,
    avg_round_6: 9.19,
    app_flat_6: 72,
    char_7: 'Gallagher',
    app_rate_7: 10.99,
    avg_round_7: 8.08,
    app_flat_7: 51,
    char_8: 'Huohuo',
    app_rate_8: 5.19,
    avg_round_8: 8.6,
    app_flat_8: 15,
    char_9: 'Seele',
    app_rate_9: 4.94,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'Robin',
    app_rate_10: 4.57,
    avg_round_10: 9.58,
    app_flat_10: 12,
    char_11: 'Lingsha',
    app_rate_11: 3.58,
    avg_round_11: 8.5,
    app_flat_11: 10,
    char_12: 'Luocha',
    app_rate_12: 3.46,
    avg_round_12: 8.86,
    app_flat_12: 14,
    char_13: 'Gepard',
    app_rate_13: 3.09,
    avg_round_13: 9.0,
    app_flat_13: 7,
    char_14: 'Bronya',
    app_rate_14: 2.72,
    avg_round_14: 9.67,
    app_flat_14: 3,
    char_15: 'dr-ratio',
    app_rate_15: 2.47,
    avg_round_15: 9.22,
    app_flat_15: 9,
    char_16: 'Qingque',
    app_rate_16: 2.35,
    avg_round_16: 10,
    app_flat_16: 2,
    char_17: 'black-swan',
    app_rate_17: 1.85,
    avg_round_17: 9.6,
    app_flat_17: 9,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 1.36,
    avg_round_18: 8.2,
    app_flat_18: 5,
    char_19: 'Kafka',
    app_rate_19: 1.36,
    avg_round_19: 8.25,
    app_flat_19: 4,
    char_20: 'Yunli',
    app_rate_20: 1.36,
    avg_round_20: 9,
    app_flat_20: 4,
    char_21: 'ruan-mei',
    app_rate_21: 1.23,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Guinaifen',
    app_rate_22: 1.23,
    avg_round_22: 9,
    app_flat_22: 6,
    char_23: 'Boothill',
    app_rate_23: 0.99,
    avg_round_23: 9.5,
    app_flat_23: 4,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.99,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Firefly',
    app_rate_25: 0.99,
    avg_round_25: 7.33,
    app_flat_25: 3,
    char_26: 'Welt',
    app_rate_26: 0.86,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Lynx',
    app_rate_27: 0.74,
    avg_round_27: 9.5,
    app_flat_27: 2,
    char_28: 'Bailu',
    app_rate_28: 0.74,
    avg_round_28: 9,
    app_flat_28: 2,
    char_29: 'Tingyun',
    app_rate_29: 0.74,
    avg_round_29: 8.5,
    app_flat_29: 2,
    char_30: 'Feixiao',
    app_rate_30: 0.62,
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'Clara',
    round: 8.49,
    char_1: 'Robin',
    app_rate_1: 75.46,
    avg_round_1: 8.15,
    app_flat_1: 223,
    char_2: 'topaz',
    app_rate_2: 30.44,
    avg_round_2: 8.7,
    app_flat_2: 91,
    char_3: 'Aventurine',
    app_rate_3: 24.35,
    avg_round_3: 8.81,
    app_flat_3: 72,
    char_4: 'Huohuo',
    app_rate_4: 24.17,
    avg_round_4: 8.24,
    app_flat_4: 72,
    char_5: 'Feixiao',
    app_rate_5: 22.32,
    avg_round_5: 8.22,
    app_flat_5: 79,
    char_6: 'Sparkle',
    app_rate_6: 19.93,
    avg_round_6: 8.72,
    app_flat_6: 47,
    char_7: 'fu-xuan',
    app_rate_7: 18.82,
    avg_round_7: 8.13,
    app_flat_7: 47,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 16.61,
    avg_round_8: 8.62,
    app_flat_8: 57,
    char_9: 'Luocha',
    app_rate_9: 15.5,
    avg_round_9: 9.0,
    app_flat_9: 57,
    char_10: 'Tingyun',
    app_rate_10: 12.92,
    avg_round_10: 8.42,
    app_flat_10: 38,
    char_11: 'Gallagher',
    app_rate_11: 8.86,
    avg_round_11: 8.6,
    app_flat_11: 29,
    char_12: 'Lingsha',
    app_rate_12: 4.61,
    avg_round_12: 8.17,
    app_flat_12: 10,
    char_13: 'Yunli',
    app_rate_13: 3.32,
    avg_round_13: 8,
    app_flat_13: 9,
    char_14: 'ruan-mei',
    app_rate_14: 3.14,
    avg_round_14: 9.6,
    app_flat_14: 5,
    char_15: 'Blade',
    app_rate_15: 3.14,
    avg_round_15: 10.0,
    app_flat_15: 11,
    char_16: 'Lynx',
    app_rate_16: 1.85,
    avg_round_16: 9,
    app_flat_16: 4,
    char_17: 'Moze',
    app_rate_17: 1.85,
    avg_round_17: 9.6,
    app_flat_17: 5,
    char_18: 'black-swan',
    app_rate_18: 1.48,
    avg_round_18: 8.67,
    app_flat_18: 3,
    char_19: 'Jiaoqiu',
    app_rate_19: 1.11,
    avg_round_19: 9.25,
    app_flat_19: 4,
    char_20: 'Bailu',
    app_rate_20: 1.11,
    avg_round_20: 7.5,
    app_flat_20: 2,
    char_21: 'Asta',
    app_rate_21: 1.11,
    avg_round_21: 10,
    app_flat_21: 4,
    char_22: 'Bronya',
    app_rate_22: 0.92,
    avg_round_22: 10,
    app_flat_22: 2,
    char_23: 'Acheron',
    app_rate_23: 0.74,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: 0.74,
    avg_round_24: 9,
    app_flat_24: 2,
    char_25: 'Himeko',
    app_rate_25: 0.55,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'dr-ratio',
    app_rate_26: 0.55,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Firefly',
    app_rate_27: 0.55,
    avg_round_27: 9.5,
    app_flat_27: 2,
    char_28: 'trailblazer-imaginary',
    app_rate_28: 0.55,
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'Pela',
    app_rate_29: 0.55,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Jade',
    app_rate_30: 0.37,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    round: 8.88,
    char_1: 'Kafka',
    app_rate_1: 63.01,
    avg_round_1: 9.11,
    app_flat_1: 110,
    char_2: 'Acheron',
    app_rate_2: 57.2,
    avg_round_2: 8.84,
    app_flat_2: 158,
    char_3: 'Huohuo',
    app_rate_3: 28.53,
    avg_round_3: 9.32,
    app_flat_3: 35,
    char_4: 'Jiaoqiu',
    app_rate_4: 23.25,
    avg_round_4: 8.28,
    app_flat_4: 71,
    char_5: 'fu-xuan',
    app_rate_5: 18.49,
    avg_round_5: 9.2,
    app_flat_5: 38,
    char_6: 'Aventurine',
    app_rate_6: 17.57,
    avg_round_6: 8.36,
    app_flat_6: 56,
    char_7: 'ruan-mei',
    app_rate_7: 17.31,
    avg_round_7: 8.92,
    app_flat_7: 22,
    char_8: 'Robin',
    app_rate_8: 14.93,
    avg_round_8: 9.12,
    app_flat_8: 17,
    char_9: 'Gallagher',
    app_rate_9: 14.4,
    avg_round_9: 8.27,
    app_flat_9: 44,
    char_10: 'Luocha',
    app_rate_10: 10.83,
    avg_round_10: 9.17,
    app_flat_10: 22,
    char_11: 'Lingsha',
    app_rate_11: 5.02,
    avg_round_11: 9.38,
    app_flat_11: 16,
    char_12: 'Guinaifen',
    app_rate_12: 4.76,
    avg_round_12: 9.17,
    app_flat_12: 12,
    char_13: 'Sparkle',
    app_rate_13: 4.62,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Pela',
    app_rate_14: 2.77,
    avg_round_14: 8.22,
    app_flat_14: 9,
    char_15: 'Asta',
    app_rate_15: 2.11,
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Gepard',
    app_rate_16: 1.98,
    avg_round_16: 9.5,
    app_flat_16: 2,
    char_17: 'silver-wolf',
    app_rate_17: 1.98,
    avg_round_17: 9.6,
    app_flat_17: 9,
    char_18: 'Bailu',
    app_rate_18: 1.85,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Sampo',
    app_rate_19: 1.59,
    avg_round_19: 10.0,
    app_flat_19: 5,
    char_20: 'Feixiao',
    app_rate_20: 1.19,
    avg_round_20: 9,
    app_flat_20: 3,
    char_21: 'Clara',
    app_rate_21: 1.06,
    avg_round_21: 8.67,
    app_flat_21: 3,
    char_22: 'Yunli',
    app_rate_22: 0.79,
    avg_round_22: 9.33,
    app_flat_22: 3,
    char_23: 'Bronya',
    app_rate_23: 0.66,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Tingyun',
    app_rate_24: 0.66,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Himeko',
    app_rate_25: 0.53,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'march-7th-swordmaster',
    app_rate_26: 0.53,
    avg_round_26: 10,
    app_flat_26: 2,
    char_27: 'Blade',
    app_rate_27: 0.4,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Firefly',
    app_rate_28: 0.4,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.4,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Lynx',
    app_rate_30: 0.4,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    round: 8.93,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 54.49,
    avg_round_1: 8.45,
    app_flat_1: 58,
    char_2: 'Firefly',
    app_rate_2: 41.32,
    avg_round_2: 8.24,
    app_flat_2: 42,
    char_3: 'Gallagher',
    app_rate_3: 40.72,
    avg_round_3: 8.47,
    app_flat_3: 47,
    char_4: 'Himeko',
    app_rate_4: 22.75,
    avg_round_4: 9.33,
    app_flat_4: 22,
    char_5: 'ruan-mei',
    app_rate_5: 18.56,
    avg_round_5: 8.4,
    app_flat_5: 5,
    char_6: 'Lingsha',
    app_rate_6: 14.37,
    avg_round_6: 8.62,
    app_flat_6: 14,
    char_7: 'Kafka',
    app_rate_7: 10.18,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'black-swan',
    app_rate_8: 9.58,
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Aventurine',
    app_rate_9: 8.98,
    avg_round_9: 10.0,
    app_flat_9: 6,
    char_10: 'fu-xuan',
    app_rate_10: 7.78,
    avg_round_10: 9.0,
    app_flat_10: 4,
    char_11: 'Huohuo',
    app_rate_11: 7.78,
    avg_round_11: 10.0,
    app_flat_11: 5,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 7.19,
    avg_round_12: 9.43,
    app_flat_12: 11,
    char_13: 'Luocha',
    app_rate_13: 7.19,
    avg_round_13: 9,
    app_flat_13: 5,
    char_14: 'topaz',
    app_rate_14: 6.59,
    avg_round_14: 10.0,
    app_flat_14: 4,
    char_15: 'Sparkle',
    app_rate_15: 5.99,
    avg_round_15: 10,
    app_flat_15: 4,
    char_16: 'Robin',
    app_rate_16: 5.39,
    avg_round_16: 8.5,
    app_flat_16: 6,
    char_17: 'Clara',
    app_rate_17: 3.59,
    avg_round_17: 10,
    app_flat_17: 4,
    char_18: 'Tingyun',
    app_rate_18: 3.59,
    avg_round_18: 9.5,
    app_flat_18: 2,
    char_19: 'Feixiao',
    app_rate_19: 2.99,
    avg_round_19: 8.33,
    app_flat_19: 3,
    char_20: 'Hook',
    app_rate_20: 2.4,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'imbibitor-lunae',
    app_rate_21: 2.4,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Bailu',
    app_rate_22: 1.8,
    avg_round_22: 9,
    app_flat_22: 3,
    char_23: 'Guinaifen',
    app_rate_23: 1.8,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Acheron',
    app_rate_24: 1.8,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Bronya',
    app_rate_25: 1.8,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Jiaoqiu',
    app_rate_26: 1.8,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Jade',
    app_rate_27: 1.2,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: 1.2,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'silver-wolf',
    app_rate_29: 1.2,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Sampo',
    app_rate_30: 0.6,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Kafka',
    round: 9.03,
    char_1: 'black-swan',
    app_rate_1: 74.18,
    avg_round_1: 9.11,
    app_flat_1: 110,
    char_2: 'Acheron',
    app_rate_2: 50.23,
    avg_round_2: 9.0,
    app_flat_2: 122,
    char_3: 'Huohuo',
    app_rate_3: 31.73,
    avg_round_3: 9.18,
    app_flat_3: 31,
    char_4: 'ruan-mei',
    app_rate_4: 22.24,
    avg_round_4: 9.0,
    app_flat_4: 26,
    char_5: 'Gallagher',
    app_rate_5: 17.57,
    avg_round_5: 8.12,
    app_flat_5: 48,
    char_6: 'Robin',
    app_rate_6: 16.64,
    avg_round_6: 9.12,
    app_flat_6: 17,
    char_7: 'Jiaoqiu',
    app_rate_7: 15.24,
    avg_round_7: 8.08,
    app_flat_7: 36,
    char_8: 'fu-xuan',
    app_rate_8: 14.0,
    avg_round_8: 9.43,
    app_flat_8: 21,
    char_9: 'Aventurine',
    app_rate_9: 12.75,
    avg_round_9: 8.73,
    app_flat_9: 30,
    char_10: 'Luocha',
    app_rate_10: 11.82,
    avg_round_10: 9.0,
    app_flat_10: 23,
    char_11: 'Guinaifen',
    app_rate_11: 8.71,
    avg_round_11: 9.91,
    app_flat_11: 21,
    char_12: 'Lingsha',
    app_rate_12: 6.53,
    avg_round_12: 9.33,
    app_flat_12: 22,
    char_13: 'Asta',
    app_rate_13: 2.64,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Sparkle',
    app_rate_14: 2.02,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Bailu',
    app_rate_15: 1.71,
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 1.71,
    avg_round_16: 9.2,
    app_flat_16: 5,
    char_17: 'silver-wolf',
    app_rate_17: 1.71,
    avg_round_17: 8.25,
    app_flat_17: 4,
    char_18: 'Pela',
    app_rate_18: 1.56,
    avg_round_18: 8.5,
    app_flat_18: 4,
    char_19: 'Gepard',
    app_rate_19: 1.4,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Sampo',
    app_rate_20: 1.24,
    avg_round_20: 10,
    app_flat_20: 2,
    char_21: 'Himeko',
    app_rate_21: 1.09,
    avg_round_21: 8.5,
    app_flat_21: 2,
    char_22: 'Lynx',
    app_rate_22: 0.78,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Tingyun',
    app_rate_23: 0.62,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 0.47,
    avg_round_24: 8,
    app_flat_24: 1,
    char_25: 'Welt',
    app_rate_25: 0.31,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 0.31,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Yunli',
    app_rate_27: 0.16,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'dr-ratio',
    app_rate_28: 0.16,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'topaz',
    app_rate_29: 0.16,
    avg_round_29: 9,
    app_flat_29: 1,
    char_30: 'Feixiao',
    app_rate_30: 0.16,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    round: 9.11,
    char_1: 'Tingyun',
    app_rate_1: 46.97,
    avg_round_1: 8.75,
    app_flat_1: 12,
    char_2: 'Sparkle',
    app_rate_2: 42.42,
    avg_round_2: 9.17,
    app_flat_2: 6,
    char_3: 'Gallagher',
    app_rate_3: 34.85,
    avg_round_3: 9.2,
    app_flat_3: 9,
    char_4: 'Robin',
    app_rate_4: 33.33,
    avg_round_4: 8.75,
    app_flat_4: 8,
    char_5: 'ruan-mei',
    app_rate_5: 27.27,
    avg_round_5: 9.33,
    app_flat_5: 5,
    char_6: 'Aventurine',
    app_rate_6: 24.24,
    avg_round_6: 8.67,
    app_flat_6: 6,
    char_7: 'Himeko',
    app_rate_7: 13.64,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'Huohuo',
    app_rate_8: 13.64,
    avg_round_8: 8,
    app_flat_8: 1,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 13.64,
    avg_round_9: 8.67,
    app_flat_9: 3,
    char_10: 'Luocha',
    app_rate_10: 10.61,
    avg_round_10: 9.5,
    app_flat_10: 2,
    char_11: 'Lingsha',
    app_rate_11: 9.09,
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'Jiaoqiu',
    app_rate_12: 6.06,
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'fu-xuan',
    app_rate_13: 4.55,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'topaz',
    app_rate_14: 4.55,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Jade',
    app_rate_15: 3.03,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 3.03,
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'Asta',
    app_rate_17: 3.03,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'Feixiao',
    app_rate_18: 1.52,
    avg_round_18: 7,
    app_flat_18: 1,
    char_19: 'Firefly',
    app_rate_19: 1.52,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Bailu',
    app_rate_20: 1.52,
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'Bronya',
    app_rate_21: 1.52,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    round: 9.22,
    char_1: 'Acheron',
    app_rate_1: 74.58,
    avg_round_1: 9.11,
    app_flat_1: 15,
    char_2: 'Gallagher',
    app_rate_2: 30.51,
    avg_round_2: 9.17,
    app_flat_2: 10,
    char_3: 'ruan-mei',
    app_rate_3: 25.42,
    avg_round_3: 9.5,
    app_flat_3: 2,
    char_4: 'Pela',
    app_rate_4: 23.73,
    avg_round_4: 9.29,
    app_flat_4: 7,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 20.34,
    avg_round_5: 9.5,
    app_flat_5: 2,
    char_6: 'fu-xuan',
    app_rate_6: 18.64,
    avg_round_6: 8.8,
    app_flat_6: 5,
    char_7: 'Sparkle',
    app_rate_7: 18.64,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'Jiaoqiu',
    app_rate_8: 18.64,
    avg_round_8: 8.67,
    app_flat_8: 5,
    char_9: 'silver-wolf',
    app_rate_9: 11.86,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'Himeko',
    app_rate_10: 10.17,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'Luocha',
    app_rate_11: 8.47,
    avg_round_11: 8,
    app_flat_11: 2,
    char_12: 'Firefly',
    app_rate_12: 8.47,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Aventurine',
    app_rate_13: 5.08,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Kafka',
    app_rate_14: 3.39,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Lingsha',
    app_rate_15: 3.39,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Guinaifen',
    app_rate_16: 3.39,
    avg_round_16: 7,
    app_flat_16: 1,
    char_17: 'Bronya',
    app_rate_17: 3.39,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Huohuo',
    app_rate_18: 3.39,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'imbibitor-lunae',
    app_rate_19: 1.69,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'dr-ratio',
    app_rate_20: 1.69,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Robin',
    app_rate_21: 1.69,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'black-swan',
    app_rate_22: 1.69,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Boothill',
    app_rate_23: 1.69,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    round: 9.26,
    char_1: 'Sparkle',
    app_rate_1: 87.32,
    avg_round_1: 9.56,
    app_flat_1: 32,
    char_2: 'Tingyun',
    app_rate_2: 43.52,
    avg_round_2: 9.55,
    app_flat_2: 19,
    char_3: 'Huohuo',
    app_rate_3: 34.01,
    avg_round_3: 10.0,
    app_flat_3: 8,
    char_4: 'ruan-mei',
    app_rate_4: 27.95,
    avg_round_4: 8.67,
    app_flat_4: 12,
    char_5: 'Gallagher',
    app_rate_5: 22.19,
    avg_round_5: 7.68,
    app_flat_5: 19,
    char_6: 'Luocha',
    app_rate_6: 17.87,
    avg_round_6: 9.5,
    app_flat_6: 2,
    char_7: 'Robin',
    app_rate_7: 17.29,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: 12.1,
    avg_round_8: 9.67,
    app_flat_8: 6,
    char_9: 'Aventurine',
    app_rate_9: 8.65,
    avg_round_9: 9.2,
    app_flat_9: 5,
    char_10: 'Jiaoqiu',
    app_rate_10: 6.34,
    avg_round_10: 7.67,
    app_flat_10: 3,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 4.61,
    avg_round_11: 6.71,
    app_flat_11: 7,
    char_12: 'Lingsha',
    app_rate_12: 2.59,
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: 'Himeko',
    app_rate_13: 2.31,
    avg_round_13: 8.5,
    app_flat_13: 2,
    char_14: 'Hanya',
    app_rate_14: 2.31,
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: 2.31,
    avg_round_15: 7,
    app_flat_15: 1,
    char_16: 'silver-wolf',
    app_rate_16: 2.31,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Bailu',
    app_rate_17: 1.15,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Asta',
    app_rate_18: 1.15,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Pela',
    app_rate_19: 0.86,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'march-7th-swordmaster',
    app_rate_20: 0.58,
    avg_round_20: 10,
    app_flat_20: 2,
    char_21: 'Yukong',
    app_rate_21: 0.58,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'dr-ratio',
    app_rate_22: 0.58,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Welt',
    app_rate_23: 0.29,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Natasha',
    app_rate_24: 0.29,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Yunli',
    app_rate_25: 0.29,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 0.29,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Jade',
    app_rate_27: 0.29,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    round: 9.31,
    char_1: 'Acheron',
    app_rate_1: 68.16,
    avg_round_1: 9.28,
    app_flat_1: 85,
    char_2: 'Gallagher',
    app_rate_2: 29.15,
    avg_round_2: 8.59,
    app_flat_2: 42,
    char_3: 'Kafka',
    app_rate_3: 25.11,
    avg_round_3: 9.91,
    app_flat_3: 21,
    char_4: 'ruan-mei',
    app_rate_4: 21.52,
    avg_round_4: 8.64,
    app_flat_4: 26,
    char_5: 'fu-xuan',
    app_rate_5: 20.63,
    avg_round_5: 9.4,
    app_flat_5: 27,
    char_6: 'Pela',
    app_rate_6: 16.59,
    avg_round_6: 9.31,
    app_flat_6: 30,
    char_7: 'black-swan',
    app_rate_7: 16.14,
    avg_round_7: 9.17,
    app_flat_7: 12,
    char_8: 'Jiaoqiu',
    app_rate_8: 16.14,
    avg_round_8: 7.79,
    app_flat_8: 19,
    char_9: 'Huohuo',
    app_rate_9: 15.7,
    avg_round_9: 9.2,
    app_flat_9: 9,
    char_10: 'Aventurine',
    app_rate_10: 14.35,
    avg_round_10: 9.67,
    app_flat_10: 22,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 14.35,
    avg_round_11: 8.75,
    app_flat_11: 24,
    char_12: 'Sparkle',
    app_rate_12: 8.07,
    avg_round_12: 8.75,
    app_flat_12: 4,
    char_13: 'Lingsha',
    app_rate_13: 6.73,
    avg_round_13: 9.4,
    app_flat_13: 9,
    char_14: 'silver-wolf',
    app_rate_14: 4.48,
    avg_round_14: 9,
    app_flat_14: 6,
    char_15: 'Luocha',
    app_rate_15: 4.04,
    avg_round_15: 8.75,
    app_flat_15: 4,
    char_16: 'Gepard',
    app_rate_16: 3.14,
    avg_round_16: 9.33,
    app_flat_16: 5,
    char_17: 'Himeko',
    app_rate_17: 3.14,
    avg_round_17: 7.5,
    app_flat_17: 2,
    char_18: 'Robin',
    app_rate_18: 2.69,
    avg_round_18: 10,
    app_flat_18: 2,
    char_19: 'Bronya',
    app_rate_19: 1.79,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Firefly',
    app_rate_20: 1.35,
    avg_round_20: 7,
    app_flat_20: 1,
    char_21: 'Asta',
    app_rate_21: 1.35,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Welt',
    app_rate_22: 0.9,
    avg_round_22: 7,
    app_flat_22: 1,
    char_23: 'Clara',
    app_rate_23: 0.9,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'topaz',
    app_rate_24: 0.45,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'march-7th-swordmaster',
    app_rate_25: 0.45,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'dr-ratio',
    app_rate_26: 0.45,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Yunli',
    app_rate_27: 0.45,
    avg_round_27: 7,
    app_flat_27: 1,
    char_28: 'Bailu',
    app_rate_28: 0.45,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Natasha',
    app_rate_29: 0.45,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Hook',
    app_rate_30: 0.45,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    round: 9.35,
    char_1: 'Bronya',
    app_rate_1: 44.38,
    avg_round_1: 9.4,
    app_flat_1: 18,
    char_2: 'Robin',
    app_rate_2: 43.79,
    avg_round_2: 9.12,
    app_flat_2: 30,
    char_3: 'Luocha',
    app_rate_3: 31.36,
    avg_round_3: 9.78,
    app_flat_3: 17,
    char_4: 'Sparkle',
    app_rate_4: 30.18,
    avg_round_4: 9.38,
    app_flat_4: 14,
    char_5: 'Huohuo',
    app_rate_5: 23.67,
    avg_round_5: 9.71,
    app_flat_5: 13,
    char_6: 'ruan-mei',
    app_rate_6: 16.57,
    avg_round_6: 10.0,
    app_flat_6: 8,
    char_7: 'fu-xuan',
    app_rate_7: 13.61,
    avg_round_7: 9.0,
    app_flat_7: 7,
    char_8: 'Gallagher',
    app_rate_8: 10.65,
    avg_round_8: 9.75,
    app_flat_8: 8,
    char_9: 'Yunli',
    app_rate_9: 10.06,
    avg_round_9: 8.8,
    app_flat_9: 7,
    char_10: 'Clara',
    app_rate_10: 10.06,
    avg_round_10: 10.0,
    app_flat_10: 11,
    char_11: 'Feixiao',
    app_rate_11: 9.47,
    avg_round_11: 8.22,
    app_flat_11: 9,
    char_12: 'Lingsha',
    app_rate_12: 8.88,
    avg_round_12: 7.57,
    app_flat_12: 7,
    char_13: 'Jade',
    app_rate_13: 7.69,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Lynx',
    app_rate_14: 7.69,
    avg_round_14: 8.33,
    app_flat_14: 3,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 6.51,
    avg_round_15: 9.4,
    app_flat_15: 7,
    char_16: 'Jiaoqiu',
    app_rate_16: 5.92,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Jingliu',
    app_rate_17: 5.92,
    avg_round_17: 8.5,
    app_flat_17: 2,
    char_18: 'topaz',
    app_rate_18: 4.14,
    avg_round_18: 9.67,
    app_flat_18: 3,
    char_19: 'Aventurine',
    app_rate_19: 1.78,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'black-swan',
    app_rate_20: 1.78,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 1.18,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Bailu',
    app_rate_22: 1.18,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Moze',
    app_rate_23: 1.18,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Himeko',
    app_rate_24: 0.59,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Boothill',
    app_rate_25: 0.59,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Natasha',
    app_rate_26: 0.59,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Pela',
    app_rate_27: 0.59,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 53.33,
    avg_round_1: 7.67,
    app_flat_1: 6,
    char_2: 'Aventurine',
    app_rate_2: 34.81,
    avg_round_2: 8,
    app_flat_2: 4,
    char_3: 'Feixiao',
    app_rate_3: 33.33,
    avg_round_3: 8,
    app_flat_3: 4,
    char_4: 'Himeko',
    app_rate_4: 28.89,
    avg_round_4: 8.1,
    app_flat_4: 10,
    char_5: 'ruan-mei',
    app_rate_5: 20.0,
    avg_round_5: 7.2,
    app_flat_5: 5,
    char_6: 'Lingsha',
    app_rate_6: 17.04,
    avg_round_6: 5.4,
    app_flat_6: 5,
    char_7: 'fu-xuan',
    app_rate_7: 14.07,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'topaz',
    app_rate_8: 12.59,
    avg_round_8: 7,
    app_flat_8: 1,
    char_9: 'Gallagher',
    app_rate_9: 12.59,
    avg_round_9: 8.33,
    app_flat_9: 3,
    char_10: 'Bronya',
    app_rate_10: 11.11,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Sparkle',
    app_rate_11: 10.37,
    avg_round_11: 9,
    app_flat_11: 3,
    char_12: 'Huohuo',
    app_rate_12: 9.63,
    avg_round_12: 9.25,
    app_flat_12: 4,
    char_13: 'Blade',
    app_rate_13: 9.63,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Luocha',
    app_rate_14: 8.15,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 2.96,
    avg_round_15: 7.25,
    app_flat_15: 4,
    char_16: 'silver-wolf',
    app_rate_16: 2.96,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 2.96,
    avg_round_17: 7,
    app_flat_17: 2,
    char_18: 'Acheron',
    app_rate_18: 2.22,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Yunli',
    app_rate_19: 2.22,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Clara',
    app_rate_20: 1.48,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Jiaoqiu',
    app_rate_21: 1.48,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: 1.48,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'jing-yuan',
    app_rate_23: 1.48,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Bailu',
    app_rate_24: 1.48,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Herta',
    app_rate_25: 0.74,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Lynx',
    app_rate_26: 0.74,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Tingyun',
    app_rate_27: 0.74,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Moze',
    app_rate_28: 0.74,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'imbibitor-lunae',
    app_rate_29: 0.74,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    round: 99.99,
    char_1: 'Sparkle',
    app_rate_1: 98.0,
    avg_round_1: 10,
    app_flat_1: 1,
    char_2: 'fu-xuan',
    app_rate_2: 82.0,
    avg_round_2: 10,
    app_flat_2: 1,
    char_3: 'silver-wolf',
    app_rate_3: 80.0,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Robin',
    app_rate_4: 20.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Tingyun',
    app_rate_5: 6.0,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Lingsha',
    app_rate_6: 4.0,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Firefly',
    app_rate_7: 2.0,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: 2.0,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: 2.0,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Jiaoqiu',
    app_rate_10: 2.0,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Aventurine',
    app_rate_11: 2.0,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    round: 99.99,
    char_1: 'Bronya',
    app_rate_1: 50.0,
    avg_round_1: 8,
    app_flat_1: 1,
    char_2: 'Robin',
    app_rate_2: 38.64,
    avg_round_2: 7,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: 31.82,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Sparkle',
    app_rate_4: 31.82,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: 'Huohuo',
    app_rate_5: 31.82,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Blade',
    app_rate_6: 22.73,
    avg_round_6: 8.5,
    app_flat_6: 2,
    char_7: 'Luocha',
    app_rate_7: 18.18,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'Lingsha',
    app_rate_8: 18.18,
    avg_round_8: 7.5,
    app_flat_8: 2,
    char_9: 'fu-xuan',
    app_rate_9: 18.18,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Aventurine',
    app_rate_10: 6.82,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Gallagher',
    app_rate_11: 6.82,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 6.82,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Clara',
    app_rate_13: 4.55,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 2.27,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Feixiao',
    app_rate_15: 2.27,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Yunli',
    app_rate_16: 2.27,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Himeko',
    app_rate_17: 2.27,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Lynx',
    app_rate_18: 2.27,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Firefly',
    app_rate_19: 2.27,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    round: 99.99,
    char_1: 'Sparkle',
    app_rate_1: 100.0,
    avg_round_1: 10,
    app_flat_1: 2,
    char_2: 'silver-wolf',
    app_rate_2: 90.48,
    avg_round_2: 10,
    app_flat_2: 2,
    char_3: 'fu-xuan',
    app_rate_3: 90.48,
    avg_round_3: 10,
    app_flat_3: 2,
    char_4: 'Jiaoqiu',
    app_rate_4: 4.76,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Aventurine',
    app_rate_5: 4.76,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Gallagher',
    app_rate_6: 4.76,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: 4.76,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    round: 99.99,
    char_1: 'black-swan',
    app_rate_1: 50.0,
    avg_round_1: 10.0,
    app_flat_1: 5,
    char_2: 'Aventurine',
    app_rate_2: 37.5,
    avg_round_2: 9,
    app_flat_2: 3,
    char_3: 'Kafka',
    app_rate_3: 33.33,
    avg_round_3: 10,
    app_flat_3: 2,
    char_4: 'Acheron',
    app_rate_4: 29.17,
    avg_round_4: 8.33,
    app_flat_4: 3,
    char_5: 'Jiaoqiu',
    app_rate_5: 29.17,
    avg_round_5: 8,
    app_flat_5: 2,
    char_6: 'Robin',
    app_rate_6: 20.83,
    avg_round_6: 10,
    app_flat_6: 3,
    char_7: 'fu-xuan',
    app_rate_7: 20.83,
    avg_round_7: 9,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: 16.67,
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Huohuo',
    app_rate_9: 16.67,
    avg_round_9: 10,
    app_flat_9: 3,
    char_10: 'Boothill',
    app_rate_10: 8.33,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Bronya',
    app_rate_11: 8.33,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Lingsha',
    app_rate_12: 8.33,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Gallagher',
    app_rate_13: 4.17,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'Asta',
    app_rate_14: 4.17,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Luocha',
    app_rate_15: 4.17,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Bailu',
    app_rate_16: 4.17,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Sparkle',
    app_rate_17: 4.17,
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    round: 99.99,
    char_1: 'Aventurine',
    app_rate_1: 34.62,
    avg_round_1: 8.75,
    app_flat_1: 4,
    char_2: 'imbibitor-lunae',
    app_rate_2: 30.77,
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'Feixiao',
    app_rate_3: 26.92,
    avg_round_3: 8.67,
    app_flat_3: 3,
    char_4: 'topaz',
    app_rate_4: 23.08,
    avg_round_4: 8.5,
    app_flat_4: 2,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 19.23,
    avg_round_5: 8.33,
    app_flat_5: 3,
    char_6: 'Tingyun',
    app_rate_6: 15.38,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Huohuo',
    app_rate_7: 15.38,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Gallagher',
    app_rate_8: 15.38,
    avg_round_8: 8,
    app_flat_8: 3,
    char_9: 'Boothill',
    app_rate_9: 15.38,
    avg_round_9: 8.67,
    app_flat_9: 3,
    char_10: 'ruan-mei',
    app_rate_10: 15.38,
    avg_round_10: 9,
    app_flat_10: 2,
    char_11: 'Sparkle',
    app_rate_11: 11.54,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Lingsha',
    app_rate_12: 11.54,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'fu-xuan',
    app_rate_13: 7.69,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Firefly',
    app_rate_14: 7.69,
    avg_round_14: 7,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: 7.69,
    avg_round_15: 9,
    app_flat_15: 1,
    char_16: 'Jiaoqiu',
    app_rate_16: 7.69,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Luocha',
    app_rate_17: 7.69,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Clara',
    app_rate_18: 7.69,
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'Natasha',
    app_rate_19: 3.85,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Acheron',
    app_rate_20: 3.85,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'march-7th-swordmaster',
    app_rate_21: 3.85,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Robin',
    app_rate_22: 3.85,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Yunli',
    app_rate_23: 3.85,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 81.82,
    avg_round_1: 9,
    app_flat_1: 1,
    char_2: 'Feixiao',
    app_rate_2: 63.64,
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'topaz',
    app_rate_3: 63.64,
    avg_round_3: 9,
    app_flat_3: 1,
    char_4: 'Clara',
    app_rate_4: 9.09,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Aventurine',
    app_rate_5: 9.09,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Huohuo',
    app_rate_6: 9.09,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Firefly',
    app_rate_7: 9.09,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 9.09,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Yunli',
    app_rate_9: 9.09,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Acheron',
    app_rate_10: 9.09,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Pela',
    app_rate_11: 9.09,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: 9.09,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    round: 99.99,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 66.67,
    avg_round_1: 8.33,
    app_flat_1: 6,
    char_2: 'ruan-mei',
    app_rate_2: 58.33,
    avg_round_2: 8.4,
    app_flat_2: 5,
    char_3: 'Gallagher',
    app_rate_3: 50.0,
    avg_round_3: 8.4,
    app_flat_3: 5,
    char_4: 'fu-xuan',
    app_rate_4: 33.33,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: 'Robin',
    app_rate_5: 16.67,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'silver-wolf',
    app_rate_6: 16.67,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Sparkle',
    app_rate_7: 16.67,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Feixiao',
    app_rate_8: 8.33,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Lingsha',
    app_rate_9: 8.33,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Firefly',
    app_rate_10: 8.33,
    avg_round_10: 8,
    app_flat_10: 1,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 8.33,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Huohuo',
    app_rate_12: 8.33,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    round: 99.99,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 75.0,
    avg_round_1: 8,
    app_flat_1: 5,
    char_2: 'ruan-mei',
    app_rate_2: 75.0,
    avg_round_2: 8,
    app_flat_2: 5,
    char_3: 'Gallagher',
    app_rate_3: 50.0,
    avg_round_3: 7.75,
    app_flat_3: 4,
    char_4: 'Robin',
    app_rate_4: 25.0,
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'Lingsha',
    app_rate_5: 25.0,
    avg_round_5: 9,
    app_flat_5: 1,
    char_6: 'Luocha',
    app_rate_6: 12.5,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 12.5,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'topaz',
    app_rate_8: 12.5,
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Aventurine',
    app_rate_9: 12.5,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 46.15,
    avg_round_1: 5,
    app_flat_1: 1,
    char_2: 'march-7th-swordmaster',
    app_rate_2: 30.77,
    avg_round_2: 7.5,
    app_flat_2: 2,
    char_3: 'Feixiao',
    app_rate_3: 23.08,
    avg_round_3: 5,
    app_flat_3: 1,
    char_4: 'Yunli',
    app_rate_4: 15.38,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'topaz',
    app_rate_5: 15.38,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Firefly',
    app_rate_6: 15.38,
    avg_round_6: 10,
    app_flat_6: 2,
    char_7: 'trailblazer-imaginary',
    app_rate_7: 15.38,
    avg_round_7: 10,
    app_flat_7: 2,
    char_8: 'Sparkle',
    app_rate_8: 15.38,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Bronya',
    app_rate_9: 15.38,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'ruan-mei',
    app_rate_10: 7.69,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'dr-ratio',
    app_rate_11: 7.69,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 7.69,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'imbibitor-lunae',
    app_rate_13: 7.69,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Hanya',
    app_rate_14: 7.69,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Luka',
    app_rate_15: 7.69,
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Asta',
    app_rate_16: 7.69,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Blade',
    app_rate_17: 7.69,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Tingyun',
    app_rate_18: 7.69,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Himeko',
    app_rate_19: 7.69,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Clara',
    app_rate_20: 7.69,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: 7.69,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'black-swan',
    app_rate_22: 7.69,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Guinaifen',
    app_rate_23: 7.69,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    round: 99.99,
    char_1: 'Sparkle',
    app_rate_1: 33.33,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'fu-xuan',
    app_rate_2: 33.33,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Robin',
    app_rate_3: 26.67,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 26.67,
    avg_round_4: 9.67,
    app_flat_4: 3,
    char_5: 'ruan-mei',
    app_rate_5: 26.67,
    avg_round_5: 9.33,
    app_flat_5: 3,
    char_6: 'Asta',
    app_rate_6: 26.67,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Gallagher',
    app_rate_7: 20.0,
    avg_round_7: 9,
    app_flat_7: 2,
    char_8: 'Lingsha',
    app_rate_8: 20.0,
    avg_round_8: 10,
    app_flat_8: 2,
    char_9: 'Huohuo',
    app_rate_9: 20.0,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'Jiaoqiu',
    app_rate_10: 13.33,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'Tingyun',
    app_rate_11: 13.33,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Aventurine',
    app_rate_12: 6.67,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 6.67,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'Boothill',
    app_rate_14: 6.67,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Luka',
    app_rate_15: 6.67,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Guinaifen',
    app_rate_16: 6.67,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Himeko',
    app_rate_17: 6.67,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    round: 99.99,
    char_1: 'Sparkle',
    app_rate_1: 50.0,
    avg_round_1: 9.33,
    app_flat_1: 3,
    char_2: 'ruan-mei',
    app_rate_2: 30.0,
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'Aventurine',
    app_rate_3: 30.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Gallagher',
    app_rate_4: 30.0,
    avg_round_4: 9,
    app_flat_4: 3,
    char_5: 'Huohuo',
    app_rate_5: 30.0,
    avg_round_5: 10,
    app_flat_5: 2,
    char_6: 'Himeko',
    app_rate_6: 30.0,
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'Jiaoqiu',
    app_rate_7: 20.0,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'Tingyun',
    app_rate_8: 20.0,
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Boothill',
    app_rate_9: 10.0,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Bronya',
    app_rate_10: 10.0,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: 10.0,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Yunli',
    app_rate_12: 10.0,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Acheron',
    app_rate_13: 10.0,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'Robin',
    app_rate_14: 10.0,
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    round: 99.99,
    char_1: 'Feixiao',
    app_rate_1: 66.67,
    avg_round_1: 8.67,
    app_flat_1: 3,
    char_2: 'march-7th-swordmaster',
    app_rate_2: 33.33,
    avg_round_2: 9.5,
    app_flat_2: 2,
    char_3: 'imbibitor-lunae',
    app_rate_3: 33.33,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Lingsha',
    app_rate_4: 33.33,
    avg_round_4: 7,
    app_flat_4: 1,
    char_5: 'Aventurine',
    app_rate_5: 16.67,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'fu-xuan',
    app_rate_6: 16.67,
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'Sparkle',
    app_rate_7: 16.67,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'Huohuo',
    app_rate_8: 16.67,
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Tingyun',
    app_rate_9: 16.67,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Gallagher',
    app_rate_10: 16.67,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Robin',
    app_rate_11: 16.67,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'topaz',
    app_rate_12: 16.67,
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 60.0,
    avg_round_1: 8.5,
    app_flat_1: 2,
    char_2: 'Aventurine',
    app_rate_2: 60.0,
    avg_round_2: 8.5,
    app_flat_2: 2,
    char_3: 'Feixiao',
    app_rate_3: 40.0,
    avg_round_3: 7,
    app_flat_3: 1,
    char_4: 'Jade',
    app_rate_4: 20.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Sparkle',
    app_rate_5: 20.0,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'fu-xuan',
    app_rate_6: 20.0,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Himeko',
    app_rate_7: 20.0,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 20.0,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'ruan-mei',
    app_rate_9: 20.0,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Gallagher',
    app_rate_10: 20.0,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 100.0,
    avg_round_1: 9,
    app_flat_1: 2,
    char_2: 'march-7th-swordmaster',
    app_rate_2: 66.67,
    avg_round_2: 9,
    app_flat_2: 2,
    char_3: 'Bronya',
    app_rate_3: 33.33,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: 33.33,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Bailu',
    app_rate_5: 33.33,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Lingsha',
    app_rate_6: 33.33,
    avg_round_6: 8,
    app_flat_6: 1,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    round: 99.99,
    char_1: 'Gallagher',
    app_rate_1: 100.0,
    avg_round_1: 10,
    app_flat_1: 1,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 50.0,
    avg_round_2: 10,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: 50.0,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Robin',
    app_rate_4: 50.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Tingyun',
    app_rate_5: 50.0,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    round: 99.99,
    char_1: 'Hook',
    app_rate_1: 25.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Boothill',
    app_rate_2: 25.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'fu-xuan',
    app_rate_3: 25.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 25.0,
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'Asta',
    app_rate_5: 25.0,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Natasha',
    app_rate_6: 25.0,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'trailblazer-imaginary',
    app_rate_7: 25.0,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'ruan-mei',
    app_rate_8: 25.0,
    avg_round_8: 9,
    app_flat_8: 1,
    char_9: 'Lingsha',
    app_rate_9: 25.0,
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'Acheron',
    app_rate_10: 25.0,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'silver-wolf',
    app_rate_11: 25.0,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Aventurine',
    app_rate_12: 25.0,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 99.99,
    char_1: 'Jiaoqiu',
    app_rate_1: 50.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: 50.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Gallagher',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Acheron',
    app_rate_4: 50.0,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: 'Pela',
    app_rate_5: 50.0,
    avg_round_5: 8,
    app_flat_5: 1,
    char_6: 'silver-wolf',
    app_rate_6: 50.0,
    avg_round_6: 8,
    app_flat_6: 1,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Arlan',
    round: 99.99,
    char_1: 'Bronya',
    app_rate_1: 100.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Robin',
    app_rate_2: 100.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: 100.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanqing',
    round: 99.99,
    char_1: 'ruan-mei',
    app_rate_1: 100.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Tingyun',
    app_rate_2: 100.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: 100.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];

export const PFDuos = [
  {
    char: 'ruan-mei',
    app: 89.21,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '75.79%',
    avg_round_1: 30451,
    app_flat_1: 2780,
    char_2: 'Firefly',
    app_rate_2: '47.1%',
    avg_round_2: 28133,
    app_flat_2: 1213,
    char_3: 'Gallagher',
    app_rate_3: '38.58%',
    avg_round_3: 29233,
    app_flat_3: 1690,
    char_4: 'Lingsha',
    app_rate_4: '36.67%',
    avg_round_4: 33219,
    app_flat_4: 1167,
    char_5: 'Himeko',
    app_rate_5: '24.93%',
    avg_round_5: 29474,
    app_flat_5: 1151,
    char_6: 'Rappa',
    app_rate_6: '23.75%',
    avg_round_6: 34130,
    app_flat_6: 1141,
    char_7: 'black-swan',
    app_rate_7: '11.81%',
    avg_round_7: 36832,
    app_flat_7: 344,
    char_8: 'Kafka',
    app_rate_8: '11.75%',
    avg_round_8: 36711,
    app_flat_8: 345,
    char_9: 'Huohuo',
    app_rate_9: '7.07%',
    avg_round_9: 33922,
    app_flat_9: 181,
    char_10: 'Aventurine',
    app_rate_10: '4.3%',
    avg_round_10: 31227,
    app_flat_10: 169,
    char_11: 'Luocha',
    app_rate_11: '3.35%',
    avg_round_11: 31000,
    app_flat_11: 106,
    char_12: 'Jiaoqiu',
    app_rate_12: '2.11%',
    avg_round_12: 35075,
    app_flat_12: 79,
    char_13: 'Acheron',
    app_rate_13: '1.57%',
    avg_round_13: 36521,
    app_flat_13: 37,
    char_14: 'fu-xuan',
    app_rate_14: '1.54%',
    avg_round_14: 32026,
    app_flat_14: 33,
    char_15: 'Robin',
    app_rate_15: '0.91%',
    avg_round_15: 30093,
    app_flat_15: 24,
    char_16: 'Sparkle',
    app_rate_16: '0.9%',
    avg_round_16: 28980,
    app_flat_16: 10,
    char_17: 'Jade',
    app_rate_17: '0.79%',
    avg_round_17: 29949,
    app_flat_17: 24,
    char_18: 'Herta',
    app_rate_18: '0.71%',
    avg_round_18: 24310,
    app_flat_18: 25,
    char_19: 'Guinaifen',
    app_rate_19: '0.67%',
    avg_round_19: 26050,
    app_flat_19: 22,
    char_20: 'Bronya',
    app_rate_20: '0.64%',
    avg_round_20: 28616,
    app_flat_20: 6,
    char_21: 'Argenti',
    app_rate_21: '0.51%',
    avg_round_21: 28317,
    app_flat_21: 16,
    char_22: 'Sampo',
    app_rate_22: '0.51%',
    avg_round_22: 28592,
    app_flat_22: 21,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '0.45%',
    avg_round_23: 27449,
    app_flat_23: 18,
    char_24: 'Tingyun',
    app_rate_24: '0.44%',
    avg_round_24: 25182,
    app_flat_24: 9,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.42%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Feixiao',
    app_rate_26: '0.39%',
    avg_round_26: 29754,
    app_flat_26: 10,
    char_27: 'Blade',
    app_rate_27: '0.31%',
    avg_round_27: 24958,
    app_flat_27: 8,
    char_28: 'topaz',
    app_rate_28: '0.3%',
    avg_round_28: 26878,
    app_flat_28: 9,
    char_29: 'Yunli',
    app_rate_29: '0.29%',
    avg_round_29: 28385,
    app_flat_29: 11,
    char_30: 'Clara',
    app_rate_30: '0.24%',
    avg_round_30: 23771,
    app_flat_30: 9
  },
  {
    char: 'trailblazer-imaginary',
    app: 72.45,
    char_1: 'ruan-mei',
    app_rate_1: '93.31%',
    avg_round_1: 30451,
    app_flat_1: 2780,
    char_2: 'Firefly',
    app_rate_2: '57.33%',
    avg_round_2: 27664,
    app_flat_2: 1178,
    char_3: 'Lingsha',
    app_rate_3: '44.01%',
    avg_round_3: 32797,
    app_flat_3: 1126,
    char_4: 'Gallagher',
    app_rate_4: '42.84%',
    avg_round_4: 27743,
    app_flat_4: 1552,
    char_5: 'Rappa',
    app_rate_5: '30.08%',
    avg_round_5: 33635,
    app_flat_5: 1187,
    char_6: 'Himeko',
    app_rate_6: '24.57%',
    avg_round_6: 28290,
    app_flat_6: 977,
    char_7: 'Aventurine',
    app_rate_7: '2.04%',
    avg_round_7: 28405,
    app_flat_7: 75,
    char_8: 'Luocha',
    app_rate_8: '1.23%',
    avg_round_8: 25604,
    app_flat_8: 42,
    char_9: 'Huohuo',
    app_rate_9: '0.8%',
    avg_round_9: 26572,
    app_flat_9: 19,
    char_10: 'Bronya',
    app_rate_10: '0.66%',
    avg_round_10: 25984,
    app_flat_10: 3,
    char_11: 'Guinaifen',
    app_rate_11: '0.43%',
    avg_round_11: 21527,
    app_flat_11: 14,
    char_12: 'fu-xuan',
    app_rate_12: '0.37%',
    avg_round_12: 21502,
    app_flat_12: 5,
    char_13: 'Robin',
    app_rate_13: '0.34%',
    avg_round_13: 25557,
    app_flat_13: 6,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '0.34%',
    avg_round_14: 22997,
    app_flat_14: 8,
    char_15: 'Jiaoqiu',
    app_rate_15: '0.29%',
    avg_round_15: 27516,
    app_flat_15: 13,
    char_16: 'Asta',
    app_rate_16: '0.22%',
    avg_round_16: 23371,
    app_flat_16: 7,
    char_17: 'Jade',
    app_rate_17: '0.22%',
    avg_round_17: 26381,
    app_flat_17: 7,
    char_18: 'Boothill',
    app_rate_18: '0.15%',
    avg_round_18: 24810,
    app_flat_18: 3,
    char_19: 'Sparkle',
    app_rate_19: '0.11%',
    avg_round_19: 23788,
    app_flat_19: 2,
    char_20: 'Acheron',
    app_rate_20: '0.09%',
    avg_round_20: 35290,
    app_flat_20: 1,
    char_21: 'Tingyun',
    app_rate_21: '0.08%',
    avg_round_21: 23018,
    app_flat_21: 5,
    char_22: 'Argenti',
    app_rate_22: '0.06%',
    avg_round_22: 23648,
    app_flat_22: 3,
    char_23: 'Yunli',
    app_rate_23: '0.06%',
    avg_round_23: 27946,
    app_flat_23: 2,
    char_24: 'topaz',
    app_rate_24: '0.06%',
    avg_round_24: 22826,
    app_flat_24: 1,
    char_25: 'Natasha',
    app_rate_25: '0.05%',
    avg_round_25: 22702,
    app_flat_25: 2,
    char_26: 'Pela',
    app_rate_26: '0.05%',
    avg_round_26: 20161,
    app_flat_26: 3,
    char_27: 'Welt',
    app_rate_27: '0.05%',
    avg_round_27: 22235,
    app_flat_27: 2,
    char_28: 'Clara',
    app_rate_28: '0.03%',
    avg_round_28: 20335,
    app_flat_28: 2,
    char_29: 'Bailu',
    app_rate_29: '0.03%',
    avg_round_29: 23498,
    app_flat_29: 2,
    char_30: 'Lynx',
    app_rate_30: '0.03%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    app: 59.78,
    char_1: 'Kafka',
    app_rate_1: '88.71%',
    avg_round_1: 35124,
    app_flat_1: 2013,
    char_2: 'Acheron',
    app_rate_2: '43.6%',
    avg_round_2: 35455,
    app_flat_2: 1127,
    char_3: 'Huohuo',
    app_rate_3: '38.05%',
    avg_round_3: 34616,
    app_flat_3: 757,
    char_4: 'Robin',
    app_rate_4: '25.54%',
    avg_round_4: 34011,
    app_flat_4: 518,
    char_5: 'Aventurine',
    app_rate_5: '19.73%',
    avg_round_5: 37478,
    app_flat_5: 580,
    char_6: 'ruan-mei',
    app_rate_6: '17.63%',
    avg_round_6: 36832,
    app_flat_6: 344,
    char_7: 'fu-xuan',
    app_rate_7: '14.53%',
    avg_round_7: 34328,
    app_flat_7: 341,
    char_8: 'Jiaoqiu',
    app_rate_8: '14.16%',
    avg_round_8: 39081,
    app_flat_8: 402,
    char_9: 'Luocha',
    app_rate_9: '9.84%',
    avg_round_9: 34519,
    app_flat_9: 214,
    char_10: 'Gallagher',
    app_rate_10: '8.79%',
    avg_round_10: 35212,
    app_flat_10: 263,
    char_11: 'Sampo',
    app_rate_11: '2.27%',
    avg_round_11: 30768,
    app_flat_11: 57,
    char_12: 'Lingsha',
    app_rate_12: '2.2%',
    avg_round_12: 37661,
    app_flat_12: 45,
    char_13: 'Gepard',
    app_rate_13: '2.11%',
    avg_round_13: 35557,
    app_flat_13: 59,
    char_14: 'Tingyun',
    app_rate_14: '1.9%',
    avg_round_14: 30518,
    app_flat_14: 40,
    char_15: 'Sparkle',
    app_rate_15: '1.73%',
    avg_round_15: 28732,
    app_flat_15: 26,
    char_16: 'Guinaifen',
    app_rate_16: '1.55%',
    avg_round_16: 28562,
    app_flat_16: 35,
    char_17: 'Pela',
    app_rate_17: '1.55%',
    avg_round_17: 32649,
    app_flat_17: 42,
    char_18: 'Bailu',
    app_rate_18: '1.12%',
    avg_round_18: 31008,
    app_flat_18: 19,
    char_19: 'Asta',
    app_rate_19: '0.75%',
    avg_round_19: 31742,
    app_flat_19: 14,
    char_20: 'Lynx',
    app_rate_20: '0.52%',
    avg_round_20: 34491,
    app_flat_20: 12,
    char_21: 'Yunli',
    app_rate_21: '0.52%',
    avg_round_21: 36573,
    app_flat_21: 10,
    char_22: 'Serval',
    app_rate_22: '0.45%',
    avg_round_22: 33732,
    app_flat_22: 10,
    char_23: 'Himeko',
    app_rate_23: '0.41%',
    avg_round_23: 31016,
    app_flat_23: 11,
    char_24: 'Clara',
    app_rate_24: '0.39%',
    avg_round_24: 30965,
    app_flat_24: 10,
    char_25: 'Herta',
    app_rate_25: '0.37%',
    avg_round_25: 31981,
    app_flat_25: 15,
    char_26: 'Feixiao',
    app_rate_26: '0.24%',
    avg_round_26: 32579,
    app_flat_26: 5,
    char_27: 'Jade',
    app_rate_27: '0.24%',
    avg_round_27: 32767,
    app_flat_27: 5,
    char_28: 'Blade',
    app_rate_28: '0.2%',
    avg_round_28: 30008,
    app_flat_28: 3,
    char_29: 'Bronya',
    app_rate_29: '0.17%',
    avg_round_29: 32251,
    app_flat_29: 4,
    char_30: 'march-7th',
    app_rate_30: '0.13%',
    avg_round_30: 40000,
    app_flat_30: 2
  },
  {
    char: 'Kafka',
    app: 58.04,
    char_1: 'black-swan',
    app_rate_1: '91.36%',
    avg_round_1: 35124,
    app_flat_1: 2013,
    char_2: 'Acheron',
    app_rate_2: '41.97%',
    avg_round_2: 34975,
    app_flat_2: 1030,
    char_3: 'Huohuo',
    app_rate_3: '38.5%',
    avg_round_3: 34363,
    app_flat_3: 737,
    char_4: 'Robin',
    app_rate_4: '26.54%',
    avg_round_4: 33710,
    app_flat_4: 521,
    char_5: 'Aventurine',
    app_rate_5: '18.86%',
    avg_round_5: 37247,
    app_flat_5: 532,
    char_6: 'ruan-mei',
    app_rate_6: '18.06%',
    avg_round_6: 36711,
    app_flat_6: 345,
    char_7: 'fu-xuan',
    app_rate_7: '14.62%',
    avg_round_7: 33872,
    app_flat_7: 330,
    char_8: 'Jiaoqiu',
    app_rate_8: '12.65%',
    avg_round_8: 37948,
    app_flat_8: 326,
    char_9: 'Luocha',
    app_rate_9: '9.9%',
    avg_round_9: 33816,
    app_flat_9: 209,
    char_10: 'Gallagher',
    app_rate_10: '9.06%',
    avg_round_10: 34714,
    app_flat_10: 262,
    char_11: 'Sampo',
    app_rate_11: '2.76%',
    avg_round_11: 28172,
    app_flat_11: 73,
    char_12: 'Tingyun',
    app_rate_12: '2.23%',
    avg_round_12: 29535,
    app_flat_12: 51,
    char_13: 'Gepard',
    app_rate_13: '2.23%',
    avg_round_13: 34755,
    app_flat_13: 62,
    char_14: 'Lingsha',
    app_rate_14: '1.98%',
    avg_round_14: 37746,
    app_flat_14: 41,
    char_15: 'Pela',
    app_rate_15: '1.46%',
    avg_round_15: 31933,
    app_flat_15: 37,
    char_16: 'Guinaifen',
    app_rate_16: '1.32%',
    avg_round_16: 27917,
    app_flat_16: 26,
    char_17: 'Bailu',
    app_rate_17: '1.13%',
    avg_round_17: 30617,
    app_flat_17: 19,
    char_18: 'Sparkle',
    app_rate_18: '1.11%',
    avg_round_18: 28980,
    app_flat_18: 19,
    char_19: 'Asta',
    app_rate_19: '0.86%',
    avg_round_19: 29837,
    app_flat_19: 18,
    char_20: 'Lynx',
    app_rate_20: '0.56%',
    avg_round_20: 33799,
    app_flat_20: 11,
    char_21: 'Jade',
    app_rate_21: '0.35%',
    avg_round_21: 30511,
    app_flat_21: 4,
    char_22: 'Yunli',
    app_rate_22: '0.27%',
    avg_round_22: 33387,
    app_flat_22: 5,
    char_23: 'Bronya',
    app_rate_23: '0.27%',
    avg_round_23: 27388,
    app_flat_23: 9,
    char_24: 'Feixiao',
    app_rate_24: '0.27%',
    avg_round_24: 30208,
    app_flat_24: 3,
    char_25: 'Himeko',
    app_rate_25: '0.27%',
    avg_round_25: 28428,
    app_flat_25: 8,
    char_26: 'Serval',
    app_rate_26: '0.25%',
    avg_round_26: 26626,
    app_flat_26: 5,
    char_27: 'Herta',
    app_rate_27: '0.21%',
    avg_round_27: 33391,
    app_flat_27: 8,
    char_28: 'Blade',
    app_rate_28: '0.19%',
    avg_round_28: 26668,
    app_flat_28: 4,
    char_29: 'Clara',
    app_rate_29: '0.19%',
    avg_round_29: 28957,
    app_flat_29: 6,
    char_30: 'march-7th',
    app_rate_30: '0.15%',
    avg_round_30: 37595,
    app_flat_30: 2
  },
  {
    char: 'Acheron',
    app: 54.33,
    char_1: 'black-swan',
    app_rate_1: '47.97%',
    avg_round_1: 35455,
    app_flat_1: 1127,
    char_2: 'Kafka',
    app_rate_2: '44.83%',
    avg_round_2: 34975,
    app_flat_2: 1030,
    char_3: 'Jiaoqiu',
    app_rate_3: '41.41%',
    avg_round_3: 35307,
    app_flat_3: 874,
    char_4: 'Aventurine',
    app_rate_4: '32.84%',
    avg_round_4: 36986,
    app_flat_4: 805,
    char_5: 'fu-xuan',
    app_rate_5: '27.06%',
    avg_round_5: 33230,
    app_flat_5: 494,
    char_6: 'Sparkle',
    app_rate_6: '18.31%',
    avg_round_6: 31280,
    app_flat_6: 123,
    char_7: 'Pela',
    app_rate_7: '17.08%',
    avg_round_7: 32745,
    app_flat_7: 486,
    char_8: 'Huohuo',
    app_rate_8: '13.86%',
    avg_round_8: 34041,
    app_flat_8: 269,
    char_9: 'Gallagher',
    app_rate_9: '10.23%',
    avg_round_9: 33377,
    app_flat_9: 254,
    char_10: 'Robin',
    app_rate_10: '9.41%',
    avg_round_10: 33906,
    app_flat_10: 170,
    char_11: 'Luocha',
    app_rate_11: '4.98%',
    avg_round_11: 33921,
    app_flat_11: 102,
    char_12: 'Gepard',
    app_rate_12: '4.28%',
    avg_round_12: 33357,
    app_flat_12: 97,
    char_13: 'Yunli',
    app_rate_13: '2.6%',
    avg_round_13: 35073,
    app_flat_13: 61,
    char_14: 'ruan-mei',
    app_rate_14: '2.58%',
    avg_round_14: 36521,
    app_flat_14: 37,
    char_15: 'Bronya',
    app_rate_15: '2.32%',
    avg_round_15: 32114,
    app_flat_15: 9,
    char_16: 'Clara',
    app_rate_16: '2.28%',
    avg_round_16: 33338,
    app_flat_16: 63,
    char_17: 'Guinaifen',
    app_rate_17: '2.28%',
    avg_round_17: 30507,
    app_flat_17: 71,
    char_18: 'Sampo',
    app_rate_18: '2.28%',
    avg_round_18: 30468,
    app_flat_18: 70,
    char_19: 'Herta',
    app_rate_19: '2.13%',
    avg_round_19: 33611,
    app_flat_19: 48,
    char_20: 'silver-wolf',
    app_rate_20: '2.03%',
    avg_round_20: 28070,
    app_flat_20: 31,
    char_21: 'Lingsha',
    app_rate_21: '1.93%',
    avg_round_21: 36663,
    app_flat_21: 43,
    char_22: 'Feixiao',
    app_rate_22: '1.35%',
    avg_round_22: 33174,
    app_flat_22: 35,
    char_23: 'Himeko',
    app_rate_23: '1.35%',
    avg_round_23: 33447,
    app_flat_23: 32,
    char_24: 'march-7th',
    app_rate_24: '0.88%',
    avg_round_24: 33302,
    app_flat_24: 18,
    char_25: 'Lynx',
    app_rate_25: '0.45%',
    avg_round_25: 33213,
    app_flat_25: 16,
    char_26: 'Bailu',
    app_rate_26: '0.43%',
    avg_round_26: 31708,
    app_flat_26: 4,
    char_27: 'Jade',
    app_rate_27: '0.43%',
    avg_round_27: 34629,
    app_flat_27: 8,
    char_28: 'Blade',
    app_rate_28: '0.39%',
    avg_round_28: 28280,
    app_flat_28: 7,
    char_29: 'Tingyun',
    app_rate_29: '0.29%',
    avg_round_29: 31024,
    app_flat_29: 7,
    char_30: 'Welt',
    app_rate_30: '0.29%',
    avg_round_30: 27287,
    app_flat_30: 8
  },
  {
    char: 'Gallagher',
    app: 46.2,
    char_1: 'ruan-mei',
    app_rate_1: '74.49%',
    avg_round_1: 29233,
    app_flat_1: 1690,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '67.19%',
    avg_round_2: 27743,
    app_flat_2: 1552,
    char_3: 'Firefly',
    app_rate_3: '38.21%',
    avg_round_3: 26320,
    app_flat_3: 630,
    char_4: 'Himeko',
    app_rate_4: '25.7%',
    avg_round_4: 27826,
    app_flat_4: 723,
    char_5: 'Rappa',
    app_rate_5: '20.52%',
    avg_round_5: 31998,
    app_flat_5: 603,
    char_6: 'Acheron',
    app_rate_6: '12.03%',
    avg_round_6: 33377,
    app_flat_6: 254,
    char_7: 'Kafka',
    app_rate_7: '11.38%',
    avg_round_7: 34714,
    app_flat_7: 262,
    char_8: 'black-swan',
    app_rate_8: '11.38%',
    avg_round_8: 35212,
    app_flat_8: 263,
    char_9: 'Robin',
    app_rate_9: '9.02%',
    avg_round_9: 30597,
    app_flat_9: 163,
    char_10: 'Jiaoqiu',
    app_rate_10: '8.24%',
    avg_round_10: 33564,
    app_flat_10: 173,
    char_11: 'Sparkle',
    app_rate_11: '3.09%',
    avg_round_11: 26540,
    app_flat_11: 40,
    char_12: 'Pela',
    app_rate_12: '2.29%',
    avg_round_12: 30899,
    app_flat_12: 69,
    char_13: 'Herta',
    app_rate_13: '2.29%',
    avg_round_13: 28073,
    app_flat_13: 50,
    char_14: 'Feixiao',
    app_rate_14: '2.03%',
    avg_round_14: 28944,
    app_flat_14: 31,
    char_15: 'Guinaifen',
    app_rate_15: '1.42%',
    avg_round_15: 26049,
    app_flat_15: 32,
    char_16: 'Clara',
    app_rate_16: '1.18%',
    avg_round_16: 27952,
    app_flat_16: 31,
    char_17: 'Tingyun',
    app_rate_17: '1.16%',
    avg_round_17: 24506,
    app_flat_17: 27,
    char_18: 'Jade',
    app_rate_18: '1.13%',
    avg_round_18: 28725,
    app_flat_18: 23,
    char_19: 'Sampo',
    app_rate_19: '1.11%',
    avg_round_19: 27710,
    app_flat_19: 33,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '1.01%',
    avg_round_20: 25724,
    app_flat_20: 23,
    char_21: 'Bronya',
    app_rate_21: '0.89%',
    avg_round_21: 26430,
    app_flat_21: 13,
    char_22: 'Asta',
    app_rate_22: '0.72%',
    avg_round_22: 26358,
    app_flat_22: 15,
    char_23: 'Yunli',
    app_rate_23: '0.63%',
    avg_round_23: 28885,
    app_flat_23: 16,
    char_24: 'Argenti',
    app_rate_24: '0.6%',
    avg_round_24: 27425,
    app_flat_24: 14,
    char_25: 'Blade',
    app_rate_25: '0.48%',
    avg_round_25: 25740,
    app_flat_25: 12,
    char_26: 'topaz',
    app_rate_26: '0.36%',
    avg_round_26: 25943,
    app_flat_26: 6,
    char_27: 'imbibitor-lunae',
    app_rate_27: '0.29%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Boothill',
    app_rate_28: '0.19%',
    avg_round_28: 29928,
    app_flat_28: 2,
    char_29: 'silver-wolf',
    app_rate_29: '0.19%',
    avg_round_29: 20980,
    app_flat_29: 3,
    char_30: 'jing-yuan',
    app_rate_30: '0.19%',
    avg_round_30: 22910,
    app_flat_30: 5
  },
  {
    char: 'Firefly',
    app: 45.37,
    char_1: 'ruan-mei',
    app_rate_1: '92.61%',
    avg_round_1: 28133,
    app_flat_1: 1213,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '91.55%',
    avg_round_2: 27664,
    app_flat_2: 1178,
    char_3: 'Lingsha',
    app_rate_3: '43.9%',
    avg_round_3: 29677,
    app_flat_3: 478,
    char_4: 'Gallagher',
    app_rate_4: '38.91%',
    avg_round_4: 26320,
    app_flat_4: 630,
    char_5: 'Himeko',
    app_rate_5: '21.11%',
    avg_round_5: 28461,
    app_flat_5: 377,
    char_6: 'Rappa',
    app_rate_6: '4.59%',
    avg_round_6: 29285,
    app_flat_6: 48,
    char_7: 'Aventurine',
    app_rate_7: '1.33%',
    avg_round_7: 27568,
    app_flat_7: 21,
    char_8: 'Luocha',
    app_rate_8: '1.28%',
    avg_round_8: 25460,
    app_flat_8: 20,
    char_9: 'Bronya',
    app_rate_9: '1.03%',
    avg_round_9: 22433,
    app_flat_9: 1,
    char_10: 'Huohuo',
    app_rate_10: '0.83%',
    avg_round_10: 25907,
    app_flat_10: 9,
    char_11: 'fu-xuan',
    app_rate_11: '0.54%',
    avg_round_11: 21039,
    app_flat_11: 4,
    char_12: 'Robin',
    app_rate_12: '0.39%',
    avg_round_12: 25028,
    app_flat_12: 2,
    char_13: 'Asta',
    app_rate_13: '0.25%',
    avg_round_13: 28072,
    app_flat_13: 2,
    char_14: 'Acheron',
    app_rate_14: '0.22%',
    avg_round_14: 28244,
    app_flat_14: 1,
    char_15: 'Jiaoqiu',
    app_rate_15: '0.2%',
    avg_round_15: 28377,
    app_flat_15: 3,
    char_16: 'Sparkle',
    app_rate_16: '0.17%',
    avg_round_16: 25914,
    app_flat_16: 2,
    char_17: 'Yunli',
    app_rate_17: '0.15%',
    avg_round_17: 24410,
    app_flat_17: 2,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.15%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Herta',
    app_rate_19: '0.15%',
    avg_round_19: 22067,
    app_flat_19: 4,
    char_20: 'Guinaifen',
    app_rate_20: '0.12%',
    avg_round_20: 20214,
    app_flat_20: 1,
    char_21: 'Pela',
    app_rate_21: '0.07%',
    avg_round_21: 20265,
    app_flat_21: 1,
    char_22: 'Gepard',
    app_rate_22: '0.07%',
    avg_round_22: 20935,
    app_flat_22: 1,
    char_23: 'Boothill',
    app_rate_23: '0.05%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Natasha',
    app_rate_24: '0.05%',
    avg_round_24: 22702,
    app_flat_24: 2,
    char_25: 'Feixiao',
    app_rate_25: '0.05%',
    avg_round_25: 24146,
    app_flat_25: 1,
    char_26: 'topaz',
    app_rate_26: '0.02%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Clara',
    app_rate_27: '0.02%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Blade',
    app_rate_28: '0.02%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Lynx',
    app_rate_29: '0.02%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Jade',
    app_rate_30: '0.02%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Robin',
    app: 44.38,
    char_1: 'Aventurine',
    app_rate_1: '37.35%',
    avg_round_1: 32720,
    app_flat_1: 652,
    char_2: 'Kafka',
    app_rate_2: '34.71%',
    avg_round_2: 33710,
    app_flat_2: 521,
    char_3: 'black-swan',
    app_rate_3: '34.41%',
    avg_round_3: 34011,
    app_flat_3: 518,
    char_4: 'Huohuo',
    app_rate_4: '25.65%',
    avg_round_4: 32659,
    app_flat_4: 361,
    char_5: 'Herta',
    app_rate_5: '22.72%',
    avg_round_5: 32162,
    app_flat_5: 423,
    char_6: 'Feixiao',
    app_rate_6: '21.41%',
    avg_round_6: 30973,
    app_flat_6: 319,
    char_7: 'Jade',
    app_rate_7: '14.03%',
    avg_round_7: 32135,
    app_flat_7: 235,
    char_8: 'Acheron',
    app_rate_8: '11.52%',
    avg_round_8: 33906,
    app_flat_8: 170,
    char_9: 'Yunli',
    app_rate_9: '11.27%',
    avg_round_9: 32120,
    app_flat_9: 211,
    char_10: 'Himeko',
    app_rate_10: '9.56%',
    avg_round_10: 28425,
    app_flat_10: 195,
    char_11: 'Gallagher',
    app_rate_11: '9.39%',
    avg_round_11: 30597,
    app_flat_11: 163,
    char_12: 'fu-xuan',
    app_rate_12: '8.08%',
    avg_round_12: 29986,
    app_flat_12: 99,
    char_13: 'Luocha',
    app_rate_13: '7.61%',
    avg_round_13: 31287,
    app_flat_13: 116,
    char_14: 'Jiaoqiu',
    app_rate_14: '6.88%',
    avg_round_14: 34407,
    app_flat_14: 87,
    char_15: 'Clara',
    app_rate_15: '6.55%',
    avg_round_15: 29859,
    app_flat_15: 125,
    char_16: 'topaz',
    app_rate_16: '5.6%',
    avg_round_16: 25567,
    app_flat_16: 61,
    char_17: 'Lingsha',
    app_rate_17: '4.79%',
    avg_round_17: 30558,
    app_flat_17: 84,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '4.67%',
    avg_round_18: 27887,
    app_flat_18: 75,
    char_19: 'Tingyun',
    app_rate_19: '4.42%',
    avg_round_19: 28455,
    app_flat_19: 68,
    char_20: 'Sparkle',
    app_rate_20: '3.82%',
    avg_round_20: 27099,
    app_flat_20: 32,
    char_21: 'Blade',
    app_rate_21: '2.06%',
    avg_round_21: 28964,
    app_flat_21: 27,
    char_22: 'ruan-mei',
    app_rate_22: '1.83%',
    avg_round_22: 30093,
    app_flat_22: 24,
    char_23: 'Argenti',
    app_rate_23: '1.53%',
    avg_round_23: 25788,
    app_flat_23: 17,
    char_24: 'jing-yuan',
    app_rate_24: '1.43%',
    avg_round_24: 28161,
    app_flat_24: 17,
    char_25: 'Pela',
    app_rate_25: '1.13%',
    avg_round_25: 28910,
    app_flat_25: 19,
    char_26: 'Bronya',
    app_rate_26: '1.08%',
    avg_round_26: 28187,
    app_flat_26: 14,
    char_27: 'Sampo',
    app_rate_27: '0.85%',
    avg_round_27: 25463,
    app_flat_27: 23,
    char_28: 'Lynx',
    app_rate_28: '0.8%',
    avg_round_28: 28520,
    app_flat_28: 17,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.6%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bailu',
    app_rate_30: '0.58%',
    avg_round_30: 30328,
    app_flat_30: 9
  },
  {
    char: 'Aventurine',
    app: 39.05,
    char_1: 'Acheron',
    app_rate_1: '45.69%',
    avg_round_1: 36986,
    app_flat_1: 805,
    char_2: 'Robin',
    app_rate_2: '42.44%',
    avg_round_2: 32720,
    app_flat_2: 652,
    char_3: 'black-swan',
    app_rate_3: '30.21%',
    avg_round_3: 37478,
    app_flat_3: 580,
    char_4: 'Jiaoqiu',
    app_rate_4: '28.49%',
    avg_round_4: 37546,
    app_flat_4: 469,
    char_5: 'Kafka',
    app_rate_5: '28.04%',
    avg_round_5: 37247,
    app_flat_5: 532,
    char_6: 'Feixiao',
    app_rate_6: '17.71%',
    avg_round_6: 31919,
    app_flat_6: 246,
    char_7: 'Herta',
    app_rate_7: '16.66%',
    avg_round_7: 33296,
    app_flat_7: 294,
    char_8: 'Himeko',
    app_rate_8: '13.23%',
    avg_round_8: 29450,
    app_flat_8: 242,
    char_9: 'Sparkle',
    app_rate_9: '10.72%',
    avg_round_9: 31237,
    app_flat_9: 68,
    char_10: 'Pela',
    app_rate_10: '9.95%',
    avg_round_10: 35379,
    app_flat_10: 233,
    char_11: 'ruan-mei',
    app_rate_11: '9.81%',
    avg_round_11: 31227,
    app_flat_11: 169,
    char_12: 'Jade',
    app_rate_12: '9.04%',
    avg_round_12: 32809,
    app_flat_12: 138,
    char_13: 'topaz',
    app_rate_13: '6.02%',
    avg_round_13: 25960,
    app_flat_13: 54,
    char_14: 'Clara',
    app_rate_14: '5.19%',
    avg_round_14: 31466,
    app_flat_14: 103,
    char_15: 'Yunli',
    app_rate_15: '4.59%',
    avg_round_15: 36728,
    app_flat_15: 84,
    char_16: 'trailblazer-imaginary',
    app_rate_16: '3.79%',
    avg_round_16: 28405,
    app_flat_16: 75,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '2.45%',
    avg_round_17: 27348,
    app_flat_17: 35,
    char_18: 'Guinaifen',
    app_rate_18: '2.03%',
    avg_round_18: 30697,
    app_flat_18: 47,
    char_19: 'Sampo',
    app_rate_19: '1.94%',
    avg_round_19: 30916,
    app_flat_19: 41,
    char_20: 'Tingyun',
    app_rate_20: '1.91%',
    avg_round_20: 28912,
    app_flat_20: 37,
    char_21: 'Firefly',
    app_rate_21: '1.54%',
    avg_round_21: 27568,
    app_flat_21: 21,
    char_22: 'Rappa',
    app_rate_22: '1.43%',
    avg_round_22: 28654,
    app_flat_22: 33,
    char_23: 'Bronya',
    app_rate_23: '1.31%',
    avg_round_23: 28462,
    app_flat_23: 8,
    char_24: 'Argenti',
    app_rate_24: '1.2%',
    avg_round_24: 28062,
    app_flat_24: 15,
    char_25: 'silver-wolf',
    app_rate_25: '1.08%',
    avg_round_25: 29725,
    app_flat_25: 20,
    char_26: 'jing-yuan',
    app_rate_26: '1.06%',
    avg_round_26: 30276,
    app_flat_26: 15,
    char_27: 'Asta',
    app_rate_27: '0.66%',
    avg_round_27: 31147,
    app_flat_27: 12,
    char_28: 'imbibitor-lunae',
    app_rate_28: '0.37%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Serval',
    app_rate_29: '0.34%',
    avg_round_29: 29486,
    app_flat_29: 6,
    char_30: 'Blade',
    app_rate_30: '0.29%',
    avg_round_30: 23992,
    app_flat_30: 2
  },
  {
    char: 'Lingsha',
    app: 37.81,
    char_1: 'ruan-mei',
    app_rate_1: '86.51%',
    avg_round_1: 33219,
    app_flat_1: 1167,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '84.33%',
    avg_round_2: 32797,
    app_flat_2: 1126,
    char_3: 'Firefly',
    app_rate_3: '52.67%',
    avg_round_3: 29677,
    app_flat_3: 478,
    char_4: 'Rappa',
    app_rate_4: '28.28%',
    avg_round_4: 36136,
    app_flat_4: 531,
    char_5: 'Himeko',
    app_rate_5: '17.5%',
    avg_round_5: 31120,
    app_flat_5: 345,
    char_6: 'Robin',
    app_rate_6: '5.63%',
    avg_round_6: 30558,
    app_flat_6: 84,
    char_7: 'black-swan',
    app_rate_7: '3.48%',
    avg_round_7: 37661,
    app_flat_7: 45,
    char_8: 'Kafka',
    app_rate_8: '3.03%',
    avg_round_8: 37746,
    app_flat_8: 41,
    char_9: 'Jade',
    app_rate_9: '2.86%',
    avg_round_9: 30731,
    app_flat_9: 38,
    char_10: 'Acheron',
    app_rate_10: '2.77%',
    avg_round_10: 36663,
    app_flat_10: 43,
    char_11: 'Jiaoqiu',
    app_rate_11: '2.68%',
    avg_round_11: 35645,
    app_flat_11: 38,
    char_12: 'Sparkle',
    app_rate_12: '1.56%',
    avg_round_12: 30727,
    app_flat_12: 19,
    char_13: 'Feixiao',
    app_rate_13: '1.18%',
    avg_round_13: 26752,
    app_flat_13: 12,
    char_14: 'Herta',
    app_rate_14: '1.06%',
    avg_round_14: 26275,
    app_flat_14: 18,
    char_15: 'Yunli',
    app_rate_15: '0.97%',
    avg_round_15: 30420,
    app_flat_15: 15,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.91%',
    avg_round_16: 28237,
    app_flat_16: 14,
    char_17: 'Tingyun',
    app_rate_17: '0.53%',
    avg_round_17: 30637,
    app_flat_17: 8,
    char_18: 'topaz',
    app_rate_18: '0.53%',
    avg_round_18: 25552,
    app_flat_18: 7,
    char_19: 'Guinaifen',
    app_rate_19: '0.5%',
    avg_round_19: 27604,
    app_flat_19: 8,
    char_20: 'Pela',
    app_rate_20: '0.44%',
    avg_round_20: 33164,
    app_flat_20: 10,
    char_21: 'Bronya',
    app_rate_21: '0.41%',
    avg_round_21: 27245,
    app_flat_21: 3,
    char_22: 'Clara',
    app_rate_22: '0.41%',
    avg_round_22: 30027,
    app_flat_22: 7,
    char_23: 'Blade',
    app_rate_23: '0.27%',
    avg_round_23: 30395,
    app_flat_23: 3,
    char_24: 'Gallagher',
    app_rate_24: '0.24%',
    avg_round_24: 27054,
    app_flat_24: 5,
    char_25: 'Asta',
    app_rate_25: '0.21%',
    avg_round_25: 25803,
    app_flat_25: 3,
    char_26: 'Argenti',
    app_rate_26: '0.21%',
    avg_round_26: 24983,
    app_flat_26: 6,
    char_27: 'Boothill',
    app_rate_27: '0.18%',
    avg_round_27: 22060,
    app_flat_27: 2,
    char_28: 'Aventurine',
    app_rate_28: '0.12%',
    avg_round_28: 27422,
    app_flat_28: 2,
    char_29: 'Sampo',
    app_rate_29: '0.12%',
    avg_round_29: 32641,
    app_flat_29: 2,
    char_30: 'jing-yuan',
    app_rate_30: '0.09%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    app: 31.24,
    char_1: 'ruan-mei',
    app_rate_1: '71.19%',
    avg_round_1: 29474,
    app_flat_1: 1151,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '56.97%',
    avg_round_2: 28290,
    app_flat_2: 977,
    char_3: 'Gallagher',
    app_rate_3: '38.0%',
    avg_round_3: 27826,
    app_flat_3: 723,
    char_4: 'Firefly',
    app_rate_4: '30.66%',
    avg_round_4: 28461,
    app_flat_4: 377,
    char_5: 'Lingsha',
    app_rate_5: '21.18%',
    avg_round_5: 31120,
    app_flat_5: 345,
    char_6: 'Aventurine',
    app_rate_6: '16.54%',
    avg_round_6: 29450,
    app_flat_6: 242,
    char_7: 'Robin',
    app_rate_7: '13.58%',
    avg_round_7: 28425,
    app_flat_7: 195,
    char_8: 'Rappa',
    app_rate_8: '6.95%',
    avg_round_8: 31068,
    app_flat_8: 138,
    char_9: 'Jiaoqiu',
    app_rate_9: '5.63%',
    avg_round_9: 33485,
    app_flat_9: 85,
    char_10: 'Luocha',
    app_rate_10: '5.2%',
    avg_round_10: 27164,
    app_flat_10: 67,
    char_11: 'Herta',
    app_rate_11: '3.81%',
    avg_round_11: 25074,
    app_flat_11: 52,
    char_12: 'Jade',
    app_rate_12: '3.49%',
    avg_round_12: 29255,
    app_flat_12: 52,
    char_13: 'Feixiao',
    app_rate_13: '2.82%',
    avg_round_13: 31263,
    app_flat_13: 38,
    char_14: 'Sparkle',
    app_rate_14: '2.6%',
    avg_round_14: 27854,
    app_flat_14: 20,
    char_15: 'topaz',
    app_rate_15: '2.39%',
    avg_round_15: 27177,
    app_flat_15: 22,
    char_16: 'Acheron',
    app_rate_16: '2.35%',
    avg_round_16: 33447,
    app_flat_16: 32,
    char_17: 'fu-xuan',
    app_rate_17: '2.14%',
    avg_round_17: 24914,
    app_flat_17: 14,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '2.07%',
    avg_round_18: 26859,
    app_flat_18: 42,
    char_19: 'Huohuo',
    app_rate_19: '1.82%',
    avg_round_19: 26190,
    app_flat_19: 23,
    char_20: 'Guinaifen',
    app_rate_20: '1.75%',
    avg_round_20: 25840,
    app_flat_20: 24,
    char_21: 'Asta',
    app_rate_21: '1.25%',
    avg_round_21: 26829,
    app_flat_21: 23,
    char_22: 'Clara',
    app_rate_22: '1.07%',
    avg_round_22: 23420,
    app_flat_22: 18,
    char_23: 'Yunli',
    app_rate_23: '1.03%',
    avg_round_23: 28547,
    app_flat_23: 19,
    char_24: 'Argenti',
    app_rate_24: '0.93%',
    avg_round_24: 27885,
    app_flat_24: 15,
    char_25: 'Tingyun',
    app_rate_25: '0.86%',
    avg_round_25: 25480,
    app_flat_25: 12,
    char_26: 'black-swan',
    app_rate_26: '0.78%',
    avg_round_26: 31016,
    app_flat_26: 11,
    char_27: 'Blade',
    app_rate_27: '0.61%',
    avg_round_27: 27349,
    app_flat_27: 5,
    char_28: 'Kafka',
    app_rate_28: '0.5%',
    avg_round_28: 28428,
    app_flat_28: 8,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.32%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: '0.29%',
    avg_round_30: 24060,
    app_flat_30: 5
  },
  {
    char: 'Jiaoqiu',
    app: 30.65,
    char_1: 'Acheron',
    app_rate_1: '73.4%',
    avg_round_1: 35307,
    app_flat_1: 874,
    char_2: 'Aventurine',
    app_rate_2: '36.3%',
    avg_round_2: 37546,
    app_flat_2: 469,
    char_3: 'black-swan',
    app_rate_3: '27.62%',
    avg_round_3: 39081,
    app_flat_3: 402,
    char_4: 'Kafka',
    app_rate_4: '23.95%',
    avg_round_4: 37948,
    app_flat_4: 326,
    char_5: 'Sparkle',
    app_rate_5: '23.44%',
    avg_round_5: 32841,
    app_flat_5: 88,
    char_6: 'fu-xuan',
    app_rate_6: '20.97%',
    avg_round_6: 33767,
    app_flat_6: 216,
    char_7: 'Huohuo',
    app_rate_7: '15.7%',
    avg_round_7: 38075,
    app_flat_7: 188,
    char_8: 'Pela',
    app_rate_8: '15.59%',
    avg_round_8: 34386,
    app_flat_8: 317,
    char_9: 'Gallagher',
    app_rate_9: '12.43%',
    avg_round_9: 33564,
    app_flat_9: 173,
    char_10: 'Robin',
    app_rate_10: '9.96%',
    avg_round_10: 34407,
    app_flat_10: 87,
    char_11: 'Luocha',
    app_rate_11: '6.54%',
    avg_round_11: 35000,
    app_flat_11: 85,
    char_12: 'ruan-mei',
    app_rate_12: '6.14%',
    avg_round_12: 35075,
    app_flat_12: 79,
    char_13: 'Himeko',
    app_rate_13: '5.74%',
    avg_round_13: 33485,
    app_flat_13: 85,
    char_14: 'Lingsha',
    app_rate_14: '3.31%',
    avg_round_14: 35645,
    app_flat_14: 38,
    char_15: 'Bronya',
    app_rate_15: '2.54%',
    avg_round_15: 33000,
    app_flat_15: 5,
    char_16: 'Guinaifen',
    app_rate_16: '2.22%',
    avg_round_16: 31561,
    app_flat_16: 38,
    char_17: 'Sampo',
    app_rate_17: '1.71%',
    avg_round_17: 31402,
    app_flat_17: 29,
    char_18: 'Gepard',
    app_rate_18: '1.6%',
    avg_round_18: 32977,
    app_flat_18: 19,
    char_19: 'Clara',
    app_rate_19: '1.53%',
    avg_round_19: 37006,
    app_flat_19: 24,
    char_20: 'silver-wolf',
    app_rate_20: '0.98%',
    avg_round_20: 31854,
    app_flat_20: 11,
    char_21: 'Argenti',
    app_rate_21: '0.84%',
    avg_round_21: 33087,
    app_flat_21: 3,
    char_22: 'Yunli',
    app_rate_22: '0.84%',
    avg_round_22: 36474,
    app_flat_22: 9,
    char_23: 'Herta',
    app_rate_23: '0.69%',
    avg_round_23: 36926,
    app_flat_23: 8,
    char_24: 'trailblazer-imaginary',
    app_rate_24: '0.69%',
    avg_round_24: 27516,
    app_flat_24: 13,
    char_25: 'Feixiao',
    app_rate_25: '0.62%',
    avg_round_25: 36130,
    app_flat_25: 10,
    char_26: 'Blade',
    app_rate_26: '0.58%',
    avg_round_26: 28484,
    app_flat_26: 6,
    char_27: 'Tingyun',
    app_rate_27: '0.55%',
    avg_round_27: 31760,
    app_flat_27: 2,
    char_28: 'Rappa',
    app_rate_28: '0.51%',
    avg_round_28: 29182,
    app_flat_28: 7,
    char_29: 'Lynx',
    app_rate_29: '0.47%',
    avg_round_29: 33202,
    app_flat_29: 8,
    char_30: 'jing-yuan',
    app_rate_30: '0.44%',
    avg_round_30: 25000,
    app_flat_30: 3
  },
  {
    char: 'Huohuo',
    app: 30.62,
    char_1: 'black-swan',
    app_rate_1: '74.28%',
    avg_round_1: 34616,
    app_flat_1: 757,
    char_2: 'Kafka',
    app_rate_2: '72.97%',
    avg_round_2: 34363,
    app_flat_2: 737,
    char_3: 'Robin',
    app_rate_3: '37.18%',
    avg_round_3: 32659,
    app_flat_3: 361,
    char_4: 'Acheron',
    app_rate_4: '24.59%',
    avg_round_4: 34041,
    app_flat_4: 269,
    char_5: 'ruan-mei',
    app_rate_5: '20.59%',
    avg_round_5: 33922,
    app_flat_5: 181,
    char_6: 'Jiaoqiu',
    app_rate_6: '15.71%',
    avg_round_6: 38075,
    app_flat_6: 188,
    char_7: 'Yunli',
    app_rate_7: '8.69%',
    avg_round_7: 31594,
    app_flat_7: 95,
    char_8: 'Tingyun',
    app_rate_8: '7.2%',
    avg_round_8: 29181,
    app_flat_8: 81,
    char_9: 'Sparkle',
    app_rate_9: '6.33%',
    avg_round_9: 27291,
    app_flat_9: 40,
    char_10: 'Herta',
    app_rate_10: '4.62%',
    avg_round_10: 29991,
    app_flat_10: 55,
    char_11: 'Jade',
    app_rate_11: '3.82%',
    avg_round_11: 31222,
    app_flat_11: 46,
    char_12: 'Sampo',
    app_rate_12: '2.22%',
    avg_round_12: 28856,
    app_flat_12: 26,
    char_13: 'trailblazer-imaginary',
    app_rate_13: '1.89%',
    avg_round_13: 26572,
    app_flat_13: 19,
    char_14: 'Himeko',
    app_rate_14: '1.86%',
    avg_round_14: 26190,
    app_flat_14: 23,
    char_15: 'Clara',
    app_rate_15: '1.82%',
    avg_round_15: 28689,
    app_flat_15: 20,
    char_16: 'Guinaifen',
    app_rate_16: '1.67%',
    avg_round_16: 26366,
    app_flat_16: 18,
    char_17: 'Feixiao',
    app_rate_17: '1.67%',
    avg_round_17: 28530,
    app_flat_17: 13,
    char_18: 'Rappa',
    app_rate_18: '1.53%',
    avg_round_18: 27966,
    app_flat_18: 20,
    char_19: 'Argenti',
    app_rate_19: '1.46%',
    avg_round_19: 25236,
    app_flat_19: 15,
    char_20: 'imbibitor-lunae',
    app_rate_20: '1.42%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Pela',
    app_rate_21: '1.38%',
    avg_round_21: 30969,
    app_flat_21: 15,
    char_22: 'Firefly',
    app_rate_22: '1.24%',
    avg_round_22: 25907,
    app_flat_22: 9,
    char_23: 'Blade',
    app_rate_23: '1.2%',
    avg_round_23: 28891,
    app_flat_23: 13,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '0.91%',
    avg_round_24: 27643,
    app_flat_24: 12,
    char_25: 'jing-yuan',
    app_rate_25: '0.87%',
    avg_round_25: 22080,
    app_flat_25: 4,
    char_26: 'Bronya',
    app_rate_26: '0.76%',
    avg_round_26: 24993,
    app_flat_26: 5,
    char_27: 'Asta',
    app_rate_27: '0.76%',
    avg_round_27: 28840,
    app_flat_27: 8,
    char_28: 'topaz',
    app_rate_28: '0.47%',
    avg_round_28: 27154,
    app_flat_28: 3,
    char_29: 'Serval',
    app_rate_29: '0.33%',
    avg_round_29: 29804,
    app_flat_29: 4,
    char_30: 'silver-wolf',
    app_rate_30: '0.11%',
    avg_round_30: 24222,
    app_flat_30: 1
  },
  {
    char: 'Rappa',
    app: 23.22,
    char_1: 'trailblazer-imaginary',
    app_rate_1: '93.86%',
    avg_round_1: 33635,
    app_flat_1: 1187,
    char_2: 'ruan-mei',
    app_rate_2: '91.22%',
    avg_round_2: 34130,
    app_flat_2: 1141,
    char_3: 'Lingsha',
    app_rate_3: '46.04%',
    avg_round_3: 36136,
    app_flat_3: 531,
    char_4: 'Gallagher',
    app_rate_4: '40.82%',
    avg_round_4: 31998,
    app_flat_4: 603,
    char_5: 'Himeko',
    app_rate_5: '9.35%',
    avg_round_5: 31068,
    app_flat_5: 138,
    char_6: 'Firefly',
    app_rate_6: '8.97%',
    avg_round_6: 29285,
    app_flat_6: 48,
    char_7: 'Aventurine',
    app_rate_7: '2.4%',
    avg_round_7: 28654,
    app_flat_7: 33,
    char_8: 'Huohuo',
    app_rate_8: '2.01%',
    avg_round_8: 27966,
    app_flat_8: 20,
    char_9: 'Luocha',
    app_rate_9: '1.34%',
    avg_round_9: 27404,
    app_flat_9: 14,
    char_10: 'Jiaoqiu',
    app_rate_10: '0.67%',
    avg_round_10: 29182,
    app_flat_10: 7,
    char_11: 'Robin',
    app_rate_11: '0.62%',
    avg_round_11: 34848,
    app_flat_11: 2,
    char_12: 'Boothill',
    app_rate_12: '0.34%',
    avg_round_12: 30310,
    app_flat_12: 1,
    char_13: 'fu-xuan',
    app_rate_13: '0.34%',
    avg_round_13: 22428,
    app_flat_13: 1,
    char_14: 'Yunli',
    app_rate_14: '0.29%',
    avg_round_14: 32598,
    app_flat_14: 2,
    char_15: 'Tingyun',
    app_rate_15: '0.29%',
    avg_round_15: 23732,
    app_flat_15: 4,
    char_16: 'Jade',
    app_rate_16: '0.29%',
    avg_round_16: 25620,
    app_flat_16: 2,
    char_17: 'Asta',
    app_rate_17: '0.19%',
    avg_round_17: 24670,
    app_flat_17: 3,
    char_18: 'Acheron',
    app_rate_18: '0.14%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Sparkle',
    app_rate_19: '0.14%',
    avg_round_19: 26504,
    app_flat_19: 2,
    char_20: 'Welt',
    app_rate_20: '0.14%',
    avg_round_20: 22235,
    app_flat_20: 2,
    char_21: 'Bronya',
    app_rate_21: '0.1%',
    avg_round_21: 30355,
    app_flat_21: 1,
    char_22: 'Guinaifen',
    app_rate_22: '0.1%',
    avg_round_22: 28158,
    app_flat_22: 2,
    char_23: 'Pela',
    app_rate_23: '0.1%',
    avg_round_23: 20108,
    app_flat_23: 2,
    char_24: 'Bailu',
    app_rate_24: '0.05%',
    avg_round_24: 22499,
    app_flat_24: 1,
    char_25: 'Feixiao',
    app_rate_25: '0.05%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Lynx',
    app_rate_26: '0.05%',
    avg_round_26: 35630,
    app_flat_26: 1,
    char_27: 'Clara',
    app_rate_27: '0.05%',
    avg_round_27: 21504,
    app_flat_27: 1,
    char_28: 'Herta',
    app_rate_28: '0.05%',
    avg_round_28: 20035,
    app_flat_28: 1,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'fu-xuan',
    app: 20.63,
    char_1: 'Acheron',
    app_rate_1: '71.27%',
    avg_round_1: 33230,
    app_flat_1: 494,
    char_2: 'black-swan',
    app_rate_2: '42.12%',
    avg_round_2: 34328,
    app_flat_2: 341,
    char_3: 'Kafka',
    app_rate_3: '41.14%',
    avg_round_3: 33872,
    app_flat_3: 330,
    char_4: 'Jiaoqiu',
    app_rate_4: '31.16%',
    avg_round_4: 33767,
    app_flat_4: 216,
    char_5: 'Sparkle',
    app_rate_5: '25.27%',
    avg_round_5: 28920,
    app_flat_5: 63,
    char_6: 'Robin',
    app_rate_6: '17.39%',
    avg_round_6: 29986,
    app_flat_6: 99,
    char_7: 'Pela',
    app_rate_7: '12.96%',
    avg_round_7: 30703,
    app_flat_7: 114,
    char_8: 'Herta',
    app_rate_8: '7.34%',
    avg_round_8: 30100,
    app_flat_8: 45,
    char_9: 'ruan-mei',
    app_rate_9: '6.64%',
    avg_round_9: 32026,
    app_flat_9: 33,
    char_10: 'Yunli',
    app_rate_10: '6.1%',
    avg_round_10: 31377,
    app_flat_10: 44,
    char_11: 'Jade',
    app_rate_11: '5.72%',
    avg_round_11: 29873,
    app_flat_11: 30,
    char_12: 'Feixiao',
    app_rate_12: '4.1%',
    avg_round_12: 28264,
    app_flat_12: 23,
    char_13: 'Himeko',
    app_rate_13: '3.24%',
    avg_round_13: 24914,
    app_flat_13: 14,
    char_14: 'Clara',
    app_rate_14: '3.24%',
    avg_round_14: 28234,
    app_flat_14: 19,
    char_15: 'Tingyun',
    app_rate_15: '3.19%',
    avg_round_15: 23641,
    app_flat_15: 13,
    char_16: 'Bronya',
    app_rate_16: '2.48%',
    avg_round_16: 29740,
    app_flat_16: 6,
    char_17: 'silver-wolf',
    app_rate_17: '2.16%',
    avg_round_17: 24035,
    app_flat_17: 3,
    char_18: 'Guinaifen',
    app_rate_18: '2.0%',
    avg_round_18: 27597,
    app_flat_18: 14,
    char_19: 'Blade',
    app_rate_19: '1.89%',
    avg_round_19: 28121,
    app_flat_19: 12,
    char_20: 'Sampo',
    app_rate_20: '1.73%',
    avg_round_20: 28217,
    app_flat_20: 22,
    char_21: 'jing-yuan',
    app_rate_21: '1.4%',
    avg_round_21: 22621,
    app_flat_21: 4,
    char_22: 'trailblazer-imaginary',
    app_rate_22: '1.3%',
    avg_round_22: 21502,
    app_flat_22: 5,
    char_23: 'Firefly',
    app_rate_23: '1.19%',
    avg_round_23: 21039,
    app_flat_23: 4,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '0.97%',
    avg_round_24: 29146,
    app_flat_24: 6,
    char_25: 'topaz',
    app_rate_25: '0.81%',
    avg_round_25: 23129,
    app_flat_25: 7,
    char_26: 'Argenti',
    app_rate_26: '0.7%',
    avg_round_26: 23068,
    app_flat_26: 3,
    char_27: 'Seele',
    app_rate_27: '0.59%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Asta',
    app_rate_28: '0.43%',
    avg_round_28: 29186,
    app_flat_28: 3,
    char_29: 'Rappa',
    app_rate_29: '0.38%',
    avg_round_29: 22428,
    app_flat_29: 1,
    char_30: 'imbibitor-lunae',
    app_rate_30: '0.27%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Sparkle',
    app: 15.88,
    char_1: 'Acheron',
    app_rate_1: '62.62%',
    avg_round_1: 31280,
    app_flat_1: 123,
    char_2: 'Jiaoqiu',
    app_rate_2: '45.23%',
    avg_round_2: 32841,
    app_flat_2: 88,
    char_3: 'fu-xuan',
    app_rate_3: '32.82%',
    avg_round_3: 28920,
    app_flat_3: 63,
    char_4: 'Aventurine',
    app_rate_4: '26.37%',
    avg_round_4: 31237,
    app_flat_4: 68,
    char_5: 'Huohuo',
    app_rate_5: '12.2%',
    avg_round_5: 27291,
    app_flat_5: 40,
    char_6: 'Tingyun',
    app_rate_6: '10.73%',
    avg_round_6: 24109,
    app_flat_6: 51,
    char_7: 'Robin',
    app_rate_7: '10.66%',
    avg_round_7: 27099,
    app_flat_7: 32,
    char_8: 'Gallagher',
    app_rate_8: '8.98%',
    avg_round_8: 26540,
    app_flat_8: 40,
    char_9: 'Yunli',
    app_rate_9: '7.85%',
    avg_round_9: 29300,
    app_flat_9: 40,
    char_10: 'Pela',
    app_rate_10: '7.15%',
    avg_round_10: 23166,
    app_flat_10: 16,
    char_11: 'black-swan',
    app_rate_11: '6.52%',
    avg_round_11: 28732,
    app_flat_11: 26,
    char_12: 'Herta',
    app_rate_12: '5.75%',
    avg_round_12: 27110,
    app_flat_12: 22,
    char_13: 'imbibitor-lunae',
    app_rate_13: '5.61%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Luocha',
    app_rate_14: '5.26%',
    avg_round_14: 26417,
    app_flat_14: 18,
    char_15: 'Himeko',
    app_rate_15: '5.12%',
    avg_round_15: 27854,
    app_flat_15: 20,
    char_16: 'ruan-mei',
    app_rate_16: '5.05%',
    avg_round_16: 28980,
    app_flat_16: 10,
    char_17: 'jing-yuan',
    app_rate_17: '4.7%',
    avg_round_17: 22345,
    app_flat_17: 18,
    char_18: 'Kafka',
    app_rate_18: '4.07%',
    avg_round_18: 28980,
    app_flat_18: 19,
    char_19: 'Lingsha',
    app_rate_19: '3.72%',
    avg_round_19: 30727,
    app_flat_19: 19,
    char_20: 'Jade',
    app_rate_20: '3.65%',
    avg_round_20: 29106,
    app_flat_20: 14,
    char_21: 'Clara',
    app_rate_21: '3.51%',
    avg_round_21: 26626,
    app_flat_21: 17,
    char_22: 'Blade',
    app_rate_22: '2.88%',
    avg_round_22: 25234,
    app_flat_22: 18,
    char_23: 'Argenti',
    app_rate_23: '2.59%',
    avg_round_23: 24201,
    app_flat_23: 18,
    char_24: 'Bronya',
    app_rate_24: '2.52%',
    avg_round_24: 22035,
    app_flat_24: 11,
    char_25: 'Gepard',
    app_rate_25: '2.38%',
    avg_round_25: 29080,
    app_flat_25: 5,
    char_26: 'silver-wolf',
    app_rate_26: '2.1%',
    avg_round_26: 27891,
    app_flat_26: 1,
    char_27: 'Feixiao',
    app_rate_27: '2.03%',
    avg_round_27: 28771,
    app_flat_27: 8,
    char_28: 'Seele',
    app_rate_28: '1.82%',
    avg_round_28: 30732,
    app_flat_28: 3,
    char_29: 'Lynx',
    app_rate_29: '1.05%',
    avg_round_29: 28596,
    app_flat_29: 6,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.7%',
    avg_round_30: 25067,
    app_flat_30: 2
  },
  {
    char: 'Herta',
    app: 12.42,
    char_1: 'Robin',
    app_rate_1: '81.17%',
    avg_round_1: 32162,
    app_flat_1: 423,
    char_2: 'Aventurine',
    app_rate_2: '52.38%',
    avg_round_2: 33296,
    app_flat_2: 294,
    char_3: 'Feixiao',
    app_rate_3: '29.96%',
    avg_round_3: 32894,
    app_flat_3: 150,
    char_4: 'Jade',
    app_rate_4: '27.09%',
    avg_round_4: 32369,
    app_flat_4: 155,
    char_5: 'fu-xuan',
    app_rate_5: '12.2%',
    avg_round_5: 30100,
    app_flat_5: 45,
    char_6: 'Huohuo',
    app_rate_6: '11.39%',
    avg_round_6: 29991,
    app_flat_6: 55,
    char_7: 'Clara',
    app_rate_7: '11.39%',
    avg_round_7: 30940,
    app_flat_7: 60,
    char_8: 'Himeko',
    app_rate_8: '9.6%',
    avg_round_8: 25074,
    app_flat_8: 52,
    char_9: 'Acheron',
    app_rate_9: '9.33%',
    avg_round_9: 33611,
    app_flat_9: 48,
    char_10: 'Gallagher',
    app_rate_10: '8.52%',
    avg_round_10: 28073,
    app_flat_10: 50,
    char_11: 'Yunli',
    app_rate_11: '8.25%',
    avg_round_11: 34220,
    app_flat_11: 45,
    char_12: 'Sparkle',
    app_rate_12: '7.35%',
    avg_round_12: 27110,
    app_flat_12: 22,
    char_13: 'Luocha',
    app_rate_13: '5.92%',
    avg_round_13: 29655,
    app_flat_13: 30,
    char_14: 'ruan-mei',
    app_rate_14: '5.11%',
    avg_round_14: 24310,
    app_flat_14: 25,
    char_15: 'Lingsha',
    app_rate_15: '3.23%',
    avg_round_15: 26275,
    app_flat_15: 18,
    char_16: 'Tingyun',
    app_rate_16: '1.88%',
    avg_round_16: 26952,
    app_flat_16: 9,
    char_17: 'black-swan',
    app_rate_17: '1.79%',
    avg_round_17: 31981,
    app_flat_17: 15,
    char_18: 'Jiaoqiu',
    app_rate_18: '1.7%',
    avg_round_18: 36926,
    app_flat_18: 8,
    char_19: 'jing-yuan',
    app_rate_19: '1.61%',
    avg_round_19: 29699,
    app_flat_19: 6,
    char_20: 'Kafka',
    app_rate_20: '0.99%',
    avg_round_20: 33391,
    app_flat_20: 8,
    char_21: 'Pela',
    app_rate_21: '0.99%',
    avg_round_21: 31202,
    app_flat_21: 5,
    char_22: 'Gepard',
    app_rate_22: '0.9%',
    avg_round_22: 32612,
    app_flat_22: 3,
    char_23: 'Argenti',
    app_rate_23: '0.9%',
    avg_round_23: 25518,
    app_flat_23: 3,
    char_24: 'march-7th',
    app_rate_24: '0.81%',
    avg_round_24: 32814,
    app_flat_24: 6,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.72%',
    avg_round_25: 25760,
    app_flat_25: 2,
    char_26: 'Bronya',
    app_rate_26: '0.54%',
    avg_round_26: 30528,
    app_flat_26: 2,
    char_27: 'Firefly',
    app_rate_27: '0.54%',
    avg_round_27: 22067,
    app_flat_27: 4,
    char_28: 'Bailu',
    app_rate_28: '0.45%',
    avg_round_28: 30188,
    app_flat_28: 2,
    char_29: 'Blade',
    app_rate_29: '0.45%',
    avg_round_29: 23750,
    app_flat_29: 2,
    char_30: 'Lynx',
    app_rate_30: '0.45%',
    avg_round_30: 27899,
    app_flat_30: 3
  },
  {
    char: 'Luocha',
    app: 10.96,
    char_1: 'black-swan',
    app_rate_1: '53.66%',
    avg_round_1: 34519,
    app_flat_1: 214,
    char_2: 'Kafka',
    app_rate_2: '52.44%',
    avg_round_2: 33816,
    app_flat_2: 209,
    char_3: 'Robin',
    app_rate_3: '30.79%',
    avg_round_3: 31287,
    app_flat_3: 116,
    char_4: 'ruan-mei',
    app_rate_4: '27.24%',
    avg_round_4: 31000,
    app_flat_4: 106,
    char_5: 'Acheron',
    app_rate_5: '24.7%',
    avg_round_5: 33921,
    app_flat_5: 102,
    char_6: 'Jiaoqiu',
    app_rate_6: '18.29%',
    avg_round_6: 35000,
    app_flat_6: 85,
    char_7: 'Himeko',
    app_rate_7: '14.84%',
    avg_round_7: 27164,
    app_flat_7: 67,
    char_8: 'trailblazer-imaginary',
    app_rate_8: '8.13%',
    avg_round_8: 25604,
    app_flat_8: 42,
    char_9: 'Sparkle',
    app_rate_9: '7.62%',
    avg_round_9: 26417,
    app_flat_9: 18,
    char_10: 'Herta',
    app_rate_10: '6.71%',
    avg_round_10: 29655,
    app_flat_10: 30,
    char_11: 'Jade',
    app_rate_11: '5.69%',
    avg_round_11: 29402,
    app_flat_11: 21,
    char_12: 'Blade',
    app_rate_12: '5.39%',
    avg_round_12: 26226,
    app_flat_12: 17,
    char_13: 'Firefly',
    app_rate_13: '5.28%',
    avg_round_13: 25460,
    app_flat_13: 20,
    char_14: 'Yunli',
    app_rate_14: '4.57%',
    avg_round_14: 29888,
    app_flat_14: 22,
    char_15: 'Bronya',
    app_rate_15: '4.47%',
    avg_round_15: 24714,
    app_flat_15: 14,
    char_16: 'Feixiao',
    app_rate_16: '4.07%',
    avg_round_16: 28910,
    app_flat_16: 14,
    char_17: 'Clara',
    app_rate_17: '3.35%',
    avg_round_17: 26134,
    app_flat_17: 12,
    char_18: 'Sampo',
    app_rate_18: '3.15%',
    avg_round_18: 27151,
    app_flat_18: 14,
    char_19: 'Tingyun',
    app_rate_19: '3.05%',
    avg_round_19: 27778,
    app_flat_19: 13,
    char_20: 'Pela',
    app_rate_20: '2.95%',
    avg_round_20: 32354,
    app_flat_20: 18,
    char_21: 'Rappa',
    app_rate_21: '2.85%',
    avg_round_21: 27404,
    app_flat_21: 14,
    char_22: 'Guinaifen',
    app_rate_22: '2.03%',
    avg_round_22: 25405,
    app_flat_22: 6,
    char_23: 'imbibitor-lunae',
    app_rate_23: '1.63%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jing-yuan',
    app_rate_24: '1.42%',
    avg_round_24: 20893,
    app_flat_24: 3,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '1.22%',
    avg_round_25: 24623,
    app_flat_25: 5,
    char_26: 'Argenti',
    app_rate_26: '1.12%',
    avg_round_26: 25808,
    app_flat_26: 6,
    char_27: 'Asta',
    app_rate_27: '0.81%',
    avg_round_27: 29259,
    app_flat_27: 4,
    char_28: 'Serval',
    app_rate_28: '0.81%',
    avg_round_28: 36425,
    app_flat_28: 3,
    char_29: 'topaz',
    app_rate_29: '0.51%',
    avg_round_29: 24160,
    app_flat_29: 2,
    char_30: 'Welt',
    app_rate_30: '0.2%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Feixiao',
    app: 10.95,
    char_1: 'Robin',
    app_rate_1: '86.78%',
    avg_round_1: 30973,
    app_flat_1: 319,
    char_2: 'Aventurine',
    app_rate_2: '63.17%',
    avg_round_2: 31919,
    app_flat_2: 246,
    char_3: 'Herta',
    app_rate_3: '33.98%',
    avg_round_3: 32894,
    app_flat_3: 150,
    char_4: 'topaz',
    app_rate_4: '15.26%',
    avg_round_4: 25093,
    app_flat_4: 44,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '14.95%',
    avg_round_5: 27355,
    app_flat_5: 51,
    char_6: 'Jade',
    app_rate_6: '10.58%',
    avg_round_6: 29010,
    app_flat_6: 12,
    char_7: 'Gallagher',
    app_rate_7: '8.55%',
    avg_round_7: 28944,
    app_flat_7: 31,
    char_8: 'Himeko',
    app_rate_8: '8.04%',
    avg_round_8: 31263,
    app_flat_8: 38,
    char_9: 'Yunli',
    app_rate_9: '7.83%',
    avg_round_9: 33102,
    app_flat_9: 35,
    char_10: 'fu-xuan',
    app_rate_10: '7.73%',
    avg_round_10: 28264,
    app_flat_10: 23,
    char_11: 'Acheron',
    app_rate_11: '6.71%',
    avg_round_11: 33174,
    app_flat_11: 35,
    char_12: 'Clara',
    app_rate_12: '5.29%',
    avg_round_12: 29071,
    app_flat_12: 25,
    char_13: 'Huohuo',
    app_rate_13: '4.68%',
    avg_round_13: 28530,
    app_flat_13: 13,
    char_14: 'Luocha',
    app_rate_14: '4.07%',
    avg_round_14: 28910,
    app_flat_14: 14,
    char_15: 'Lingsha',
    app_rate_15: '4.07%',
    avg_round_15: 26752,
    app_flat_15: 12,
    char_16: 'ruan-mei',
    app_rate_16: '3.15%',
    avg_round_16: 29754,
    app_flat_16: 10,
    char_17: 'Sparkle',
    app_rate_17: '2.95%',
    avg_round_17: 28771,
    app_flat_17: 8,
    char_18: 'Bronya',
    app_rate_18: '2.34%',
    avg_round_18: 29753,
    app_flat_18: 6,
    char_19: 'Jiaoqiu',
    app_rate_19: '1.73%',
    avg_round_19: 36130,
    app_flat_19: 10,
    char_20: 'Kafka',
    app_rate_20: '1.42%',
    avg_round_20: 30208,
    app_flat_20: 3,
    char_21: 'black-swan',
    app_rate_21: '1.32%',
    avg_round_21: 32579,
    app_flat_21: 5,
    char_22: 'Blade',
    app_rate_22: '0.81%',
    avg_round_22: 26168,
    app_flat_22: 3,
    char_23: 'march-7th',
    app_rate_23: '0.71%',
    avg_round_23: 30995,
    app_flat_23: 3,
    char_24: 'jing-yuan',
    app_rate_24: '0.71%',
    avg_round_24: 30058,
    app_flat_24: 3,
    char_25: 'Lynx',
    app_rate_25: '0.51%',
    avg_round_25: 27795,
    app_flat_25: 4,
    char_26: 'Bailu',
    app_rate_26: '0.41%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Pela',
    app_rate_27: '0.31%',
    avg_round_27: 31635,
    app_flat_27: 2,
    char_28: 'Sampo',
    app_rate_28: '0.31%',
    avg_round_28: 26264,
    app_flat_28: 2,
    char_29: 'Tingyun',
    app_rate_29: '0.2%',
    avg_round_29: 25412,
    app_flat_29: 1,
    char_30: 'Argenti',
    app_rate_30: '0.2%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Pela',
    app: 9.72,
    char_1: 'Acheron',
    app_rate_1: '95.42%',
    avg_round_1: 32745,
    app_flat_1: 486,
    char_2: 'Jiaoqiu',
    app_rate_2: '49.14%',
    avg_round_2: 34386,
    app_flat_2: 317,
    char_3: 'Aventurine',
    app_rate_3: '39.98%',
    avg_round_3: 35379,
    app_flat_3: 233,
    char_4: 'fu-xuan',
    app_rate_4: '27.49%',
    avg_round_4: 30703,
    app_flat_4: 114,
    char_5: 'Sparkle',
    app_rate_5: '11.68%',
    avg_round_5: 23166,
    app_flat_5: 16,
    char_6: 'Gallagher',
    app_rate_6: '10.88%',
    avg_round_6: 30899,
    app_flat_6: 69,
    char_7: 'black-swan',
    app_rate_7: '9.51%',
    avg_round_7: 32649,
    app_flat_7: 42,
    char_8: 'Kafka',
    app_rate_8: '8.71%',
    avg_round_8: 31933,
    app_flat_8: 37,
    char_9: 'Gepard',
    app_rate_9: '7.56%',
    avg_round_9: 30052,
    app_flat_9: 30,
    char_10: 'Robin',
    app_rate_10: '5.15%',
    avg_round_10: 28910,
    app_flat_10: 19,
    char_11: 'Huohuo',
    app_rate_11: '4.35%',
    avg_round_11: 30969,
    app_flat_11: 15,
    char_12: 'Bronya',
    app_rate_12: '3.44%',
    avg_round_12: 26916,
    app_flat_12: 2,
    char_13: 'Luocha',
    app_rate_13: '3.32%',
    avg_round_13: 32354,
    app_flat_13: 18,
    char_14: 'Guinaifen',
    app_rate_14: '2.98%',
    avg_round_14: 27534,
    app_flat_14: 19,
    char_15: 'silver-wolf',
    app_rate_15: '2.86%',
    avg_round_15: 25422,
    app_flat_15: 16,
    char_16: 'Yunli',
    app_rate_16: '2.86%',
    avg_round_16: 34381,
    app_flat_16: 11,
    char_17: 'march-7th',
    app_rate_17: '2.29%',
    avg_round_17: 30519,
    app_flat_17: 8,
    char_18: 'Clara',
    app_rate_18: '2.18%',
    avg_round_18: 29114,
    app_flat_18: 13,
    char_19: 'Lingsha',
    app_rate_19: '1.72%',
    avg_round_19: 33164,
    app_flat_19: 10,
    char_20: 'Sampo',
    app_rate_20: '1.49%',
    avg_round_20: 26941,
    app_flat_20: 10,
    char_21: 'Herta',
    app_rate_21: '1.26%',
    avg_round_21: 31202,
    app_flat_21: 5,
    char_22: 'ruan-mei',
    app_rate_22: '0.92%',
    avg_round_22: 22728,
    app_flat_22: 1,
    char_23: 'trailblazer-fire',
    app_rate_23: '0.46%',
    avg_round_23: 28900,
    app_flat_23: 1,
    char_24: 'Blade',
    app_rate_24: '0.46%',
    avg_round_24: 26624,
    app_flat_24: 2,
    char_25: 'Firefly',
    app_rate_25: '0.34%',
    avg_round_25: 20265,
    app_flat_25: 1,
    char_26: 'Tingyun',
    app_rate_26: '0.34%',
    avg_round_26: 21484,
    app_flat_26: 1,
    char_27: 'jing-yuan',
    app_rate_27: '0.34%',
    avg_round_27: 23771,
    app_flat_27: 2,
    char_28: 'Lynx',
    app_rate_28: '0.34%',
    avg_round_28: 29355,
    app_flat_28: 1,
    char_29: 'Himeko',
    app_rate_29: '0.34%',
    avg_round_29: 34765,
    app_flat_29: 1,
    char_30: 'Welt',
    app_rate_30: '0.34%',
    avg_round_30: 24220,
    app_flat_30: 3
  },
  {
    char: 'Jade',
    app: 8.58,
    char_1: 'Robin',
    app_rate_1: '72.6%',
    avg_round_1: 32135,
    app_flat_1: 235,
    char_2: 'Aventurine',
    app_rate_2: '41.17%',
    avg_round_2: 32809,
    app_flat_2: 138,
    char_3: 'Herta',
    app_rate_3: '39.22%',
    avg_round_3: 32369,
    app_flat_3: 155,
    char_4: 'fu-xuan',
    app_rate_4: '13.77%',
    avg_round_4: 29873,
    app_flat_4: 30,
    char_5: 'Huohuo',
    app_rate_5: '13.64%',
    avg_round_5: 31222,
    app_flat_5: 46,
    char_6: 'Feixiao',
    app_rate_6: '13.51%',
    avg_round_6: 29010,
    app_flat_6: 12,
    char_7: 'Himeko',
    app_rate_7: '12.73%',
    avg_round_7: 29255,
    app_flat_7: 52,
    char_8: 'Lingsha',
    app_rate_8: '12.6%',
    avg_round_8: 30731,
    app_flat_8: 38,
    char_9: 'Blade',
    app_rate_9: '11.3%',
    avg_round_9: 29174,
    app_flat_9: 37,
    char_10: 'ruan-mei',
    app_rate_10: '8.18%',
    avg_round_10: 29949,
    app_flat_10: 24,
    char_11: 'Yunli',
    app_rate_11: '7.79%',
    avg_round_11: 34248,
    app_flat_11: 29,
    char_12: 'Luocha',
    app_rate_12: '7.27%',
    avg_round_12: 29402,
    app_flat_12: 21,
    char_13: 'Sparkle',
    app_rate_13: '6.75%',
    avg_round_13: 29106,
    app_flat_13: 14,
    char_14: 'Bronya',
    app_rate_14: '6.36%',
    avg_round_14: 28988,
    app_flat_14: 20,
    char_15: 'Gallagher',
    app_rate_15: '6.1%',
    avg_round_15: 28725,
    app_flat_15: 23,
    char_16: 'Clara',
    app_rate_16: '5.45%',
    avg_round_16: 31648,
    app_flat_16: 13,
    char_17: 'Acheron',
    app_rate_17: '2.73%',
    avg_round_17: 34629,
    app_flat_17: 8,
    char_18: 'Kafka',
    app_rate_18: '2.34%',
    avg_round_18: 30511,
    app_flat_18: 4,
    char_19: 'Argenti',
    app_rate_19: '2.08%',
    avg_round_19: 26956,
    app_flat_19: 4,
    char_20: 'topaz',
    app_rate_20: '2.08%',
    avg_round_20: 25055,
    app_flat_20: 1,
    char_21: 'trailblazer-imaginary',
    app_rate_21: '1.82%',
    avg_round_21: 26381,
    app_flat_21: 7,
    char_22: 'black-swan',
    app_rate_22: '1.69%',
    avg_round_22: 32767,
    app_flat_22: 5,
    char_23: 'Tingyun',
    app_rate_23: '1.43%',
    avg_round_23: 31856,
    app_flat_23: 7,
    char_24: 'Lynx',
    app_rate_24: '1.17%',
    avg_round_24: 30166,
    app_flat_24: 5,
    char_25: 'jing-yuan',
    app_rate_25: '1.17%',
    avg_round_25: 27032,
    app_flat_25: 3,
    char_26: 'Jiaoqiu',
    app_rate_26: '1.17%',
    avg_round_26: 36140,
    app_flat_26: 2,
    char_27: 'Rappa',
    app_rate_27: '0.78%',
    avg_round_27: 25620,
    app_flat_27: 2,
    char_28: 'Bailu',
    app_rate_28: '0.65%',
    avg_round_28: 30266,
    app_flat_28: 2,
    char_29: 'Asta',
    app_rate_29: '0.52%',
    avg_round_29: 31684,
    app_flat_29: 2,
    char_30: 'Gepard',
    app_rate_30: '0.39%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Yunli',
    app: 7.36,
    char_1: 'Robin',
    app_rate_1: '67.93%',
    avg_round_1: 32120,
    app_flat_1: 211,
    char_2: 'Huohuo',
    app_rate_2: '36.16%',
    avg_round_2: 31594,
    app_flat_2: 95,
    char_3: 'Aventurine',
    app_rate_3: '24.36%',
    avg_round_3: 36728,
    app_flat_3: 84,
    char_4: 'Tingyun',
    app_rate_4: '23.0%',
    avg_round_4: 30355,
    app_flat_4: 64,
    char_5: 'Acheron',
    app_rate_5: '19.21%',
    avg_round_5: 35073,
    app_flat_5: 61,
    char_6: 'fu-xuan',
    app_rate_6: '17.1%',
    avg_round_6: 31377,
    app_flat_6: 44,
    char_7: 'Sparkle',
    app_rate_7: '16.94%',
    avg_round_7: 29300,
    app_flat_7: 40,
    char_8: 'Herta',
    app_rate_8: '13.92%',
    avg_round_8: 34220,
    app_flat_8: 45,
    char_9: 'Feixiao',
    app_rate_9: '11.65%',
    avg_round_9: 33102,
    app_flat_9: 35,
    char_10: 'Jade',
    app_rate_10: '9.08%',
    avg_round_10: 34248,
    app_flat_10: 29,
    char_11: 'Luocha',
    app_rate_11: '6.81%',
    avg_round_11: 29888,
    app_flat_11: 22,
    char_12: 'Lingsha',
    app_rate_12: '4.99%',
    avg_round_12: 30420,
    app_flat_12: 15,
    char_13: 'topaz',
    app_rate_13: '4.54%',
    avg_round_13: 24637,
    app_flat_13: 8,
    char_14: 'Himeko',
    app_rate_14: '4.39%',
    avg_round_14: 28547,
    app_flat_14: 19,
    char_15: 'black-swan',
    app_rate_15: '4.24%',
    avg_round_15: 36573,
    app_flat_15: 10,
    char_16: 'Gallagher',
    app_rate_16: '3.93%',
    avg_round_16: 28885,
    app_flat_16: 16,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '3.93%',
    avg_round_17: 29071,
    app_flat_17: 13,
    char_18: 'Pela',
    app_rate_18: '3.78%',
    avg_round_18: 34381,
    app_flat_18: 11,
    char_19: 'Jiaoqiu',
    app_rate_19: '3.48%',
    avg_round_19: 36474,
    app_flat_19: 9,
    char_20: 'ruan-mei',
    app_rate_20: '3.48%',
    avg_round_20: 28385,
    app_flat_20: 11,
    char_21: 'Lynx',
    app_rate_21: '3.33%',
    avg_round_21: 29728,
    app_flat_21: 15,
    char_22: 'Clara',
    app_rate_22: '2.87%',
    avg_round_22: 24749,
    app_flat_22: 11,
    char_23: 'Kafka',
    app_rate_23: '2.12%',
    avg_round_23: 33387,
    app_flat_23: 5,
    char_24: 'Blade',
    app_rate_24: '1.36%',
    avg_round_24: 28960,
    app_flat_24: 4,
    char_25: 'Bronya',
    app_rate_25: '1.06%',
    avg_round_25: 27080,
    app_flat_25: 5,
    char_26: 'Firefly',
    app_rate_26: '0.91%',
    avg_round_26: 24410,
    app_flat_26: 2,
    char_27: 'Rappa',
    app_rate_27: '0.91%',
    avg_round_27: 32598,
    app_flat_27: 2,
    char_28: 'jing-yuan',
    app_rate_28: '0.76%',
    avg_round_28: 37870,
    app_flat_28: 1,
    char_29: 'march-7th',
    app_rate_29: '0.76%',
    avg_round_29: 27606,
    app_flat_29: 2,
    char_30: 'trailblazer-imaginary',
    app_rate_30: '0.61%',
    avg_round_30: 27946,
    app_flat_30: 2
  },
  {
    char: 'Tingyun',
    app: 5.52,
    char_1: 'Huohuo',
    app_rate_1: '39.92%',
    avg_round_1: 29181,
    app_flat_1: 81,
    char_2: 'Robin',
    app_rate_2: '35.48%',
    avg_round_2: 28455,
    app_flat_2: 68,
    char_3: 'Sparkle',
    app_rate_3: '30.85%',
    avg_round_3: 24109,
    app_flat_3: 51,
    char_4: 'Yunli',
    app_rate_4: '30.65%',
    avg_round_4: 30355,
    app_flat_4: 64,
    char_5: 'Kafka',
    app_rate_5: '23.39%',
    avg_round_5: 29535,
    app_flat_5: 51,
    char_6: 'black-swan',
    app_rate_6: '20.56%',
    avg_round_6: 30518,
    app_flat_6: 40,
    char_7: 'Aventurine',
    app_rate_7: '13.51%',
    avg_round_7: 28912,
    app_flat_7: 37,
    char_8: 'fu-xuan',
    app_rate_8: '11.9%',
    avg_round_8: 23641,
    app_flat_8: 13,
    char_9: 'Argenti',
    app_rate_9: '10.08%',
    avg_round_9: 24203,
    app_flat_9: 24,
    char_10: 'Gallagher',
    app_rate_10: '9.68%',
    avg_round_10: 24506,
    app_flat_10: 27,
    char_11: 'jing-yuan',
    app_rate_11: '8.27%',
    avg_round_11: 22059,
    app_flat_11: 16,
    char_12: 'imbibitor-lunae',
    app_rate_12: '7.06%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '7.06%',
    avg_round_13: 25182,
    app_flat_13: 9,
    char_14: 'Luocha',
    app_rate_14: '6.05%',
    avg_round_14: 27778,
    app_flat_14: 13,
    char_15: 'Himeko',
    app_rate_15: '4.84%',
    avg_round_15: 25480,
    app_flat_15: 12,
    char_16: 'Clara',
    app_rate_16: '4.84%',
    avg_round_16: 24546,
    app_flat_16: 11,
    char_17: 'Herta',
    app_rate_17: '4.23%',
    avg_round_17: 26952,
    app_flat_17: 9,
    char_18: 'Lingsha',
    app_rate_18: '3.63%',
    avg_round_18: 30637,
    app_flat_18: 8,
    char_19: 'Seele',
    app_rate_19: '3.02%',
    avg_round_19: 30732,
    app_flat_19: 3,
    char_20: 'Jiaoqiu',
    app_rate_20: '3.02%',
    avg_round_20: 31760,
    app_flat_20: 2,
    char_21: 'Acheron',
    app_rate_21: '2.82%',
    avg_round_21: 31024,
    app_flat_21: 7,
    char_22: 'Lynx',
    app_rate_22: '2.62%',
    avg_round_22: 26652,
    app_flat_22: 6,
    char_23: 'Sampo',
    app_rate_23: '2.22%',
    avg_round_23: 24338,
    app_flat_23: 9,
    char_24: 'Jade',
    app_rate_24: '2.22%',
    avg_round_24: 31856,
    app_flat_24: 7,
    char_25: 'Bronya',
    app_rate_25: '1.81%',
    avg_round_25: 25736,
    app_flat_25: 7,
    char_26: 'Bailu',
    app_rate_26: '1.81%',
    avg_round_26: 24675,
    app_flat_26: 5,
    char_27: 'Asta',
    app_rate_27: '1.61%',
    avg_round_27: 26302,
    app_flat_27: 4,
    char_28: 'Rappa',
    app_rate_28: '1.21%',
    avg_round_28: 23732,
    app_flat_28: 4,
    char_29: 'trailblazer-imaginary',
    app_rate_29: '1.01%',
    avg_round_29: 23018,
    app_flat_29: 5,
    char_30: 'Serval',
    app_rate_30: '1.01%',
    avg_round_30: 22101,
    app_flat_30: 3
  },
  {
    char: 'Clara',
    app: 4.72,
    char_1: 'Robin',
    app_rate_1: '61.56%',
    avg_round_1: 29859,
    app_flat_1: 125,
    char_2: 'Aventurine',
    app_rate_2: '42.92%',
    avg_round_2: 31466,
    app_flat_2: 103,
    char_3: 'Herta',
    app_rate_3: '29.95%',
    avg_round_3: 30940,
    app_flat_3: 60,
    char_4: 'Acheron',
    app_rate_4: '26.18%',
    avg_round_4: 33338,
    app_flat_4: 63,
    char_5: 'fu-xuan',
    app_rate_5: '14.15%',
    avg_round_5: 28234,
    app_flat_5: 19,
    char_6: 'Feixiao',
    app_rate_6: '12.26%',
    avg_round_6: 29071,
    app_flat_6: 25,
    char_7: 'Sparkle',
    app_rate_7: '11.79%',
    avg_round_7: 26626,
    app_flat_7: 17,
    char_8: 'Huohuo',
    app_rate_8: '11.79%',
    avg_round_8: 28689,
    app_flat_8: 20,
    char_9: 'Gallagher',
    app_rate_9: '11.56%',
    avg_round_9: 27952,
    app_flat_9: 31,
    char_10: 'Jade',
    app_rate_10: '9.91%',
    avg_round_10: 31648,
    app_flat_10: 13,
    char_11: 'Jiaoqiu',
    app_rate_11: '9.91%',
    avg_round_11: 37006,
    app_flat_11: 24,
    char_12: 'Luocha',
    app_rate_12: '7.78%',
    avg_round_12: 26134,
    app_flat_12: 12,
    char_13: 'Himeko',
    app_rate_13: '7.08%',
    avg_round_13: 23420,
    app_flat_13: 18,
    char_14: 'Tingyun',
    app_rate_14: '5.66%',
    avg_round_14: 24546,
    app_flat_14: 11,
    char_15: 'black-swan',
    app_rate_15: '4.95%',
    avg_round_15: 30965,
    app_flat_15: 10,
    char_16: 'Pela',
    app_rate_16: '4.48%',
    avg_round_16: 29114,
    app_flat_16: 13,
    char_17: 'Yunli',
    app_rate_17: '4.48%',
    avg_round_17: 24749,
    app_flat_17: 11,
    char_18: 'ruan-mei',
    app_rate_18: '4.48%',
    avg_round_18: 23771,
    app_flat_18: 9,
    char_19: 'Lingsha',
    app_rate_19: '3.3%',
    avg_round_19: 30027,
    app_flat_19: 7,
    char_20: 'topaz',
    app_rate_20: '2.59%',
    avg_round_20: 21459,
    app_flat_20: 5,
    char_21: 'Kafka',
    app_rate_21: '2.36%',
    avg_round_21: 28957,
    app_flat_21: 6,
    char_22: 'march-7th-swordmaster',
    app_rate_22: '2.12%',
    avg_round_22: 26916,
    app_flat_22: 1,
    char_23: 'march-7th',
    app_rate_23: '1.65%',
    avg_round_23: 32626,
    app_flat_23: 4,
    char_24: 'Lynx',
    app_rate_24: '1.18%',
    avg_round_24: 29207,
    app_flat_24: 4,
    char_25: 'Sampo',
    app_rate_25: '0.94%',
    avg_round_25: 25040,
    app_flat_25: 2,
    char_26: 'Blade',
    app_rate_26: '0.94%',
    avg_round_26: 20204,
    app_flat_26: 2,
    char_27: 'jing-yuan',
    app_rate_27: '0.71%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'trailblazer-imaginary',
    app_rate_28: '0.47%',
    avg_round_28: 20335,
    app_flat_28: 2,
    char_29: 'Bailu',
    app_rate_29: '0.47%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: '0.47%',
    avg_round_30: 21999,
    app_flat_30: 2
  },
  {
    char: 'Bronya',
    app: 3.4,
    char_1: 'Acheron',
    app_rate_1: '37.05%',
    avg_round_1: 32114,
    app_flat_1: 9,
    char_2: 'Blade',
    app_rate_2: '23.61%',
    avg_round_2: 26071,
    app_flat_2: 31,
    char_3: 'Jiaoqiu',
    app_rate_3: '22.95%',
    avg_round_3: 33000,
    app_flat_3: 5,
    char_4: 'ruan-mei',
    app_rate_4: '16.72%',
    avg_round_4: 28616,
    app_flat_4: 6,
    char_5: 'Jade',
    app_rate_5: '16.07%',
    avg_round_5: 28988,
    app_flat_5: 20,
    char_6: 'Aventurine',
    app_rate_6: '15.08%',
    avg_round_6: 28462,
    app_flat_6: 8,
    char_7: 'fu-xuan',
    app_rate_7: '15.08%',
    avg_round_7: 29740,
    app_flat_7: 6,
    char_8: 'Luocha',
    app_rate_8: '14.43%',
    avg_round_8: 24714,
    app_flat_8: 14,
    char_9: 'Robin',
    app_rate_9: '14.1%',
    avg_round_9: 28187,
    app_flat_9: 14,
    char_10: 'trailblazer-imaginary',
    app_rate_10: '14.1%',
    avg_round_10: 25984,
    app_flat_10: 3,
    char_11: 'Firefly',
    app_rate_11: '13.77%',
    avg_round_11: 22433,
    app_flat_11: 1,
    char_12: 'Gallagher',
    app_rate_12: '12.13%',
    avg_round_12: 26430,
    app_flat_12: 13,
    char_13: 'Sparkle',
    app_rate_13: '11.8%',
    avg_round_13: 22035,
    app_flat_13: 11,
    char_14: 'Pela',
    app_rate_14: '9.84%',
    avg_round_14: 26916,
    app_flat_14: 2,
    char_15: 'Feixiao',
    app_rate_15: '7.54%',
    avg_round_15: 29753,
    app_flat_15: 6,
    char_16: 'Huohuo',
    app_rate_16: '6.89%',
    avg_round_16: 24993,
    app_flat_16: 5,
    char_17: 'Kafka',
    app_rate_17: '4.59%',
    avg_round_17: 27388,
    app_flat_17: 9,
    char_18: 'Lingsha',
    app_rate_18: '4.59%',
    avg_round_18: 27245,
    app_flat_18: 3,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '4.59%',
    avg_round_19: 28165,
    app_flat_19: 7,
    char_20: 'Gepard',
    app_rate_20: '4.26%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'black-swan',
    app_rate_21: '2.95%',
    avg_round_21: 32251,
    app_flat_21: 4,
    char_22: 'Tingyun',
    app_rate_22: '2.95%',
    avg_round_22: 25736,
    app_flat_22: 7,
    char_23: 'Himeko',
    app_rate_23: '2.62%',
    avg_round_23: 24060,
    app_flat_23: 5,
    char_24: 'Yunli',
    app_rate_24: '2.3%',
    avg_round_24: 27080,
    app_flat_24: 5,
    char_25: 'Herta',
    app_rate_25: '1.97%',
    avg_round_25: 30528,
    app_flat_25: 2,
    char_26: 'Argenti',
    app_rate_26: '1.97%',
    avg_round_26: 26924,
    app_flat_26: 3,
    char_27: 'Sampo',
    app_rate_27: '1.64%',
    avg_round_27: 23092,
    app_flat_27: 4,
    char_28: 'imbibitor-lunae',
    app_rate_28: '1.64%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'jing-yuan',
    app_rate_29: '1.64%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Jingliu',
    app_rate_30: '1.64%',
    avg_round_30: 22361,
    app_flat_30: 1
  },
  {
    char: 'Sampo',
    app: 3.13,
    char_1: 'Kafka',
    app_rate_1: '51.25%',
    avg_round_1: 28172,
    app_flat_1: 73,
    char_2: 'black-swan',
    app_rate_2: '43.42%',
    avg_round_2: 30768,
    app_flat_2: 57,
    char_3: 'Acheron',
    app_rate_3: '39.5%',
    avg_round_3: 30468,
    app_flat_3: 70,
    char_4: 'Aventurine',
    app_rate_4: '24.2%',
    avg_round_4: 30916,
    app_flat_4: 41,
    char_5: 'Huohuo',
    app_rate_5: '21.71%',
    avg_round_5: 28856,
    app_flat_5: 26,
    char_6: 'Jiaoqiu',
    app_rate_6: '16.73%',
    avg_round_6: 31402,
    app_flat_6: 29,
    char_7: 'Gallagher',
    app_rate_7: '16.37%',
    avg_round_7: 27710,
    app_flat_7: 33,
    char_8: 'ruan-mei',
    app_rate_8: '14.59%',
    avg_round_8: 28592,
    app_flat_8: 21,
    char_9: 'Robin',
    app_rate_9: '12.1%',
    avg_round_9: 25463,
    app_flat_9: 23,
    char_10: 'fu-xuan',
    app_rate_10: '11.39%',
    avg_round_10: 28217,
    app_flat_10: 22,
    char_11: 'Luocha',
    app_rate_11: '11.03%',
    avg_round_11: 27151,
    app_flat_11: 14,
    char_12: 'Pela',
    app_rate_12: '4.63%',
    avg_round_12: 26941,
    app_flat_12: 10,
    char_13: 'Tingyun',
    app_rate_13: '3.91%',
    avg_round_13: 24338,
    app_flat_13: 9,
    char_14: 'Guinaifen',
    app_rate_14: '3.56%',
    avg_round_14: 21918,
    app_flat_14: 6,
    char_15: 'Gepard',
    app_rate_15: '3.56%',
    avg_round_15: 28106,
    app_flat_15: 4,
    char_16: 'Bronya',
    app_rate_16: '1.78%',
    avg_round_16: 23092,
    app_flat_16: 4,
    char_17: 'Sparkle',
    app_rate_17: '1.78%',
    avg_round_17: 25510,
    app_flat_17: 1,
    char_18: 'Asta',
    app_rate_18: '1.78%',
    avg_round_18: 21826,
    app_flat_18: 1,
    char_19: 'Bailu',
    app_rate_19: '1.78%',
    avg_round_19: 26962,
    app_flat_19: 2,
    char_20: 'Serval',
    app_rate_20: '1.78%',
    avg_round_20: 22277,
    app_flat_20: 3,
    char_21: 'Clara',
    app_rate_21: '1.42%',
    avg_round_21: 25040,
    app_flat_21: 2,
    char_22: 'march-7th',
    app_rate_22: '1.42%',
    avg_round_22: 31688,
    app_flat_22: 2,
    char_23: 'Lingsha',
    app_rate_23: '1.42%',
    avg_round_23: 32641,
    app_flat_23: 2,
    char_24: 'Herta',
    app_rate_24: '1.42%',
    avg_round_24: 31360,
    app_flat_24: 2,
    char_25: 'Blade',
    app_rate_25: '1.07%',
    avg_round_25: 23375,
    app_flat_25: 3,
    char_26: 'Lynx',
    app_rate_26: '1.07%',
    avg_round_26: 29785,
    app_flat_26: 1,
    char_27: 'Yunli',
    app_rate_27: '1.07%',
    avg_round_27: 25064,
    app_flat_27: 3,
    char_28: 'Feixiao',
    app_rate_28: '1.07%',
    avg_round_28: 26264,
    app_flat_28: 2,
    char_29: 'Himeko',
    app_rate_29: '1.07%',
    avg_round_29: 27098,
    app_flat_29: 3,
    char_30: 'Argenti',
    app_rate_30: '0.71%',
    avg_round_30: 31570,
    app_flat_30: 1
  },
  {
    char: 'topaz',
    app: 3.1,
    char_1: 'Robin',
    app_rate_1: '80.22%',
    avg_round_1: 25567,
    app_flat_1: 61,
    char_2: 'Aventurine',
    app_rate_2: '75.9%',
    avg_round_2: 25960,
    app_flat_2: 54,
    char_3: 'Feixiao',
    app_rate_3: '53.96%',
    avg_round_3: 25093,
    app_flat_3: 44,
    char_4: 'Himeko',
    app_rate_4: '24.1%',
    avg_round_4: 27177,
    app_flat_4: 22,
    char_5: 'Yunli',
    app_rate_5: '10.79%',
    avg_round_5: 24637,
    app_flat_5: 8,
    char_6: 'ruan-mei',
    app_rate_6: '8.63%',
    avg_round_6: 26878,
    app_flat_6: 9,
    char_7: 'Lingsha',
    app_rate_7: '6.47%',
    avg_round_7: 25552,
    app_flat_7: 7,
    char_8: 'Jade',
    app_rate_8: '5.76%',
    avg_round_8: 25055,
    app_flat_8: 1,
    char_9: 'Gallagher',
    app_rate_9: '5.4%',
    avg_round_9: 25943,
    app_flat_9: 6,
    char_10: 'fu-xuan',
    app_rate_10: '5.4%',
    avg_round_10: 23129,
    app_flat_10: 7,
    char_11: 'Huohuo',
    app_rate_11: '4.68%',
    avg_round_11: 27154,
    app_flat_11: 3,
    char_12: 'Clara',
    app_rate_12: '3.96%',
    avg_round_12: 21459,
    app_flat_12: 5,
    char_13: 'Acheron',
    app_rate_13: '2.52%',
    avg_round_13: 28396,
    app_flat_13: 4,
    char_14: 'Luocha',
    app_rate_14: '1.8%',
    avg_round_14: 24160,
    app_flat_14: 2,
    char_15: 'Jiaoqiu',
    app_rate_15: '1.8%',
    avg_round_15: 37050,
    app_flat_15: 1,
    char_16: 'Herta',
    app_rate_16: '1.44%',
    avg_round_16: 24420,
    app_flat_16: 1,
    char_17: 'Sparkle',
    app_rate_17: '1.44%',
    avg_round_17: 20018,
    app_flat_17: 1,
    char_18: 'trailblazer-imaginary',
    app_rate_18: '1.44%',
    avg_round_18: 22826,
    app_flat_18: 1,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '1.08%',
    avg_round_19: 23776,
    app_flat_19: 2,
    char_20: 'Asta',
    app_rate_20: '1.08%',
    avg_round_20: 26803,
    app_flat_20: 1,
    char_21: 'Bronya',
    app_rate_21: '0.72%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'dr-ratio',
    app_rate_22: '0.72%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Firefly',
    app_rate_23: '0.36%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Tingyun',
    app_rate_24: '0.36%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    app: 2.99,
    char_1: 'Acheron',
    app_rate_1: '41.42%',
    avg_round_1: 30507,
    app_flat_1: 71,
    char_2: 'black-swan',
    app_rate_2: '30.97%',
    avg_round_2: 28562,
    app_flat_2: 35,
    char_3: 'Aventurine',
    app_rate_3: '26.49%',
    avg_round_3: 30697,
    app_flat_3: 47,
    char_4: 'Kafka',
    app_rate_4: '25.75%',
    avg_round_4: 27917,
    app_flat_4: 26,
    char_5: 'Jiaoqiu',
    app_rate_5: '22.76%',
    avg_round_5: 31561,
    app_flat_5: 38,
    char_6: 'Gallagher',
    app_rate_6: '22.01%',
    avg_round_6: 26049,
    app_flat_6: 32,
    char_7: 'ruan-mei',
    app_rate_7: '20.15%',
    avg_round_7: 26050,
    app_flat_7: 22,
    char_8: 'Himeko',
    app_rate_8: '18.28%',
    avg_round_8: 25840,
    app_flat_8: 24,
    char_9: 'Huohuo',
    app_rate_9: '17.16%',
    avg_round_9: 26366,
    app_flat_9: 18,
    char_10: 'fu-xuan',
    app_rate_10: '13.81%',
    avg_round_10: 27597,
    app_flat_10: 14,
    char_11: 'trailblazer-imaginary',
    app_rate_11: '10.45%',
    avg_round_11: 21527,
    app_flat_11: 14,
    char_12: 'Pela',
    app_rate_12: '9.7%',
    avg_round_12: 27534,
    app_flat_12: 19,
    char_13: 'Luocha',
    app_rate_13: '7.46%',
    avg_round_13: 25405,
    app_flat_13: 6,
    char_14: 'Lingsha',
    app_rate_14: '6.34%',
    avg_round_14: 27604,
    app_flat_14: 8,
    char_15: 'Robin',
    app_rate_15: '5.22%',
    avg_round_15: 24895,
    app_flat_15: 6,
    char_16: 'Gepard',
    app_rate_16: '4.85%',
    avg_round_16: 28464,
    app_flat_16: 9,
    char_17: 'Sampo',
    app_rate_17: '3.73%',
    avg_round_17: 21918,
    app_flat_17: 6,
    char_18: 'Sparkle',
    app_rate_18: '2.61%',
    avg_round_18: 21405,
    app_flat_18: 1,
    char_19: 'Firefly',
    app_rate_19: '1.87%',
    avg_round_19: 20214,
    app_flat_19: 1,
    char_20: 'Asta',
    app_rate_20: '1.87%',
    avg_round_20: 26410,
    app_flat_20: 1,
    char_21: 'Yunli',
    app_rate_21: '1.12%',
    avg_round_21: 26932,
    app_flat_21: 1,
    char_22: 'Herta',
    app_rate_22: '1.12%',
    avg_round_22: 20771,
    app_flat_22: 1,
    char_23: 'Rappa',
    app_rate_23: '0.75%',
    avg_round_23: 28158,
    app_flat_23: 2,
    char_24: 'Tingyun',
    app_rate_24: '0.75%',
    avg_round_24: 20914,
    app_flat_24: 1,
    char_25: 'march-7th',
    app_rate_25: '0.75%',
    avg_round_25: 35190,
    app_flat_25: 1,
    char_26: 'trailblazer-fire',
    app_rate_26: '0.37%',
    avg_round_26: 32080,
    app_flat_26: 1,
    char_27: 'Jade',
    app_rate_27: '0.37%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Blade',
    app_rate_28: '0.37%',
    avg_round_28: 24624,
    app_flat_28: 1,
    char_29: 'Bronya',
    app_rate_29: '0.37%',
    avg_round_29: 23289,
    app_flat_29: 1,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.37%',
    avg_round_30: 24755,
    app_flat_30: 1
  },
  {
    char: 'march-7th-swordmaster',
    app: 2.87,
    char_1: 'Robin',
    app_rate_1: '72.09%',
    avg_round_1: 27887,
    app_flat_1: 75,
    char_2: 'Feixiao',
    app_rate_2: '56.98%',
    avg_round_2: 27355,
    app_flat_2: 51,
    char_3: 'Aventurine',
    app_rate_3: '33.33%',
    avg_round_3: 27348,
    app_flat_3: 35,
    char_4: 'Himeko',
    app_rate_4: '22.48%',
    avg_round_4: 26859,
    app_flat_4: 42,
    char_5: 'Gallagher',
    app_rate_5: '16.28%',
    avg_round_5: 25724,
    app_flat_5: 23,
    char_6: 'ruan-mei',
    app_rate_6: '13.95%',
    avg_round_6: 27449,
    app_flat_6: 18,
    char_7: 'Lingsha',
    app_rate_7: '12.02%',
    avg_round_7: 28237,
    app_flat_7: 14,
    char_8: 'Yunli',
    app_rate_8: '10.08%',
    avg_round_8: 29071,
    app_flat_8: 13,
    char_9: 'Huohuo',
    app_rate_9: '9.69%',
    avg_round_9: 27643,
    app_flat_9: 12,
    char_10: 'trailblazer-imaginary',
    app_rate_10: '8.53%',
    avg_round_10: 22997,
    app_flat_10: 8,
    char_11: 'fu-xuan',
    app_rate_11: '6.98%',
    avg_round_11: 29146,
    app_flat_11: 6,
    char_12: 'Bronya',
    app_rate_12: '5.43%',
    avg_round_12: 28165,
    app_flat_12: 7,
    char_13: 'Luocha',
    app_rate_13: '4.65%',
    avg_round_13: 24623,
    app_flat_13: 5,
    char_14: 'Acheron',
    app_rate_14: '4.26%',
    avg_round_14: 29126,
    app_flat_14: 6,
    char_15: 'Sparkle',
    app_rate_15: '3.88%',
    avg_round_15: 25067,
    app_flat_15: 2,
    char_16: 'Clara',
    app_rate_16: '3.49%',
    avg_round_16: 26916,
    app_flat_16: 1,
    char_17: 'Herta',
    app_rate_17: '3.1%',
    avg_round_17: 25760,
    app_flat_17: 2,
    char_18: 'Firefly',
    app_rate_18: '2.33%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'topaz',
    app_rate_19: '1.16%',
    avg_round_19: 23776,
    app_flat_19: 2,
    char_20: 'Blade',
    app_rate_20: '1.16%',
    avg_round_20: 25650,
    app_flat_20: 2,
    char_21: 'Lynx',
    app_rate_21: '1.16%',
    avg_round_21: 22597,
    app_flat_21: 1,
    char_22: 'black-swan',
    app_rate_22: '0.78%',
    avg_round_22: 33654,
    app_flat_22: 1,
    char_23: 'Tingyun',
    app_rate_23: '0.78%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Natasha',
    app_rate_24: '0.78%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Jade',
    app_rate_25: '0.78%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Bailu',
    app_rate_26: '0.78%',
    avg_round_26: 23135,
    app_flat_26: 1,
    char_27: 'Asta',
    app_rate_27: '0.39%',
    avg_round_27: 23801,
    app_flat_27: 1,
    char_28: 'Kafka',
    app_rate_28: '0.39%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'silver-wolf',
    app_rate_29: '0.39%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Jiaoqiu',
    app_rate_30: '0.39%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    app: 2.52,
    char_1: 'Acheron',
    app_rate_1: '92.48%',
    avg_round_1: 33357,
    app_flat_1: 97,
    char_2: 'Kafka',
    app_rate_2: '51.33%',
    avg_round_2: 34755,
    app_flat_2: 62,
    char_3: 'black-swan',
    app_rate_3: '50.0%',
    avg_round_3: 35557,
    app_flat_3: 59,
    char_4: 'Pela',
    app_rate_4: '29.2%',
    avg_round_4: 30052,
    app_flat_4: 30,
    char_5: 'Jiaoqiu',
    app_rate_5: '19.47%',
    avg_round_5: 32977,
    app_flat_5: 19,
    char_6: 'Sparkle',
    app_rate_6: '15.04%',
    avg_round_6: 29080,
    app_flat_6: 5,
    char_7: 'Robin',
    app_rate_7: '7.96%',
    avg_round_7: 32838,
    app_flat_7: 5,
    char_8: 'Guinaifen',
    app_rate_8: '5.75%',
    avg_round_8: 28464,
    app_flat_8: 9,
    char_9: 'Bronya',
    app_rate_9: '5.75%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Herta',
    app_rate_10: '4.42%',
    avg_round_10: 32612,
    app_flat_10: 3,
    char_11: 'Sampo',
    app_rate_11: '4.42%',
    avg_round_11: 28106,
    app_flat_11: 4,
    char_12: 'ruan-mei',
    app_rate_12: '3.1%',
    avg_round_12: 26388,
    app_flat_12: 2,
    char_13: 'silver-wolf',
    app_rate_13: '2.65%',
    avg_round_13: 27819,
    app_flat_13: 3,
    char_14: 'Jade',
    app_rate_14: '1.33%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Tingyun',
    app_rate_15: '1.33%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Firefly',
    app_rate_16: '1.33%',
    avg_round_16: 20935,
    app_flat_16: 1,
    char_17: 'Himeko',
    app_rate_17: '0.88%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Feixiao',
    app_rate_18: '0.88%',
    avg_round_18: 31635,
    app_flat_18: 2,
    char_19: 'trailblazer-imaginary',
    app_rate_19: '0.44%',
    avg_round_19: 20935,
    app_flat_19: 1,
    char_20: 'Asta',
    app_rate_20: '0.44%',
    avg_round_20: 32928,
    app_flat_20: 1,
    char_21: 'jing-yuan',
    app_rate_21: '0.44%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Clara',
    app_rate_22: '0.44%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Lingsha',
    app_rate_23: '0.44%',
    avg_round_23: 28325,
    app_flat_23: 1,
    char_24: 'march-7th',
    app_rate_24: '0.44%',
    avg_round_24: 27156,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    app: 2.25,
    char_1: 'Jade',
    app_rate_1: '43.07%',
    avg_round_1: 29174,
    app_flat_1: 37,
    char_2: 'Robin',
    app_rate_2: '40.59%',
    avg_round_2: 28964,
    app_flat_2: 27,
    char_3: 'Bronya',
    app_rate_3: '35.64%',
    avg_round_3: 26071,
    app_flat_3: 31,
    char_4: 'Luocha',
    app_rate_4: '26.24%',
    avg_round_4: 26226,
    app_flat_4: 17,
    char_5: 'Sparkle',
    app_rate_5: '20.3%',
    avg_round_5: 25234,
    app_flat_5: 18,
    char_6: 'fu-xuan',
    app_rate_6: '17.33%',
    avg_round_6: 28121,
    app_flat_6: 12,
    char_7: 'Huohuo',
    app_rate_7: '16.34%',
    avg_round_7: 28891,
    app_flat_7: 13,
    char_8: 'ruan-mei',
    app_rate_8: '12.38%',
    avg_round_8: 24958,
    app_flat_8: 8,
    char_9: 'Gallagher',
    app_rate_9: '9.9%',
    avg_round_9: 25740,
    app_flat_9: 12,
    char_10: 'Acheron',
    app_rate_10: '9.41%',
    avg_round_10: 28280,
    app_flat_10: 7,
    char_11: 'Himeko',
    app_rate_11: '8.42%',
    avg_round_11: 27349,
    app_flat_11: 5,
    char_12: 'Jiaoqiu',
    app_rate_12: '7.92%',
    avg_round_12: 28484,
    app_flat_12: 6,
    char_13: 'black-swan',
    app_rate_13: '5.45%',
    avg_round_13: 30008,
    app_flat_13: 3,
    char_14: 'Kafka',
    app_rate_14: '4.95%',
    avg_round_14: 26668,
    app_flat_14: 4,
    char_15: 'Lynx',
    app_rate_15: '4.95%',
    avg_round_15: 27645,
    app_flat_15: 3,
    char_16: 'Aventurine',
    app_rate_16: '4.95%',
    avg_round_16: 23992,
    app_flat_16: 2,
    char_17: 'Lingsha',
    app_rate_17: '4.46%',
    avg_round_17: 30395,
    app_flat_17: 3,
    char_18: 'Yunli',
    app_rate_18: '4.46%',
    avg_round_18: 28960,
    app_flat_18: 4,
    char_19: 'Feixiao',
    app_rate_19: '3.96%',
    avg_round_19: 26168,
    app_flat_19: 3,
    char_20: 'jing-yuan',
    app_rate_20: '2.97%',
    avg_round_20: 27792,
    app_flat_20: 2,
    char_21: 'Jingliu',
    app_rate_21: '2.48%',
    avg_round_21: 22361,
    app_flat_21: 1,
    char_22: 'Herta',
    app_rate_22: '2.48%',
    avg_round_22: 23750,
    app_flat_22: 2,
    char_23: 'Pela',
    app_rate_23: '1.98%',
    avg_round_23: 26624,
    app_flat_23: 2,
    char_24: 'Clara',
    app_rate_24: '1.98%',
    avg_round_24: 20204,
    app_flat_24: 2,
    char_25: 'Sampo',
    app_rate_25: '1.49%',
    avg_round_25: 23375,
    app_flat_25: 3,
    char_26: 'Argenti',
    app_rate_26: '1.49%',
    avg_round_26: 24564,
    app_flat_26: 2,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '1.49%',
    avg_round_27: 25650,
    app_flat_27: 2,
    char_28: 'Welt',
    app_rate_28: '0.5%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Serval',
    app_rate_29: '0.5%',
    avg_round_29: 20316,
    app_flat_29: 1,
    char_30: 'Firefly',
    app_rate_30: '0.5%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    app: 1.64,
    char_1: 'Robin',
    app_rate_1: '41.5%',
    avg_round_1: 25788,
    app_flat_1: 17,
    char_2: 'Tingyun',
    app_rate_2: '34.01%',
    avg_round_2: 24203,
    app_flat_2: 24,
    char_3: 'Aventurine',
    app_rate_3: '28.57%',
    avg_round_3: 28062,
    app_flat_3: 15,
    char_4: 'ruan-mei',
    app_rate_4: '27.89%',
    avg_round_4: 28317,
    app_flat_4: 16,
    char_5: 'Huohuo',
    app_rate_5: '27.21%',
    avg_round_5: 25236,
    app_flat_5: 15,
    char_6: 'Sparkle',
    app_rate_6: '25.17%',
    avg_round_6: 24201,
    app_flat_6: 18,
    char_7: 'Himeko',
    app_rate_7: '17.69%',
    avg_round_7: 27885,
    app_flat_7: 15,
    char_8: 'Gallagher',
    app_rate_8: '17.01%',
    avg_round_8: 27425,
    app_flat_8: 14,
    char_9: 'Jiaoqiu',
    app_rate_9: '15.65%',
    avg_round_9: 33087,
    app_flat_9: 3,
    char_10: 'Jade',
    app_rate_10: '10.88%',
    avg_round_10: 26956,
    app_flat_10: 4,
    char_11: 'fu-xuan',
    app_rate_11: '8.84%',
    avg_round_11: 23068,
    app_flat_11: 3,
    char_12: 'Luocha',
    app_rate_12: '7.48%',
    avg_round_12: 25808,
    app_flat_12: 6,
    char_13: 'Herta',
    app_rate_13: '6.8%',
    avg_round_13: 25518,
    app_flat_13: 3,
    char_14: 'Lingsha',
    app_rate_14: '4.76%',
    avg_round_14: 24983,
    app_flat_14: 6,
    char_15: 'Kafka',
    app_rate_15: '4.08%',
    avg_round_15: 33470,
    app_flat_15: 3,
    char_16: 'Bronya',
    app_rate_16: '4.08%',
    avg_round_16: 26924,
    app_flat_16: 3,
    char_17: 'black-swan',
    app_rate_17: '3.4%',
    avg_round_17: 31338,
    app_flat_17: 2,
    char_18: 'trailblazer-imaginary',
    app_rate_18: '2.72%',
    avg_round_18: 23648,
    app_flat_18: 3,
    char_19: 'Blade',
    app_rate_19: '2.04%',
    avg_round_19: 24564,
    app_flat_19: 2,
    char_20: 'Asta',
    app_rate_20: '1.36%',
    avg_round_20: 26482,
    app_flat_20: 1,
    char_21: 'Hanya',
    app_rate_21: '1.36%',
    avg_round_21: 22735,
    app_flat_21: 1,
    char_22: 'Feixiao',
    app_rate_22: '1.36%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Yunli',
    app_rate_23: '1.36%',
    avg_round_23: 25383,
    app_flat_23: 1,
    char_24: 'Sampo',
    app_rate_24: '1.36%',
    avg_round_24: 31570,
    app_flat_24: 1,
    char_25: 'Acheron',
    app_rate_25: '1.36%',
    avg_round_25: 34945,
    app_flat_25: 1,
    char_26: 'jing-yuan',
    app_rate_26: '0.68%',
    avg_round_26: 20000,
    app_flat_26: 1,
    char_27: 'Firefly',
    app_rate_27: '0.68%',
    avg_round_27: 22135,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.68%',
    avg_round_28: 21323,
    app_flat_28: 1,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    app: 1.37,
    char_1: 'Sparkle',
    app_rate_1: '54.47%',
    avg_round_1: 22345,
    app_flat_1: 18,
    char_2: 'Robin',
    app_rate_2: '46.34%',
    avg_round_2: 28161,
    app_flat_2: 17,
    char_3: 'Tingyun',
    app_rate_3: '33.33%',
    avg_round_3: 22059,
    app_flat_3: 16,
    char_4: 'Aventurine',
    app_rate_4: '30.08%',
    avg_round_4: 30276,
    app_flat_4: 15,
    char_5: 'fu-xuan',
    app_rate_5: '21.14%',
    avg_round_5: 22621,
    app_flat_5: 4,
    char_6: 'Huohuo',
    app_rate_6: '19.51%',
    avg_round_6: 22080,
    app_flat_6: 4,
    char_7: 'Herta',
    app_rate_7: '14.63%',
    avg_round_7: 29699,
    app_flat_7: 6,
    char_8: 'Luocha',
    app_rate_8: '11.38%',
    avg_round_8: 20893,
    app_flat_8: 3,
    char_9: 'Jiaoqiu',
    app_rate_9: '9.76%',
    avg_round_9: 25000,
    app_flat_9: 3,
    char_10: 'Jade',
    app_rate_10: '7.32%',
    avg_round_10: 27032,
    app_flat_10: 3,
    char_11: 'Gallagher',
    app_rate_11: '6.5%',
    avg_round_11: 22910,
    app_flat_11: 5,
    char_12: 'Feixiao',
    app_rate_12: '5.69%',
    avg_round_12: 30058,
    app_flat_12: 3,
    char_13: 'Blade',
    app_rate_13: '4.88%',
    avg_round_13: 27792,
    app_flat_13: 2,
    char_14: 'Bronya',
    app_rate_14: '4.07%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Yunli',
    app_rate_15: '4.07%',
    avg_round_15: 37870,
    app_flat_15: 1,
    char_16: 'Acheron',
    app_rate_16: '4.07%',
    avg_round_16: 23504,
    app_flat_16: 2,
    char_17: 'black-swan',
    app_rate_17: '4.07%',
    avg_round_17: 37520,
    app_flat_17: 1,
    char_18: 'ruan-mei',
    app_rate_18: '4.07%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Clara',
    app_rate_19: '2.44%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Pela',
    app_rate_20: '2.44%',
    avg_round_20: 23771,
    app_flat_20: 2,
    char_21: 'Lingsha',
    app_rate_21: '2.44%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: '1.63%',
    avg_round_22: 26251,
    app_flat_22: 1,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.81%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Kafka',
    app_rate_24: '0.81%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Argenti',
    app_rate_25: '0.81%',
    avg_round_25: 20000,
    app_flat_25: 1,
    char_26: 'march-7th-swordmaster',
    app_rate_26: '0.81%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Sampo',
    app_rate_27: '0.81%',
    avg_round_27: 20949,
    app_flat_27: 1,
    char_28: 'Himeko',
    app_rate_28: '0.81%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Gepard',
    app_rate_29: '0.81%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    app: 1.23,
    char_1: 'Kafka',
    app_rate_1: '40.91%',
    avg_round_1: 29837,
    app_flat_1: 18,
    char_2: 'black-swan',
    app_rate_2: '36.36%',
    avg_round_2: 31742,
    app_flat_2: 14,
    char_3: 'Himeko',
    app_rate_3: '31.82%',
    avg_round_3: 26829,
    app_flat_3: 23,
    char_4: 'Gallagher',
    app_rate_4: '27.27%',
    avg_round_4: 26358,
    app_flat_4: 15,
    char_5: 'Aventurine',
    app_rate_5: '20.91%',
    avg_round_5: 31147,
    app_flat_5: 12,
    char_6: 'Huohuo',
    app_rate_6: '19.09%',
    avg_round_6: 28840,
    app_flat_6: 8,
    char_7: 'trailblazer-imaginary',
    app_rate_7: '12.73%',
    avg_round_7: 23371,
    app_flat_7: 7,
    char_8: 'ruan-mei',
    app_rate_8: '11.82%',
    avg_round_8: 29847,
    app_flat_8: 7,
    char_9: 'Acheron',
    app_rate_9: '9.09%',
    avg_round_9: 36103,
    app_flat_9: 3,
    char_10: 'Firefly',
    app_rate_10: '9.09%',
    avg_round_10: 28072,
    app_flat_10: 2,
    char_11: 'Jiaoqiu',
    app_rate_11: '8.18%',
    avg_round_11: 31675,
    app_flat_11: 6,
    char_12: 'Luocha',
    app_rate_12: '7.27%',
    avg_round_12: 29259,
    app_flat_12: 4,
    char_13: 'Tingyun',
    app_rate_13: '7.27%',
    avg_round_13: 26302,
    app_flat_13: 4,
    char_14: 'fu-xuan',
    app_rate_14: '7.27%',
    avg_round_14: 29186,
    app_flat_14: 3,
    char_15: 'Lingsha',
    app_rate_15: '6.36%',
    avg_round_15: 25803,
    app_flat_15: 3,
    char_16: 'Sampo',
    app_rate_16: '4.55%',
    avg_round_16: 21826,
    app_flat_16: 1,
    char_17: 'Sparkle',
    app_rate_17: '4.55%',
    avg_round_17: 25552,
    app_flat_17: 2,
    char_18: 'Guinaifen',
    app_rate_18: '4.55%',
    avg_round_18: 26410,
    app_flat_18: 1,
    char_19: 'Jade',
    app_rate_19: '3.64%',
    avg_round_19: 31684,
    app_flat_19: 2,
    char_20: 'Rappa',
    app_rate_20: '3.64%',
    avg_round_20: 24670,
    app_flat_20: 3,
    char_21: 'topaz',
    app_rate_21: '2.73%',
    avg_round_21: 26803,
    app_flat_21: 1,
    char_22: 'Argenti',
    app_rate_22: '1.82%',
    avg_round_22: 26482,
    app_flat_22: 1,
    char_23: 'jing-yuan',
    app_rate_23: '1.82%',
    avg_round_23: 26251,
    app_flat_23: 1,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.82%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: '1.82%',
    avg_round_25: 23259,
    app_flat_25: 1,
    char_26: 'Feixiao',
    app_rate_26: '1.82%',
    avg_round_26: 23714,
    app_flat_26: 1,
    char_27: 'Pela',
    app_rate_27: '1.82%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.91%',
    avg_round_28: 23801,
    app_flat_28: 1,
    char_29: 'Gepard',
    app_rate_29: '0.91%',
    avg_round_29: 32928,
    app_flat_29: 1,
    char_30: 'Seele',
    app_rate_30: '0.91%',
    avg_round_30: 29196,
    app_flat_30: 1
  },
  {
    char: 'silver-wolf',
    app: 1.12,
    char_1: 'Acheron',
    app_rate_1: '98.02%',
    avg_round_1: 28070,
    app_flat_1: 31,
    char_2: 'fu-xuan',
    app_rate_2: '39.6%',
    avg_round_2: 24035,
    app_flat_2: 3,
    char_3: 'Aventurine',
    app_rate_3: '37.62%',
    avg_round_3: 29725,
    app_flat_3: 20,
    char_4: 'Sparkle',
    app_rate_4: '29.7%',
    avg_round_4: 27891,
    app_flat_4: 1,
    char_5: 'Jiaoqiu',
    app_rate_5: '26.73%',
    avg_round_5: 31854,
    app_flat_5: 11,
    char_6: 'Pela',
    app_rate_6: '24.75%',
    avg_round_6: 25422,
    app_flat_6: 16,
    char_7: 'Gallagher',
    app_rate_7: '7.92%',
    avg_round_7: 20980,
    app_flat_7: 3,
    char_8: 'Gepard',
    app_rate_8: '5.94%',
    avg_round_8: 27819,
    app_flat_8: 3,
    char_9: 'Bronya',
    app_rate_9: '3.96%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'black-swan',
    app_rate_10: '3.96%',
    avg_round_10: 33915,
    app_flat_10: 1,
    char_11: 'Kafka',
    app_rate_11: '3.96%',
    avg_round_11: 24222,
    app_flat_11: 1,
    char_12: 'Huohuo',
    app_rate_12: '2.97%',
    avg_round_12: 24222,
    app_flat_12: 1,
    char_13: 'Lingsha',
    app_rate_13: '2.97%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Jade',
    app_rate_14: '1.98%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Luocha',
    app_rate_15: '1.98%',
    avg_round_15: 32965,
    app_flat_15: 1,
    char_16: 'Yunli',
    app_rate_16: '0.99%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '0.99%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Herta',
    app_rate_18: '0.99%',
    avg_round_18: 27000,
    app_flat_18: 1,
    char_19: 'Seele',
    app_rate_19: '0.99%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Tingyun',
    app_rate_20: '0.99%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Guinaifen',
    app_rate_21: '0.99%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Clara',
    app_rate_22: '0.99%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Robin',
    app_rate_23: '0.99%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 1.06,
    char_1: 'Sparkle',
    app_rate_1: '84.21%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Huohuo',
    app_rate_2: '41.05%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Tingyun',
    app_rate_3: '36.84%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: '35.79%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Robin',
    app_rate_5: '25.26%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Luocha',
    app_rate_6: '16.84%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Aventurine',
    app_rate_7: '13.68%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Gallagher',
    app_rate_8: '12.63%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Himeko',
    app_rate_9: '9.47%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Bronya',
    app_rate_10: '5.26%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: '5.26%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Jiaoqiu',
    app_rate_12: '4.21%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Herta',
    app_rate_13: '2.11%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Asta',
    app_rate_14: '2.11%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'trailblazer-imaginary',
    app_rate_15: '1.05%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'jing-yuan',
    app_rate_16: '1.05%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Feixiao',
    app_rate_17: '1.05%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Hanya',
    app_rate_18: '1.05%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jade',
    app_rate_19: '1.05%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    app: 0.89,
    char_1: 'Robin',
    app_rate_1: '40.0%',
    avg_round_1: 28520,
    app_flat_1: 17,
    char_2: 'Kafka',
    app_rate_2: '36.25%',
    avg_round_2: 33799,
    app_flat_2: 11,
    char_3: 'black-swan',
    app_rate_3: '35.0%',
    avg_round_3: 34491,
    app_flat_3: 12,
    char_4: 'Acheron',
    app_rate_4: '27.5%',
    avg_round_4: 33213,
    app_flat_4: 16,
    char_5: 'Yunli',
    app_rate_5: '27.5%',
    avg_round_5: 29728,
    app_flat_5: 15,
    char_6: 'Sparkle',
    app_rate_6: '18.75%',
    avg_round_6: 28596,
    app_flat_6: 6,
    char_7: 'Jiaoqiu',
    app_rate_7: '16.25%',
    avg_round_7: 33202,
    app_flat_7: 8,
    char_8: 'Tingyun',
    app_rate_8: '16.25%',
    avg_round_8: 26652,
    app_flat_8: 6,
    char_9: 'Blade',
    app_rate_9: '12.5%',
    avg_round_9: 27645,
    app_flat_9: 3,
    char_10: 'Jade',
    app_rate_10: '11.25%',
    avg_round_10: 30166,
    app_flat_10: 5,
    char_11: 'ruan-mei',
    app_rate_11: '11.25%',
    avg_round_11: 35675,
    app_flat_11: 4,
    char_12: 'Clara',
    app_rate_12: '6.25%',
    avg_round_12: 29207,
    app_flat_12: 4,
    char_13: 'Feixiao',
    app_rate_13: '6.25%',
    avg_round_13: 27795,
    app_flat_13: 4,
    char_14: 'Herta',
    app_rate_14: '6.25%',
    avg_round_14: 27899,
    app_flat_14: 3,
    char_15: 'Bronya',
    app_rate_15: '5.0%',
    avg_round_15: 24177,
    app_flat_15: 1,
    char_16: 'Sampo',
    app_rate_16: '3.75%',
    avg_round_16: 29785,
    app_flat_16: 1,
    char_17: 'Pela',
    app_rate_17: '3.75%',
    avg_round_17: 29355,
    app_flat_17: 1,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '3.75%',
    avg_round_18: 22597,
    app_flat_18: 1,
    char_19: 'Himeko',
    app_rate_19: '3.75%',
    avg_round_19: 28122,
    app_flat_19: 2,
    char_20: 'Serval',
    app_rate_20: '2.5%',
    avg_round_20: 28914,
    app_flat_20: 2,
    char_21: 'trailblazer-imaginary',
    app_rate_21: '2.5%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: '1.25%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Firefly',
    app_rate_23: '1.25%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Rappa',
    app_rate_24: '1.25%',
    avg_round_24: 35630,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    app: 0.87,
    char_1: 'black-swan',
    app_rate_1: '76.92%',
    avg_round_1: 31008,
    app_flat_1: 19,
    char_2: 'Kafka',
    app_rate_2: '75.64%',
    avg_round_2: 30617,
    app_flat_2: 19,
    char_3: 'Robin',
    app_rate_3: '29.49%',
    avg_round_3: 30328,
    app_flat_3: 9,
    char_4: 'Acheron',
    app_rate_4: '26.92%',
    avg_round_4: 31708,
    app_flat_4: 4,
    char_5: 'ruan-mei',
    app_rate_5: '19.23%',
    avg_round_5: 32501,
    app_flat_5: 6,
    char_6: 'Tingyun',
    app_rate_6: '11.54%',
    avg_round_6: 24675,
    app_flat_6: 5,
    char_7: 'Herta',
    app_rate_7: '6.41%',
    avg_round_7: 30188,
    app_flat_7: 2,
    char_8: 'Himeko',
    app_rate_8: '6.41%',
    avg_round_8: 25178,
    app_flat_8: 5,
    char_9: 'Jade',
    app_rate_9: '6.41%',
    avg_round_9: 30266,
    app_flat_9: 2,
    char_10: 'Sampo',
    app_rate_10: '6.41%',
    avg_round_10: 26962,
    app_flat_10: 2,
    char_11: 'Feixiao',
    app_rate_11: '5.13%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Sparkle',
    app_rate_12: '3.85%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Bronya',
    app_rate_13: '3.85%',
    avg_round_13: 28955,
    app_flat_13: 1,
    char_14: 'Jiaoqiu',
    app_rate_14: '3.85%',
    avg_round_14: 34700,
    app_flat_14: 1,
    char_15: 'Yunli',
    app_rate_15: '2.56%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Clara',
    app_rate_16: '2.56%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'trailblazer-imaginary',
    app_rate_17: '2.56%',
    avg_round_17: 23498,
    app_flat_17: 2,
    char_18: 'Asta',
    app_rate_18: '2.56%',
    avg_round_18: 23259,
    app_flat_18: 1,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '2.56%',
    avg_round_19: 23135,
    app_flat_19: 1,
    char_20: 'Serval',
    app_rate_20: '1.28%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Rappa',
    app_rate_21: '1.28%',
    avg_round_21: 22499,
    app_flat_21: 1,
    char_22: 'Welt',
    app_rate_22: '1.28%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Blade',
    app_rate_23: '1.28%',
    avg_round_23: 28955,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.56,
    char_1: 'Acheron',
    app_rate_1: '86.0%',
    avg_round_1: 33302,
    app_flat_1: 18,
    char_2: 'Pela',
    app_rate_2: '40.0%',
    avg_round_2: 30519,
    app_flat_2: 8,
    char_3: 'Robin',
    app_rate_3: '32.0%',
    avg_round_3: 31954,
    app_flat_3: 8,
    char_4: 'Herta',
    app_rate_4: '18.0%',
    avg_round_4: 32814,
    app_flat_4: 6,
    char_5: 'Kafka',
    app_rate_5: '16.0%',
    avg_round_5: 37595,
    app_flat_5: 2,
    char_6: 'Feixiao',
    app_rate_6: '14.0%',
    avg_round_6: 30995,
    app_flat_6: 3,
    char_7: 'black-swan',
    app_rate_7: '14.0%',
    avg_round_7: 40000,
    app_flat_7: 2,
    char_8: 'Clara',
    app_rate_8: '14.0%',
    avg_round_8: 32626,
    app_flat_8: 4,
    char_9: 'Sparkle',
    app_rate_9: '14.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Jiaoqiu',
    app_rate_10: '12.0%',
    avg_round_10: 36860,
    app_flat_10: 3,
    char_11: 'Yunli',
    app_rate_11: '10.0%',
    avg_round_11: 27606,
    app_flat_11: 2,
    char_12: 'Sampo',
    app_rate_12: '8.0%',
    avg_round_12: 31688,
    app_flat_12: 2,
    char_13: 'Aventurine',
    app_rate_13: '6.0%',
    avg_round_13: 35505,
    app_flat_13: 1,
    char_14: 'Bronya',
    app_rate_14: '4.0%',
    avg_round_14: 28775,
    app_flat_14: 1,
    char_15: 'Guinaifen',
    app_rate_15: '4.0%',
    avg_round_15: 35190,
    app_flat_15: 1,
    char_16: 'Gallagher',
    app_rate_16: '2.0%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Asta',
    app_rate_17: '2.0%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Hanya',
    app_rate_18: '2.0%',
    avg_round_18: 40000,
    app_flat_18: 1,
    char_19: 'Gepard',
    app_rate_19: '2.0%',
    avg_round_19: 27156,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    app: 0.48,
    char_1: 'black-swan',
    app_rate_1: '55.81%',
    avg_round_1: 33732,
    app_flat_1: 10,
    char_2: 'Kafka',
    app_rate_2: '30.23%',
    avg_round_2: 26626,
    app_flat_2: 5,
    char_3: 'Aventurine',
    app_rate_3: '27.91%',
    avg_round_3: 29486,
    app_flat_3: 6,
    char_4: 'Robin',
    app_rate_4: '27.91%',
    avg_round_4: 24788,
    app_flat_4: 5,
    char_5: 'Huohuo',
    app_rate_5: '20.93%',
    avg_round_5: 29804,
    app_flat_5: 4,
    char_6: 'Luocha',
    app_rate_6: '18.6%',
    avg_round_6: 36425,
    app_flat_6: 3,
    char_7: 'Jiaoqiu',
    app_rate_7: '11.63%',
    avg_round_7: 34309,
    app_flat_7: 5,
    char_8: 'Sampo',
    app_rate_8: '11.63%',
    avg_round_8: 22277,
    app_flat_8: 3,
    char_9: 'Tingyun',
    app_rate_9: '11.63%',
    avg_round_9: 22101,
    app_flat_9: 3,
    char_10: 'fu-xuan',
    app_rate_10: '9.3%',
    avg_round_10: 22376,
    app_flat_10: 2,
    char_11: 'ruan-mei',
    app_rate_11: '9.3%',
    avg_round_11: 27614,
    app_flat_11: 2,
    char_12: 'Sparkle',
    app_rate_12: '9.3%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Acheron',
    app_rate_13: '9.3%',
    avg_round_13: 31185,
    app_flat_13: 3,
    char_14: 'Herta',
    app_rate_14: '6.98%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Gallagher',
    app_rate_15: '4.65%',
    avg_round_15: 20000,
    app_flat_15: 1,
    char_16: 'Lingsha',
    app_rate_16: '4.65%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Bronya',
    app_rate_17: '4.65%',
    avg_round_17: 24177,
    app_flat_17: 1,
    char_18: 'Lynx',
    app_rate_18: '4.65%',
    avg_round_18: 28914,
    app_flat_18: 2,
    char_19: 'Pela',
    app_rate_19: '4.65%',
    avg_round_19: 24680,
    app_flat_19: 1,
    char_20: 'Bailu',
    app_rate_20: '2.33%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: '2.33%',
    avg_round_21: 20073,
    app_flat_21: 1,
    char_22: 'Blade',
    app_rate_22: '2.33%',
    avg_round_22: 20316,
    app_flat_22: 1,
    char_23: 'Asta',
    app_rate_23: '2.33%',
    avg_round_23: 20000,
    app_flat_23: 1,
    char_24: 'Yunli',
    app_rate_24: '2.33%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Guinaifen',
    app_rate_25: '2.33%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Jade',
    app_rate_26: '2.33%',
    avg_round_26: 22125,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    app: 0.29,
    char_1: 'Sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 30732,
    app_flat_1: 3,
    char_2: 'Robin',
    app_rate_2: '57.69%',
    avg_round_2: 40000,
    app_flat_2: 1,
    char_3: 'Tingyun',
    app_rate_3: '57.69%',
    avg_round_3: 30732,
    app_flat_3: 3,
    char_4: 'fu-xuan',
    app_rate_4: '42.31%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Herta',
    app_rate_5: '11.54%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Bronya',
    app_rate_6: '7.69%',
    avg_round_6: 23000,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: '7.69%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Huohuo',
    app_rate_8: '7.69%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Asta',
    app_rate_9: '3.85%',
    avg_round_9: 29196,
    app_flat_9: 1,
    char_10: 'silver-wolf',
    app_rate_10: '3.85%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    app: 0.26,
    char_1: 'Acheron',
    app_rate_1: '60.87%',
    avg_round_1: 27287,
    app_flat_1: 8,
    char_2: 'ruan-mei',
    app_rate_2: '26.09%',
    avg_round_2: 23599,
    app_flat_2: 2,
    char_3: 'Aventurine',
    app_rate_3: '21.74%',
    avg_round_3: 29116,
    app_flat_3: 3,
    char_4: 'Jiaoqiu',
    app_rate_4: '21.74%',
    avg_round_4: 29127,
    app_flat_4: 5,
    char_5: 'Gallagher',
    app_rate_5: '21.74%',
    avg_round_5: 22904,
    app_flat_5: 2,
    char_6: 'Himeko',
    app_rate_6: '13.04%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'black-swan',
    app_rate_7: '13.04%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: '13.04%',
    avg_round_8: 24725,
    app_flat_8: 1,
    char_9: 'Pela',
    app_rate_9: '13.04%',
    avg_round_9: 24220,
    app_flat_9: 3,
    char_10: 'Robin',
    app_rate_10: '13.04%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Rappa',
    app_rate_11: '13.04%',
    avg_round_11: 22235,
    app_flat_11: 2,
    char_12: 'trailblazer-imaginary',
    app_rate_12: '13.04%',
    avg_round_12: 22235,
    app_flat_12: 2,
    char_13: 'Luocha',
    app_rate_13: '8.7%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Kafka',
    app_rate_14: '8.7%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Huohuo',
    app_rate_15: '8.7%',
    avg_round_15: 32475,
    app_flat_15: 1,
    char_16: 'Sparkle',
    app_rate_16: '8.7%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Lingsha',
    app_rate_17: '8.7%',
    avg_round_17: 25207,
    app_flat_17: 1,
    char_18: 'Blade',
    app_rate_18: '4.35%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Bailu',
    app_rate_19: '4.35%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Bronya',
    app_rate_20: '4.35%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Boothill',
    app: 0.22,
    char_1: 'ruan-mei',
    app_rate_1: '70.0%',
    avg_round_1: 24556,
    app_flat_1: 3,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '50.0%',
    avg_round_2: 24810,
    app_flat_2: 3,
    char_3: 'Gallagher',
    app_rate_3: '40.0%',
    avg_round_3: 29928,
    app_flat_3: 2,
    char_4: 'Rappa',
    app_rate_4: '35.0%',
    avg_round_4: 30310,
    app_flat_4: 1,
    char_5: 'Lingsha',
    app_rate_5: '30.0%',
    avg_round_5: 22060,
    app_flat_5: 2,
    char_6: 'Himeko',
    app_rate_6: '25.0%',
    avg_round_6: 29547,
    app_flat_6: 1,
    char_7: 'Firefly',
    app_rate_7: '10.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Aventurine',
    app_rate_8: '10.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Luocha',
    app_rate_9: '5.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Sparkle',
    app_rate_10: '5.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Jingliu',
    app_rate_11: '5.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Bronya',
    app_rate_12: '5.0%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Huohuo',
    app_rate_13: '5.0%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Robin',
    app_rate_14: '5.0%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    app: 0.12,
    char_1: 'Acheron',
    app_rate_1: '81.82%',
    avg_round_1: 29544,
    app_flat_1: 3,
    char_2: 'Pela',
    app_rate_2: '36.36%',
    avg_round_2: 28900,
    app_flat_2: 1,
    char_3: 'black-swan',
    app_rate_3: '36.36%',
    avg_round_3: 29866,
    app_flat_3: 2,
    char_4: 'Sparkle',
    app_rate_4: '27.27%',
    avg_round_4: 27651,
    app_flat_4: 1,
    char_5: 'Bronya',
    app_rate_5: '18.18%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Kafka',
    app_rate_6: '18.18%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Himeko',
    app_rate_7: '18.18%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '18.18%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Guinaifen',
    app_rate_9: '9.09%',
    avg_round_9: 32080,
    app_flat_9: 1,
    char_10: 'Sampo',
    app_rate_10: '9.09%',
    avg_round_10: 28900,
    app_flat_10: 1,
    char_11: 'Hanya',
    app_rate_11: '9.09%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Firefly',
    app_rate_12: '9.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Asta',
    app_rate_13: '9.09%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    app: 0.11,
    char_1: 'Blade',
    app_rate_1: '50.0%',
    avg_round_1: 22361,
    app_flat_1: 1,
    char_2: 'Bronya',
    app_rate_2: '50.0%',
    avg_round_2: 22361,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Robin',
    app_rate_4: '40.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Aventurine',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Herta',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Luocha',
    app_rate_7: '20.0%',
    avg_round_7: 22361,
    app_flat_7: 1,
    char_8: 'Huohuo',
    app_rate_8: '20.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'fu-xuan',
    app_rate_9: '10.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Boothill',
    app_rate_10: '10.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Gallagher',
    app_rate_11: '10.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.08,
    char_1: 'Robin',
    app_rate_1: '57.14%',
    avg_round_1: 20035,
    app_flat_1: 1,
    char_2: 'Aventurine',
    app_rate_2: '57.14%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Himeko',
    app_rate_3: '42.86%',
    avg_round_3: 20035,
    app_flat_3: 1,
    char_4: 'topaz',
    app_rate_4: '28.57%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Jiaoqiu',
    app_rate_5: '28.57%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Luocha',
    app_rate_6: '14.29%',
    avg_round_6: 20035,
    app_flat_6: 1,
    char_7: 'march-7th-swordmaster',
    app_rate_7: '14.29%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Natasha',
    app_rate_8: '14.29%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Clara',
    app_rate_9: '14.29%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Feixiao',
    app_rate_10: '14.29%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Lingsha',
    app_rate_11: '14.29%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    app: 0.08,
    char_1: 'Tingyun',
    app_rate_1: '42.86%',
    avg_round_1: 22735,
    app_flat_1: 1,
    char_2: 'Acheron',
    app_rate_2: '42.86%',
    avg_round_2: 40000,
    app_flat_2: 1,
    char_3: 'Argenti',
    app_rate_3: '28.57%',
    avg_round_3: 22735,
    app_flat_3: 1,
    char_4: 'Huohuo',
    app_rate_4: '28.57%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Pela',
    app_rate_5: '28.57%',
    avg_round_5: 40000,
    app_flat_5: 1,
    char_6: 'Sparkle',
    app_rate_6: '14.29%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'imbibitor-lunae',
    app_rate_7: '14.29%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'black-swan',
    app_rate_8: '14.29%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'trailblazer-fire',
    app_rate_9: '14.29%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Aventurine',
    app_rate_10: '14.29%',
    avg_round_10: 22735,
    app_flat_10: 1,
    char_11: 'fu-xuan',
    app_rate_11: '14.29%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'march-7th',
    app_rate_12: '14.29%',
    avg_round_12: 40000,
    app_flat_12: 1,
    char_13: 'Yunli',
    app_rate_13: '14.29%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Robin',
    app_rate_14: '14.29%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    app: 0.08,
    char_1: 'ruan-mei',
    app_rate_1: '42.86%',
    avg_round_1: 21875,
    app_flat_1: 1,
    char_2: 'trailblazer-imaginary',
    app_rate_2: '42.86%',
    avg_round_2: 22702,
    app_flat_2: 2,
    char_3: 'Himeko',
    app_rate_3: '42.86%',
    avg_round_3: 23529,
    app_flat_3: 1,
    char_4: 'Kafka',
    app_rate_4: '28.57%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Firefly',
    app_rate_5: '28.57%',
    avg_round_5: 22702,
    app_flat_5: 2,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '28.57%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'black-swan',
    app_rate_7: '14.29%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'dr-ratio',
    app_rate_8: '14.29%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Pela',
    app_rate_9: '14.29%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Tingyun',
    app_rate_10: '14.29%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Clara',
    app_rate_11: '14.29%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Robin',
    app_rate_12: '14.29%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Moze',
    app: 0.03,
    char_1: 'Feixiao',
    app_rate_1: '66.67%',
    avg_round_1: 25620,
    app_flat_1: 1,
    char_2: 'Robin',
    app_rate_2: '66.67%',
    avg_round_2: 25620,
    app_flat_2: 1,
    char_3: 'Kafka',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'black-swan',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Huohuo',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Aventurine',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Yunli',
    app_rate_7: '33.33%',
    avg_round_7: 25620,
    app_flat_7: 1,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    app: 0.02,
    char_1: 'Sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'fu-xuan',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Herta',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Yunli',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    app: 0.02,
    char_1: 'Acheron',
    app_rate_1: '50.0%',
    avg_round_1: 24983,
    app_flat_1: 1,
    char_2: 'Jiaoqiu',
    app_rate_2: '50.0%',
    avg_round_2: 24983,
    app_flat_2: 1,
    char_3: 'Gallagher',
    app_rate_3: '50.0%',
    avg_round_3: 24983,
    app_flat_3: 1,
    char_4: 'Kafka',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'black-swan',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Aventurine',
    app_rate_6: '50.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanqing',
    app: 0.01,
    char_1: 'Kafka',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'black-swan',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Luocha',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    app: 0.01,
    char_1: 'Jade',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Herta',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Gallagher',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];

import React from 'react';
import { Table } from 'react-bootstrap';

export const ProfileLingshaTable: React.FC = () => {
  return (
    <>
      <div className="info-box with-margin">
        <p>
          <strong>Disclaimers for the calcs below</strong>
        </p>
        <ul>
          <li>
            Calculations are structured to allow Gallagher to perform at peak
            performance not requiring him to use any Skills. In the event you
            need to use a Skill, his damage, toughness, hits, and Skill Point
            generation will decrease.
          </li>
          <li>
            Lingsha despite 'performing' better than Gallagher in the categories
            below is unable to be played smoothly in some teams due to her Skill
            Point restrictions - so keep that in mind.
          </li>
        </ul>
      </div>
      <p>
        E0-E6 Lingsha and E6 Gallagher performance against a{' '}
        <strong>single enemy</strong>. Both are equipped with the same Relics
        (Cavalry + Forge) and use S5 QPQ Light Cone.{' '}
      </p>
      <Table bordered responsive striped className="colored-table">
        <thead>
          <tr>
            <th>Character</th>
            <th>Damage (+HMC)</th>
            <th>SP</th>
            <th>Toughness DMG</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Gallagher E6</strong>
            </td>
            <td>466,419</td>
            <td>+18</td>
            <td>43.8</td>
            <td>18</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E0</strong>
            </td>
            <td>597,483</td>
            <td>+5</td>
            <td>51.0</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E1</strong>
            </td>
            <td>961,159</td>
            <td>+5</td>
            <td>76.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E2</strong>
            </td>
            <td>1,061,533</td>
            <td>+5</td>
            <td>76.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E3</strong>
            </td>
            <td>1,075,321</td>
            <td>+5</td>
            <td>76.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E4</strong>
            </td>
            <td>1,075,321</td>
            <td>+5</td>
            <td>76.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E5</strong>
            </td>
            <td>1,076,936</td>
            <td>+5</td>
            <td>76.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E6</strong>
            </td>
            <td>2,050,642</td>
            <td>+5</td>
            <td>121.5</td>
            <td>13</td>
          </tr>
        </tbody>
      </Table>
      <p>
        E0-E6 Lingsha and E6 Gallagher performance against{' '}
        <strong>three enemies</strong>. Both are equipped with the same Relics
        (Cavalry + Forge) and use S5 QPQ Light Cone.{' '}
      </p>
      <Table bordered responsive striped className="colored-table">
        <thead>
          <tr>
            <th>Character</th>
            <th>Damage (+HMC)</th>
            <th>SP</th>
            <th>Toughness DMG</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Gallagher E6</strong>
            </td>
            <td>614,660</td>
            <td>+18</td>
            <td>65.7</td>
            <td>18</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E0</strong>
            </td>
            <td>1,088,175</td>
            <td>+5</td>
            <td>105.0</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E1</strong>
            </td>
            <td>1,744,434</td>
            <td>+5</td>
            <td>157.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E2</strong>
            </td>
            <td>1,925,255</td>
            <td>+5</td>
            <td>157.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E3</strong>
            </td>
            <td>1,951,554</td>
            <td>+5</td>
            <td>157.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E4</strong>
            </td>
            <td>1,951,554</td>
            <td>+5</td>
            <td>157.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E5</strong>
            </td>
            <td>1,954,017</td>
            <td>+5</td>
            <td>157.5</td>
            <td>13</td>
          </tr>
          <tr>
            <td>
              <strong>Lingsha E6</strong>
            </td>
            <td>3,014,306</td>
            <td>+5</td>
            <td>202.5</td>
            <td>13</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
